<template>
  <b-modal ref="blogStateModal" hide-footer :title="modalTitle" centered lazy scrollable @hide="hideModal">
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div v-for="item in [1, 2, 3]" :key="item" class="d-flex pt-2 px-1">
          <div class="avatar avatar-sm rounded-circle o-hidden border">
            <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
          </div>
          <div class="w-100 ml-2">
            <b-skeleton width="60%"></b-skeleton>
            <b-skeleton width="80%"></b-skeleton>
          </div>
        </div>
      </template>

      <i v-if="!states.length">No states yet.</i>
      <div v-for="st in states" :key="st.id" class="d-flex event-mod-item pt-2 px-1" v-else>
        <div class="avatar avatar-sm">
          <img :src="st.image_url || DefaultImg" alt="Avatar" class="avatar-img rounded-circle" />
        </div>
        <div class="d-flex align-items-center">
          <strong class="ml-2">{{ st.name }} </strong>
          <!-- <p class="ml-2 text-muted text-break">{{ st.description }}</p> -->
        </div>
      </div>
    </b-skeleton-wrapper>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { get } from 'lodash';

import DefaultImg from '@/assets/images/placeholders/cna_career.jpg';

export default {
  name: 'StateBlogModal',

  components: {},

  props: {
    showModal: { type: Boolean, default: false },
    blog: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      DefaultImg,

      modalTitle: '',
      states: [],
      isLoading: false,
    };
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.modalTitle = `States for "${this.blog.title}"`;
        this.isLoading = true;
        this.$refs.blogStateModal.show();
        if (this.blog) {
          this.fetchBlogStates();
        }
      }
    },
  },

  methods: {
    ...mapActions('blog', ['getBlogStates']),
    get,

    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.blogStateModal.hide();
      this.hideModal();
    },

    async fetchBlogStates() {
      this.isLoading = true;

      this.states = await this.getBlogStates(this.blog.id);
      this.isLoading = false;
    },
  },

  async mounted() {},
};
</script>
