<template>
  <div>
    <div :class="pending ? '' : 'page-section'">
      <div :class="pending ? '' : containerClass">
        <h3 v-if="!pending" class="text-center">
          LET'S GET YOU ON THE PATH TO SUCCESS
          <i
            class="material-icons icon-16pt clickable-item"
            v-b-popover.hover.top="`Guide of all major functionalities of transition employer portal.`"
            >info</i
          >
        </h3>

        <div v-if="pending ? pendingGuides.length : setupGuideData.length" class="card">
          <div class="card-body">
            <div v-for="(guide, index) in pending ? pendingGuides : setupGuideData" :key="guide.id">
              <guide-row
                v-if="!pending || pending !== guide.status"
                :title="guide.title"
                :description="guide.description"
                :status="guide.status"
                :isLoading="isLoading"
                :redirect-to="guide.redirectTo"
                :icon="guide.icon"
                :btn-text="guide.btnText"
              />
              <hr v-if="index + 1 < (pending ? pendingGuides.length : setupGuideData.length)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import GuideRow from './GuideRow.vue';
import Page from '../../../components/Page.vue';

export default {
  components: { GuideRow },
  extends: Page,
  name: 'EmployerSetupGuide',
  props: { pending: { type: Boolean, default: false }, pendingCount: { type: Number, default: () => 3 } },

  data() {
    return {
      title: 'Setup Guide',
      isLoading: false,

      setupStatuses: {
        partnership_exists: false,
        scholarship_exists: false,
        job_exists: false,
        token_purchased: false,
        unlocked_students: false,
        hire_exists: false,
        scholarship_ques_exists: false,
      },
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Setup Guide', active: true },
      ];
    },
    pendingGuides() {
      const pendingGuides = this.setupGuideData.filter((guide) => this.pending !== guide.status);
      const showGuides = this.setupGuideData
        .filter((guide) => this.pending !== guide.status)
        .slice(0, this.pendingCount);

      this.$emit('pendingGuides', pendingGuides.length - this.pendingCount);
      return showGuides;
    },
    setupGuideData() {
      return [
        {
          id: 1,
          title: 'Establish a Partnership',
          description:
            'Partner directly with local training providers and universities to unlock a pipeline of skilled healthcare students.',
          status: this.setupStatuses.partnership_exists,
          redirectTo: 'emp-schools-list',
          icon: 'business',
          btnText: 'Establish',
        },
        {
          id: 2,
          title: 'Offer a Sponsorship',
          description: `Present unique sponsorship opportunities to candidates enrolling in our partner institutions, enhancing your company's visibility and reputation.`,
          status: this.setupStatuses.scholarship_exists,
          redirectTo: 'scholarships-management-list',
          icon: 'stars',
          btnText: 'Offer',
        },
        {
          id: 7,
          title: 'Add Application Questions',
          description:
            'Integrate qualifying questions for candidates interested in your opportunities, ensuring an effective screening process.',
          status: this.setupStatuses.scholarship_ques_exists,
          redirectTo: 'emp-manage-questions',
          icon: 'question_answer',
          btnText: 'Add',
        },
        {
          id: 3,
          title: 'List a Job',
          description:
            'Display your job openings on our platform, reaching a broad spectrum of potential candidates with diverse skills and backgrounds.',
          status: this.setupStatuses.job_exists,
          redirectTo: 'jobs-management-list',
          icon: 'work',
          btnText: 'List',
        },
        {
          id: 4,
          title: 'Add Tokens',
          description:
            'Use Tokens to unveil premium features, optimizing your user experience and interaction with potential hires.',
          status: this.setupStatuses.token_purchased,
          redirectTo: 'manage-purchase-tokens',
          btnText: 'Add',
        },
        {
          id: 5,
          title: 'Unlock a Candidate',
          description:
            'Use our platform to gain access to comprehensive profiles of promising candidates, refining your search for the perfect fit.',
          status: this.setupStatuses.unlocked_students,
          redirectTo: 'employer-candidates-list',
          icon: 'school',
          btnText: 'Unlock',
        },
        {
          id: 6,
          title: 'Hire a Candidate',
          description:
            'Streamline your hiring process by selecting, interviewing, and hiring top-tier candidates directly from our platform. Boost your workforce efficiency and quality with Transition.',
          status: this.setupStatuses.hire_exists,
          redirectTo: 'hires',
          icon: 'how_to_reg',
          btnText: 'Hire',
        },
      ];
    },
  },

  methods: {
    ...mapActions('employer', ['getEmpSetupGuideStatuses']),

    async fetchSetupGuideStatus() {
      this.isLoading = true;
      try {
        this.setupStatuses = (await this.getEmpSetupGuideStatuses()).data;
      } catch (_err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.fetchSetupGuideStatus();
  },
};
</script>
