<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      info-text="Manage all advisors associated with your organization."
    />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card bg-primary p-2" style="color: white">
          <div class="d-flex justify-content-center align-items-center p-2" style="font-size: 16px">
            <strong> Invite Advisors </strong>
          </div>

          <div class="p-2 mb-4">
            {{ inviteInfoText }}
          </div>
          <b-alert variant="soft-light px-12pt" show>
            <div class="d-flex align-items-center justify-content-between">
              <span class="text-black-100"
                ><strong>{{ inviteURL }}</strong></span
              >

              <span v-if="isUrlCopied" class="text-success"><md-icon>check</md-icon> Copied</span>
              <a href="#" @click.prevent="copyInviteUrl" v-else v-b-popover.hover.top="'Copy Invite Link'">
                <md-icon>content_copy</md-icon>
              </a>
            </div>
          </b-alert>
        </div>
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input class="w-lg-auto" placeholder="Search advisor" v-model="searchTerm" @input="onSearch" />

                  <b-btn variant="flush" type="submit">
                    <md-icon>search</md-icon>
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-6 mx-0 px-0"></div>

                <b-btn
                  class="ml-2"
                  :to="{
                    name:
                      get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN ? 'sa-add-advisor' : 'add-org-advisor',
                  }"
                  exact
                  variant="primary"
                  >Add Advisor</b-btn
                >
              </div>
            </div>
          </div>

          <b-table
            :fields="tableColumns"
            :items="allAdvisors"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap sticky-lcol-table"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
            @row-clicked="editAdvisor"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #empty>
              <div class="text-center">No advisors found.</div>
            </template>

            <template #cell(logo)="data">
              <user-avatar slot="aside" size="md" :user="data.item" url-field="image"> </user-avatar>
            </template>

            <template #cell(first_name)="data"> {{ data.item.first_name }} {{ data.item.last_name }}</template>

            <template #cell(phone)="data"> {{ data.item.phone }}</template>
            <template #cell(email)="data"> {{ data.item.email }}</template>

            <template #cell(status)="data">
              <b-badge
                :class="
                  'border border-' + (data.item.status === 'active' ? `success text-success` : `danger text-danger`)
                "
                variant="none"
                >{{ data.item.status === 'active' ? 'Active' : 'Inactive' }}</b-badge
              >
            </template>

            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <router-link
                :to="{
                  name:
                    get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN ? 'sa-edit-advisor' : 'edit-org-advisor',
                  params: { id: data.item.id },
                }"
                class="svg-icon mr-2"
                v-b-popover.hover.right
                :title="'Edit'"
              >
                <i class="mr-1 material-icons">edit</i>
              </router-link>
              <a href="#" @click.prevent="removeAdvisor(data.item.id)" v-b-popover.hover.right :title="'Remove'">
                <i class="material-icons text-danger">delete</i>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination v-model="currentPage" :total-rows="totalAdvisors" :per-page="perPage" @change="onPageChange" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import MdIcon from '@/components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '@/common/constants';
import UserAvatar from '@/components/User/UserAvatar.vue';
import { USER_ROLES } from '@/common/constants';

// TODO: add this import when created_at field is needed
// import { formatFullDate } from '@/common/utils';

export default {
  components: { MdIcon, PageHeader, Pagination, UserAvatar },
  extends: Page,

  data() {
    return {
      title: 'Manage Advisors',
      USER_ROLES,

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalAdvisors: 0,
      searchTerm: '',
      allAdvisors: [],
      isUrlCopied: false,
      inviteId: '',
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Advisors', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'logo', label: '', tdClass: 'clickable-item', thStyle: { width: '60px' } },

        { key: 'first_name', label: 'Name', tdClass: 'clickable-item' },
        { key: 'email', label: 'Email', tdClass: 'clickable-item' },
        { key: 'phone', label: 'Phone', tdClass: 'clickable-item' },
        { key: 'status', label: 'Status', tdClass: 'clickable-item' },

        ...(get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN
          ? [
              {
                key: 'advisor_association',
                label: 'Association',
                tdClass: 'clickable-item',
                formatter: (value) => (value === 'university' ? 'University' : 'Organization'),
              },
            ]
          : []),

        // TODO: add this field if needed
        // {
        //   key: 'created_at',
        //   label: 'Added On',
        //   tdClass: 'clickable-item',
        //   formatter: (value) => (value ? formatFullDate(value) : value),
        // },

        { key: 'actions', tdClass: 'text-right', thStyle: { width: '70px' } },
      ];
    },

    inviteURL() {
      const invId = get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN ? 'SdwT4rYq' : this.inviteId;
      return `${this.getClickableLink(process.env.VUE_APP_MAIN_DOMAIN)}/advisor-onboarding/${invId}`;
    },

    inviteInfoText() {
      if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
        return `Use your invite link below to let your advisors fill their data themselves (only for the advisors associated with universities). Showcase the advisors on CareCampus after reviewing their filled form.`;
      } else {
        return `Use your invite link below to let your advisors fill their data themselves. Showcase the advisors on your site after reviewing their filled form.`;
      }
    },
  },

  methods: {
    get,
    ...mapActions('advisors', ['getAllAdvisors', 'deleteAdvisor', 'getOrgAdvisorInviteId']),

    async copyInviteUrl() {
      try {
        await navigator.clipboard.writeText(this.inviteURL);
        this.isUrlCopied = true;

        setTimeout(() => (this.isUrlCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },

    async fetchOrgAdvisorId() {
      const response = await this.getOrgAdvisorInviteId();
      this.inviteId = response.data.invite_id;
    },

    async fetchAdvisors(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllAdvisors({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.allAdvisors = response.data.results;
      this.currentPage = pageNum;
      this.totalAdvisors = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchAdvisors(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchAdvisors();
    },

    editAdvisor(data) {
      if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
        this.$router.push({ name: 'sa-edit-advisor', params: { id: data.id } });
      } else {
        this.$router.push({ name: 'edit-org-advisor', params: { id: data.id } });
      }
    },

    onSearch() {
      this.debouncedSearchAdvisors(this);
    },

    debouncedSearchAdvisors: debounce((vm) => {
      vm.fetchAdvisors();
    }, 500),

    async removeAdvisor(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to remove the selected Advisor. The Advisor will no longer appear on your portal.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          this.isLoading = true;
          await this.deleteAdvisor(id);
          this.fetchAdvisors();
          this.makeToast({ variant: 'success', msg: 'Advisor removed successfully!' });
          this.isLoading = false;
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        this.isLoading = false;
      }
    },

    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://')
        ? link
        : `${process.env.VUE_APP_DOMAIN_PREFIX || 'https'}://${link}`;
    },
  },

  async mounted() {
    this.fetchAdvisors();
    this.fetchOrgAdvisorId();
  },
};
</script>
