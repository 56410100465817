<template>
  <div data-perfect-scrollbar>
    <b-skeleton-wrapper :loading="isLoading" v-if="isLoading">
      <template #loading>
        <div class="page-section pb10 student-page">
          <div
            class="container page__container d-flex align-items-center"
            v-if="getLoggedInUser.role !== USER_ROLES.STUDENT"
          >
            <p class="backResult text-50">
              <router-link :to="{ name: 'classes-management-list' }" style="text-decoration: underline">
                <span class="material-icons icon-18pt text-80">navigate_before</span>
              </router-link>
            </p>
          </div>

          <div
            class="container page__container d-flex flex-column flex-md-row align-items-center text-center text-md-left"
          >
            <b-col md="2">
              <b-skeleton-img height="113px" no-aspect></b-skeleton-img>
            </b-col>
            <div class="flex mb-32pt mb-md-0 mobilemb0">
              <h4 class="mb-0"><b-skeleton width="60%" height="1.5rem"></b-skeleton></h4>

              <b-row>
                <b-col md="2">
                  <b-skeleton type="button" width="100%"></b-skeleton>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>

        <div class="container page__container page-section student-card mt-0 pt-0 mb-32pt">
          <div class="card" v-for="i in [1, 2, 3]" :key="i">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="mb-1">
                    <h5 class="card-title fw600"><b-skeleton width="50%"></b-skeleton></h5>
                  </div>

                  <h6>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="60%"></b-skeleton>
                  </h6>

                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-skeleton-wrapper>

    <div v-else>
      <div class="page-section pb10 student-page">
        <div class="container page__container d-flex align-items-center">
          <p class="backResult text-50">
            <a @click.prevent="goBack" class="clickable-item" style="text-decoration: underline">
              <span class="material-icons icon-18pt text-80">navigate_before</span>
            </a>
          </p>
        </div>

        <div
          class="container page__container d-flex flex-column flex-md-row align-items-center text-center text-md-left"
        >
          <!-- Old avatar image style (use if needed) -->
          <img
            :src="
              get(student.user, 'avatar_url') || student.user.avatar_url.length
                ? student.user.avatar_url
                : DefaultAvatar
            "
            width="115"
            class="mr-md-32pt mb-32pt mb-md-0 rounded mobilemb10"
          />
          <!-- <user-avatar
            size="7.3rem"
            :user="student.user"
            rounded
            hide-text
            class="mr-md-32pt mb-32pt mb-md-0 mobilemb10"
          /> -->

          <div class="flex mb-32pt mb-md-0 mobilemb0">
            <h4 style="margin-bottom: 0rem">{{ student.user.first_name }} {{ student.user.last_name }} Application</h4>
            <div>Applied on: {{ formatDateTime(application.created_at) }}</div>
            <!-- <div class="d-flex justify-content-between"> -->
            <!-- <b-dropdown text="Contact" variant="primary" class="mt-4">
              <b-dropdown-item :href="`mailto:${student.user.email}`"
                ><i class="material-icons mr-1">email</i>Email</b-dropdown-item
              >
              <b-dropdown-item :href="`tel:${student.user.phone}`"
                ><i class="material-icons mr-1">phone</i>Call</b-dropdown-item
              >
            </b-dropdown> -->
            <span
              v-b-popover.hover.top
              :title="student.show_profile ? '' : `This student hasn't completed its profile yet.`"
            >
              <b-btn
                href="#"
                :disabled="!student.show_profile"
                variant="secondary"
                class="btn-normal mt-4 ml-2"
                @click.prevent="openStdProfile(student.id)"
                >Show Profile</b-btn
              >
            </span>

            <!-- </div> -->
          </div>
        </div>
      </div>

      <div class="container page__container page-section student-card mt-0 pt-0 mb-32pt">
        <div class="card" v-for="sec in application.app_question_response" :key="sec.id">
          <div class="card-body">
            <div class="d-flex">
              <div class="flex">
                <div class="d-flex align-items-center mb-1">
                  <h5 class="card-title fw600">{{ sec.section.title }}</h5>
                </div>

                <i v-if="!sec.data.length">No questions in the section.</i>

                <b-form-group
                  :label="ques.title"
                  label-class="form-label"
                  v-for="(ques, index) in sec.data"
                  :key="index"
                  v-else
                >
                  <b-form-input
                    :placeholder="ques.placeholder"
                    v-if="ques.type === QUES_TYPES.SHORT_TEXT"
                    :value="ques.response.data"
                    disabled
                  />
                  <b-form-textarea
                    v-else-if="ques.type === QUES_TYPES.LONG_TEXT"
                    class="form-control"
                    rows="10"
                    :placeholder="ques.placeholder"
                    :value="ques.response.data"
                    disabled
                  ></b-form-textarea>
                  <v-select
                    v-else-if="ques.type === QUES_TYPES.SINGLE_SELECT"
                    class="form-control v-select-custom"
                    label="text"
                    disabled
                    :value="ques.response.data"
                    :reduce="(ins) => ins.value"
                    :options="get(ques, 'extra.options', [])"
                    :placeholder="ques.placeholder"
                    :clearable="false"
                  >
                  </v-select>
                  <v-select
                    v-if="ques.type === QUES_TYPES.MULTI_SELECT"
                    class="form-control v-select-custom"
                    label="text"
                    :value="ques.response.data"
                    :reduce="(ins) => ins.value"
                    :options="get(ques, 'extra.options', [])"
                    :placeholder="ques.placeholder"
                    multiple
                    disabled
                    :clearable="false"
                  >
                  </v-select>
                  <div v-else-if="ques.type === QUES_TYPES.FILE_TYPE">
                    <a
                      v-if="ques.response.data"
                      href="#"
                      @click.prevent="
                        downloadGivenFile({
                          fileUrl: ques.response.data,
                          removeTimestamp: true,
                        })
                      "
                      v-b-popover.hover.top="'Download'"
                      ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(ques.response.data, true) }}
                    </a>
                    <i v-else>No file uploaded.</i>
                  </div>

                  <b-form-radio-group
                    v-else-if="ques.type === QUES_TYPES.BOOLEAN"
                    v-model="ques.response.data"
                    :options="get(ques, 'extra.options', YES_NO_OPTIONS)"
                    disabled
                  ></b-form-radio-group>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>

        <!-- Terms & Conditions Card -->
        <div class="card">
          <div class="card-header bg-light">
            <h5 class="my-0">Terms and Conditions</h5>
          </div>
          <div class="card-body">
            <b-form-group label-class="form-label">
              <b-form-checkbox v-model="termsAndConditions" name="check-button" disabled>
                <span style="font-weight: initial" class="text-black">
                  I understand and accept the
                  <a href="#" class="text-primary">terms and conditions</a>.
                </span>
              </b-form-checkbox>
            </b-form-group>

            <!-- <b-form-group label="Signature*" label-for="signature" label-class="form-label">
              <b-form-input disabled id="signature" placeholder="Type Full Name" :value="signature" />
            </b-form-group> -->

            <!-- <div class="text-dark" v-if="signature">Preview</div> -->
            <h1 class="signature-font">{{ signature }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';

import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';

import Page from '@/components/Page.vue';
import {
  formatFullDate,
  // getLangOptions,
  // getHumanReadableCommaSepStr,
  getWorkTravelOptions,
  formatDateTime,
  parseFileNameFromUrl,
  formatDateSimple,
} from '@/common/utils';
import DefaultAvatar from '@/assets/images/people/user_avatar.png';
import {
  USER_ROLES,
  USER_ROLE_TYPES,
  FACILITY_TYPES,
  ETHNICITY_OPTIONS,
  STATIC_FIELD_TYPE,
  GENDER_OPTIONS,
  QUES_TYPES,
  YES_NO_OPTIONS,
} from '@/common/constants';
// import UserAvatar from '@/components/User/UserAvatar.vue';

export default {
  components: {},
  extends: Page,
  name: 'ApplicationProfile',

  data() {
    return {
      DefaultAvatar,
      USER_ROLES,
      USER_ROLE_TYPES,
      STATIC_FIELD_TYPE,
      GENDER_OPTIONS,
      QUES_TYPES,
      title: 'Student Application',
      //studentsListLinkName: '',
      signature: '',
      isLoading: true,
      isLastContactLoading: true,
      isPayStatusLoading: false,
      termsAndConditions: true,
      student: {},
      application: {},

      // TODO: Check 'Cats Acceptable' Certification.
      certificationTypes: [
        { key: 'cna_license_no', name: 'CNA', downloadable: false, showText: 'Show License' },
        { key: 'cpr_url', name: 'CPR Certification', downloadable: true },
        { key: 'first_aid_url', name: 'First Aid Certification', downloadable: true },
        { key: 'bls_url', name: 'BLS Certification (Life Support)', downloadable: true },
      ],
      ethnicity: null,
      ethnicityOptions: ETHNICITY_OPTIONS,
      showDoc: { cna_license_no: false },
      certifications: [],
      records: [],
      careerPreferences: [],
      stdCountLoading: true,
      schoolStdCount: 0,
      sections: [],
      showDocModal: false,
      selectedDocTitle: '',
      selectedDocSrc: '',
      showContactModal: false,
      langOptions: [],
      workTravelOptions: [],
      FACILITY_TYPES,

      staticFieldsConfig: {
        lang_preferences: null,
        desired_work_type: null,
        desired_work_time: null,
        desired_work_day: null,
        work_travel: null,
        interested_facility_types: null,
        student_ssn: null,
        reference: null,
        registration_reason: null,
        occupation: null,
        gender: null,
        ethnicity: null,
        address: null,
        zipcode: null,
        city: null,
        state: null,
        country: null,
        resume_url: null,
      },
      school: null,
      showTermsModal: false,
      registrationTerms: null,
      YES_NO_OPTIONS,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.title, active: true },
      ];
    },
  },

  methods: {
    ...mapActions('student', ['getStudent', 'getStudentRegProfile', 'updateStdRegPayStatus']),
    ...mapActions('registrationForm', ['getPublicRegistrationForm']),
    ...mapActions('applicationForm', ['getApplicationProfile']),
    ...mapActions('fileDownload', ['downloadFile']),

    formatFullDate,
    formatDateTime,
    parseFileNameFromUrl,
    formatDateSimple,
    get,
    goBack() {
      this.$router.push({
        name: 'std-apps-list',
      });
    },
    openStdProfile(id) {
      if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION) {
        this.$router.push({
          name: 'org-student-details',
          params: { id, to: 'app-profile', applicant_id: this.$route.params.id },
        });
      } else {
        this.$router.push({
          name: 'employer-unlocked-candidate-profile',
          params: { id, to: 'app-profile', applicant_id: this.$route.params.id },
        });
      }
    },
    async fetchStudent() {
      this.isLoading = true;

      let resp = (await this.getApplicationProfile({ applicant: this.$route.params.id })).data;

      // Mapping student data.
      const student = resp.student;

      // student.desired_work_type = getHumanReadableCommaSepStr(
      //   student.desired_work_type.map((type) => this.$t(`studentWorkTypeOpts.${type}`)),
      //   'or'
      // );
      // student.desired_work_time = getHumanReadableCommaSepStr(
      //   student.desired_work_time.map((time) => this.$t(`studentWorkTimeOpts.${time}`))
      // );
      // student.desired_work_day = getHumanReadableCommaSepStr(
      //   student.desired_work_day.map((day) => this.$t(`jobWorkDays.${day}`))
      // );

      // if (get(student, 'lang_preferences.length')) {
      //   const arr = getLangOptions();
      //   const langPreference = keyBy(arr, 'value');
      //   student.lang_preferences = student.lang_preferences.map((lang) => langPreference[lang].name);
      // }

      // if (get(student, 'interested_facility_types.length')) {
      //   student.interested_facility_types = student.interested_facility_types.map((type) => this.FACILITY_TYPES[type]);
      // }

      this.student = student;
      this.student.student_ssn = this.student.student_ssn ? '***-**-' + this.student.student_ssn : '';

      this.application = resp;
      this.signature = resp.signature;
      this.isLoading = false;
    },

    async downloadGivenFile({ fileUrl, removeTimestamp = true }) {
      const h = this.$createElement;

      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left',
      });

      await this.downloadFile({ fileUrl, removeTimestamp });

      this.makeToast({
        variant: 'success',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
        toaster: 'b-toaster-bottom-left',
      });
    },
  },

  async mounted() {
    this.registrationTerms = this.getLoggedInUser.linked_entity.registration_terms;
    this.workTravelOptions = getWorkTravelOptions();
    if (this.$route.params.id) {
      await this.fetchStudent();
      //this.fetchStaticFieldsConfig();
    }
  },
};
</script>