<template>
  <div>
    <div class="page-section border-bottom-2">
      <div :class="containerClass">
        <div class="mb-3">
          <h3>{{ `Welcome ${this.getLoggedInUser.first_name}!` }}</h3>
        </div>
        <employer-setup-guide :pending="true" @pendingGuides="pendingGuides" />
        <div v-if="pending > 0" class="text-center">
          <router-link :to="{ name: 'employer-setup-guide' }"> View {{ pending }} more </router-link>
        </div>
        <div class="row mt-3">
          <div class="col-lg-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ dashboard.active_schools }}</h4>
                </b-skeleton-wrapper>
                <div>
                  Partnered Schools
                  <i
                    class="fas fa-info-circle clickable-item text-black-50"
                    v-b-popover.hover.top
                    title="Schools with active partnerships"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ dashboard.pending_partner_req }}</h4>
                </b-skeleton-wrapper>
                <div>
                  Pending Partnership Requests
                  <i
                    class="fas fa-info-circle clickable-item text-black-50"
                    v-b-popover.hover.top
                    title="Partnership Requests are not yet accepted by schools"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ dashboard.jobs_posted }}</h4>
                </b-skeleton-wrapper>
                <div>
                  Jobs Posted
                  <i
                    class="fas fa-info-circle clickable-item text-black-50"
                    v-b-popover.hover.top
                    title="Total Job Posted"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ dashboard.hires }}</h4>
                </b-skeleton-wrapper>
                <div>
                  Hires
                  <i
                    class="fas fa-info-circle clickable-item text-black-50"
                    v-b-popover.hover.top
                    title="Number of hiring done by employer using this platform"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import EmployerSetupGuide from '../../EmployerPortal/SetupGuide/EmployerSetupGuide.vue';

export default {
  components: { EmployerSetupGuide },
  extends: Page,

  data() {
    return {
      title: 'Dashboard',
      dashboard: {
        active_schools: 0,
        pending_partner_req: 0,
        jobs_posted: 0,
        hires: 0,
      },

      isLoading: false,
      pending: null,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Dashboard', active: true },
      ];
    },
  },

  methods: {
    ...mapActions('employer', ['getDashboard']),
    pendingGuides(pending) {
      this.pending = pending;
    },
    async fetchDashboard() {
      this.isLoading = true;
      const response = await this.getDashboard();
      this.dashboard = response.data;
      this.isLoading = false;
    },
  },

  async mounted() {
    this.fetchDashboard();
  },
};
</script>
