<template>
  <div>
    <div class="page-section border-bottom-2">
      <div :class="containerClass">
        <div class="mb-3">
          <h3>{{ `Welcome ${this.getLoggedInUser.first_name}!` }}</h3>
        </div>
        <div>
          <b-alert v-for="notification in pendingNotifications" :key="notification.id" show>
            <div class="d-flex">
              <router-link
                :to="{
                  name: 'feature-update-details',
                  params: { id: notification.extra.app_update_id },
                  query: { notification: notification.id },
                }"
              >
                {{ notification.extra.notification_title }}
              </router-link>
              <div class="ml-auto clickable-item" @click.prevent="setNotificationRead(notification.id)">
                <md-icon>close</md-icon>
              </div>
            </div>
          </b-alert>
          <div class="text-center" v-if="moreNotifications > 0">
            <router-link :to="{ name: 'feature-updates-page' }"> View {{ moreNotifications }} more </router-link>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ dashboard.active_students }}</h4>
                </b-skeleton-wrapper>
                <div>
                  Active Students
                  <i
                    class="fas fa-info-circle clickable-item text-black-50"
                    v-b-popover.hover.top
                    title="Registered students with an active account and complete profile"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ dashboard.in_active_students }}</h4>
                </b-skeleton-wrapper>
                <div>
                  Inactive Students
                  <i
                    class="fas fa-info-circle clickable-item text-black-50"
                    v-b-popover.hover.top
                    title="Students with disabled profiles"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ dashboard.incomplete_students }}</h4>
                </b-skeleton-wrapper>
                <div>
                  Incomplete Profile
                  <i
                    class="fas fa-info-circle clickable-item text-black-50"
                    v-b-popover.hover.top
                    title="Students with incomplete profiles"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ dashboard.pending_signup_requests }}</h4>
                </b-skeleton-wrapper>
                <div>
                  Pending Signup Requests
                  <i
                    class="fas fa-info-circle clickable-item text-black-50"
                    v-b-popover.hover.top
                    title="Pending signup requests of students"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ dashboard.universies }}</h4>
                </b-skeleton-wrapper>
                <div>
                  Partnered Universities
                  <i
                    class="fas fa-info-circle clickable-item text-black-50"
                    v-b-popover.hover.top
                    title="Active partnership with universities"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ dashboard.partnererd_employers }}</h4>
                </b-skeleton-wrapper>
                <div>
                  Partnered Employers
                  <i
                    class="fas fa-info-circle clickable-item text-black-50"
                    v-b-popover.hover.top
                    title="Active partnership with employers"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ dashboard.pending_partner_requests }}</h4>
                </b-skeleton-wrapper>
                <div>
                  Pending Requests
                  <i
                    class="fas fa-info-circle clickable-item text-black-50"
                    v-b-popover.hover.top
                    title="Pending Partnership requests from employers"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';

export default {
  components: {},
  extends: Page,

  data() {
    return {
      title: 'Dashboard',
      dashboard: {
        active_students: 0,
        in_active_students: 0,
        incomplete_students: 0,
        pending_signup_requests: 0,
        universies: 0,
        partnererd_employers: 0,
        pending_partner_requests: 0,
      },

      isLoading: false,
      areNotificationsLoading: false,
      allNotifications: [],
      pendingNotifications: [],
      moreNotifications: 0,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Dashboard', active: true },
      ];
    },
  },

  methods: {
    ...mapActions('school', ['getDashboard']),
    ...mapActions('notifications', ['getAllNotifications', 'updateNotification']),

    async fetchDashboard() {
      this.isLoading = true;
      const response = await this.getDashboard();
      this.dashboard = response.data;
      this.isLoading = false;
    },

    async fetchNotifications() {
      this.areNotificationsLoading = true;
      const response = await this.getAllNotifications({
        notification_type__type_abbreviation: 'app_feature_update',
      });
      if (response.data) {
        this.allNotifications = response.data;

        const unReadNotification = (this.pendingNotifications = this.allNotifications.filter(
          (notification) => notification.is_read === false
        ));

        this.pendingNotifications = this.allNotifications
          .filter((notification) => notification.is_read === false)
          .slice(0, 3);

        this.moreNotifications = unReadNotification.length - this.pendingNotifications.length;
      }

      this.areNotificationsLoading = false;
    },

    async setNotificationRead(id) {
      const data = { is_read: true };
      await this.updateNotification({ id: id, data });
      this.fetchNotifications();
    },
  },

  async mounted() {
    this.fetchDashboard();
    this.fetchNotifications();
  },
};
</script>
