<template>
  <b-modal
    ref="confirmModal"
    hide-footer
    title="Confirm Trainings"
    centered
    lazy
    scrollable
    @hide="hideModalManual"
    size="md"
  >
    <div>
      {{ message }}
    </div>

    <div class="d-flex justify-content-end mt-3">
      <b-btn variant="primary" @click.prevent="hideModalManual">Confirm</b-btn>
      <b-btn class="ml-2" variant="light" @click.prevent="cancelConfig">Cancel</b-btn>
    </div>
  </b-modal>
</template>

<script>
import { get } from 'lodash';
import { USER_ROLE_TYPES } from '@/common/constants';
import { mapGetters } from 'vuex';
export default {
  name: 'ConfirmModal',

  props: {
    showModal: { type: Boolean, default: false },
    value: { type: Boolean },
  },

  data() {
    return {
      isLoading: false,
      USER_ROLE_TYPES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    message() {
      return this.value
        ? 'This will result in displaying porgrams, classes and providers associated with your offered sponsorship on site. Do you want to continue?'
        : 'This will result in displaying programs and classes  associated with your offered sponsorship along with your partnered schools  on your site. Do you want to continue?';
    },
  },

  watch: {
    showModal(value) {
      if (value) {
        this.$refs.confirmModal.show();
      }
    },
  },

  methods: {
    get,
    hideModal() {
      this.$refs.confirmModal.hide();
    },

    cancelConfig() {
      this.hideModal();
      this.$emit('update');
    },

    hideModalManual() {
      this.$refs.confirmModal.hide();
      this.$emit('close');
    },
  },

  async mounted() {},
};
</script>
