<template>
  <div :class="containerClass">
    <page-header :title="'Write a Testimonial'" :breadcrumb="breadcrumb" :container-class="null" class="mb-32pt" />

    <page-separator title="" />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <!-- Basic School Info -->
      <b-form-group
        :label="$t('userMsgs.avatar')"
        description="Ensure there is good lighting and that no other people or objects are in the photo."
        label-for="avatar"
        label-class="form-label"
        class="row-align-items-center"
      >
        <b-media class="align-items-center" vertical-align="center">
          <user-avatar
            slot="aside"
            size="lg"
            :isLoading="isUploading.avatar"
            :user="testimonial.user"
            url-field="avatar_url"
            first-name-field="first_name"
            last-name-field="last_name"
            variant="dark"
          >
          </user-avatar>

          <image-uploader
            @image="(e) => setImage(e, 'avatar', 'images', 'avatar_url', ['image'])"
            :isUploading="isUploading.avatar"
            :isRequired="!testimonial.user.avatar_url"
            placeholder="Select professional looking headshot photo (max size: 4MB)."
          />
        </b-media>
      </b-form-group>
      <b-form-group :label="'Graduation Date'" label-for="grad_date" label-class="form-label">
        <date-picker
          v-model="testimonial.grad_date"
          type="month"
          format="MMMM, YYYY"
          value-type="date"
          style="width: 100%"
          id="grad_date"
          lang="en"
          placeholder="No Date Selected"
          :disabled="isFormLoading"
          :input-attr="{ required: 'true' }"
        ></date-picker>
      </b-form-group>

      <b-form-group label="Current Employer" label-for="curr_employer" label-class="form-label">
        <b-form-input
          id="curr_employer"
          placeholder="Enter current employer's name (if any)."
          v-model="testimonial.curr_employer"
        />
      </b-form-group>

      <b-form-group
        :label="`Why I came to ${get(getCurrentSchool, 'name')}?`"
        label-for="schoolJoiningReason"
        label-class="form-label"
      >
        <b-form-textarea
          id="name"
          placeholder="Enter the reason why you joined."
          :rows="5"
          v-model="testimonial.school_joining_reason"
          :disabled="isFormLoading"
          required
        />
      </b-form-group>

      <b-form-group
        :label="`What career successes have happened with you, since completing your CNA training at ${get(
          getCurrentSchool,
          'name'
        )}?`"
        label-for="careerSuccessDetails"
        label-class="form-label"
      >
        <b-form-textarea
          id="careerSuccessDetails"
          placeholder="Please specify."
          v-model="testimonial.career_success_details"
          :rows="5"
          :disabled="isFormLoading"
          required
        />
      </b-form-group>

      <b-form-group :label="'Tips to pass on to the readers: '" label-for="readersTips" label-class="form-label">
        <b-form-textarea
          id="readersTips"
          :rows="5"
          placeholder="Share tips for the readers."
          v-model="testimonial.readers_tips"
          :disabled="isFormLoading"
          required
        />
      </b-form-group>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 180px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>Post Testimonial</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import { get } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { VERIFICATION_TESTIMONIAL_STATUS } from '../../../common/constants';
import { formatToAPIDate } from '../../../common/utils';

import moment from 'moment';
import Page from '../../../components/Page.vue';
import UserAvatar from '../../../components/User/UserAvatar.vue';
import DatePicker from 'vue2-datepicker';

import 'vue2-datepicker/locale/en';
import 'vue-swatches/dist/vue-swatches.css';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    PageHeader,
    PageSeparator,
    UserAvatar,
    DatePicker,
    ImageUploader,
  },
  extends: Page,

  data() {
    return {
      title: 'Write a Testimonial',

      isLoading: false,
      isVerifying: false,

      testimonial: {
        grad_date: null,
        curr_employer: null,
        school_joining_reason: '',
        career_success_details: '',
        readers_tips: '',
        user: {
          avatar_url: '',
        },
      },
      years: [],

      isLogoUploading: false,
      yearName: null,
      isUploading: {
        avatar: false,
      },
      selectedUploadFile: {
        avatar: null,
      },
      uploadPercent: { avatar: 0 },
      uploadCancelTokenSource: {
        avatar: null,
      },
      fileSizeExceed: {
        avatar: false,
      },
      invalidFileType: {
        avatar: false,
      },
    };
  },

  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    breadcrumb() {
      return [
        { text: 'Home', to: this.routes.home },
        {
          text: 'Write a Testimonial',
          active: true,
        },
      ];
    },
    isFormLoading() {
      return this.isLoading || this.isVerifying || Object.values(this.isUploading).some((val) => val);
    },
  },

  methods: {
    ...mapActions('student', ['getMyStudentProfile']),
    ...mapActions('testimonial', ['verifyTestimonial', 'createTestimonial']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('student', ['getStudentUploadPresignedUrl']),
    get,

    setImage(file, fileType, uploadType = 'images', profileUrlField = 'resume_url', allowedTypes = ['image']) {
      this.selectedUploadFile[fileType] = file;
      this.uploadFile(fileType, uploadType, profileUrlField, allowedTypes);
    },

    async verification() {
      this.isVerifying = true;
      try {
        const res = await this.verifyTestimonial();
        if (res.data.status === VERIFICATION_TESTIMONIAL_STATUS.TESTIMONIAL_EXISTS) {
          this.$router.push({ name: 'student-home' });
        }
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isVerifying = false;
    },

    fillInOptions() {
      for (let i = moment().year(); i > 1970; i--) {
        this.years.push({ value: i, text: i });
      }
    },

    async onSubmit() {
      this.isLoading = true;

      try {
        const data = {
          grad_date: formatToAPIDate(moment(this.testimonial.grad_date)),
          avatar_url: this.testimonial.user.avatar_url,
          school_joining_reason: this.testimonial.school_joining_reason,
          career_success_details: this.testimonial.career_success_details,
          readers_tips: this.testimonial.readers_tips,
          curr_employer: this.testimonial.curr_employer,
        };
        await this.createTestimonial(data);
        this.makeToast({ variant: 'success', msg: 'Testimonial Posted!' });
        setTimeout(() => this.$router.push({ name: 'student-home' }), 250);
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },
    async uploadFile(fileType, uploadType = 'images', profileUrlField = 'resume_url', allowedTypes = ['image']) {
      this.fileSizeExceed[fileType] = this.invalidFileType[fileType] = false;

      if (this.selectedUploadFile[fileType].size > 10 * 1024 * 1024) {
        this.fileSizeExceed[fileType] = true;
        return;
      } else if (allowedTypes.every((val) => !this.selectedUploadFile[fileType].type.includes(val))) {
        this.invalidFileType[fileType] = true;
        return;
      }

      this.isUploading[fileType] = true;
      this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();

      try {
        const urlResp = await this.getStudentUploadPresignedUrl({
          file_name: this.selectedUploadFile[fileType].name,
          content_type: this.selectedUploadFile[fileType].type,
          upload_type: uploadType,
        });
        if (fileType === 'avatar') this.testimonial.user.avatar_url = urlResp.upload_url.split('?')[0];
        else this.testimonial.user[profileUrlField] = urlResp.upload_url.split('?')[0];
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile[fileType],
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent[fileType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token,
          },
        });
      } catch (error) {
        this.selectedUploadFile[fileType] = null;
        if (fileType === 'avatar') this.testimonial.user.avatar_url = null;
        else this.testimonial.user[profileUrlField] = null;
      }

      this.uploadCancelTokenSource[fileType] = null;
      this.uploadPercent[fileType] = 0;

      this.isUploading[fileType] = false;
    },
  },

  async mounted() {
    this.verification();
    this.fillInOptions();
    const resp = await this.getMyStudentProfile(true);
    this.testimonial = resp;
    if (get(resp, 'grad_date')) {
      this.testimonial.grad_date = resp.grad_date ? new Date(resp.grad_date) : null;
      this.testimonial.curr_employer = resp.curr_employer;
    }
  },
};
</script>
