<template>
  <div :class="containerClass" v-if="!isSubmit">
    <div class="d-flex justify-content-center mt-2 pt-2">
      <b-skeleton-wrapper :loading="isLoading">
        <template #loading>
          <b-skeleton-img width="160px" height="120px" no-aspect></b-skeleton-img>
        </template>

        <b-img :src="get(scholarship, 'image_url')" height="110" v-if="get(scholarship, 'image_url')" />
        <b-avatar icon="star-fill" square v-else size="5rem"></b-avatar>
      </b-skeleton-wrapper>
    </div>

    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="d-flex justify-content-center mt-3">
          <b-skeleton width="60%"></b-skeleton>
        </div>
      </template>
      <div class="d-flex justify-content-center mt-2 text-center">
        <h3 class="mb-0 font-weight-bolder" v-text="`${get(scholarship, 'title')}`" />
      </div>
    </b-skeleton-wrapper>

    <div class="mx-3 mb-4 mt-4 text-center">
      <b-skeleton-wrapper :loading="isLoading">
        <template #loading>
          <p class="d-flex justify-content-center">
            <b-skeleton width="90%"></b-skeleton>
          </p>
        </template>

        <span style="font-size: 0.9125rem">
          <span v-if="get(scholarship, 'summary')">
            {{ get(scholarship, 'summary') }}
          </span>
        </span>
      </b-skeleton-wrapper>
    </div>

    <b-form @submit.prevent="onSubmit">
      <div class="card">
        <div class="card-body">
          <b-form-group label="Contact email" label-for="email" label-class="form-label">
            <b-form-input
              id="email"
              placeholder="Enter student email"
              v-model="email"
              type="email"
              @input="inputEmail"
            />
          </b-form-group>

          <div class="pt-2" v-if="emailNotExist">
            <div class="alert alert-danger w-100" role="alert">
              <span class="text-90">No eligible student with this email exists.</span>
            </div>
          </div>
        </div>
      </div>

      <div class="card" v-for="section in sections" :key="`sec-${section.id}`">
        <div class="card-header bg-light">
          <h5 class="my-0">{{ section.title }}</h5>
        </div>
        <div class="card-body">
          <b-form-group
            :label="ques.is_required ? `${get(ques, 'question.title')}*` : get(ques, 'question.title')"
            :label-for="`section-${section.id}-question-${ques.question.id}-${ques.id}`"
            label-class="form-label"
            v-slot="{ ariaDescribedby }"
            v-for="ques in section.questions"
            :key="`sec-que-${ques.id}`"
          >
            <b-form-radio-group
              v-if="ques.question.type === QUES_TYPES.BOOLEAN"
              :id="`section-${section.id}-question-${ques.question.id}-${ques.id}`"
              v-model="customSectionData[`section-${section.id}`][`question-${ques.question.id}-${ques.id}`]"
              :options="get(ques.question, 'extra.options', [])"
              :aria-describedby="ariaDescribedby"
              :state="
                !get(
                  $v,
                  `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.required`,
                  true
                ) &&
                get($v, `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.$dirty`, false)
                  ? false
                  : null
              "
              :required="ques.is_required"
            ></b-form-radio-group>

            <b-form-input
              :id="`section-${section.id}-question-${ques.question.id}-${ques.id}`"
              v-if="ques.question.type === QUES_TYPES.SHORT_TEXT"
              :placeholder="get(ques.question, 'placeholder', 'Please write your answer here.')"
              v-model="customSectionData[`section-${section.id}`][`question-${ques.question.id}-${ques.id}`]"
              :state="
                !get(
                  $v,
                  `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.required`,
                  true
                ) &&
                get($v, `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.$dirty`, false)
                  ? false
                  : null
              "
              :required="ques.is_required"
            />
            <b-form-textarea
              v-if="ques.question.type === QUES_TYPES.LONG_TEXT"
              :id="`section-${section.id}-question-${ques.question.id}-${ques.id}`"
              class="form-control"
              rows="10"
              :placeholder="get(ques.question, 'placeholder', 'Please write your answer here.')"
              v-model="customSectionData[`section-${section.id}`][`question-${ques.question.id}-${ques.id}`]"
              :state="
                !get(
                  $v,
                  `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.required`,
                  true
                ) &&
                get($v, `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.$dirty`, false)
                  ? false
                  : null
              "
              :required="ques.is_required"
            ></b-form-textarea>

            <b-media
              class="align-items-center"
              vertical-align="center"
              v-if="ques.question.type === QUES_TYPES.FILE_TYPE"
            >
              <b-form-file
                :id="`section-${section.id}-question-${ques.question.id}-${ques.id}`"
                :placeholder="get(ques.question, 'placeholder', 'Select File')"
                :browse-text="$t('generalMsgs.browse')"
                v-model="selectedQueUploadFile[`sec-${section.id}-que-${ques.question.id}`]"
                @input="uploadFile('file_type', 'documents', '', section.id, ques.question.id)"
                :disabled="isQueFileUploading[`sec-${section.id}-que-${ques.question.id}`]"
                :required="ques.is_required && !selectedQueUploadFile[`sec-${section.id}-que-${ques.question.id}`]"
              />
            </b-media>

            <v-select
              v-if="ques.question.type === QUES_TYPES.SINGLE_SELECT"
              :id="`section-${section.id}-question-${ques.question.id}-${ques.id}`"
              class="form-control v-select-custom"
              label="text"
              item-text="text"
              v-model="customSectionData[`section-${section.id}`][`question-${ques.question.id}-${ques.id}`]"
              :reduce="(ins) => ins.value"
              :placeholder="get(ques.question, 'placeholder', 'Please write your answer here.')"
              :options="get(ques.question, 'extra.options', [])"
              :class="
                !get(
                  $v,
                  `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.required`,
                  true
                ) &&
                get($v, `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.$dirty`, false)
                  ? 'form-control is-invalid'
                  : ''
              "
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="
                    ques.is_required &&
                    !customSectionData[`section-${section.id}`][`question-${ques.question.id}-${ques.id}`]
                  "
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
              <template slot="option" slot-scope="option"> {{ option.text }} </template>
              <template slot="selected-option" slot-scope="option">
                {{ option.text }}
              </template>
            </v-select>
            <v-select
              v-if="ques.question.type === QUES_TYPES.MULTI_SELECT"
              :id="`section-${section.id}question-${ques.question.id}`"
              class="form-control v-select-custom"
              label="text"
              item-text="text"
              v-model="customSectionData[`section-${section.id}`][`question-${ques.question.id}-${ques.id}`]"
              :reduce="(ins) => ins.value"
              :placeholder="get(ques.question, 'placeholder', 'Please write your answer here.')"
              :options="get(ques.question, 'extra.options', [])"
              :class="
                !get(
                  $v,
                  `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.required`,
                  true
                ) &&
                get($v, `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.$dirty`, false)
                  ? 'form-control is-invalid'
                  : ''
              "
              multiple
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="
                    ques.is_required &&
                    !get(
                      customSectionData[`section-${section.id}`][`question-${ques.question.id}-${ques.id}`],
                      'length'
                    )
                  "
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
              <template slot="option" slot-scope="option"> {{ option.text }} </template>
              <template slot="selected-option" slot-scope="option">
                {{ option.text }}
              </template>
            </v-select>
            <b-form-invalid-feedback
              :state="
                !get(
                  $v,
                  `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.required`,
                  true
                ) &&
                get($v, `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.$dirty`, false)
                  ? false
                  : null
              "
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
      </div>

      <b-btn
        variant="primary"
        :disabled="isFormLoading || emailNotExist"
        style="width: 150px"
        type="submit"
        class="btn-normal float-right mb-3"
      >
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>Submit</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';

import { truncate, get, debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
//import { required } from 'vuelidate/lib/validators';

import { QUES_TYPES } from '@/common/constants';
import { formatDateSimple, formatFullDate } from '@/common/utils';

export default {
  components: {},
  extends: Page,
  layout: 'blank',

  data() {
    return {
      email: '',
      isSubmit: false,
      isSuccess: false,
      isLoading: false,
      termsAndConditions: false,
      showTermsModal: false,
      school: null,
      scholarship: {},
      isUploading: {},
      isQueFileUploading: {},
      selectedUploadFile: {},
      selectedQueUploadFile: {},
      uploadPercent: { avatar: 0, resume: 0, file_type: {} },
      uploadCancelTokenSource: {
        file_type: {},
      },

      sponsorship: {},
      file_type: null,
      areSectionsLoading: false,
      QUES_TYPES,
      sections: [],
      customSectionData: {},
      isEmailStatusLoading: false,
      emailNotExist: false,
    };
  },
  validations() {
    const val = {
      customSectionData: {},
    };

    return val;
  },
  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    ...mapGetters('auth', ['getLoggedInUser']),

    isFormLoading() {
      return (
        this.isLoading ||
        Object.values(this.isUploading).some((val) => val) ||
        Object.values(this.isQueFileUploading).some((val) => val)
      );
    },
  },

  methods: {
    ...mapActions('student', ['getStudentUploadPresignedUrl']),
    ...mapActions('applicationForm', [
      'getPublicRegistrationForm',
      'CreateApplicationForm',
      'getEmailStatus',
      'createManualApplication',
    ]),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('sections', ['getPublicSections']),
    ...mapActions('scholarships', ['getScholarship']),
    formatDateSimple,
    formatFullDate,

    get,
    truncate,
    inputEmail(value) {
      if (value) {
        this.debouncedInputEmail(this);
      }
    },
    debouncedInputEmail: debounce((vm) => {
      vm.fetchEmailStatus();
    }, 500),
    async fetchEmailStatus() {
      this.isEmailStatusLoading = false;
      try {
        const data = {
          email: this.email,
          scholarship: this.$route.params.id,
        };

        const res = await this.getEmailStatus(data);

        if (res.data.status_code === 200) {
          this.emailNotExist = false;
        }
      } catch (err) {
        if (err.response.status === 400) {
          this.emailNotExist = true;
          // this.makeToast({ variant: 'danger', msg: `Email doesn't exist!` });
        }
      }
      this.isEmailStatusLoading = false;
    },
    goToHome() {
      this.$router.push({ name: 'home' });
    },

    async fetchSections(params = {}) {
      this.areSectionsLoading = true;
      try {
        const res = await this.getPublicSections({
          scholarship: this.$route.params.id,
          ...params,
        });

        const secData = {};
        const queSecUploadFile = {};
        const queFileUploading = {};

        for (const sectionObj of res.data) {
          const quesObj = sectionObj.questions.reduce((obj, item) => {
            if (item.question.type === QUES_TYPES.FILE_TYPE) {
              queSecUploadFile[`sec-${sectionObj.id}-que-${item.question.id}`] = null;
              queFileUploading[`sec-${sectionObj.id}-que-${item.question.id}`] = false;
            }

            return Object.assign(obj, {
              [`question-${item.question.id}-${item.id}`]: null,
            });
          }, {});
          secData[`section-${sectionObj.id}`] = quesObj;
        }

        this.selectedQueUploadFile = queSecUploadFile;
        this.isQueFileUploading = queFileUploading;

        this.customSectionData = secData;
        this.sections = res.data;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areSectionsLoading = false;
    },

    async fetchScholarship() {
      this.isLoading = true;
      try {
        const res = await this.getScholarship(this.$route.params.id);
        this.scholarship = res.data;

        this.title = this.scholarship.title;
      } catch (e) {
        this.$router.go(-1);
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },

    async onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;
        try {
          const sectionsArr = [];
          Object.entries(this.customSectionData).map((section) => {
            Object.entries(section[1]).map((question) => {
              sectionsArr.push({
                section_question: parseInt(question[0].split('-')[2]),

                response: { data: question[1] },
              });
            });
          });

          const data = {
            email: this.email,
            scholarship: this.scholarship.id,
            application_responses: sectionsArr,
          };

          await this.createManualApplication({ ...data });
          this.makeToast({ variant: 'success', msg: 'Student added manually!' });
          setTimeout(
            () =>
              this.$router.push({
                name: 'std-apps-list',
              }),
            250
          );
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please enter required detials!' });
      }
      this.isLoading = false;
    },

    async uploadFile(
      fileType,
      uploadType = 'documents',
      profileUrlField = 'resume_url',
      section = null,
      question = null
    ) {
      let file;

      if (fileType === QUES_TYPES.FILE_TYPE) {
        this.uploadCancelTokenSource[fileType][`sec-${section}-que-${question}`] = axios.CancelToken.source();
        this.isQueFileUploading[`sec-${section}-que-${question}`] = true;
        file = this.selectedQueUploadFile[`sec-${section}-que-${question}`];
      } else {
        this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();
        this.isUploading[fileType] = true;
        file = this.selectedUploadFile[fileType];
      }

      try {
        const urlResp = await this.getStudentUploadPresignedUrl({
          file_name: file.name,
          content_type: file.type,
          upload_type: uploadType,
        });

        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: file,
          config: {
            onUploadProgress: function (progressEvent) {
              const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              if (fileType === QUES_TYPES.FILE_TYPE) {
                this.uploadPercent[fileType][`sec-${section}-que-${question}`] = percentage;
              } else {
                this.uploadPercent[fileType] = percentage;
              }
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token,
          },
        });
        if (fileType === 'avatar') {
          this.user.avatarUrl = urlResp.upload_url.split('?')[0];
        } else if (fileType === 'file_type') {
          this.customSectionData[`section-${section}`][`question-${question}`] = urlResp.upload_url.split('?')[0];
          this.file_type = urlResp.upload_url.split('?')[0];
        } else this.student[profileUrlField] = urlResp.upload_url.split('?')[0];
      } catch (error) {
        if (fileType === QUES_TYPES.FILE_TYPE) {
          this.selectedQueUploadFile[`sec-${section}-que-${question}`] = null;
        } else {
          this.selectedUploadFile[fileType] = null;
        }
      }
      if (fileType === QUES_TYPES.FILE_TYPE) {
        this.uploadCancelTokenSource[fileType][`sec-${section}-que-${question}`] = null;
        this.uploadPercent[fileType][`sec-${section}-que-${question}`] = 0;
        this.isQueFileUploading[`sec-${section}-que-${question}`] = false;
      } else {
        this.uploadCancelTokenSource[fileType] = null;
        this.uploadPercent[fileType] = 0;
        this.isUploading[fileType] = false;
      }
    },
  },
  async mounted() {
    this.fetchScholarship();
    if (this.$route.params.id) {
      this.fetchSections();
    }
  },
};
</script>

<style>
</style>
