<template>
  <div :class="containerClass">
    <page-header :title="$route.params.id ? 'Edit Prize' : 'Post Prize'" :container-class="null" />
    <div class="page-section">
      <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
        <b-form-group class="row-align-items-center">
          <label class="form-label"> Image </label>
          <b-media class="align-items-center" vertical-align="center">
            <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
              <span v-if="isLogoUploading">...</span>
              <b-img :src="prizeImg" class="img-fluid" width="40" alt="Logo" v-else-if="prizeImg" />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>

            <image-uploader
              @image="(e) => setImage(e, 'logo')"
              :isUploading="isLogoUploading"
              :isRequired="false"
              placeholder="Upload prize image"
            />
          </b-media>
        </b-form-group>

        <b-form-group label="Title" label-for="title" label-class="form-label">
          <b-form-input id="title" placeholder="Enter prize title." v-model.trim="prizeTitle" />
        </b-form-group>

        <b-form-group label="Description" label-for="description" label-class="form-label">
          <b-form-textarea id="description" placeholder="Enter prize description." :rows="5" v-model="description" />
        </b-form-group>

        <b-form-group label="Credits Required" label-for="total_credits" label-class="form-label">
          <b-form-input
            id="total_credits"
            placeholder="Enter total credits required to redeem"
            type="number"
            min="1"
            v-model="total_credits"
          />
        </b-form-group>

        <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
        </b-btn>
      </b-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import Page from '../../../components/Page.vue';

import { USER_ROLES, USER_ROLE_TYPES } from '../../../common/constants';
import ImageUploader from '@/components/ImageUploader.vue';
import { FmvAvatar } from 'fmv-avatar';
import PageHeader from '@/components/Ui/PageHeader.vue';

export default {
  components: { ImageUploader, FmvAvatar, PageHeader },
  extends: Page,

  data() {
    return {
      title: this.$route.params.id ? 'Edit Prize' : 'Post Prize',
      USER_ROLES,
      USER_ROLE_TYPES,
      uploadFile: { logo: null },
      uploadPercent: { logo: 0 },
      uploadCancelTokenSource: { logo: null },
      isLogoUploading: false,
      employer: null,
      total_credits: '',
      prizeTitle: '',

      description: '',
      summary: '',
      prizeImg: null,

      isLoading: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isFormLoading() {
      return this.isLoading || this.isLogoUploading;
    },
  },

  watch: {},

  methods: {
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('credit', ['getPrize', 'createPrize', 'updatePrize', 'getPrizeImageUploadPresignedUrl']),
    setImage(file, fileType) {
      this.uploadFile[fileType] = file;
      this.uploadImage(fileType);
    },
    async onSubmit() {
      this.isLoading = true;

      const data = {
        title: this.prizeTitle,
        image: this.prizeImg,
        description: this.description,
        total_credits: this.total_credits,
      };

      try {
        if (this.$route.params.id) {
          await this.updatePrize({
            id: this.$route.params.id,
            data,
          });
          this.makeToast({ variant: 'success', msg: 'Prize successfully updated!' });
        } else {
          await this.createPrize(data);
          this.makeToast({ variant: 'success', msg: 'Prize successfully added!' });
        }
        setTimeout(() => this.$router.push({ name: 'manage-prize' }), 250);
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },

    async fetchPrize() {
      this.isLoading = true;
      try {
        const res = await this.getPrize(this.$route.params.id);
        this.description = res.data.description;
        this.prizeTitle = res.data.title;
        this.total_credits = res.data.total_credits;
        this.prizeImg = res.data.image;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isLogoUploading = uploadType === 'logo';

      try {
        const urlResp = await this.getPrizeImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type,
        });

        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token,
          },
        });
        if (uploadType === 'logo') {
          this.prizeImg = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'logo') this.isLogoUploading = false;
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.fetchPrize();
    }
  },
};
</script>
