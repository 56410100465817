<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION
          ? `List of all users who took their career assessments using your site.`
          : getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER
          ? 'List of all users who created their resumes using your site'
          : ''
      "
    />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Assessments"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <!-- TODO: Add filters here. (if needed) -->
                <!-- <b-dropdown class="ml-lg-auto" :text="$t('All Topics')" variant="link text-black-70" right>
                  <b-dropdown-item active>All Topics</b-dropdown-item>
                  <b-dropdown-item>My Topics</b-dropdown-item>
                </b-dropdown>

                <b-dropdown class="mr-8pt" :text="$t('Newest')" variant="link text-black-70" right>
                  <b-dropdown-item active>Newest</b-dropdown-item>
                  <b-dropdown-item>Unanswered</b-dropdown-item>
                </b-dropdown> -->
                <!--
                <b-btn :to="{ name: 'add-student' }" exact variant="primary" v-text="$t('studentMsgs.addStudent')" /> -->
              </div>
            </div>
          </div>

          <!-- Students Table -->
          <b-table
            :fields="tableColumns"
            :items="assessments"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
            @row-clicked="onRowClick"
          >
            <template #cell(name)="data"
              ><strong> {{ data.item.first_name }} {{ data.item.last_name }} </strong></template
            >

            <template #cell(entity)="data">
              <div class="d-flex align-items-center">
                <!-- <md-icon
                  class="text-info mr-2 clickable-item"
                  v-b-popover.hover.left
                  :title="
                    data.item.entity_type === USER_ROLE_TYPES.SCHOOL
                      ? 'School'
                      : data.item.entity_type === USER_ROLE_TYPES.PARTNER
                      ? 'Partner'
                      : data.item.entity_type === USER_ROLE_TYPES.ORGANIZATION
                      ? 'Organization'
                      : data.item.entity_type === USER_ROLE_TYPES.UNIVERSITY
                      ? 'University'
                      : 'Employer'
                  "
                  >{{
                    data.item.entity_type === USER_ROLE_TYPES.SUPER_ADMIN
                      ? ''
                      : data.item.entity_type === USER_ROLE_TYPES.SCHOOL
                      ? 'account_balance'
                      : data.item.entity_type === USER_ROLE_TYPES.EMPLOYER
                      ? 'business'
                      : data.item.entity_type === USER_ROLE_TYPES.ORGANIZATION
                      ? 'business_center'
                      : data.item.entity_type === USER_ROLE_TYPES.UNIVERSITY
                      ? 'chrome_reader_mode'
                      : ''
                  }}</md-icon
                > -->

                <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside">
                  <b-img
                    :src="data.item.entity.small_logo_url || data.item.entity.image_url"
                    class="img-fluid"
                    width="40"
                    alt="Logo"
                    v-if="data.item.entity.small_logo_url || data.item.entity.image_url"
                    v-b-popover.hover.left
                    :title="
                      get(data.item, 'entity_type') === ENTITY_TYPES.ORGANIZATION
                        ? 'Organization'
                        : get(data.item, 'entity_type') === ENTITY_TYPES.DISTRICT
                        ? 'District'
                        : 'University'
                    "
                  />
                  <i class="fas fa-university fa-lg text-primary" v-else></i>
                </fmv-avatar>

                {{ get(data.item.entity, 'name') || get(data.item.entity, 'title') }}
              </div>
            </template>
            <template #cell(portal)="data">
              {{ $t(`portalTypes.${data.item.portal}`) }}
            </template>

            <template #head(actions)="">
              <span></span>
            </template>

            <template #cell(actions)="data">
              <a href="#" @click.prevent="openListModal(data.item.id)" v-b-popover.hover.left title="Preview">
                <md-icon class="text-primary">remove_red_eye</md-icon>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalAssessments"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="students-table"
            />
          </div>
        </div>
      </div>
    </div>
    <assess-list-modal :showModal="showListModal" @close="hideListModal" :id="rowId" />
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE, ENTITY_TYPES, USER_ROLES, USER_ROLE_TYPES } from '../../../common/constants';
import { formatDateTime } from '../../../common/utils';
import AssessListModal from './AssessListModal.vue';
import { FmvAvatar } from 'fmv-avatar';
export default {
  components: { MdIcon, PageHeader, Pagination, AssessListModal, FmvAvatar },
  extends: Page,

  data() {
    return {
      title: 'Manage Assessments',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalAssessments: 0,
      ENTITY_TYPES,
      assessments: [],
      showListModal: false,
      searchTerm: '',
      rowId: null,
      USER_ROLES,
      USER_ROLE_TYPES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Manage Assessments', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'name', label: this.$t('studentMsgs.name'), tdClass: 'clickable-item' },
        { key: 'email', label: this.$t('authMsgs.email'), tdClass: 'clickable-item' },

        ...(this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN ? [{ key: 'entity', label: 'Entity' }] : []),
        ...(this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN
          ? [{ key: 'portal', label: 'Portal', tdClass: 'clickable-item' }]
          : []),
        {
          key: 'latest_date',
          label: 'Assessment Date',
          tdClass: 'clickable-item',
          formatter: (value) => {
            return formatDateTime(value);
          },
        },

        { key: 'assessment_count', label: 'Assessment Count', tdClass: 'clickable-item' },
        // {
        //   key: 'actions',
        //   tdClass: 'text-right',
        //   thStyle: { minWidth: '100px' },
        // },
      ];
    },
  },

  methods: {
    ...mapActions('assessment', ['getAllAssessments']),
    get,
    openListModal(id) {
      this.showListModal = true;
      this.rowId = id;
    },
    hideListModal() {
      this.showListModal = false;
      this.rowId = null;
    },
    async fetchAssessments(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllAssessments({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.assessments = response.data.results;
      this.currentPage = pageNum;
      this.totalAssessments = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchAssessments(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchAssessments();
    },

    onRowClick(item) {
      this.openListModal(item.id);
    },

    onSearch() {
      this.debouncedSearchAssessments(this);
    },

    debouncedSearchAssessments: debounce((vm) => {
      vm.fetchAssessments();
    }, 500),
  },

  async mounted() {
    this.fetchAssessments();
    if (this.$route.params.id) {
      this.openListModal(this.$route.params.id);
    }
  },
};
</script>
