<template>
  <div class="card card-lg overlay--primary-dodger-blue mb-2">
    <div class="card-body d-flex flex-column">
      <div class="d-flex align-items-center">
        <div class="col-1 icon-align">
          <i class="fas fa-bars icon-16pt mr-8pt"></i>
        </div>
        <div class="col-6 icon-align">
          <span>{{ isShowMore ? question.title : truncate(question.title, { length: 180 }) }}</span>
          <span v-if="question.title.length > 180"
            ><strong class="text-primary" @click="() => (isShowMore ? (isShowMore = false) : (isShowMore = true))">{{
              isShowMore ? ' Show Less' : ' Show More'
            }}</strong></span
          >
        </div>

        <div class="col-2 font-weight-bold">
          <span>{{ $t(`quesTypeOptions.${question.type}`) }}</span>
        </div>

        <div class="col-md-2">
          <b-badge :variant="question.required ? 'primary' : 'secondary'">
            {{ question.required ? 'Required' : 'Optional' }}
          </b-badge>
        </div>

        <div class="col-md-1">
          <a href="#" @click.prevent="removeQuestion" v-b-popover.hover.right :title="'Remove Question'">
            <i class="material-icons icon-18pt">close</i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { truncate } from 'lodash';
export default {
  name: 'QuesCard',

  props: {
    question: { type: Object, default: null },
    index: { type: Number },
  },
  data() {
    return {
      isShowMore: false,
    };
  },
  computed: {
    quesTitle() {
      return truncate(this.question.title, { length: 180 });
    },
  },
  methods: {
    truncate,
    async removeQuestion() {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `Please confirm that you want to remove the selected question for current section. The question will no longer appear on the registration form and all existing responses will be removed.`,
        {
          title: 'Are you sure?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (isConfirmed) {
        this.$emit('removeQuestion', this.index);
      }
    },
  },
};
</script>

<style>
</style>
