<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator :title="title" />

        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Redeemed Prizes"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-6">
                  <v-select
                    id="program"
                    class="form-control v-select-custom"
                    label="title"
                    v-model="filters.prize"
                    :reduce="(prize) => prize.id"
                    placeholder="Select Prize"
                    :options="allPrizes"
                    :loading="arePrizesLoading"
                    @input="onFilterApplied"
                  >
                    <template #search="{ attributes, events }">
                      <input class="vs__search" v-bind="attributes" v-on="events" />
                    </template>

                    <template slot="option" slot-scope="option">
                      <div class="d-flex align-items-center">
                        <span>{{ option.title }}</span>
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ truncate(option.title, { length: 20 }) }}
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <!-- Events Table -->
          <b-table
            :fields="tableColumns"
            :items="redemptions"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(image)="data">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside">
                <b-img
                  :src="data.item.prize.image"
                  class="img-fluid"
                  width="40"
                  alt="Logo"
                  v-if="data.item.prize.image"
                />
                <i class="material-icons" v-else>card_giftcard </i>
              </fmv-avatar>
            </template>

            <template #cell(delivery_status)="data">
              <div class="custom-select-icon" style="min-width: 130px">
                <b-form-select
                  class="custom-select-icon__select"
                  v-model="data.item.delivery_status"
                  :options="options"
                  @change="changeStatus(data.item.id, data.item.delivery_status)"
                >
                  ></b-form-select
                >
                <span class="material-icons custom-select-icon__icon">sort</span>
              </div>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalPrizes"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="prizes-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { FmvAvatar } from 'fmv-avatar';
import { debounce, get, truncate } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';
import moment from 'moment';

export default {
  components: { MdIcon, PageHeader, PageSeparator, Pagination, FmvAvatar },
  extends: Page,

  data() {
    return {
      title: 'Manage Prize Redemptions',

      isLoading: false,
      arePrizesLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalPrizes: 0,
      redemptions: [],
      allPrizes: [],

      filters: { prize: null },

      searchTerm: '',
      ordering: '',
      options: [
        { value: null, text: 'Please Select', disabled: true },
        { value: 'pending', text: 'Pending' },
        { value: 'delivered', text: 'Delivered' },
      ],
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Redeemed Prizes', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'image', label: '', tdClass: 'clickable-item' },

        { key: 'prize.title', label: 'Title', tdClass: 'clickable-item' },
        { key: 'school.name', label: 'School' },
        {
          key: 'created_at',
          label: 'Redeemed At',
          sortable: true,
          tdClass: 'clickable-item',
          formatter: (val) => moment(val).format('MM/DD/YYYY, HH:mm:ss'),
        },
        { key: 'delivery_status', label: 'Status', sortable: true },
        // {
        //   key: 'actions',
        //   tdClass: 'text-right',
        //   thStyle: { minWidth: '100px' },
        // },
      ];
    },
  },

  methods: {
    ...mapActions('credit', ['getAllRedeemPrizes', 'updateRedeemPrizeStatus', 'getAllPrizes']),
    get,
    truncate,

    async fetchPrizes() {
      this.arePrizesLoading = true;

      const response = await this.getAllPrizes({});
      this.allPrizes = response.data;

      this.arePrizesLoading = false;
    },

    async fetchRedeemPrizes(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllRedeemPrizes({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...(this.$route.query.title && { prize_id: this.$route.params.id }),
        ...this.filters,
        ...params,
      });
      this.redemptions = response.data.results;
      this.currentPage = pageNum;
      this.totalPrizes = response.data.count;

      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchRedeemPrizes(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchRedeemPrizes();
    },

    onSearch() {
      this.debouncedSearchRedeemPrizes(this);
    },

    debouncedSearchRedeemPrizes: debounce((vm) => {
      vm.fetchRedeemPrizes();
    }, 500),

    onFilterApplied(val) {
      this.$router.replace({ name: 'manage-redeem-prizes', ...(val && { query: { prize: val } }) });

      this.fetchRedeemPrizes();
    },

    async changeStatus(id, status) {
      this.isLoading = true;
      try {
        const data = { delivery_status: status };
        await this.updateRedeemPrizeStatus({
          id: id,
          data,
        });
        this.fetchRedeemPrizes();
        this.makeToast({ variant: 'success', msg: 'Status updated successfully!' });
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
  },

  async mounted() {
    this.fetchPrizes();

    if (get(this.$route.query, 'prize')) {
      this.filters.prize = parseInt(this.$route.query.prize);
    }

    this.fetchRedeemPrizes();
  },
};
</script>
