<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit Feature Update' : 'Post Feature Update'"
      :breadcrumb="breadcrumb"
      :container-class="null"
      class="mb-32pt"
    />

    <page-separator title="Feature Update Details" />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Target Portal" label-for="target-portal" label-class="form-label">
        <v-select
          id="target-portal"
          class="form-control v-select-custom"
          label="text"
          v-model="updateTarget"
          :reduce="feature => feature.value"
          placeholder="Select portal"
          :options="updateTargetOptions"
          :state="!$v.updateTarget.required && $v.updateTarget.$dirty ? false : null"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.updateTarget.required && $v.updateTarget.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Title" label-for="title" label-class="form-label">
        <b-form-input value="dd" id="title" placeholder="Enter title" v-model="updateTitle" required />
      </b-form-group>

      <b-form-group v-if="!$route.params.id">
        <b-form-checkbox id="same-title" v-model="status" name="same-title" value="yes" unchecked-value="no">
          Keep notification title same as feature title
        </b-form-checkbox>
      </b-form-group>

      <b-form-group v-if="status === 'no'" label-for="notification-title" label-class="form-label">
        <label for="notification-title" class="form-label"> Notification Title </label>
        <b-form-input
          id="notification-title"
          placeholder="Enter notification's title"
          v-model="notificationTitle"
          :required="status === 'no' && !$route.params.id"
          :disabled="$route.params.id"
        />
      </b-form-group>

      <!-- <b-form-group label="Summary" label-for="summary" label-class="form-label">
        <b-form-textarea
          id="summary"
          placeholder="Enter a short summary for the blog"
          :rows="5"
          v-model="blog.summary"
          required
        />
      </b-form-group> -->

      <b-form-group label="Summary" label-for="text" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
          }"
          v-model="summary"
          placeholder="Enter summary"
        />
        <b-form-invalid-feedback :state="!$v.summary.required && $v.summary.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group>
        <b-form-checkbox id="details-req" v-model="detailsReq" name="details-req" value="yes" unchecked-value="no">
          Details required?
        </b-form-checkbox>
      </b-form-group>

      <b-form-group v-if="detailsReq === 'yes'" label="Description" label-for="description" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
          }"
          v-model="description"
          placeholder="Enter details"
        />
        <b-form-invalid-feedback :state="!$v.description.required && $v.description.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Entities" label-for="entity_type" label-class="form-label">
        <v-select
          id="states"
          class="form-control v-select-custom"
          label="text"
          v-model="entityType"
          :reduce="entity => entity.value"
          placeholder="Select site"
          :options="entityTypeOptions"
          multiple
          :state="!$v.entityType.required && $v.entityType.$dirty ? false : null"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.entityType.required && $v.entityType.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? 'Update' : 'Post' }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Editor from '@tinymce/tinymce-vue';
import Page from '../../../components/Page.vue';
import { required, requiredIf } from 'vuelidate/lib/validators';

export default {
  components: { PageHeader, PageSeparator, Editor },
  extends: Page,

  data() {
    return {
      title: this.$route.params.id ? 'Edit Feature Update' : 'Post Feature Update',

      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,

      updateTarget: null,
      updateTitle: '',
      notificationTitle: '',
      status: 'no',
      summary: '',
      description: '',
      detailsReq: 'no',

      updateTargetOptions: [
        { value: 'transition', text: 'Transition' },
        { value: 'cna', text: 'Healthcare Enroll' }
      ],
      entityType: [],
      entityTypeOptions: [
        { value: 'school_admin', text: 'School Admin' },
        { value: 'school_owner', text: 'School Owner' }
      ],
      isLoading: false
    };
  },
  validations() {
    return {
      summary: { required },
      description: { required: requiredIf(() => this.detailsReq === 'yes') },
      entityType: { required },
      updateTarget: { required }
    };
  },
  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Feature Updates', to: { name: 'manage-features' } },
        {
          text: this.$route.params.id ? 'Edit Feature Update' : 'Post Feature Update',
          active: true
        }
      ];
    },
    isFormLoading() {
      return this.isLoading;
    }
  },

  methods: {
    ...mapActions('featureUpdates', ['getFeatureUpdate', 'createFeatureUpdate', 'updateFeatureUpdate']),

    async onSubmit() {
      this.isLoading = true;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            title: this.updateTitle,
            details: this.description,
            summary: this.summary,
            entities: this.entityType,
            portal: this.updateTarget,
            ...(!this.$route.params.id && {
              notification_title: this.status === 'yes' ? this.updateTitle : this.notificationTitle
            })
          };
          if (this.$route.params.id) {
            await this.updateFeatureUpdate({
              id: this.$route.params.id,
              data
            });
            this.makeToast({ variant: 'success', msg: 'Feature Update Details Updated' });
          } else {
            await this.createFeatureUpdate(data);
            this.makeToast({ variant: 'success', msg: 'Feature Update Posted' });
          }
          setTimeout(() => this.$router.push({ name: 'manage-features' }), 250);
        } catch (_err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    }
  },

  async mounted() {
    this.isLoading = true;

    try {
      if (this.$route.params.id) {
        const res = await this.getFeatureUpdate(this.$route.params.id);
        this.updateTitle = res.data.title;
        this.description = res.data.details;
        this.summary = res.data.summary;
        this.entityType = res.data.entities;
        this.updateTarget = res.data.portal;
        this.notificationTitle = res.data.extra.notification_title;
      }
      if (this.description) {
        this.detailsReq = 'yes';
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({ name: 'manage-features' });
    }

    this.isLoading = false;
  }
};
</script>
