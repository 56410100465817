<template>
  <div>
    <page-header :title="title" :container-class="containerClass" infoText="Manage your available resources." />

    <div class="page-section">
      <div :class="containerClass">
        <!-- <page-separator title="" /> -->

        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Resource"
                    v-model="searchTerm"
                    @input="onSearch"
                    :disabled="isLoading"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <div class="col-lg d-flex flex-wrap justify-content-end">
                <span class="clickable-item" v-b-popover.hover.top>
                  <b-btn
                    :to="{
                      name: getLoggedInUser.role === USER_ROLES.SUPER_ADMIN ? 'add-resource' : 'sch-add-resource',
                    }"
                    exact
                    variant="primary"
                    >Add Resource</b-btn
                  >
                </span>
              </div>
            </div>
          </div>

          <b-skeleton-wrapper :loading="isLoading">
            <template #loading>
              <b-skeleton-table :rows="5" :columns="4" :table-props="{ hover: true }"></b-skeleton-table>
            </template>

            <!-- Jobs Table -->
            <b-table
              :fields="tableColumns"
              :items="resources"
              :busy="isLoading"
              head-variant="light"
              class="table-nowrap"
              responsive
              no-local-sorting
              @sort-changed="onSortChange"
            >
              <template #cell(image_url)="data">
                <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside">
                  <b-img
                    :src="data.item.image_url"
                    class="img-fluid"
                    width="40"
                    alt="Logo"
                    v-if="data.item.image_url"
                  />
                  <i class="material-icons" v-else>event</i>
                </fmv-avatar>
              </template>
              <template #cell(title)="data">
                <strong class="mr-1"> {{ data.item.title }} </strong>
                <b-badge
                  :class="'border border-' + (data.item.is_featured ? `success text-success` : ``)"
                  variant="none"
                  >{{ data.item.is_featured ? 'Featured' : '' }}</b-badge
                >
              </template>

              <template #cell(is_visible)="data">
                <b-badge
                  :class="'border border-' + (data.item.is_visible ? `success text-success` : `danger text-danger`)"
                  variant="none"
                  >{{ data.item.is_visible ? 'Active' : 'Inactive' }}</b-badge
                >
              </template>
              <template #cell(is_featured)="data">
                <b-badge
                  :class="'border border-' + (data.item.is_featured ? `success text-success` : `danger text-danger`)"
                  variant="none"
                  >{{ data.item.is_featured ? 'Featured' : 'Not Featured' }}</b-badge
                >
              </template>

              <template #cell(actions)="data">
                <a
                  href="#"
                  class="mr-1"
                  @click.prevent="toggleResourceStatus(data.index)"
                  v-b-popover.hover.left
                  :title="data.item.is_visible ? 'Mark Inactive' : 'Mark Active'"
                >
                  <md-icon :class="data.item.is_visible ? 'text-danger' : 'text-success'">{{
                    data.item.is_visible ? 'block' : 'check_circle_outline'
                  }}</md-icon>
                </a>
                <router-link
                  :to="{
                    name: getLoggedInUser.role === USER_ROLES.SUPER_ADMIN ? 'edit-resource' : 'sch-edit-resource',
                    params: { id: data.item.id },
                  }"
                  class="svg-icon mr-1"
                  v-b-popover.hover.left
                  :title="$t('generalMsgs.edit')"
                >
                  <md-icon class="text-info">edit</md-icon>
                </router-link>
                <a
                  href="#"
                  @click.prevent="confirmDeleteResource(data.item.id)"
                  v-b-popover.hover.left
                  :title="$t('generalMsgs.remove')"
                >
                  <md-icon class="text-danger">delete</md-icon>
                </a>
              </template>
            </b-table>

            <!-- Footer Pagination -->
            <div class="card-footer">
              <pagination
                v-model="currentPage"
                :total-rows="totalResources"
                :per-page="perPage"
                @change="onPageChange"
                aria-controls="scholarships-table"
              />
            </div>
          </b-skeleton-wrapper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';

import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import MdIcon from '@/components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE, USER_ROLES } from '@/common/constants';

export default {
  components: {
    MdIcon,
    PageHeader,
    // PageSeparator
    FmvAvatar,
    Pagination,
  },
  extends: Page,

  name: 'ListResources',

  data() {
    return {
      title: 'Resources',
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalResources: 0,
      resources: [],
      searchTerm: '',
      USER_ROLES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    tableColumns() {
      return [
        // { key: 'id', label: 'ID', tdClass: 'clickable-item' },
        { key: 'image_url', label: '' },
        { key: 'title', label: 'Title', tdClass: 'clickable-item' },

        { key: 'is_visible', label: this.$t('generalMsgs.status'), tdClass: 'clickable-item' },

        ...(this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN
          ? [{ key: 'school.name', label: 'School', tdClass: 'clickable-item' }]
          : []),

        {
          key: 'actions',
          label: '',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('school', ['getAllResources', 'updateResource', 'deleteResource']),
    get,

    async fetchResources(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllResources({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.resources = response.data.results;

      this.currentPage = pageNum;
      this.totalResources = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchResources(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchResources();
    },

    onSearch() {
      this.debouncedSearchResources(this);
    },

    debouncedSearchResources: debounce((vm) => {
      vm.fetchResources();
    }, 500),

    async toggleResourceStatus(index) {
      const currStatus = this.resources[index].is_visible;
      this.resources[index].is_visible = !currStatus;

      await this.updateResource({ id: this.resources[index].id, data: { is_visible: !currStatus } });
      this.makeToast({ variant: 'success', msg: `Resource marked as ${currStatus ? 'Inactive' : 'Active'}` });
    },

    async confirmDeleteResource(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to remove the selected resource.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deleteResource(id);
          const refreshPage = this.resources.length > 1 ? this.currentPage : this.currentPage - 1;
          this.fetchResources(refreshPage || 1);
          this.makeToast({ variant: 'success', msg: 'Resource Successfully Removed' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
  },

  async mounted() {
    this.fetchResources();
  },
};
</script>
