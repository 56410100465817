<template>
  <div>
    <div class="d-flex" v-if="isLoading">
      <div style="width: 6%">
        <b-skeleton-icon icon="question" :icon-props="{ fontScale: 3 }"></b-skeleton-icon>
      </div>
      <div class="mr-2" style="width: 82%">
        <b-skeleton width="25%"></b-skeleton>
        <b-skeleton width="95%"></b-skeleton>
      </div>
      <div>
        <b-skeleton type="button" width="100px"></b-skeleton>
      </div>
    </div>
    <div v-else class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center mr-2">
        <md-icon v-if="icon" class="mr-2 setup-guide-icon">{{ icon }}</md-icon>
        <i v-else style="margin-right: 10px" class="fas fa-coins ml-1 setup-guide-icon-font-awesome"></i>
        <div>
          <strong>{{ title }}</strong>
          <p class="mb-0 text-muted">
            {{ description }}
          </p>
        </div>
      </div>
      <b-btn variant="primary" class="setup-guide-btn btn-normal" disabled v-if="isLoading"
        ><i class="fas fa-circle-notch fa-spin"></i
      ></b-btn>
      <b-btn variant="success" class="setup-guide-btn btn-normal" :to="{ name: redirectTo }" v-else-if="status"
        ><md-icon>check</md-icon></b-btn
      >
      <router-link v-else class="btn btn-primary setup-guide-btn btn-normal" :to="{ name: redirectTo }">{{
        btnText
      }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GuideRow',
  props: {
    icon: { type: String, default: () => '' },
    title: { type: String, default: () => '' },
    description: { type: String, default: () => '' },
    redirectTo: { type: String, default: () => '' },
    isLoading: { type: Boolean, default: () => true },
    status: { type: Boolean, default: () => false },
    btnText: { type: String, default: () => 'Explore' },
  },
};
</script>

<style>
</style>