<template>
  <div class="container">
    <div class="d-flex py-4 mb-2">
      <img :src="get(school, 'school_info.logo_url')" alt="" style="width: 100px; height: 100px" />
      <div class="ml-2 mt-2 px-2">
        <h3 class="mb-2">
          {{ get(school, 'school_info.name') }}
        </h3>
        <b-badge
          v-if="get(school, 'review_status') === REVIEW_STATUSES.REJECTED"
          variant="none"
          class="border border-danger text-danger mb-2"
          >Disapproved</b-badge
        >
        <b-badge
          v-if="get(school, 'review_status') === REVIEW_STATUSES.APPROVED"
          variant="none"
          class="border border-success text-success mb-2"
          >Approved</b-badge
        >
        <div v-if="get(school, 'review_status') !== REVIEW_STATUSES.APPROVED">
          <b-btn
            class="mr-2"
            variant="success"
            style="width: 150px"
            :to="{
              name: 'add-interested-school',
              query: { signupRequestId: get(school, 'id') },
            }"
          >
            Approve
          </b-btn>
          <b-btn
            v-if="get(school, 'review_status') === REVIEW_STATUSES.PENDING"
            @click.prevent="disapprove()"
            variant="danger"
            style="width: 150px"
          >
            Disapprove
          </b-btn>
        </div>
      </div>
    </div>
    <div v-if="get(school, 'review_status') === REVIEW_STATUSES.REJECTED">
      <b-alert show variant="danger">
        <b> Disapproval reason </b>
        <div class="mt-2">
          {{ get(school, 'review_remarks') }}
        </div>
      </b-alert>
    </div>

    <b-form>
      <page-separator title="Owner's Information" />

      <div class="row">
        <b-form-group :label="$t('userMsgs.firstName')" label-for="firstname" label-class="form-label" class="col-md-6">
          <b-form-input
            id="firstname"
            :placeholder="$t('userMsgs.firstName')"
            :value="get(school, 'school_info.owner.first_name')"
            disabled
          />
        </b-form-group>

        <b-form-group :label="$t('userMsgs.lastName')" label-for="lastname" label-class="form-label" class="col-md-6">
          <b-form-input
            id="lastname"
            :placeholder="$t('userMsgs.lastName')"
            :value="get(school, 'school_info.owner.last_name')"
            disabled
          />
        </b-form-group>
      </div>

      <b-form-group :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label">
        <b-form-input
          id="email"
          :placeholder="$t('authMsgs.emailAddr')"
          type="email"
          :value="get(school, 'school_info.owner.email')"
          disabled
        />
      </b-form-group>

      <page-separator title="School Information" />

      <b-form-group label="Contact Email" label-for="email-school" label-class="form-label">
        <b-form-input
          id="email-school"
          :placeholder="$t('authMsgs.emailAddr')"
          type="email"
          :value="get(school, 'school_info.email')"
          disabled
        />
      </b-form-group>
      <b-form-group label="Phone" label-for="phone" label-class="form-label" class="col-md-6">
        <b-form-input id="phone" placeholder="Enter phone" :value="get(school, 'school_info.phone')" disabled />
      </b-form-group>
      <b-form-group label="Website" label-for="website_url " label-class="form-label" class="col-md-6">
        <b-form-input
          id="website_url "
          placeholder="Enter website url"
          :value="get(school, 'school_info.website_url ')"
          disabled
        />
      </b-form-group>

      <!-- Address Fields -->
      <b-form-group label="Street Address" label-for="address" label-class="form-label">
        <b-form-input id="address" placeholder="Street Address" :value="get(school, 'school_info.address')" disabled />
      </b-form-group>

      <div class="row">
        <b-form-group :label="$t('addressMsgs.zipcode')" label-for="zipcode" label-class="form-label" class="col-md-6">
          <b-form-input
            id="zipcode"
            :placeholder="$t('addressMsgs.zipcode')"
            :value="get(school, 'school_info.zipcode')"
            disabled
          />
        </b-form-group>

        <b-form-group :label="$t('addressMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
          <b-form-input
            id="city"
            :placeholder="$t('addressMsgs.city')"
            :value="get(school, 'school_info.city')"
            disabled
          />
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group :label="$t('addressMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
          <v-select
            id="state"
            class="form-control v-select-custom"
            label="text"
            :value="get(school, 'school_info.state')"
            disabled
          >
          </v-select>
        </b-form-group>

        <b-form-group :label="$t('addressMsgs.country')" label-for="country" label-class="form-label" class="col-md-6">
          <v-select
            id="country"
            class="form-control v-select-custom"
            label="text"
            :value="get(school, 'school_info.country')"
            disabled
          >
          </v-select>
        </b-form-group>
      </div>

      <b-form-group label="Class Tuition Range" label-for="classTuitionRange" label-class="form-label">
        <div class="row">
          <b-form-group label="From" label-for="fromClassTuition" label-class="form-label" class="col-md-6">
            <currency-input
              id="fromClassTuition"
              :value="get(school, 'school_info.from_class_tuition')"
              class="form-control"
              placeholder="Enter tuition fee range"
              :allow-negative="false"
              :currency="{ prefix: '$', suffix: '' }"
              disabled
            />
          </b-form-group>
          <b-form-group label="To" label-for="toClassTuition" label-class="form-label" class="col-md-6">
            <currency-input
              id="toClassTuition"
              :value="get(school, 'school_info.to_class_tuition')"
              class="form-control"
              placeholder="Enter tuition fee range"
              :allow-negative="false"
              :currency="{ prefix: '$', suffix: '' }"
              disabled
            />
          </b-form-group>
        </div>
      </b-form-group>

      <b-form-group label="Pass Rate" label-for="passRatio" label-class="form-label">
        <currency-input
          id="passRatio"
          :value="get(school, 'school_info.pass_ratio')"
          class="form-control"
          placeholder="Enter pass percentage"
          :allow-negative="false"
          :value-range="{ min: 0, max: 100 }"
          :currency="{ prefix: '', suffix: '%' }"
          disabled
        />
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.offeredPrg')" label-for="programs" label-class="form-label">
        <v-select
          id="programs"
          class="form-control v-select-custom"
          label="title"
          :value="get(school, 'school_info.programs')"
          :reduce="(programs) => programs.id"
          :options="allPrograms"
          multiple
          disabled
        >
        </v-select>
      </b-form-group>

      <b-form-group label="Average Program Weeks" label-for="avgProgramWeeks" label-class="form-label">
        <b-form-input
          id="avgProgramWeeks"
          :value="get(school, 'school_info.avg_program_weeks')"
          class="form-control"
          placeholder="Enter average program weeks"
          type="number"
          :allow-negative="false"
          disabled
        />
      </b-form-group>

      <b-form-group label="Students Trained Last Year" label-for="stdTrainedLastYear" label-class="form-label">
        <b-form-input
          id="stdTrainedLastYear"
          :value="get(school, 'school_info.trained_students') ? $n(get(school, 'school_info.trained_students')) : ''"
          class="form-control"
          placeholder="Enter number of students trained last year"
          type="number"
          :allow-negative="false"
          disabled
        />
      </b-form-group>

      <b-form-group label="Best School Offerings" label-for="bestSchoolOfferings" label-class="form-label">
        <v-select
          id="bestSchoolOfferings"
          class="form-control v-select-custom"
          label="text"
          :value="get(school, 'school_info.best_school_offerings')"
          :reduce="(item) => item.value"
          placeholder="Select best school offerings"
          :options="BestSchoolOfferingsOptions"
          multiple
          disabled
        >
        </v-select>

        <b-form-input
          id="other_offerings"
          class="mt-2"
          v-if="get(school, 'school_info.other_offerings')"
          placeholder="Please specify."
          :value="get(school, 'school_info.other_offerings')"
          disabled
        />
      </b-form-group>

      <b-form-group
        v-if="get(school, 'universities.length')"
        label="Select All Interested Universities"
        label-for="universities"
        label-class="form-label"
      >
        <div class="">
          <div class="row">
            <div
              class="mb-2 col-md-6"
              style="cursor: pointer"
              v-for="option in get(school, 'universities')"
              :key="option.id"
            >
              <div class="card p-3 my-auto">
                <b-form-checkbox v-model="option.id" name="universities" :value="option.id" disabled>
                  <div class="d-flex">
                    <img style="width: 40px; height: 40px" class="mr-2" :src="option.small_logo_url" alt="" />
                    <span style="font-size: 16px">
                      {{ option.name }}
                    </span>
                  </div>
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </b-form-group>

      <b-form-group
        v-if="get(school, 'best_cities.length')"
        label="Select All High Schools"
        label-for="best_cities"
        label-class="form-label"
      >
        <div class="">
          <div class="row">
            <div
              class="mb-2 col-md-6"
              style="cursor: pointer"
              v-for="option in get(school, 'best_cities')"
              :key="option.id"
            >
              <div class="card p-3 my-auto">
                <b-form-checkbox v-model="option.id" name="best_cities" :value="option.id" disabled>
                  <div class="d-flex">
                    <img style="width: 40px; height: 40px" class="mr-2" :src="option.image_url" alt="" />
                    <span style="font-size: 16px">
                      {{ option.name }}
                    </span>
                  </div>
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </b-form-group>

      <b-form-group
        v-if="!isEmpty(get(school, 'organization'))"
        label="Interested In"
        label-for="organization"
        label-class="form-label"
      >
        <div class="mb-2 col-md-6" style="cursor: pointer">
          <div class="card p-3 my-auto">
            <div class="d-flex">
              <img
                style="width: 40px; height: 40px"
                class="mr-2"
                :src="get(school, 'organization.small_logo_url')"
                alt=""
              />
              <span style="font-size: 16px">
                {{ get(school, 'organization.name') }}
              </span>
            </div>
          </div>
        </div>
      </b-form-group>
    </b-form>
    <disapprove-modal :show-modal="showDisapproveModal" @close="hideDisapproveModal" :school="school" />
  </div>
</template>

<script>
import { get, isEmpty } from 'lodash';
import { mapActions } from 'vuex';
import Page from '../../../components/Page.vue';
import DisapproveModal from './DisapproveModal.vue';
import { REVIEW_STATUSES, SCHOOL_OFFERINGS } from '../../../common/constants';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  extends: Page,
  components: { DisapproveModal, PageSeparator },
  data() {
    return {
      title: '',
      school: {},
      showDisapproveModal: false,
      REVIEW_STATUSES,
      BestSchoolOfferingsOptions: SCHOOL_OFFERINGS,
      allPrograms: [],
      areProgramsLoading: false,
    };
  },
  watch: {
    school: {
      immediate: true,
      deep: true,
      handler(value) {
        this.title = get(value, 'school_info.name');
      },
    },
  },
  methods: {
    ...mapActions('school', ['getInterestedSchool']),
    ...mapActions('program', ['getAllPublicPrograms']),
    get,
    isEmpty,
    disapprove() {
      this.showDisapproveModal = true;
    },
    hideDisapproveModal(no) {
      this.showDisapproveModal = false;
      if (!no) {
        this.fetchInterestedSchool();
      }
    },
    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getAllPublicPrograms();
      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },
    async fetchInterestedSchool() {
      const res = await this.getInterestedSchool(this.$route.params.id);

      this.school = res.data;
    },
  },
  mounted() {
    this.fetchInterestedSchool();
    this.fetchPrograms();
  },
};
</script>

<style></style>
