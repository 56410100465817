<template>
  <b-modal ref="addQuestionModal" hide-footer title="Add Question" centered lazy @hide="hideModal" size="md">
    <b-form-group label="Questions" label-for="questions" label-class="form-label">
      <v-select
        id="type"
        class="form-control v-select-custom"
        label="title"
        v-model="questionObj"
        :reduce="(que) => que.id"
        placeholder="Select Question "
        :options="questionOptions"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!questionObj" v-bind="attributes" v-on="events" />
        </template>
        <template slot="selected-option" slot-scope="option">
          {{ truncate(option.title, { length: 40 }) }}
        </template>
      </v-select>
      <b-form-invalid-feedback :state="!$v.questionObj.required && $v.questionObj.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group label-class="form-label">
      <b-form-checkbox v-model="isRequired" name="check-button" switch> Is Required? </b-form-checkbox>
    </b-form-group>
    <div class="d-flex justify-content-end">
      <b-btn
        variant="primary"
        class="ml-2"
        style="width: 70px"
        @click.prevent="submitData"
        href="#"
        data-toggle="modal"
      >
        <span>{{ question ? $t('update') : $t('add') }}</span></b-btn
      >
    </div>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { truncate } from 'lodash';
export default {
  name: 'AddQuestionModal',

  props: {
    showModal: { type: Boolean, default: false },
    question: { type: Object, default: null },
    selectedQuestions: {
      type: Array,
      default() {
        return [];
      },
    },
    questions: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      questionObj: {},
      questionOptions: [],
      isLoading: false,
      isRequired: false,
    };
  },
  validations() {
    return {
      questionObj: { required },
    };
  },
  methods: {
    truncate,
    resetData() {
      this.questionObj = null;
      this.isRequired = false;
    },
    submitData() {
      this.$refs.addQuestionModal.hide();
      const obj = this.questions.find((q) => q.id === this.questionObj);
      obj['required'] = this.isRequired;
      this.$emit('update', obj);
    },

    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.addQuestionModal.hide();
      this.hideModal();
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        this.$refs.addQuestionModal.show();
        const itemsToRemove = this.selectedQuestions.map((a) => a.id);
        this.questionOptions = this.questions.filter((ques) => !itemsToRemove.includes(ques.id));
        this.resetData();
      }
    },
  },
};
</script>

<style>
</style>