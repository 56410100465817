var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-header',{attrs:{"title":_vm.title,"container-class":_vm.containerClass,"info-text":_vm.getLoggedInUser.role_type === _vm.USER_ROLE_TYPES.ORGANIZATION
        ? "List of all users who took their career assessments using your site."
        : _vm.getLoggedInUser.role_type === _vm.USER_ROLE_TYPES.EMPLOYER
        ? 'List of all users who created their resumes using your site'
        : ''}}),_c('div',{staticClass:"page-section"},[_c('div',{class:_vm.containerClass},[_c('div',{staticClass:"card mb-0"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center",staticStyle:{"white-space":"nowrap"}},[_c('div',{staticClass:"col-lg-auto"},[_c('form',{staticClass:"search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-input',{staticClass:"w-lg-auto",attrs:{"placeholder":"Search Assessments"},on:{"input":_vm.onSearch},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('b-btn',{attrs:{"variant":"flush","type":"submit"}},[_c('md-icon',{domProps:{"textContent":_vm._s('search')}})],1)],1)]),_c('div',{staticClass:"col-lg d-flex flex-wrap justify-content-end"})])]),_c('b-table',{staticClass:"table-nowrap",attrs:{"fields":_vm.tableColumns,"items":_vm.assessments,"busy":_vm.isLoading,"head-variant":"light","hover":"","responsive":"","no-local-sorting":""},on:{"sort-changed":_vm.onSortChange,"row-clicked":_vm.onRowClick},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('strong',[_vm._v(" "+_vm._s(data.item.first_name)+" "+_vm._s(data.item.last_name)+" ")])]}},{key:"cell(entity)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('fmv-avatar',{attrs:{"slot":"aside","title":true,"title-class":"bg-transparent","rounded":"","no-link":""},slot:"aside"},[(data.item.entity.small_logo_url || data.item.entity.image_url)?_c('b-img',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.left",modifiers:{"hover":true,"left":true}}],staticClass:"img-fluid",attrs:{"src":data.item.entity.small_logo_url || data.item.entity.image_url,"width":"40","alt":"Logo","title":_vm.get(data.item, 'entity_type') === _vm.ENTITY_TYPES.ORGANIZATION
                      ? 'Organization'
                      : _vm.get(data.item, 'entity_type') === _vm.ENTITY_TYPES.DISTRICT
                      ? 'District'
                      : 'University'}}):_c('i',{staticClass:"fas fa-university fa-lg text-primary"})],1),_vm._v(" "+_vm._s(_vm.get(data.item.entity, 'name') || _vm.get(data.item.entity, 'title'))+" ")],1)]}},{key:"cell(portal)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(("portalTypes." + (data.item.portal))))+" ")]}},{key:"head(actions)",fn:function(){return [_c('span')]},proxy:true},{key:"cell(actions)",fn:function(data){return [_c('a',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.left",modifiers:{"hover":true,"left":true}}],attrs:{"href":"#","title":"Preview"},on:{"click":function($event){$event.preventDefault();return _vm.openListModal(data.item.id)}}},[_c('md-icon',{staticClass:"text-primary"},[_vm._v("remove_red_eye")])],1)]}}])}),_c('div',{staticClass:"card-footer"},[_c('pagination',{attrs:{"total-rows":_vm.totalAssessments,"per-page":_vm.perPage,"aria-controls":"students-table"},on:{"change":_vm.onPageChange},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)])]),_c('assess-list-modal',{attrs:{"showModal":_vm.showListModal,"id":_vm.rowId},on:{"close":_vm.hideListModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }