<template>
  <div>
    <page-header :title="title" :container-class="containerClass" :breadcrumb="breadcrumb" />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Sections"
                    v-model="searchTerm"
                    @input="onSearch"
                  />

                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-6 mx-0 px-0"></div>

                <b-btn
                  class="ml-2"
                  exact
                  variant="primary"
                  :to="{
                    name: 'add-section',
                    params: {
                      sponsorship: this.$route.params.id,
                    },
                  }"
                  v-text="'Add Section'"
                />
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="allSections"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
            @row-clicked="showPage"
          >
            <template #cell(name)="data"> {{ data.item.name }}</template>
            <template #cell(question_count)="data">
              {{
                get(data.item, 'isStatic')
                  ? data.item.question_count
                  : $n(get(quesCountBySection, `${data.item.id}.question_count`, 0))
              }}</template
            >
            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <a
                v-if="!get(data.item, 'isStatic')"
                @click.prevent="editSection(data.index, data.item)"
                class="svg-icon mr-2"
                v-b-popover.hover.right
                :title="'Edit'"
              >
                <i class="mr-1 material-icons">edit</i>
              </a>
              <a
                href="#"
                v-if="!get(data.item, 'isStatic')"
                @click.prevent="removeSection(data.item.id)"
                v-b-popover.hover.right
                :title="'Delete'"
              >
                <i class="mr-1 material-icons text-danger">delete</i>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalSections"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="schools-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, get, keyBy } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';

export default {
  name: 'ManageRegistrationForm',

  components: {
    //MdIcon,
    PageHeader,
    Pagination,
  },
  extends: Page,

  data() {
    return {
      title: 'Manage Sponsorship Questions for Application Form',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalSections: 0,
      searchTerm: '',

      allSections: [],
      staticSection: {},

      showAddSection: false,
      areCountsLoading: false,
      quesCountBySection: {},
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Sponsorships', to: { name: 'scholarships-management-list' } },
        ...(this.$route.params.name ? [{ text: this.$route.params.name, active: true }] : []),
        {
          text: this.$route.name === 'manage-application-form' ? 'Application Form' : 'Pre Enrollment Form',
          active: true,
        },
      ];
    },
    tableColumns() {
      return [
        { key: 'title', label: 'Section', tdClass: 'clickable-item' },
        { key: 'question_count', label: 'Questions', tdClass: 'clickable-item' },
        { key: 'actions', tdClass: 'text-right clickable-item', thStyle: { minWidth: '100px' } },
      ];
    },
  },

  methods: {
    ...mapActions('sections', ['getAllSections', 'deleteSection', 'getQuestionCount']),
    get,
    async fetchQuestionCount(data) {
      this.areCountsLoading = true;
      try {
        const resp = await this.getQuestionCount({
          sections: data.map((cs) => cs.id),
        });
        this.quesCountBySection = keyBy(resp, 'section');
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areCountsLoading = false;
    },
    showPage(data, index) {
      if (get(data, 'isStatic')) {
        this.$router.push({
          name: 'configure-reg-static-fields',

          params: {
            sponsorship: this.$route.params.id,
          },
        });
      } else {
        this.$router.push({
          name: 'edit-section',
          params: {
            sponsorship: this.$route.params.id,
            id: this.allSections[index].id,
          },
        });
      }
    },

    editSection(index, data) {
      this.$router.push({
        name: 'edit-section',
        params: {
          sponsorship: this.$route.params.id,
          id: data.id,
        },
      });
    },
    async removeSection(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this section.', {
          title: 'Delete Section',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        });
        if (isConfirmed) {
          await this.deleteSection(id);
          this.fetchSections();
          this.makeToast({ variant: 'success', msg: 'Section Deleted!' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async fetchSections(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllSections({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,

        scholarship: this.$route.params.id,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),

        ...params,
      });

      this.allSections = response.data.results;

      this.fetchQuestionCount(response.data.results);
      this.currentPage = pageNum;
      this.totalSections = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchSections(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchSections();
    },

    onSearch() {
      this.debouncedSearchSections(this);
    },

    debouncedSearchSections: debounce((vm) => {
      vm.fetchSections();
    }, 500),
  },

  async mounted() {
    this.fetchSections();
  },
};
</script>
