var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.containerClass},[_c('page-header',{staticClass:"mb-16pt",attrs:{"title":this.$route.params.id ? 'Edit State' : 'Add New State',"container-class":null}}),_c('b-form',{staticClass:"col-md-12 px-0 page-section pt-0",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"row-align-items-center",attrs:{"label":"Image","label-for":"image","label-class":"form-label"}},[_c('b-media',{staticClass:"align-items-center",attrs:{"vertical-align":"center"}},[_c('fmv-avatar',{attrs:{"slot":"aside","title":true,"rounded":"","size":"lg","no-link":""},slot:"aside"},[(_vm.isImgUploading)?_c('span',[_vm._v("...")]):(_vm.image)?_c('b-img',{staticClass:"img-fluid",attrs:{"src":_vm.image,"width":"40","alt":"Image"}}):_c('i',{staticClass:"fas fa-shopping-bag fa-lg"})],1),_c('image-uploader',{attrs:{"isUploading":_vm.isImgUploading,"isRequired":!_vm.image,"placeholder":"Select Image"},on:{"image":function (e) { return _vm.setImage(e, 'image'); }}})],1)],1),_c('b-form-group',{attrs:{"label":_vm.$t('schoolMsgs.state'),"label-for":"state","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",attrs:{"id":"state","label":"text","reduce":function (state) { return state.value; },"placeholder":"Select State","options":_vm.stateOptions},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.name}},'input',attributes,false),events))]}}]),model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('b-form-group',{attrs:{"label":"Description","label-for":"description","label-class":"form-label"}},[_c('b-form-textarea',{attrs:{"id":"description","placeholder":"Enter State's Description","rows":5},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('b-form-group',{attrs:{"label":"How we rate schools?","label-for":"rankDesc","label-class":"form-label"}},[_c('editor',{attrs:{"init":{
          height: 400,
          menubar: 'edit view insert format tools table help',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | removeformat | help'
        },"placeholder":"Enter the description for ranking schools here."},model:{value:(_vm.rankDesc),callback:function ($$v) {_vm.rankDesc=$$v},expression:"rankDesc"}})],1),_c('b-form-group',{attrs:{"label-class":"form-label"}},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_vm._v(" Active? ")])],1),_c('b-btn',{staticClass:"btn-normal",staticStyle:{"width":"150px"},attrs:{"variant":"primary","disabled":_vm.isFormLoading,"type":"submit"}},[(_vm.isLoading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('span',[_vm._v(_vm._s(_vm.$route.params.id ? _vm.$t('update') : _vm.$t('add')))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }