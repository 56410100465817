<template>
  <b-modal
    ref="partneredOrgsModal"
    hide-footer
    title="Are you sure?"
    centered
    lazy
    size="md"
    scrollable
    @hide="hideModal"
  >
    <div class="" style="font-size: 12pt">
      You are about to make the following changes, are you sure you want to continue?
    </div>
    <div v-if="organizationsAdded.length" class="mt-4">
      <div>
        <b>Partnered Organizations Added</b>
        <i
          class="material-icons icon-16pt clickable-item text-muted ml-1 mb-1"
          v-b-popover.hover.top="`All employers of newly added organizations will be partnered with this school.`"
          >info</i
        >
      </div>
      <b-badge pill variant="dark" v-for="(org, index) of organizationsAdded" :key="`org-${index}`" class="mr-2">
        {{ org.name }}
      </b-badge>
    </div>

    <div v-if="organizationsRemoved.length" class="mt-2">
      <div>
        <b>Partnered Organizations Removed</b>
        <i
          class="material-icons icon-16pt clickable-item text-muted ml-1 mb-1"
          v-b-popover.hover.top="`Partnership with all the employers of these organizations will be revoked.`"
          >info</i
        >
      </div>
      <b-badge pill variant="dark" v-for="(org, index) of organizationsRemoved" :key="`org-${index}`" class="mr-2">
        {{ org.name }}
      </b-badge>
    </div>

    <div class="row mt-4 d-flex justify-content-end">
      <b-button @click.prevent="hideModalManual()" variant="danger" size="sm">Continue</b-button>
      <b-button variant="light" @click.prevent="hideModal()" size="sm" class="ml-2 mr-3">Cancel</b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';
export default {
  name: 'PartneredOrgsModal',

  props: {
    showModal: { type: Boolean, default: false },
    refOrgs: { type: Array, default: () => [] },
    orgs: { type: Array, default: () => [] },
  },

  data() {
    return {
      reason: '',
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    organizationsAdded() {
      return this.orgs.filter((x) => !this.refOrgs.includes(x));
    },
    organizationsRemoved() {
      return this.refOrgs.filter((x) => !this.orgs.includes(x));
    },
  },

  watch: {
    showModal(value) {
      if (value) {
        setTimeout(() => this.$refs.partneredOrgsModal.show());
      }
    },
  },

  methods: {
    get,

    hideModal() {
      this.$refs.partneredOrgsModal.hide();
      this.$emit('close');
    },
    hideUpdate() {
      this.$emit('closeUpdate');
    },
    hideModalManual() {
      this.$refs.partneredOrgsModal.hide();
      this.hideUpdate();
    },
  },

  async mounted() {},
};
</script>
