<template>
  <div>
    <page-header :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section border-bottom-2">
      <div :class="containerClass">
        <div class="row" v-if="isLoading">
          <div class="col-md-4 col-6" v-for="i in [1, 2, 3]" :key="i">
            <b-card>
              <b-skeleton class="mb-2" width="25px" height="25px"></b-skeleton>
              <b-skeleton width="25%" class="mb-2"></b-skeleton>
              <b-skeleton animation="wave" width="85%"></b-skeleton>
              <b-skeleton animation="wave" width="75%"></b-skeleton>
              <b-skeleton animation="wave" width="80%"></b-skeleton>
            </b-card>
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-md-4 col-sm-6" v-for="template in allTemplates" :key="template.id">
            <div class="card p-3" style="height: 95%" :id="template.id">
              <div class="ml-0">
                <img
                  style="object-fit: contain"
                  class="mb-2"
                  v-if="template.image"
                  :src="getImage(template.image)"
                  width="45"
                  height="45"
                />
                <md-icon v-else style="font-size: 40px" class="mb-2 text-primary">descriptionoutlined</md-icon>
              </div>
              <div class="mb-2">
                <strong> {{ template.title }} </strong>
              </div>
              <b-card-text>{{ template.description }}</b-card-text>
              <div class="mt-auto">
                <b-btn
                  class="w-100"
                  style="height: 40px"
                  :variant="template.enabled ? 'success' : template.variant"
                  @click.prevent="template.enabled ? null : template.btnAction()"
                  :disabled="template.disabled"
                >
                  <i v-if="template.isLoading" class="fas fa-circle-notch fa-spin"></i>
                  <span v-else>
                    <md-icon v-if="template.enabled" style="font-size: 20px" class="mr-1">check</md-icon>
                    {{ template.enabled ? 'Enabled' : template.btnTitle }}
                  </span>
                </b-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import PageHeader from '@/components/Ui/PageHeader.vue';
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
var UsaStates = require('usa-states').UsaStates;
import generator from 'generate-password';

export default {
  components: { PageHeader },
  extends: Page,

  data() {
    return {
      title: 'Integrations',
      isLoading: false,
      isFacetLinkLoading: false,
      isFacetLinked: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getIsFacetsLinked']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Integrations', active: true },
      ];
    },
    allTemplates() {
      return [
        {
          id: 1,
          image: 'integration1.webp',
          title: 'FACETS Healthcare',
          description:
            'With FACETS, experience seamless syncing of online learning and easy management of healthcare-focused course content, quizzes, and assignments.',
          btnTitle: 'Configure',
          btnAction: this.linkToFacet,
          isLoading: this.isFacetLinkLoading,
          enabled: this.isFacetLinked,
          disabled: false,
          variant: 'primary',
        },
        {
          id: 2,
          image: 'integration2.png',
          title: 'Mailchimp',
          description: `Combine Mailchimp's powerful email marketing with your student data for more effective, targeted communication.`,
          btnTitle: 'Coming Soon',
          btnAction: null,
          isLoading: false,
          enabled: false,
          disabled: true,
          variant: 'light',
        },
        {
          id: 3,
          image: 'integration3.png',
          title: 'Google Sheets',
          description:
            'Connect with Google Sheets for effortless student data synchronization. Collaborate on spreadsheets in real-time.',
          btnTitle: 'Coming Soon',
          btnAction: null,
          isLoading: false,
          enabled: false,
          disabled: true,
          variant: 'light',
        },
        {
          id: 4,
          image: 'integration4.png',
          title: 'TalentLMS',
          description:
            'Integrate TalentLMS to sync your online learning management system, fostering growth and learning in a streamlined manner.',
          btnTitle: 'Coming Soon',
          btnAction: null,
          isLoading: false,
          enabled: false,
          disabled: true,
          variant: 'light',
        },
        {
          id: 5,
          image: 'integration5.png',
          title: 'Hubspot',
          description: `Sync your student data with Hubspot. Benefit from real-time integration within your dashboard.`,
          btnTitle: 'Coming Soon',
          btnAction: null,
          isLoading: false,
          enabled: false,
          disabled: true,
          variant: 'light',
        },
        {
          id: 6,
          image: 'integration6.jpeg',
          title: 'Moodle',
          description:
            'Sync student data with Moodle and manage secure, engaging eLearning experiences directly within your system.',
          btnTitle: 'Coming Soon',
          btnAction: null,
          isLoading: false,
          enabled: false,
          disabled: true,
          variant: 'light',
        },
      ];
    },
  },
  watch: {
    getIsFacetsLinked: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.isFacetLinked = this.getIsFacetsLinked;
        }
      },
    },
  },
  methods: {
    ...mapActions('school', ['linkFacets', 'getFacetsVerification']),
    get,
    getImage(image) {
      return require(`@/assets/images/integrations/${image}`);
    },
    stateAbbreviation(name) {
      var usStates = new UsaStates();
      return get(usStates.states.filter((state) => state.name === name)[0], 'abbreviation');
    },
    async fetchFacetVerification() {
      await this.getFacetsVerification();
    },
    async linkToFacet() {
      this.isFacetLinkLoading = true;

      const data = {
        state_abbr: this.stateAbbreviation(this.getLoggedInUser.linked_entity.state),
        password: generator.generate({ length: 10, numbers: true, strict: true }),
      };
      try {
        await this.linkFacets(data);

        this.isFacetLinked = this.getIsFacetsLinked;
        if (this.isFacetLinked) {
          this.makeToast({ variant: 'success', msg: 'Your Account Linked Successfully.' });
        } else {
          this.isFacetLinked = false;
          this.makeToast({ variant: 'danger', msg: 'Unable To Link Please Try Again Later.' });
        }
      } catch (error) {
        //
      }

      this.isFacetLinkLoading = false;
    },
  },

  async mounted() {},
};
</script>
