<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator :title="title" />

        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Universities"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <!-- TODO: Add filters here. (if needed) -->
                <!-- <b-dropdown class="ml-lg-auto" :text="$t('All Topics')" variant="link text-black-70" right>
                  <b-dropdown-item active>All Topics</b-dropdown-item>
                  <b-dropdown-item>My Topics</b-dropdown-item>
                </b-dropdown>

                <b-dropdown class="mr-8pt" :text="$t('Newest')" variant="link text-black-70" right>
                  <b-dropdown-item active>Newest</b-dropdown-item>
                  <b-dropdown-item>Unanswered</b-dropdown-item>
                </b-dropdown> -->

                <b-btn :to="{ name: 'add-uni' }" exact variant="primary" v-text="'Add University'" />
              </div>
            </div>
          </div>

          <!-- Unis Table -->
          <b-table
            :fields="tableColumns"
            :items="unis"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(name)="data">
              <strong> {{ data.item.name }} </strong>
            </template>

            <template #cell(logo)="data">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside">
                <b-img
                  :src="data.item.small_logo_url"
                  class="img-fluid"
                  width="40"
                  alt="Logo"
                  v-if="data.item.small_logo_url"
                />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
            </template>

            <template #cell(address)="data">
              {{ data.item.address }}, {{ data.item.zipcode }}, {{ data.item.city }}, {{ data.item.state }},
              {{ data.item.country }}
            </template>
            <template #cell(assessCount)="data">
              <i v-if="areAsessCountsLoading">Loading...</i>
              {{ $n(get(assessCountByEntity, `${data.item.id}.assessment_count`, 0)) }}
            </template>
            <template #cell(resume_count)="data">
              <i v-if="areResumeCountLoading">Loading...</i>
              {{ $n(get(resumeByUni, `${data.item.id}.resume_count`, 0)) }}
            </template>

            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <router-link
                :to="{
                  name: 'edit-uni',
                  params: { id: data.item.id },
                }"
                class="svg-icon mr-2"
                v-b-popover.hover.top
                :title="$t('generalMsgs.edit')"
              >
                <md-icon class="text-info">edit</md-icon>
                <!-- <i class="far fa-edit text-info" /> -->
              </router-link>

              <!-- <a
                href="#"
                @click.prevent="confirmDeleteUni(data.item.id)"
                v-b-popover.hover.top
                :title="$t('generalMsgs.remove')"
              >
                <md-icon class="text-danger">delete</md-icon>
                <i class="far fa-trash-alt text-danger" />
              </a> -->
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalUnis"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="unis-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { FmvAvatar } from 'fmv-avatar';
import { debounce, get, keyBy } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';

export default {
  components: { MdIcon, PageHeader, PageSeparator, Pagination, FmvAvatar },
  extends: Page,

  data() {
    return {
      title: 'Manage Universities',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalUnis: 0,
      unis: [],
      assessCounts: [],
      assessCountByEntity: {},
      areAsessCountsLoading: false,
      resumeCounts: [],
      resumeByUni: {},
      areResumeCountLoading: false,
      searchTerm: '',
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Manage Universities', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'logo', label: '' },
        { key: 'name', label: this.$t('schoolMsgs.name') },
        { key: 'contact_email', label: 'Email' },
        { key: 'phone', label: this.$t('schoolMsgs.phone') },
        { key: 'address', label: this.$t('schoolMsgs.address') },
        { key: 'assessCount', label: 'Assessments' },
        { key: 'resume_count', label: 'Resumes Generated', tdClass: 'clickable-item text-center' },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('uni', ['getAllUnis']),
    ...mapActions('resume', ['getResumeCounts']),
    ...mapActions('assessment', ['getEntityAssessmentCount']),
    get,
    async fetchAsessCount() {
      this.areAsessCountsLoading = true;
      try {
        const res = await this.getEntityAssessmentCount({
          entity_ids: this.unis.map((c) => c.id),
          entity_type: 'university',
        });
        this.assessCounts = res;
        this.assessCountByEntity = keyBy(res, 'entity_id');
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areAsessCountsLoading = false;
    },

    async fetchResumeCounts() {
      this.areResumeCountLoading = true;

      try {
        const response = await this.getResumeCounts({
          entity_ids: this.unis.map((p) => p.id),
          entity_type: 'university',
        });
        this.resumeCounts = response;
        this.resumeByUni = keyBy(response, 'entity_id');
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.areResumeCountLoading = false;
    },

    async fetchUnis(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllUnis({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.unis = response.data.results;
      this.currentPage = pageNum;
      this.totalUnis = response.data.count;
      this.fetchAsessCount();
      this.fetchResumeCounts();
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchUnis(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchUnis();
    },

    onSearch() {
      this.debouncedSearchUnis(this);
    },

    debouncedSearchUnis: debounce((vm) => {
      vm.fetchUnis();
    }, 500),
  },

  async mounted() {
    this.fetchUnis();
  },
};
</script>
