<template>
    <fmv-drawer id="filters" align="end">
      <filters-sidebar></filters-sidebar>
    </fmv-drawer>
  </template>
  
  <script>
  import { FmvDrawer } from 'fmv-layout';
  import FiltersSidebar from '@/views/Simulations/Filters/FiltersSidebar.vue'
  
  export default {
    name: 'Filters',
  
    components: { FmvDrawer, FiltersSidebar },
  
    data() {
      return {};
    },
  
    computed: {},
  
    methods: {},
  
    async mounted() {},
  };
  </script>
  