<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator :title="title" />

        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    :placeholder="$t('schoolMsgs.searchSchools')"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon>search </md-icon>
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-6">
                  <v-select
                    id="program"
                    class="form-control v-select-custom"
                    label="text"
                    v-model="status"
                    :reduce="(status) => status.value"
                    placeholder="Select Status"
                    :options="approveStatuses"
                    @input="fetchInterestedSchools()"
                  >
                    <template #search="{ attributes, events }">
                      <input class="vs__search" v-bind="attributes" v-on="events" />
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <b-table
            :fields="tableColumns"
            :items="schools"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap clickable-item"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
            @row-clicked="onRowClicked"
          >
            <template #cell(menu)="data">
              <div v-if="get(data, 'item.review_status') !== REVIEW_STATUSES.APPROVED" class="text-center">
                <b-dropdown :boundary="'main'" no-flip variant="flush" toggle-class="text-muted" no-caret>
                  <template #button-content>
                    <i class="material-icons icon-24pt card-course__icon-favorite text-info">more_vert</i>
                    <span class="sr-only">Menu</span>
                  </template>
                  <div>
                    <b-dropdown-item
                      :to="{
                        name: 'add-interested-school',
                        query: { signupRequestId: get(data.item, 'id') },
                      }"
                    >
                      <i class="text-success material-icons mr-1">check_circle_outline </i>Approve</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click.prevent="disapprove(data.item)"
                      v-if="get(data, 'item.review_status') === REVIEW_STATUSES.PENDING"
                    >
                      <i class="text-danger material-icons mr-1">block</i>Disapprove</b-dropdown-item
                    >
                  </div>
                </b-dropdown>
              </div>
            </template>
            <template #cell(name)="data">
              <strong> {{ data.item.school_info.name }} </strong>
            </template>

            <template #cell(logo)="data">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside">
                <b-img
                  :src="data.item.school_info.logo_url"
                  class="img-fluid"
                  width="40"
                  alt="Logo"
                  v-if="data.item.school_info.logo_url"
                />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
            </template>

            <template #cell(interested)="data">
              <div v-if="data.item.interest_from == 'signup_request'">
                <i class="fas fa-university fa-lg mr-2 text-primary" style="font-size: 24px"></i>
                <span>Universities</span>
              </div>

              <div class="d-flex align-items-center" v-else>
                <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside">
                  <b-img
                    :src="data.item.organization.small_logo_url"
                    class="img-fluid mr-2"
                    width="30"
                    alt="Logo"
                    v-if="data.item.organization.small_logo_url"
                  />
                  <i class="fas fa-university fa-lg" v-else></i>
                </fmv-avatar>
                <span> {{ data.item.organization.name }} </span>
              </div>
            </template>

            <template #cell(owner)="data">
              {{ data.item.school_info.owner.first_name }} {{ data.item.school_info.owner.last_name }}
            </template>

            <template #cell(address)="data">
              {{ data.item.school_info.address }}, {{ data.item.school_info.zipcode }},
              {{ data.item.school_info.city }}, {{ data.item.school_info.state }},
              {{ data.item.school_info.country }}
            </template>

            <template #cell(review_status)="data">
              <b-badge
                :class="
                  'border border-' +
                  (data.item.review_status === 'approved'
                    ? `success text-success`
                    : data.item.review_status === 'rejected'
                    ? `danger text-danger`
                    : `secondary text-secondary`)
                "
                variant="none"
                >{{
                  data.item.review_status === 'approved'
                    ? 'Approved'
                    : data.item.review_status === 'rejected'
                    ? 'Rejected'
                    : 'Pending'
                }}</b-badge
              >
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalSchools"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="schools-table"
            />
          </div>
        </div>
      </div>
    </div>

    <disapprove-modal :show-modal="showDisapproveModal" @close="hideDisapproveModal" :school="school" />
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { FmvAvatar } from 'fmv-avatar';
import { debounce, get } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE, REVIEW_STATUSES } from '../../../common/constants';

import DisapproveModal from './DisapproveModal.vue';

export default {
  components: {
    MdIcon,
    PageHeader,
    PageSeparator,
    Pagination,
    FmvAvatar,

    DisapproveModal,
  },
  extends: Page,

  data() {
    return {
      title: 'Manage Interested Schools',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalSchools: 0,
      schools: [],
      school: {},

      searchTerm: '',
      status: '',
      REVIEW_STATUSES,
      showDisapproveModal: false,
      approveStatuses: [
        { text: 'Approved', value: 'approved' },
        { text: 'Pending', value: 'pending' },
        { text: 'Rejected', value: 'rejected' },
      ],
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Schools' },
        { text: 'Interested Schools', active: true },
      ];
    },

    tableColumns() {
      return [
        {
          key: 'menu',
          tdClass: 'text-left',
        },
        { key: 'logo', label: '' },
        { key: 'name', label: this.$t('schoolMsgs.name') },
        { key: 'review_status', label: this.$t('generalMsgs.status') },
        { key: 'interested', label: 'Interested in' },
        { key: 'owner', label: this.$t('schoolMsgs.owner') },
        { key: 'school_info.owner.email', label: this.$t('schoolMsgs.ownersEmail') },

        { key: 'address', label: this.$t('schoolMsgs.address') },
      ];
    },
  },

  methods: {
    ...mapActions('school', ['getAllInterestedSchools']),
    get,
    disapprove(school) {
      this.showDisapproveModal = true;
      this.school = school;
    },
    hideDisapproveModal(onCancel) {
      this.showDisapproveModal = false;
      if (!onCancel) {
        this.fetchInterestedSchools();
      }
    },
    async fetchInterestedSchools(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllInterestedSchools({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.searchTerm && { search: this.searchTerm }),
        ...(this.status && { review_status: this.status }),
        ...params,
      });
      this.schools = response.data.results;

      this.currentPage = pageNum;
      this.totalSchools = response.data.count;
      this.isLoading = false;
    },
    onRowClicked(data) {
      this.$router.push({
        name: 'interested-school-profile',
        params: { id: data.id },
      });
    },

    onPageChange(pageNum) {
      this.fetchInterestedSchools(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchInterestedSchools();
    },

    onSearch() {
      this.debouncedSearchSchools(this);
    },

    debouncedSearchSchools: debounce((vm) => {
      vm.fetchInterestedSchools();
    }, 500),
  },

  async mounted() {
    this.fetchInterestedSchools();
  },
};
</script>
