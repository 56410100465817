<template>
  <div>
    <div :class="containerClass">
      <page-header :title="title" :container-class="containerClass" :info-text="''" />
      <div class="page-section">
        <div :class="containerClass">
          <div class="card mb-0">
            <div class="card-header d-flex align-items-center justify-content-end">
              <b-btn v-if="enableSidebarToggle !== false" v-fmv-toggle.filters size="sm" variant="white" class="mr-2">
                <md-icon left>tune</md-icon>
                <span>Filters</span>
              </b-btn>
            </div>
            <!-- Students Table -->
            <b-table
              :fields="tableColumns"
              :items="students"
              :busy="isLoading"
              head-variant="light"
              class="table-nowrap"
              hover
              responsive
              no-local-sorting
              @row-clicked="onRowClicked"
              @sort-changed="onSortChange"
            >
              <template #cell(name)="data">
                {{ data.item.student.user.first_name }} {{ data.item.student.user.last_name }}
              </template>

              <template #cell(simulation)="data">
                {{ data.item.simulation.title }}
              </template>

              <template #cell(school)="data">
                {{ data.item.student.school.name }}
              </template>
            </b-table>

            <!-- Footer Pagination -->
            <div class="card-footer">
              <pagination
                v-model="currentPage"
                :total-rows="totalStudents"
                :per-page="perPage"
                @change="onPageChange"
                aria-controls="students-table"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, truncate, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';

import { DEFAULT_PAGE_SIZE, USER_ROLES, USER_ROLE_TYPES } from '@/common/constants';
import { formatFullDate } from '@/common/utils';
import Filters from './Filters/Filters.vue';
import FiltersSidebar from './Filters/FiltersSidebar.vue';

export default {
  components: { PageHeader, Pagination },
  extends: Page,

  data() {
    return {
      title: 'Simulation Submissions',
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalStudents: 0,
      students: [],
      USER_ROLE_TYPES,
      USER_ROLES,
      windowWidth: window.innerWidth,
      filters: {
        search: null,
        simulation__title: null,
        current_status: null,
        call_duration__gte: null
      }
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('simulations', ['getSimulationsFilters']),
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.$t('studentMsgs.students'), active: true }
      ];
    },
    tableColumns() {
      return [
        { key: 'name', label: this.$t('studentMsgs.name'), tdClass: 'clickable-item' },
        {
          key: 'simulation',
          label: 'Simulation',
          tdClass: 'clickable-item',
          formatter: value => (value ? formatFullDate(value) : value)
        },
        {
          key: 'school',
          label: 'School',
          tdClass: 'clickable-item',
          formatter: value => (value ? formatFullDate(value) : value)
        },
        { key: 'current_status', label: 'Status', tdClass: 'clickable-item' },
        {
          key: 'updated_at',
          label: 'Applied On',
          tdClass: 'clickable-item',
          formatter: value => (value ? formatFullDate(value) : value)
        },
        {
          key: 'call_duration',
          label: 'Duration (seconds)',
          tdClass: 'clickable-item',
          formatter: value => Math.floor(value)
          
        }
      ];
    },
    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
    subLayout() {
      return this.windowWidth > 992;
    },
    subLayoutDrawer() {
      return this.windowWidth > 992 ? FiltersSidebar : Filters;
    },
    subLayoutDrawerId() {
      return 'filters';
    },

    enableSidebarToggle() {
      return this.windowWidth <= 992;
    }
  },
  watch: {
    getSimulationsFilters: {
      deep: true,
      handler(filterValues) {
        this.filters.search = filterValues.search;
        this.filters.simulation__title = filterValues.simulation__title;
        this.filters.current_status = filterValues.current_status;
        this.filters.call_duration__gte = filterValues.call_duration__gte;
        this.debouncedSearchStudents(this);
      }
    }
  },

  methods: {
    get,
    ...mapActions('applicationForm', ['getAllSchApps']),
    ...mapActions('simulations', ['getSimulationsSubmissions']),
    truncate,
    onRowClicked(data) {
      this.$router.push({
        name: 'simulations-submission-profile',
        params: { id: data.id }
      });
    },
    async fetchStudents(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      if (
        get(this.getLoggedInUser, 'role') === USER_ROLES.ORGANIZATION ||
        get(this.getLoggedInUser, 'role') === USER_ROLES.EMPLOYER_ADMIN
      ) {
        params.current_status = 'ended';
      }
      const response = await this.getSimulationsSubmissions({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...this.filters,
        ...params
      });
      this.students = response.data.results;
      this.currentPage = pageNum;
      this.totalStudents = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchStudents(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchStudents();
    },

    onSearch() {
      this.debouncedSearchStudents(this);
    },

    debouncedSearchStudents: debounce(vm => {
      vm.fetchStudents();
    }, 500),
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },

  async mounted() {
    if (this.$route.query) {
      this.filters.simulation__title = this.$route.query.simulation;
    }
    this.fetchStudents();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
