<template>
  <div>
    <page-header :title="title" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input class="w-lg-auto" :placeholder="'Search...'" v-model="searchTerm" @input="onSearch" />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="prizes"
            :busy="isLoading"
            head-variant="light"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(image)="data">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside">
                <b-img
                  :src="data.item.prize.image"
                  class="img-fluid"
                  width="40"
                  alt="Logo"
                  v-if="data.item.prize.image"
                />
                <i class="material-icons" v-else>card_giftcard </i>
              </fmv-avatar>
            </template>
            <template #cell(delivery_status)="data">
              <b-badge
                :class="`border border-${PRIZE_STATUSES_COLORS[data.item.delivery_status]} text-${
                  PRIZE_STATUSES_COLORS[data.item.delivery_status]
                }`"
                variant="none"
                >{{ $t(`prizeStatuses.${data.item.delivery_status}`) }}
              </b-badge>
            </template>

            <!-- <template #head(actions)="">
              <span></span>
            </template> -->
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalPrizes"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="payments-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import Page from '@/components/Page.vue';
import Pagination from '../../components/Ui/Pagination.vue';
import MdIcon from '../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE, PRIZE_STATUSES_COLORS } from '../../common/constants';
import { formatFullDate } from '../../common/utils';

export default {
  components: { MdIcon, PageHeader, Pagination, FmvAvatar },
  extends: Page,

  name: 'PrizeCreditHistory',

  data() {
    return {
      title: 'Rewards Redemption History',
      PRIZE_STATUSES_COLORS,

      isLoading: false,

      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,

      totalPrizes: 0,
      prizes: [],

      searchTerm: '',
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Prizes Credit History', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'image', label: '' },
        { key: 'prize.title', label: 'Title' },
        { key: 'delivery_status', label: 'Status' },
      ];
    },
  },

  methods: {
    ...mapActions('credit', ['getSchoolPrizes']),
    formatFullDate,

    async fetchPrizes(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();
      let response;

      response = await this.getSchoolPrizes({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });

      this.prizes = response.data.results;
      this.currentPage = pageNum;
      this.totalPrizes = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchPrizes(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchPrizes();
    },

    onSearch() {
      this.debouncedSearchPrizes(this);
    },

    debouncedSearchPrizes: debounce((vm) => {
      vm.fetchPrizes();
    }, 500),
  },

  async mounted() {
    this.fetchPrizes();
  },
};
</script>
