<template>
  <b-modal ref="assessListModal" hide-footer title="Assessments" centered lazy scrollable @hide="hideModal" size="lg">
    <div
      v-for="re in get(assessDetail, 'assessment_response.assessment_list') || []"
      :key="re.id"
      class="d-flex event-mod-item pt-2 px-1 clickable-item"
      @click.prevent="openDetailPage(re.date_generated)"
    >
      <div class="d-flex align-items-center">
        <strong class="ml-2 mb-2"
          >{{ $t(`assessTypes.${re.assessment_type}`) }} - {{ formatDateTime(re.date_generated) }}
        </strong>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { get } from 'lodash';
import { formatDateSimple, formatDateTime } from '@/common/utils';
import { mapActions } from 'vuex';

export default {
  name: 'AssessListModal',

  props: {
    showModal: { type: Boolean, default: false },
    id: { type: Number, default: null },
  },

  data() {
    return {
      isLoading: false,
      assessDetail: null,
    };
  },

  computed: {},

  watch: {
    async showModal(value) {
      if (value) {
        this.$refs.assessListModal.show();
        this.fetchAssessment();
      }
    },
  },

  methods: {
    get,
    ...mapActions('assessment', ['getAssessment']),
    formatDateSimple,
    formatDateTime,

    async fetchAssessment() {
      this.isLoading = true;
      try {
        const res = (await this.getAssessment(this.id)).data;
        this.assessDetail = res;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
    openDetailPage(date) {
      this.$router.push({
        name: 'assessment-detail',
        params: { id: this.id },
        query: { date },
      });
    },
    hideModal() {
      this.$emit('close');
      setTimeout(() => this.$refs.assessListModal.hide());
    },

    hideModalManual() {
      this.$refs.assessListModal.hide();
      this.hideModal();
    },
  },

  async mounted() {},
};
</script>