<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator :title="title" />

        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-4">
                  <v-select
                    id="program"
                    class="form-control v-select-custom"
                    label="text"
                    v-model="searchTerm"
                    :reduce="(searchTerm) => searchTerm.value"
                    placeholder="Select Status"
                    :options="partnershipStatuses"
                    @input="fetchPartnerships()"
                  >
                    <template #search="{ attributes, events }">
                      <input class="vs__search" v-bind="attributes" v-on="events" />
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="partnerships"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(request_from)="data">
              {{ get(data.item.request_from, 'name') }}
            </template>

            <template #cell(request_to)="data">
              {{ get(data.item.request_to, 'name') }}
            </template>

            <template #cell(status)="data">
              <b-badge
                :class="`border border-${
                  data.item.partner_revoke_at ? 'danger' : data.item.status === 'requested' ? 'secondary' : 'success'
                } text-${
                  data.item.partner_revoke_at ? 'danger' : data.item.status === 'requested' ? 'secondary' : 'success'
                }`"
                variant="none"
                >{{
                  data.item.partner_revoke_at ? 'To be dissolved' : $t(`partnershipStatusTypes.${data.item.status}`)
                }}</b-badge
              >
            </template>

            <template #cell(type)="data">
              {{ $t(`partnershipTypes.${data.item.type}`) }}
            </template>

            <template #cell(accepted_at)="data">
              {{ get(data.item, 'accepted_at') ? formatDateTime(get(data.item, 'accepted_at')) : '' }}
            </template>

            <template #cell(partner_revoke_at)="data">
              {{ get(data.item, 'partner_revoke_at') ? formatDateTime(get(data.item, 'partner_revoke_at')) : '' }}
            </template>
            <template #cell(to_be_freed)="data">
              <b-badge
                :class="`border border-${data.item.to_be_freed ? 'primary' : ''} text-${
                  data.item.to_be_freed ? 'primary' : ''
                }`"
                variant="none"
                v-b-popover.hover.left
                :title="
                  data.item.to_be_freed
                    ? 'This partnership will be freed in next cycle, because this employer now belongs to partnered organization'
                    : ''
                "
                >{{ data.item.to_be_freed ? 'To be Freed' : '' }}</b-badge
              >
            </template>
            <template #cell(programs)="data">
              <a href="#" @click.prevent class="text-primary" :id="`program-popover-${data.item.id}`"
                >{{ data.item.programs.length }} Program(s)</a
              >
              <programs-list-popover :programs="data.item.programs" :target-id="data.item.id" />
            </template>
            <template #cell(unlocked_students)="data">
              <span class="d-flex justify-content-center">
                <a
                  href="#"
                  @click.prevent="openStdModal(data.item)"
                  class="text-primary"
                  v-b-popover.hover.right
                  title="View Students"
                  >{{ data.item.unlocked_students }} Student(s)</a
                >
              </span>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalPartnerships"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="schools-table"
            />
          </div>
        </div>
      </div>
    </div>
    <unlocked-std-modal :showModal="showStdModal" :item="item" @close="hideStdModal" />
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { debounce, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import UnlockedStdModal from '../../Partnership/UnlockStudents/UnlockedStdModal.vue';
import ProgramsListPopover from '../../Partnership/PartneredPrograms/ProgramsListPopover.vue';

import { DEFAULT_PAGE_SIZE, USER_ROLES, USER_ROLE_TYPES } from '../../../common/constants';
import { formatDateTime } from '../../../common/utils';

export default {
  components: { PageHeader, PageSeparator, Pagination, UnlockedStdModal, ProgramsListPopover },
  extends: Page,

  data() {
    return {
      title: 'Partnerships',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalPartnerships: 0,
      partnerships: [],
      item: null,
      searchTerm: '',
      USER_ROLE_TYPES,
      USER_ROLES,

      partnershipStatuses: [
        { text: 'Accepted', value: 'request_accepted' },
        { text: 'Pending', value: 'requested' },
        { text: 'To be Dissolved', value: 'revoked' },
      ],
      showStdModal: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Partnerships', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'request_from', label: 'Requested By' },
        { key: 'request_to', label: 'Requested To' },

        { key: 'status', label: this.$t('generalMsgs.status') },
        { key: 'type', label: 'Partnership Type' },

        {
          key: 'created_at',
          label: 'Requested At',
          formatter: (value) => {
            return formatDateTime(value);
          },
        },
        {
          key: 'accepted_at',
          label: 'Accepted At',
          formatter: (value) => {
            return formatDateTime(value);
          },
        },
        {
          key: 'partner_revoke_at',
          label: 'To Be Revoked On',
          formatter: (value) => {
            return formatDateTime(value);
          },
        },
        { key: 'to_be_freed', label: 'Payment Status', tdClass: 'clickable-item' },
        { key: 'programs', label: 'Programs', tdClass: 'clickable-item' },
        {
          key: 'unlocked_students',
          label: 'Unlocked Students',
          tdClass: 'text-right ',
        },
      ];
    },
  },

  methods: {
    ...mapActions('partnership', ['getAllPartnerships']),
    formatDateTime,
    get,
    openStdModal(item) {
      this.showStdModal = true;
      this.item = item;
    },

    hideStdModal() {
      this.showStdModal = false;
      this.item = null;
    },
    async fetchPartnerships(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllPartnerships({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { partnership_status: this.searchTerm }),
        ...params,
      });

      this.partnerships = response.data.results;
      this.currentPage = pageNum;
      this.totalPartnerships = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchPartnerships(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchPartnerships();
    },

    onSearch() {
      this.debouncedSearchUsers(this);
    },

    debouncedSearchUsers: debounce((vm) => {
      vm.fetchPartnerships();
    }, 500),
  },

  async mounted() {
    this.fetchPartnerships();
  },
};
</script>
