<template>
  <fmv-sidebar type="light" variant="white" class="py-16pt" wheel-propagation>
    <div class="sidebar-heading mb-4" style="font-size: 18px; font-weight: bolder">Filter Simulations</div>
    <div class="sidebar-heading">Students</div>
    <div class="sidebar-block">
      <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
        <b-form-input class="w-lg-auto" :placeholder="$t('studentMsgs.searchStudents')" v-model="filters.search" />
        <b-btn variant="flush" type="submit">
          <md-icon v-text="'search'" />
        </b-btn>
      </form>
    </div>

    <div class="sidebar-heading">Simulation</div>

    <div class="sidebar-block">
      <v-select
        id="simulation"
        class="form-control v-select-custom"
        label="title"
        v-model="filters.simulation__title"
        :reduce="simulation => simulation.title"
        placeholder="Select simulation"
        :options="allSimulations"
        :loading="areSimulationsLoading"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" v-bind="attributes" v-on="events" />
        </template>

        <template slot="option" slot-scope="option">
          <span>{{ option.title }}</span>
        </template>
        <template slot="selected-option" slot-scope="option">
          {{ truncate(option.title, { length: 20 }) }}
        </template>
      </v-select>
    </div>

    <div v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN">
      <div class="sidebar-heading">Simulation Status</div>

      <div class="sidebar-block">
        <v-select
          id="status"
          class="form-control v-select-custom"
          label="text"
          v-model="filters.current_status"
          :reduce="status => status.value"
          placeholder="Select status"
          :options="simulationsStatus"
        >
        </v-select>
      </div>
    </div>

    <div class="sidebar-heading mb-2" style="z-index: 1;">Minimum Simulation Duration(Seconds)</div>

    <div class="sidebar-block" style="z-index: 100">
      <vue-slider v-model="filters.call_duration__gte" :value="filters.call_duration__gte" :min="0" :max="360" :interval="20"></vue-slider>
    </div>
  </fmv-sidebar>
</template>

<script>
import { FmvSidebar } from 'fmv-layout';
import { USER_ROLE_TYPES, USER_ROLES } from '@/common/constants';
import { truncate, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import MdIcon from '@/components/Ui/MdIcon.vue';

export default {
  name: 'FiltersSidebar',

  components: { FmvSidebar, MdIcon },

  data() {
    return {
      USER_ROLE_TYPES,
      USER_ROLES,
      allSimulations: [],
      filters: {
        search: null,
        simulation__title: null,
        current_status: null,
        call_duration__gte: 0
      },
      areSimulationsLoading: false,
      simulationsStatus: [
        { value: 'ended', text: 'Ended' },
        { value: 'registered', text: 'Registered' }
      ]
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('simulations', ['getSimulationsFilters']),
    breadcrumb() {
      return [{ text: this.$t('studentMsgs.students'), active: true }];
    }
  },

  methods: {
    get,
    truncate,
    ...mapActions('simulations', ['getAllSimulations', 'updateSimulationsFilters']),
    async fetchSimulations() {
      this.areSimulationsLoading = true;

      try {
        const response = await this.getAllSimulations({});

        this.allSimulations = response.data;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areSimulationsLoading = false;
    }
  },

  watch: {
    filters: {
      deep: true,
      handler(filters) {
        if (filters.simulation__title) {
          this.$router
            .replace({
              name: 'simulations-submission-list',
              query: {
                ...(filters.simulation__title && { simulation: filters.simulation__title })
              }
            })
            .catch(() => {});
        }
        this.updateSimulationsFilters({ filters });
      }
    }
  },
  async mounted() {
    this.fetchSimulations();
    if (this.$route.query) {
      this.filters.simulation__title = this.$route.query.simulation;
    }
  }
};
</script>
