<template>
  <b-modal
    ref="unlockedStudentsModal"
    hide-footer
    title="Unlocked Students"
    centered
    lazy
    size="lg"
    scrollable
    @hide="hideModal"
  >
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div v-for="item in [1, 2, 3]" :key="item" class="d-flex pt-2 px-1">
          <div class="avatar avatar-sm rounded o-hidden border">
            <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
          </div>
          <div class="w-100 ml-2">
            <b-skeleton width="60%"></b-skeleton>
            <b-skeleton width="80%"></b-skeleton>
          </div>
        </div>
      </template>

      <i v-if="!unlockedStudents.length">No student is unlocked yet.</i>
      <router-link
        :to="{ name: 'schl-student-profile', params: { id: std.id } }"
        v-for="std in unlockedStudents"
        :key="std.id"
        v-else
      >
        <div class="d-flex event-mod-item pt-3 px-1 clickable-item">
          <!-- <div class="avatar avatar-sm">
          <img :src="std.student.user.avatar_url || DefaultImg" alt="Avatar" class="avatar-img rounded-circle" />
        </div> -->
          <user-avatar
            slot="aside"
            :user="std.user"
            size="lg"
            :isLoading="isLoading"
            :file-url="std.user.avatar_url"
            variant="dark"
            rounded="false"
          >
          </user-avatar>
          <div class="mb-3">
            <strong class="ml-2 text-black font-weight-bolder"
              >{{ std.user.first_name }} {{ std.user.last_name }}</strong
            >
            <div class="ml-2 text-break text-black font-weight-bolder">{{ std.user.email }}</div>
            <div class="ml-2 text-break">
              <b class="text-black">Unlocked On:</b> {{ formatDateTime(std.unlocked_at) }}
            </div>
          </div>
        </div>
      </router-link>
    </b-skeleton-wrapper>

    <pagination
      v-model="currentPage"
      :total-rows="totalStds"
      :per-page="perPage"
      @change="onPageChange"
      aria-controls="students-table"
      class="mt-2"
    />
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';
import UserAvatar from '@/components/User/UserAvatar.vue';
import DefaultImg from '@/assets/images/placeholders/cna_career.jpg';
import { formatDateTime } from '@/common/utils';
import Pagination from '@/components/Ui/Pagination.vue';
import { DEFAULT_PAGE_SIZE, USER_ROLES } from '@/common/constants';
export default {
  name: 'UnlockedStudentsModal',

  components: { UserAvatar, Pagination },

  props: {
    showModal: { type: Boolean, default: false },
    item: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      DefaultImg,
      unlockedStudents: [],
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalStds: 0,
      USER_ROLES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },

  watch: {
    showModal(value) {
      if (value) {
        this.isLoading = true;
        this.fetchUnlockedStudents();
        this.$refs.unlockedStudentsModal.show();
      }
    },
  },

  methods: {
    ...mapActions('student', ['getEmpUnlockStudents']),
    get,
    formatDateTime,
    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.unlockedStudentsModal.hide();
      this.hideModal();
    },

    async fetchUnlockedStudents(pageNum = 1) {
      this.isLoading = true;
      const response = await this.getEmpUnlockStudents({
        employer_id: this.item.request_from.id,
        ...(get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN && { school_id: this.item.request_to.id }),
        //  params: { limit: this.perPage, offset: (pageNum - 1) * this.perPage },
      });
      this.unlockedStudents = response.data;
      this.currentPage = pageNum;
      this.totalStds = response.data.length;
      this.isLoading = false;
    },
    onPageChange(pageNum) {
      this.fetchUnlockedStudents(pageNum);
    },
  },

  async mounted() {},
};
</script>
