<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator :title="title" />

        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    :placeholder="$t('employerMsgs.searchEmployers')"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <!-- TODO: Add filters here. (if needed) -->
                <!-- <b-dropdown class="ml-lg-auto" :text="$t('All Topics')" variant="link text-black-70" right>
                  <b-dropdown-item active>All Topics</b-dropdown-item>
                  <b-dropdown-item>My Topics</b-dropdown-item>
                </b-dropdown>

                <b-dropdown class="mr-8pt" :text="$t('Newest')" variant="link text-black-70" right>
                  <b-dropdown-item active>Newest</b-dropdown-item>
                  <b-dropdown-item>Unanswered</b-dropdown-item>
                </b-dropdown> -->

                <b-btn :to="{ name: 'add-employer' }" exact variant="primary" v-text="$t('employerMsgs.addEmployer')" />
              </div>
            </div>
          </div>

          <!-- Employers Table -->
          <b-table
            :fields="tableColumns"
            :items="employers"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(logo)="data">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside">
                <b-img :src="data.item.logo_url" class="img-fluid" width="40" alt="Logo" v-if="data.item.logo_url" />
                <md-icon v-else>business</md-icon>
              </fmv-avatar>
            </template>

            <template #cell(name)="data"
              ><strong> {{ data.item.name }} </strong></template
            >

            <template #cell(admin)="data"
              >{{ data.item.main_admin.first_name }} {{ data.item.main_admin.last_name }}</template
            >

            <template #cell(address)="data">
              {{ data.item.address }}, {{ data.item.zipcode }}, {{ data.item.city }}, {{ data.item.state }},
              {{ data.item.country }}
            </template>

            <template #cell(referral_school)="data">{{ get(data.item, 'referral_school.name', '') || '' }} </template>

            <template #cell(organization)="data">{{ get(data.item, 'organization.name', '') || '' }} </template>

            <template #cell(status)="data">
              <b-badge
                :class="
                  'border border-' +
                  (data.item.is_profile_visible ? `success text-success` : `secondary text-secondary`)
                "
                variant="none"
                >{{ data.item.is_profile_visible ? 'Active' : 'Paused' }}</b-badge
              >
            </template>

            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <router-link
                :to="{
                  name: 'edit-employer',
                  params: { id: data.item.id },
                }"
                class="svg-icon mr-2"
                v-b-popover.hover.top
                :title="$t('generalMsgs.edit')"
              >
                <md-icon class="text-info">edit</md-icon>
              </router-link>
              <!-- <a
                href="#"
                @click.prevent="confirmDeleteEmployer(data.item.id)"
                v-b-popover.hover.top
                :title="$t('generalMsgs.remove')"
              >
                <md-icon class="text-danger">delete</md-icon>
                <i class="far fa-trash-alt text-danger" />
              </a> -->
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalEmployers"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="employers-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { debounce, get } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';
import { FmvAvatar } from 'fmv-avatar';

export default {
  components: { MdIcon, PageHeader, PageSeparator, Pagination, FmvAvatar },
  extends: Page,

  data() {
    return {
      title: this.$t('employerMsgs.manageEmployers'),

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalEmployers: 0,
      employers: [],

      searchTerm: '',
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.$t('employerMsgs.employers'), active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'logo', label: '' },
        { key: 'name', label: this.$t('employerMsgs.name') },
        { key: 'status', label: this.$t('generalMsgs.status') },
        { key: 'admin', label: 'Admin' },

        { key: 'main_admin.email', label: "Admin's Email" },
        { key: 'phone', label: this.$t('employerMsgs.phone') },
        { key: 'address', label: this.$t('addressMsgs.address') },
        { key: 'referral_school', label: 'Referral School' },
        { key: 'organization', label: 'Organization' },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('employer', ['getAllEmployers']),
    get,

    async fetchEmployers(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllEmployers({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.employers = response.data.results;
      this.currentPage = pageNum;
      this.totalEmployers = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchEmployers(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchEmployers();
    },

    onSearch() {
      this.debouncedSearchEmployers(this);
    },

    debouncedSearchEmployers: debounce((vm) => {
      vm.fetchEmployers();
    }, 500),
  },

  async mounted() {
    this.fetchEmployers();
  },
};
</script>
