<template>
  <div class="bg-light min-vh-100 d-flex align-items-center justify-content-center">
    <div
      class="col-lg-8 col-md-12 bg-white border shadowed p-4"
      style="
            -webkit-box-shadow: 0px 0px 19px -6px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 0px 19px -6px rgba(0, 0, 0, 0.75);
            box-shadow: 0px 0px 19px -6px rgba(0, 0, 0, 0.75);
            max-width: 800px;
            "
    >
      <div class="page-section">
        <div class="container">
          <div class="feedback-page">
            <div class="d-flex justify-content-center mb-4">
              <img class="logo" :src="schoolLogo" style="height: 100px; width: 100px; object-fit: cover" />
            </div>
            <h2 class="mb-0 font-weight-bolder text-center">Your feedback matters.</h2>
            <div class="mx-3 mb-4 mt-4 text-center" style="font-size: 16px">
              Let us know your feedback on the current simulation so that we can make this experience better for you
            </div>
            <form @submit.prevent="onSubmit">
              <textarea
                id="feedback"
                class="form-control m-3"
                rows="10"
                placeholder="Type your feedback here!"
                v-model="feedback"
                required
              ></textarea>
              <div class="text-center m-2">
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="isPosting || !$route.query.callId"
                  style="min-width: 100px"
                  class="btn-normal"
                >
                  <i v-if="isPosting" class="fas fa-circle-notch fa-spin"></i>
                  <span v-else> Post </span>
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';

export default {
  name: 'PublicSimulationFeedback',
  data() {
    return {
      feedback: '',
      isPosting: false
    };
  },
  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    schoolLogo() {
      return get(this.getCurrentSchool, 'logo_url');
    }
  },
  methods: {
    ...mapActions('simulations', ['createSimulationFeedback']),
    get,
    async onSubmit() {
      try {
        this.isPosting = true;
        const data = { call_id: this.$route.query.callId , feedback: this.feedback };
        await this.createSimulationFeedback({ ...data });
        this.makeToast({ variant: 'success', msg: 'Submitted' });
        this.isPosting = false;
        this.feedback = null    
        this.$router.push({
            name: 'submission-feedback-success',
            params: {
              submission: 'success'
            }
          });
      } catch (err) {
        if (get(err.response, 'status') === 400) {
        //  this.makeToast({ variant: 'danger', msg: `Already Submitted` });
          this.$router.replace({
            name: 'submission-feedback-success',
            params: {
              submission: 'failed'
            }
          });
          throw err;       
        }
      }
    }
  }
};
</script>
<style>
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 10px;
}

.logo {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
</style>
