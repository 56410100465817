<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator :title="title" />
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Connection Requests"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

            
            </div>
          </div>
          <b-table
            :fields="tableColumns"
            :items="connectionRequests"
            :busy="loading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
          >
          <template #cell(uni_name)="data">
              <strong> {{ data.item.university.name }} </strong>
            </template>
            <template #cell(contact_email)="data">
              <strong> {{ data.item.requested_user.email }} </strong>
            </template>
            <template #cell(first_name)="data">
              <strong> {{ data.item.requested_user.first_name }} </strong>
            </template>
            <template #cell(last_name)="data">
              <strong> {{ data.item.requested_user.last_name }} </strong>
            </template>

            <template #head(actions)="">
              <span></span>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalConnections"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="connections-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { debounce, get } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';

export default {
  components: { MdIcon, PageHeader, PageSeparator, Pagination },
  extends: Page,

  data() {
    return {
      title: 'Manage Connect Requests',
      loading: true,
      connectionRequests: [],
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalConnections: 0,
      searchTerm: '',
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Manage Connect Requests', active: true },
      ];
    },
    tableColumns() {
      return [
        { key: 'uni_name', label: 'University Name' },
        { key: 'created_at', label: 'Created At' },
        { key: 'first_name', label: 'First Name' },
        { key: 'last_name', label: 'Last Name' },
        { key: 'contact_email', label: 'Email' },
      ];
    },
  },

  methods: {
    ...mapActions('student', ['getStudentsConnectionRequests']),
    get,
    async fetchConnectionRequests(pageNum = 1, params = {}) {
    
      const res = await this.getStudentsConnectionRequests({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.connectionRequests = res.data.results;
      this.currentPage = pageNum;
      this.totalConnections = res.data.count;
      this.loading = false;
    },

    onPageChange(pageNum) {
      this.fetchConnectionRequests(pageNum);
    },
    onSearch() {
      this.debouncedSearchConnections(this);
    },

    debouncedSearchConnections: debounce((vm) => {
      vm.fetchConnectionRequests();
    }, 500),
  },

  async mounted() {
    this.fetchConnectionRequests();
  },
};
</script>
