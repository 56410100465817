<template>
  <div style="padding-top: 16px" id="page-container">
    <!-- <page-header :title="title" :container-class="containerClass" /> -->

    <div class="page-section jobs_container">
      <div :class="containerClass">
        <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt" style="white-space: nowrap">
          <h2 class="mb-0">Career Pathways</h2>

          <div class="w-auto ml-sm-auto mb-sm-0" :class="{ 'd-flex': !isMobileScreen }">
            <div class="col-lg-auto">
              <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                <b-form-input
                  class="w-lg-auto"
                  placeholder="Search Career Path..."
                  v-model="searchTerm"
                  @input="onSearch"
                />
                <b-btn variant="flush" type="submit">
                  <md-icon>search</md-icon>
                </b-btn>
              </form>
            </div>
            <div :class="isMobileScreen ? 'mt-2 ml-auto' : 'ml-3'">
              <b-btn @click.prevent="addCareer()" variant="primary" :class="{ 'w-100': isMobileScreen }"
                >Add Career Pathway</b-btn
              >
            </div>
          </div>
        </div>

        <page-separator
          :title="
            !isLoading
              ? `showing ${totalCareers ? `${pageFromCount}-${pageToCount}` : 0} of ${totalCareers} Careers`
              : ''
          "
        />

        <div class="d-flex justify-content-center" v-if="!totalCareers && !isLoading">
          <div class="card card-block card-stretch card-height col-md-12">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Career Pathways</h4>

              <p class="text-muted">Couldn't find any open pathways right now.</p>

              <!-- <a href="#" @click.prevent="" class="btn btn-primary mt-2"> Sample Button </a> -->
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row card-group-row mb-lg-12pt home-card">
              <div class="col-sm-12 card-group-row__col" v-for="item in [1, 2]" :key="item">
                <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
                  <div class="card-header d-flex align-items-center">
                    <a href="#" @click.prevent class="card-title flex"> <b-skeleton width="100%"></b-skeleton></a>
                  </div>
                  <div class="card-body d-flex flex-column">
                    <div class="d-flex align-items-center">
                      <div class="flex">
                        <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                          <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                            <a
                              href="#"
                              @click.prevent
                              class="avatar avatar-xl overlay js-overlay overlay--primary rounded-circle p-relative o-hidden mb-16pt align-self-center"
                              style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                            >
                              <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                            </a>
                          </div>
                          <div class="flex">
                            <div class="mb-0" style="text-transform: none">
                              <b-skeleton width="100%"></b-skeleton>
                              <b-skeleton width="55%"></b-skeleton>
                              <b-skeleton width="70%"></b-skeleton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <!-- <div class="row card-group-row mb-lg-12pt home-card"> -->
          <draggable
            class="row card-group-row mb-lg-12pt home-card"
            @start="dragging = true"
            @end="updateOrder"
            :list="careers"
          >
            <div class="col-sm-12 card-group-row__col" v-for="ques in careers" :key="ques.id">
              <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
                <router-link :to="{ name: 'sa-edit-career-pathway', params: { id: ques.id } }" class="text-black">
                  <div class="card-body d-flex flex-column">
                    <div class="d-flex align-items-center">
                      <div class="flex">
                        <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                          <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                            <fmv-avatar
                              :title="true"
                              title-class="bg-transparent"
                              no-link
                              slot="aside"
                              style="width: 5rem"
                            >
                              <b-img :src="ques.photo_url" class="img-fluid" alt="Logo" v-if="ques.photo_url" />
                              <i class="material-icons" v-else>card_giftcard </i>
                            </fmv-avatar>
                          </div>

                          <div class="flex">
                            <div class="mb-0" style="text-transform: none">
                              <!-- for ques description -->

                              <div>
                                <b>{{ get(ques, 'name') }} </b>
                              </div>
                              <div class="mb-2 d-flex justify-content-between flex-wrap">
                                <span class="text-muted">{{ formatDateTime(get(ques, 'updated_at')) }}</span>

                                <!-- <div class="badge badge-dark" style="text-transform: none">
                                {{ $t(`audienceTypes.${ques.audience}`) }}
                              </div> -->
                              </div>

                              <div>
                                {{
                                  truncate(trim(get(ques, 'description')), {
                                    length: 250,
                                    separator: /,? +/,
                                  })
                                }}
                              </div>
                            </div>
                          </div>

                          <div class="d-flex justify-content-end mr-2">
                            <a
                              :to="{ name: 'sa-edit-career-pathway', params: { id: ques.id } }"
                              v-b-popover.hover.right
                              :title="'Edit CareerPathway'"
                            >
                              <i class="material-icons icon-18pt">edit</i></a
                            >
                            <a
                              href="#"
                              @click.prevent="confirmDeleteCareer(ques.id)"
                              v-b-popover.hover.right
                              :title="'Remove CareerPathway'"
                            >
                              <i class="material-icons icon-18pt text-danger">delete</i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <!-- </div> -->
          </draggable>
        </b-skeleton-wrapper>

        <pagination
          v-if="totalCareers > careers.length"
          v-model="currentPage"
          :total-rows="totalCareers"
          :per-page="perPage"
          @change="onPageChange"
          aria-controls="jobs-table"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, get, keyBy, trim, truncate, map } from 'lodash';
import { FmvAvatar } from 'fmv-avatar';
import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
//import UserAvatar from '@/components/User/UserAvatar.vue';
import { mapActions, mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import { USER_ROLES, USER_TITLES, USER_TITLE_OPTIONS } from '../../../common/constants';
import { formatDateTime } from '../../../common/utils';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  components: { Pagination, FmvAvatar, draggable, PageSeparator },
  extends: Page,

  data() {
    return {
      DefaultAvatar,
      USER_TITLES,

      title: 'Career Pathways',
      isLoading: true,
      perPage: 14,
      currentPage: 1,
      totalCareers: 0,
      careers: [],
      searchTerm: '',
      dragging: false,
      quesOptions: [
        { value: null, text: 'All Questions' },
        { value: 'jobs_options', text: 'Jobs/Options' },
        { value: 'exam_testing', text: 'Exam/Testing' },
        { value: 'skills', text: 'Skills' },
        { value: 'class', text: 'Class' },
        { value: 'equipment', text: 'Equipment' },
        { value: 'general', text: 'General' },
        { value: 'miscellaneous', text: 'Miscellaneous' },
      ],
      pageFromCount: 0,
      pageToCount: 0,
      filters: {
        topic: null,
      },
      windowWidth: window.innerWidth,
      firstLoad: true,
      USER_ROLES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Career Pathways', active: true },
      ];
    },

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
    isMobileScreen() {
      return this.windowWidth <= 720;
    },

    userTitleOptions() {
      return keyBy(USER_TITLE_OPTIONS, 'value');
    },
  },

  methods: {
    ...mapActions('careerPreference', ['getAllCareerPreferences', 'deleteCareerPreference', 'updateCareerOrder']),
    get,
    trim,
    truncate,
    formatDateTime,
    async confirmDeleteCareer(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          "Please confirm that you want to delete the selected career pathways and it's related student data.",
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deleteCareerPreference(id);
          const refreshPage = this.careers.length > 1 ? this.currentPage : this.currentPage - 1;
          this.fetchCareers(refreshPage || 1);
          this.makeToast({ variant: 'success', msg: 'Career Preference Successfully Deleted' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async updateOrder() {
      this.dragging = false;
      const data = {
        career_pathways: map(this.careers, (id, order_num) => ({
          id: id.id,
          order_num: order_num + 1,
        })),
      };
      try {
        await this.updateCareerOrder(data);
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    addCareer() {
      this.$router.push({ name: 'sa-add-career-pathway' });
    },
    onFilterApplied() {
      this.fetchQuestions();
    },
    onSearch() {
      this.debouncedSearchCareers(this);
    },

    debouncedSearchCareers: debounce((vm) => {
      vm.fetchCareers();
    }, 500),
    async fetchCareers(pageNum = 1, params = {}) {
      this.isLoading = true;
      if (document.getElementById('page-container')) {
        document.getElementById('page-container').scrollIntoView();
      }

      const response = await this.getAllCareerPreferences({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.careers = response.data.results;
      this.currentPage = pageNum;
      this.totalCareers = response.data.count;
      this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
      this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchCareers(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchCareers();
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.fetchCareers();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
