<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator :title="title" />

        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Organizations"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <div class="col-lg d-flex flex-wrap justify-content-end">
                <b-btn :to="{ name: 'add-organization' }" exact variant="primary"> Add Organization </b-btn>
              </div>
            </div>
          </div>

          <!-- Organizations Table -->
          <b-table
            :fields="tableColumns"
            :items="orgs"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(name)="data">
              <strong> {{ data.item.name }} </strong>
            </template>

            <template #cell(logo)="data">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside">
                <b-img :src="data.item.logo_url" class="img-fluid" width="40" alt="Logo" v-if="data.item.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
            </template>

            <template #cell(org_owner)="data">
              {{ data.item.org_owner.first_name }} {{ data.item.org_owner.last_name }}
            </template>

            <template #cell(address)="data">
              {{ data.item.address }}, {{ data.item.zipcode }}, {{ data.item.city }}, {{ data.item.state }},
              {{ data.item.country }}
            </template>
            <template #cell(assessCount)="data">
              <i v-if="areAsessCountsLoading">Loading...</i>
              {{ $n(get(assessCountByEntity, `${data.item.id}.assessment_count`, 0)) }}
            </template>
            <template #cell(resume_count)="data">
              <i v-if="areResumeCountLoading">Loading...</i>
              {{ $n(get(resumeByUni, `${data.item.id}.resume_count`, 0)) }}
            </template>

            <template #cell(unlock)="data">
              <div class="text-center" v-b-popover.hover.top title="Total students unlocked by employers">
                {{ unlockStudents[data.item.id] || '0' }}
              </div>
            </template>

            <!-- <template #cell(status)="data">
              <b-badge
                :class="
                  'border border-' +
                  (data.item.is_profile_visible ? `success text-success` : `secondary text-secondary`)
                "
                variant="none"
                >{{ data.item.is_profile_visible ? 'Active' : 'Paused' }}</b-badge
              >
            </template> -->
            <template #cell(sch_payment_plan)="data">
              <span class="d-flex justify-content-center">{{ get(data.item, 'sch_payment_plan.title') }}</span>
            </template>

            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <router-link
                :to="{
                  name: 'edit-organization',
                  params: { id: data.item.id },
                }"
                class="svg-icon mr-2"
                v-b-popover.hover.top
                :title="$t('generalMsgs.edit')"
              >
                <md-icon class="text-info">edit</md-icon>
              </router-link>

              <a
                href="#"
                class="svg-icon mr-2"
                @click.prevent="openAddMetaTagsModal(data.item.id, data.item.meta_tags)"
                v-b-popover.hover.top
                title="Add Meta Tags"
              >
                <md-icon class="text-info">format_list_bulleted</md-icon></a
              >
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalSchools"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="orgs-table"
            />
          </div>
        </div>
      </div>
    </div>
    <add-meta-tags-modal
      :show-modal="showAddMetaTagsModal"
      @close="hideAddMetaTagsModal"
      :index="index"
      :existingMetaTags="metaTags"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { FmvAvatar } from 'fmv-avatar';
import { debounce, get, keyBy } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE, USER_ROLE_TYPES } from '../../../common/constants';
import AddMetaTagsModal from '../Schools/AddMetaTagsModal.vue';

export default {
  components: { MdIcon, PageHeader, PageSeparator, Pagination, FmvAvatar, AddMetaTagsModal },
  extends: Page,

  data() {
    return {
      title: 'Manage Organizations',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalSchools: 0,
      orgs: [],
      showAddMetaTagsModal: false,
      index: null,
      metaTags: [],
      searchTerm: '',
      unlockStudents: {},
      assessCounts: [],
      assessCountByEntity: {},
      areAsessCountsLoading: false,
      resumeCounts: [],
      resumeByUni: {},
      areResumeCountLoading: false,
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Organizations', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'logo', label: '' },
        { key: 'name', label: this.$t('schoolMsgs.name') },
        // { key: 'status', label: this.$t('generalMsgs.status') },
        { key: 'org_owner', label: this.$t('schoolMsgs.owner') },
        { key: 'org_owner.email', label: this.$t('schoolMsgs.ownersEmail') },
        { key: 'phone', label: this.$t('schoolMsgs.phone') },
        { key: 'address', label: this.$t('schoolMsgs.address') },
        { key: 'unlock', label: 'Unlocked Students' },
        { key: 'assessCount', label: 'Assessments' },
        { key: 'resume_count', label: 'Resumes Generated', tdClass: 'clickable-item text-center' },

        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('organization', ['getAllOrganizations']),
    ...mapActions('student', ['getUnlockStudentsCount']),
    ...mapActions('resume', ['getResumeCounts']),
    ...mapActions('assessment', ['getEntityAssessmentCount']),
    get,
    async fetchAsessCount() {
      this.areAsessCountsLoading = true;
      try {
        const res = await this.getEntityAssessmentCount({
          entity_ids: this.orgs.map((c) => c.id),
          entity_type: USER_ROLE_TYPES.ORGANIZATION,
        });
        this.assessCounts = res;
        this.assessCountByEntity = keyBy(res, 'entity_id');
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areAsessCountsLoading = false;
    },

    async fetchResumeCounts() {
      this.areResumeCountLoading = true;

      try {
        const response = await this.getResumeCounts({
          entity_ids: this.orgs.map((p) => p.id),
          entity_type: USER_ROLE_TYPES.ORGANIZATION,
        });
        this.resumeCounts = response;
        this.resumeByUni = keyBy(response, 'entity_id');
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.areResumeCountLoading = false;
    },

    async fetchSchools(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllOrganizations({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.orgs = response.data.results;
      this.currentPage = pageNum;
      this.totalSchools = response.data.count;
      this.fetchAsessCount();
      this.fetchResumeCounts();
      this.isLoading = false;
    },
    async fetchUnlockStudents() {
      const data = {
        orgs: this.orgs.map((org) => org.id),
      };

      const response = await this.getUnlockStudentsCount(data);
      this.unlockStudents = response.data;
    },
    openAddMetaTagsModal(index, metaTags) {
      this.metaTags = metaTags;
      this.index = index;
      this.showAddMetaTagsModal = true;
    },
    hideAddMetaTagsModal() {
      this.showAddMetaTagsModal = false;
      this.fetchSchools(this.currentPage);
    },
    onPageChange(pageNum) {
      this.fetchSchools(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchSchools();
    },

    onSearch() {
      this.debouncedSearchSchools(this);
    },

    debouncedSearchSchools: debounce((vm) => {
      vm.fetchSchools();
    }, 500),
  },

  async mounted() {
    await this.fetchSchools();
    this.fetchUnlockStudents();
  },
};
</script>
