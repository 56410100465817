<template>
  <div>
    <page-header :title="title" :container-class="containerClass" />

    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Feature Update"
                    v-model="searchTerm"
                    @input="onSearch"
                    :disabled="isLoading"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon> search </md-icon>
                  </b-btn>
                </form>
              </div>

              <div class="col-lg d-flex flex-wrap justify-content-end">
                <span class="clickable-item" v-b-popover.hover.top>
                  <b-btn :to="{ name: 'add-features' }" exact variant="primary">Post Feature Update</b-btn>
                </span>
              </div>
            </div>
          </div>

          <b-skeleton-wrapper :loading="isLoading">
            <template #loading>
              <b-skeleton-table :rows="5" :columns="3" :table-props="{ hover: true }"></b-skeleton-table>
            </template>

            <!-- Jobs Table -->
            <b-table
              :fields="tableColumns"
              :items="blogs"
              :busy="isLoading"
              head-variant="light"
              class="table-nowrap"
              responsive
              no-local-sorting
              @sort-changed="onSortChange"
            >
              <template #cell(title)="data">
                <strong> {{ data.item.title }} </strong>
              </template>

              <template #cell(portal)="data">
                {{ $t(`portalTypes.${data.item.portal}`) }}
              </template>
              <template #cell(entities)="data">
                <span v-for="(entity, index) in data.item.entities" :key="index">
                  {{ $t(`roleType.${entity}`) }}<span v-if="index < data.item.entities.length - 1">,</span>
                </span>
              </template>
              <template #head(actions)="">
                <span></span>
              </template>
              <template #cell(actions)="data">
                <router-link
                  :to="{
                    name: 'edit-features',
                    params: { id: data.item.id },
                  }"
                  class="svg-icon mr-1"
                  v-b-popover.hover.left
                  :title="$t('generalMsgs.edit')"
                >
                  <md-icon class="text-info">edit</md-icon>
                </router-link>
              </template>
            </b-table>

            <!-- Footer Pagination -->
            <div class="card-footer">
              <pagination
                v-model="currentPage"
                :total-rows="totalBlogs"
                :per-page="perPage"
                @change="onPageChange"
                aria-controls="scholarships-table"
              />
            </div>
          </b-skeleton-wrapper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, get } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import MdIcon from '@/components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '@/common/constants';

export default {
  components: {
    MdIcon,
    PageHeader,

    Pagination,
  },
  extends: Page,

  name: 'ManageFeatureUpdates',

  data() {
    return {
      title: 'Manage Feature Updates',
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalBlogs: 0,
      blogs: [],

      searchTerm: '',
    };
  },

  computed: {
    tableColumns() {
      return [
        { key: 'title', label: 'Title', tdClass: 'clickable-item' },

        { key: 'portal', label: 'Target Portal', tdClass: 'clickable-item' },
        { key: 'entities', label: 'Entity', tdClass: 'clickable-item' },
        {
          key: 'actions',
          tdClass: 'text-right',
        },
      ];
    },
  },

  methods: {
    ...mapActions('featureUpdates', ['getAllFeatureUpdates']),
    get,

    async fetchFeatureUpdates(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllFeatureUpdates({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        // ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.blogs = response.data.results;
      this.currentPage = pageNum;
      this.totalBlogs = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchFeatureUpdates(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchFeatureUpdates();
    },

    onSearch() {
      this.debouncedSearchBlogs(this);
    },

    debouncedSearchBlogs: debounce((vm) => {
      vm.fetchFeatureUpdates();
    }, 500),
  },

  async mounted() {
    this.fetchFeatureUpdates();
  },
};
</script>
