<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit Partner' : 'Add Partner'"
      :breadcrumb="breadcrumb"
      :container-class="null"
      class="mb-32pt"
    />

    <page-separator title="Partner's Info" />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Title" label-for="title" label-class="form-label">
        <b-form-input id="title" placeholder="Enter partner's title" v-model="partnerTitle" type="text" required />
      </b-form-group>

      <b-form-group label="Category" label-for="category" label-class="form-label">
        <v-select
          id="category"
          class="form-control v-select-custom"
          label="text"
          v-model="category"
          :reduce="(item) => item.value"
          placeholder="Select category"
          :options="CategoryOptions"
          :class="!$v.category.required && $v.category.$dirty ? 'form-control is-invalid' : ''"
        >
        </v-select>
        <b-form-invalid-feedback :state="!$v.category.required && $v.category.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Status" label-for="status" label-class="form-label">
        <v-select
          id="status"
          class="form-control v-select-custom"
          label="text"
          v-model="status"
          :reduce="(item) => item.value"
          placeholder="Select status"
          :options="StatusOptions"
          :class="!$v.status.required && $v.status.$dirty ? 'form-control is-invalid' : ''"
        >
        </v-select>
        <b-form-invalid-feedback :state="!$v.status.required && $v.status.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

import Page from '../../../components/Page.vue';

export default {
  components: {
    PageHeader,
    PageSeparator,
  },
  extends: Page,

  data() {
    return {
      title: this.$route.params.id ? 'Edit Partner' : 'Add Partner',

      partnerTitle: '',
      category: null,
      status: 'active',
      CategoryOptions: [{ value: 'jobs', text: 'Jobs' }],
      StatusOptions: [
        { value: 'active', text: 'Active' },
        { value: 'revoke', text: 'Revoke' },
      ],
      isLoading: false,
    };
  },

  validations() {
    return {
      category: { required },
      status: { required },
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Partners', to: { name: 'manage-partners' } },
        {
          text: this.$route.params.id ? 'Edit Partner' : 'Add Partner',
          active: true,
        },
      ];
    },
    isFormLoading() {
      return this.isLoading;
    },
  },

  methods: {
    ...mapActions('partner', ['updatePartner', 'createPartner', 'getPartner']),

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            title: this.partnerTitle,
            category: this.category,
            status: this.status,
          };
          if (this.$route.params.id) {
            await this.updatePartner({
              id: this.$route.params.id,
              data,
            });
            this.makeToast({ variant: 'success', msg: 'Partner Updated' });
          } else {
            await this.createPartner({
              ...data,
            });
            this.makeToast({ variant: 'success', msg: 'Partner Added' });
          }
          setTimeout(() => this.$router.push({ name: 'manage-partners' }), 250);
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly' });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.isLoading = true;

    try {
      if (this.$route.params.id) {
        const resp = (await this.getPartner(this.$route.params.id)).data;

        this.partnerTitle = resp.title;
        this.category = resp.category;
        this.status = resp.status;
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({ name: 'manage-partners' });
    }

    this.isLoading = false;
  },
};
</script>
