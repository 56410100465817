<template>
  <b-modal ref="disapproveModal" hide-footer title="Are you sure?" centered lazy size="md" scrollable @hide="hideModal">
    <p>Are you sure you want to disapprove the school?</p>

    <b-form @submit.prevent="onSubmit">
      <b-form-group label="Disapprove Reason" label-for="cancellation_reason" label-class="form-label">
        <b-form-textarea
          id="cancellation_reason"
          maxlength="250"
          placeholder="Enter reason."
          :rows="4"
          required
          v-model="reason"
        />
      </b-form-group>

      <div class="row">
        <div class="ml-auto" style="margin-right: 12px">
          <b-button @click.prevent="hideModalManual(true)" size="sm">Cancel</b-button>
          <b-button type="submit" variant="danger" size="sm" class="ml-2">Confirm</b-button>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { get } from 'lodash';
import { REVIEW_STATUSES } from '@/common/constants';
export default {
  name: 'DisapproveModal',

  props: {
    showModal: { type: Boolean, default: false },
    school: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      reason: '',
      isLoading: false,
      REVIEW_STATUSES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },

  watch: {
    showModal(value) {
      if (value) {
        this.isLoading = true;

        this.$refs.disapproveModal.show();
      }
    },
  },

  methods: {
    ...mapActions('school', ['updateInterestedSchoolStatus']),
    get,

    hideModal(no = false) {
      this.reason = '';
      this.$emit('close', no);
    },

    hideModalManual(no = false) {
      this.$refs.disapproveModal.hide();
      this.hideModal(no);
    },

    async onSubmit() {
      const data = {
        review_status: REVIEW_STATUSES.REJECTED,
        review_remarks: this.reason,
      };

      try {
        await this.updateInterestedSchoolStatus({ id: this.school.id, data });
        this.hideModalManual();
      } catch (e) {
        //
      }
    },
  },

  async mounted() {},
};
</script>
