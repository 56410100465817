<template>
  <div>
    <span v-if="!isEditLabel">
      {{ inputLabel }}
      <a
        href="#"
        class="svg-icon mr-2"
        @click.prevent="() => (isEditLabel = true)"
        v-b-popover.hover.top
        title="Edit label"
      >
        <md-icon v-if="!isEditLabel" style="font-size: 18px" class="text-info mb-1">edit</md-icon></a
      >
    </span>
    <div v-if="isEditLabel" class="d-flex align-items-center mb-2">
      <input
        type="text"
        :value="value"
        @input="$emit('input', $event.target.value)"
        class="pii-label-input"
        style="height: 26px"
      />
      <a
        href="#"
        class="svg-icon mx-2"
        @click.prevent="() => (isEditLabel = false)"
        v-b-popover.hover.top
        title="Cancel"
      >
        <md-icon style="font-size: 20px" class="text-info mb-1">close</md-icon></a
      >
      <a
        href="#"
        class="svg-icon mr-2"
        @click.prevent="
          () => {
            isEditLabel = false;
            $emit('saveLabel');
          }
        "
        v-b-popover.hover.top
        title="Save"
      >
        <md-icon style="font-size: 20px" class="text-info mb-1">check</md-icon></a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditableLabel',
  props: {
    inputLabel: { type: String, default: '' },
    value: { type: String },
  },

  data() {
    return {
      isEditLabel: false,
    };
  },
};
</script>

<style>
</style>