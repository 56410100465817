<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator :title="title" />

        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input class="w-lg-auto" placeholder="Search Events" v-model="searchTerm" @input="onSearch" />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <!-- TODO: Add filters here. (if needed) -->
                <!-- <b-dropdown class="ml-lg-auto" :text="$t('All Topics')" variant="link text-black-70" right>
                  <b-dropdown-item active>All Topics</b-dropdown-item>
                  <b-dropdown-item>My Topics</b-dropdown-item>
                </b-dropdown>

                <b-dropdown class="mr-8pt" :text="$t('Newest')" variant="link text-black-70" right>
                  <b-dropdown-item active>Newest</b-dropdown-item>
                  <b-dropdown-item>Unanswered</b-dropdown-item>
                </b-dropdown> -->

                <b-btn :to="{ name: 'sa-add-event' }" exact variant="primary">Post Event</b-btn>
              </div>
            </div>
          </div>

          <!-- Events Table -->
          <b-table
            :fields="tableColumns"
            :items="events"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
            @row-clicked="editEvent"
          >
            <template #cell(name)="data">
              <strong> {{ data.item.name }} </strong>
            </template>

            <template #cell(image)="data">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside">
                <b-img :src="data.item.image_url" class="img-fluid" width="40" alt="Logo" v-if="data.item.image_url" />
                <i class="material-icons" v-else>event</i>
              </fmv-avatar>
            </template>

            <template #cell(type)="data"> {{ $t(`eventTypes.${data.item.type}`) }}</template>

            <!-- <template #cell(attendeeCount)="data">
              <i v-if="areAttendeeCountsLoading">Loading...</i>
              <a href="#" v-else @click.prevent="showEventAttendeesModal(data.item)" class="text-primary">{{
                $n(get(attendeeCountByEvent, `${data.item.id}.attendee_count`, 0))
              }}</a>
            </template> -->

            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <router-link
                :to="{ name: 'sa-edit-event', params: { id: data.item.id } }"
                class="svg-icon mr-2"
                v-b-popover.hover.top
                :title="$t('generalMsgs.edit')"
              >
                <md-icon class="text-info">edit</md-icon>
              </router-link>
              <a
                href="#"
                @click.prevent="confirmDeleteEvent(data.item.id)"
                v-b-popover.hover.top
                :title="$t('generalMsgs.remove')"
              >
                <md-icon class="text-danger">delete</md-icon>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalEvents"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="events-table"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- <event-attendees-modal
      :show-modal="modals.showAttendeesModal"
      :event="modals.selectedEvent"
      @close="hideEventAttendeesModal"
    >
    </event-attendees-modal> -->
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { FmvAvatar } from 'fmv-avatar';
import { debounce, get } from 'lodash';
import { mapActions } from 'vuex';
import moment from 'moment';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
//import EventAttendeesModal from './EventAttendees/EventAttendeesModal.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';

export default {
  components: { MdIcon, PageHeader, PageSeparator, Pagination, FmvAvatar },
  extends: Page,

  data() {
    return {
      title: 'Manage Events',

      isLoading: false,
      //areAttendeeCountsLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalEvents: 0,
      events: [],
      //attendeeCounts: [],
     // attendeeCountByEvent: {},

      searchTerm: '',
      ordering: '-scheduled_at',

      modals: {
        //showAttendeesModal: false,
        selectedEvent: {}
      }
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Events', active: true }
      ];
    },

    tableColumns() {
      return [
        { key: 'image', label: '', tdClass: 'clickable-item' },
        { key: 'title', label: 'Title', tdClass: 'clickable-item' },
        { key: 'type', label: 'Type', tdClass: 'clickable-item' },
        { key: 'location', label: 'Location', tdClass: 'clickable-item' },
        {
          key: 'scheduled_at',
          label: 'Scheduled At',
          tdClass: 'clickable-item',
          formatter: val => moment(val).format('MM/DD/YYYY, HH:mm:ss')
          // formatter: (val) => moment(val).toString(),
        },
        {
          key: 'created_at',
          label: 'Posted At',
          tdClass: 'clickable-item',
          formatter: val => moment(val).format('MM/DD/YYYY, HH:mm:ss')
        },

        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' }
        }
      ];
    }
  },

  methods: {
    ...mapActions('event', ['getAllEvents', 'deleteEvent']),
    get,

    async fetchEvents(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllEvents({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params
      });
      this.events = response.data.results;
      this.currentPage = pageNum;
      this.totalEvents = response.data.count;

      //this.fetchAttendeeCounts();
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchEvents(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchEvents();
    },

    onSearch() {
      this.debouncedSearchEvents(this);
    },

    debouncedSearchEvents: debounce(vm => {
      vm.fetchEvents();
    }, 500),

    editEvent(row) {
      this.$router.push({ name: 'sa-edit-event', params: { id: row.id } });
    },

    // async fetchAttendeeCounts() {
    //   this.areAttendeeCountsLoading = true;

    //   try {
    //     const response = await this.getEventsAttendeeCounts({
    //       event_ids: this.events.map((event) => event.id),
    //     });
    //     this.attendeeCounts = response;
    //     this.attendeeCountByEvent = keyBy(response, 'event_id');
    //   } catch (error) {
    //     this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
    //   }

    //   this.areAttendeeCountsLoading = false;
    // },

    async confirmDeleteEvent(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          "Please confirm that you want to delete the selected event and it's related event data.",
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }
        );
        if (isConfirmed) {
          await this.deleteEvent(id);
          const refreshPage = this.events.length > 1 ? this.currentPage : this.currentPage - 1;
          this.fetchEvents(refreshPage || 1);
          this.makeToast({ variant: 'success', msg: 'Event Successfully Deleted' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    }

    // showEventAttendeesModal(item) {
    //   this.modals.showAttendeesModal = true;
    //   this.modals.selectedEvent = item;
    // },

    //   hideEventAttendeesModal() {
    //     this.modals.showAttendeesModal = false;
    //     this.modals.selectedEvent = {};
    //   }
  },

  async mounted() {
    this.fetchEvents();
  }
};
</script>
