<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit Career Pathway' : 'Add Career Pathway'"
      :breadcrumb="breadcrumb"
      :container-class="null"
      class="mb-32pt"
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <!-- Career Preference Details -->

      <page-separator title="Career Details" />

      <b-form-group label="Photo" label-for="photo" label-class="form-label" class="row-align-items-center">
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isUploading.photo">...</span>
            <b-img :src="career.photo_url" class="img-fluid" width="40" alt="Photo" v-else-if="career.photo_url" />
            <md-icon v-else>local_hospital</md-icon>
          </fmv-avatar>

          <image-uploader
            @image="(e) => setImage(e, 'photo')"
            :isUploading="isUploading.photo"
            :isRequired="!career.photo_url"
            placeholder="Select image to upload."
          />
        </b-media>
      </b-form-group>

      <b-form-group label="Name" label-for="name" label-class="form-label">
        <b-form-input id="name" placeholder="Enter career's name" v-model="career.name" required />
      </b-form-group>

      <b-form-group label="Description" label-for="about" label-class="form-label">
        <b-form-textarea
          id="about"
          placeholder="Enter career's description."
          :rows="5"
          v-model="career.description"
          required
        />
      </b-form-group>

      <b-form-group label="Overview" label-for="overview" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
          }"
          v-model="career.overview"
          placeholder="Write pathway's overview."
        />
      </b-form-group>

      <b-form-group label="Traits" label-for="traits" label-class="form-label">
        <v-select
          id="traits"
          class="form-control v-select-custom"
          label="text"
          v-model="career.traits"
          :reduce="(exp) => exp.value"
          placeholder="Select Traits"
          :options="traitOptions"
        >
        </v-select>
      </b-form-group>
      <!-- <div class="row"> -->
      <!-- <b-form-group label="Category" label-for="category" label-class="form-label">
        <v-select
          id="category"
          class="form-control v-select-custom"
          label="text"
          v-model="career.category"
          :reduce="(item) => item.value"
          placeholder="Select Career Category"
          :options="categoryOptions"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!career.category" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
      </b-form-group> -->
      <b-form-group label="Category" label-for="category" label-class="form-label">
        <v-select
          id="category"
          class="form-control v-select-custom"
          label="text"
          v-model="career.new_category"
          :reduce="(item) => item.value"
          placeholder="Select Career Category"
          :options="newcategoryOptions"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!career.new_category" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
      </b-form-group>

      <!-- <b-form-group
          label="Category Order Number"
          label-for="category_order_no"
          label-class="form-label"
          class="col-md-6"
        >
          <b-form-input
            id="category_order_no"
            placeholder="Enter category order number."
            v-model="career.category_order_no"
            type="number"
            min="0"
          />
        </b-form-group> -->

      <!-- 
      <div class="row">
        <b-form-group label="Starting Salary Range" label-class="form-label" class="col-md-6">
          <b-form-input
            id="salary_range_from"
            placeholder="Enter starting salary range ($)."
            v-model="career.salary_range_from"
            type="number"
            min="0"
            required
          />
        </b-form-group>
        <b-form-group label="Ending Salary Range" label-class="form-label" class="col-md-6">
          <b-form-input
            id="salary_range_to"
            placeholder="Enter ending salary range ($)."
            v-model="career.salary_range_to"
            type="number"
            :min="career.salary_range_from || 0"
            required
          />
        </b-form-group>
      </div>

      <b-form-group label="Required Education" label-for="required_education" label-class="form-label">
        <b-form-input
          id="required_education"
          placeholder="Enter required education"
          v-model="career.required_education"
          required
        />
      </b-form-group>

      <b-form-group label="Required Skills" label-for="required_skills" label-class="form-label">
        <b-form-input
          id="required_skills"
          placeholder="Enter required skills"
          v-model="career.required_skills"
          required
        />
      </b-form-group> -->

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import { pick } from 'lodash';
import { mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Editor from '@tinymce/tinymce-vue';

import Page from '../../../components/Page.vue';
import ImageUploader from '@/components/ImageUploader.vue';
import { FmvAvatar } from 'fmv-avatar';

export default {
  components: { PageHeader, PageSeparator, ImageUploader, FmvAvatar, Editor },
  extends: Page,

  data() {
    return {
      title: this.$route.params.id ? 'Edit Career Preference' : 'Add Career Preference',
      career: {
        name: '',
        description: '',
        new_category: null,
        category_order_no: null,
        photo_url: null,
        overview: null,
        // salary_range_from: null,
        // salary_range_to: null,
        // required_education: null,
        // required_skills: null,
        traits: '',
      },
      isLoading: false,
      traitOptions: [
        { value: 'realistic', text: 'Realistic' },
        { value: 'investigate', text: 'Investigate' },
        { value: 'artistic', text: 'Artistic' },
        { value: 'social', text: 'Social' },
        { value: 'enterprising', text: 'Enterprising' },
        { value: 'conventional', text: 'Conventional' },
      ],
      // categoryOptions: [
      //   { value: 'administrative', text: 'Administrative' },
      //   { value: 'clinical', text: 'Clinical' },
      //   { value: 'technology', text: 'Technology' },
      // ],
      newcategoryOptions: [
        { value: 'environment', text: 'Environment' },
        { value: 'sector', text: 'Sector' },
      ],

      isUploading: { photo: false },
      selectedUploadFile: { photo: null },
      uploadPercent: { photo: 0 },
      uploadCancelTokenSource: { photo: null },
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Career Pathways', to: { name: 'sa-manage-career-pathways' } },
        {
          text: this.$route.params.id ? 'Edit Career Pathway' : 'Add Career Pathway',
          active: true,
        },
      ];
    },
    isFormLoading() {
      return this.isLoading || Object.values(this.isUploading).some((val) => val);
    },
  },

  methods: {
    ...mapActions('careerPreference', [
      'getCareerPreference',
      'createCareerPreference',
      'updateCareerPreference',
      'getCareerPrefUploadPresignedUrl',
    ]),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),

    setImage(file, fileType) {
      this.selectedUploadFile[fileType] = file;
      this.uploadFile(fileType);
    },

    async onSubmit() {
      this.isLoading = true;

      try {
        if (this.$route.params.id) {
          await this.updateCareerPreference({
            id: this.$route.params.id,
            data: this.career,
          });
          this.makeToast({ variant: 'success', msg: 'Career Pathway Updated!' });
        } else {
          await this.createCareerPreference(this.career);
          this.makeToast({ variant: 'success', msg: 'Career Pathway Added!' });
        }
        setTimeout(() => this.$router.push({ name: 'sa-manage-career-pathways' }), 250);
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },

    async uploadFile(fileType, uploadType = 'images', uploadUrlField = 'photo_url') {
      this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();
      this.isUploading[fileType] = true;

      try {
        const urlResp = await this.getCareerPrefUploadPresignedUrl({
          file_name: this.selectedUploadFile[fileType].name,
          content_type: this.selectedUploadFile[fileType].type,
          upload_type: uploadType,
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile[fileType],
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent[fileType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token,
          },
        });
        this.career[uploadUrlField] = urlResp.upload_url.split('?')[0];
      } catch (error) {
        this.selectedUploadFile[fileType] = null;
      }

      this.uploadCancelTokenSource[fileType] = null;
      this.uploadPercent[fileType] = 0;

      this.isUploading[fileType] = false;
    },
  },

  async mounted() {
    this.isLoading = true;

    try {
      if (this.$route.params.id) {
        const resp = (await this.getCareerPreference(this.$route.params.id)).data;
        this.career = pick(resp, [
          'name',
          'description',
          'new_category',
          'category_order_no',
          'photo_url',
          'traits',
          'overview',
        ]);
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({ name: 'sa-manage-career-preferences' });
    }

    this.isLoading = false;
  },
};
</script>
