<template>
  <div>
    <div class="container page__container d-flex align-items-center student-page">
      <p class="backResult text-50">
        <router-link
          :to="{ name: 'manage-assessments', params: { id: $route.params.id } }"
          style="text-decoration: underline"
        >
          <span class="material-icons icon-18pt text-80">navigate_before</span> Back to Assessments
        </router-link>
      </p>
    </div>
    <!-- <page-header :title="title" :container-class="containerClass" /> -->

    <!-- <div class="page-section"> -->
    <div :class="containerClass">
      <h2 class="d-flex mb-4">Assessment Result</h2>

      <div class="row bd-highlight mb-3 mt-5">
        <div class="d-flex flex-column bd-highlight mb-3 col-md-6">
          <div class="d-flex justify-content-center mt-4">
            <user-avatar slot="aside" size="7rem" :user="get(assessDetail, 'student')"> </user-avatar>
          </div>
          <span class="text-70 d-flex justify-content-center mt-3" style="font-size: 12pt"
            >{{ get(assessDetail, 'student.first_name') }} {{ get(assessDetail, 'student.last_name') }}</span
          >
          <span class="text-70 d-flex justify-content-center" style="font-size: 12pt">{{
            get(assessDetail, 'student.email')
          }}</span>
          <span class="text-70 d-flex justify-content-center" style="font-size: 12pt">{{
            formatDateTime(get(assessObject, 'date_generated'))
          }}</span>
          <span class="text-70 d-flex justify-content-center" style="font-size: 12pt"
            >Assessment Type: {{ $t(`assessTypes.${get(assessObject, 'assessment_type')}`) }}</span
          >

          <span class="mt-5 mb-2"
            >The top traits found in {{ get(assessDetail, 'student.first_name') }}
            {{ get(assessDetail, 'student.last_name') }} are :</span
          >

          <b-row>
            <b-col v-for="cert in mapArray" :key="cert" cols="6">
              <span class="material-icons icon-16pt text-primary mr-2">check</span>{{ capitalizeFirstLetter(cert) }}
            </b-col>
          </b-row>
        </div>
        <div class="d-flex flex-column bd-highlight mb-3 col-md-6">
          <div v-for="trait in Object.entries(get(assessObject, 'trait_percentage'))" :key="trait[0]">
            <b-progress :max="100" class="mt-3">
              <b-progress-bar :value="trait[1]" variant="primary"> </b-progress-bar>
            </b-progress>

            <div class="d-flex justify-content-between mt-2">
              <span>{{ $t(`careerPathwaysTraits.${trait[0]}`) }} </span><span> {{ Math.round(trait[1]) }}%</span>
            </div>
          </div>
        </div>
      </div>

      <page-separator>
        <b-link v-b-toggle="'career-pathways-section'" class="accordion__toggle p-0">
          <span class="flex">Career Pathways</span>
          <md-icon class="accordion__toggle-icon">
            {{ careerVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        id="career-pathways-section"
        accordion="careerPathwayAccordionId"
        :visible="careerVisible"
        class="accordion__menu"
        @hide="careerVisible = false"
        @show="careerVisible = true"
      >
        <b-skeleton-wrapper :loading="isLoading">
          <template #loading>
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-6" v-for="item in [1, 2, 3]" :key="item">
                <div class="card card-sm card--elevated p-relative">
                  <span class="js-image">
                    <b-skeleton-img width="100%" class="pb-0 mb-0"></b-skeleton-img>
                  </span>

                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex">
                        <a class="card-title" href="#" @click.prevent><b-skeleton width="70%"></b-skeleton></a>
                        <small class="text-50 font-weight-bold mb-4pt"><b-skeleton width="50%"></b-skeleton></small>
                      </div>

                      <div class="media ml-sm-auto align-items-center">
                        <div class="media-left mr-2 avatar-group">
                          <div
                            class="avatar avatar-sm rounded-circle o-hidden"
                            style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                          >
                            <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                          </div>
                          <div
                            class="avatar avatar-sm rounded-circle o-hidden"
                            style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                          >
                            <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <!-- <div class="row card-group-row mb-lg-12pt home-card">
            <div class="col-lg-4 col-md-6 col-sm-6" v-for="career in careers" :key="career.id"> -->
          <div class="col-sm-12 card-group-row__col" v-for="career in careers" :key="career.id">
            <div class="card card-lg overlay--primary-dodger-blue card-group-row__card">
              <div class="card-body d-flex flex-column">
                <div class="d-flex align-items-center">
                  <div class="flex">
                    <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                      <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                        <fmv-avatar :title="true" title-class="bg-transparent" no-link slot="aside" style="width: 5rem">
                          <b-img :src="career.photo_url" class="img-fluid" alt="Logo" v-if="career.photo_url" />
                          <i class="material-icons" v-else>card_giftcard </i>
                        </fmv-avatar>
                      </div>

                      <div class="flex">
                        <div class="mb-0" style="text-transform: none">
                          <div>
                            <b>{{ get(career, 'name') }} </b>
                          </div>
                          <div class="mb-2 d-flex justify-content-between flex-wrap">
                            <span class="text-muted">{{ formatDateTime(get(career, 'updated_at')) }}</span>
                          </div>

                          <div>
                            {{
                              truncate(trim(get(career, 'description')), {
                                length: 250,
                                separator: /,? +/,
                              })
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </div>
        </b-skeleton-wrapper>
      </b-collapse>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
//import PageHeader from '@/components/Ui/PageHeader.vue';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import { get, trim, truncate } from 'lodash';
// import Pagination from '../../../components/Ui/Pagination.vue';
// import MdIcon from '../../../components/Ui/MdIcon.vue';
import { formatDateTime, capitalizeFirstLetter } from '@/common/utils';
import UserAvatar from '@/components/User/UserAvatar.vue';
import { FmvAvatar } from 'fmv-avatar';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  components: {
    // PageHeader
    UserAvatar,
    FmvAvatar,
    PageSeparator,
  },
  extends: Page,
  data() {
    return {
      title: 'Assessment Result',
      careerVisible: true,
      windowWidth: window.innerWidth,
      careers: [],
      isLoading: false,
      isAssesLoading: false,
      assessObject: null,
      assessDetail: null,
    };
  },
  computed: {
    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
    isMobileScreen() {
      return this.windowWidth <= 720;
    },
    mapArray() {
      const traitArray = Object.entries(get(this.assessObject, 'trait_percentage'));
      traitArray.sort((a, b) => b[1] - a[1]);
      const top5Values = traitArray.slice(0, 5);
      const top5Object = Object.fromEntries(top5Values);
      return Object.keys(top5Object);
    },
  },
  methods: {
    ...mapActions('assessment', ['getCareersByIds', 'getAssessment']),
    get,
    trim,
    truncate,
    formatDateTime,
    capitalizeFirstLetter,
    async fetchAssessment() {
      this.isAssesLoading = true;
      try {
        const res = (await this.getAssessment(this.$route.params.id)).data;
        this.assessDetail = res;
        this.assessObject = get(this.assessDetail, 'assessment_response.assessment_list').find(
          (item) => item.date_generated === this.$route.query.date
        );
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isAssesLoading = false;
    },

    async fetchCareers() {
      this.isLoading = true;
      try {
        const res = await this.getCareersByIds({ career_ids: get(this.assessObject, 'career_pathways').map((c) => c) });
        this.careers = res;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
    if (this.$route.query.date) {
      await this.fetchAssessment();
      this.fetchCareers();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style>
</style>