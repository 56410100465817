<template>
  <b-modal ref="addMetaTagsModal" hide-footer title="Meta Tags" centered lazy @hide="hideModal" size="md">
    <b-row class="mb-2" v-for="(meta, index) in metaTags" :key="`metatags-${index}`">
      <b-form-input class="col-9 col-md-9 ml-1" id="name" placeholder="Add meta tag" v-model="metaTags[index]" />
      <a class="btn btn-primary btn-md ml-2" href="#" @click.prevent="addField">
        <md-icon>add</md-icon>
      </a>
      <a
        href="#"
        v-if="metaTags.length > 1 || existingMetaTags.length"
        class="btn btn-danger ml-2 btn-md"
        @click.prevent="removeField(metaTags[index], index)"
      >
        <md-icon>close</md-icon>
      </a>
    </b-row>
    <div class="d-flex justify-content-end mt-2">
      <b-btn
        variant="primary"
        class="ml-2"
        style="width: 70px"
        @click.prevent="submitData"
        href="#"
        data-toggle="modal"
        :disabled="isLoading"
      >
        <span>{{ 'update' }}</span></b-btn
      >
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { get } from 'lodash';

export default {
  name: 'AddMetaTagsModal',

  props: {
    showModal: { type: Boolean, default: false },
    index: { type: Number, default: null },
    existingMetaTags: { type: Array, default: () => [] },
  },

  data() {
    return {
      metaTags: [''],
      updated: false,
      isLoading: false,
    };
  },
  validations() {
    return {};
  },
  methods: {
    ...mapActions('school', ['updateMetaTags']),
    get,
    addField() {
      this.metaTags.push('');
    },
    async removeField(data, index) {
      if (data) {
        try {
          const isConfirmed = await this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete it.`, {
            title: 'Are you sure?',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          });
          if (isConfirmed) {
            if (index > -1) {
              this.metaTags.splice(index, 1);
            }
            this.updated = true;
            this.updateTags();
            if (!this.metaTags.length) {
              this.metaTags = [''];
            }
          }
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        if (index > -1) {
          this.metaTags.splice(index, 1);
        }
      }
    },
    async updateTags() {
      this.isLoading = true;

      const data = {
        meta_tags: this.metaTags.filter(Boolean),
      };
      try {
        await this.updateMetaTags({
          id: this.index,
          data,
        });
        this.makeToast({ variant: 'success', msg: 'Meta tags updated' });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
    async submitData() {
      await this.updateTags();
      this.$emit('update');
      this.metaTags = [''];
      this.$refs.addMetaTagsModal.hide();
    },

    async hideModal() {
      this.$emit('close');
      this.metaTags = [''];
    },

    hideModalManual() {
      this.$refs.addMetaTagsModal.hide();
      this.hideModal();
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        this.metaTags = this.existingMetaTags.length ? this.existingMetaTags : [''];
        this.$refs.addMetaTagsModal.show();
      }
    },
  },
  mounted() {},
};
</script>

<style>
</style>