<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator :title="title" />

        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Partners"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <b-btn :to="{ name: 'add-partner' }" exact variant="primary"> Add Partner </b-btn>
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="partners"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(title)="data">
              <strong> {{ data.item.title }} </strong>
            </template>

            <template #cell(category)="data">
              <strong> {{ $t(`jobCategory.${data.item.category}`) }} </strong>
            </template>

            <template #cell(status)="data">
              <b-badge
                :class="
                  'border border-' +
                  (data.item.status === JOB_STATUS.ACTIVE ? `success text-success` : `secondary text-secondary`)
                "
                variant="none"
                >{{ data.item.status === JOB_STATUS.ACTIVE ? 'Active' : 'Revoked' }}</b-badge
              >
              <!-- <strong> {{ $t(`jobStatus.${data.item.status}`) }} </strong> -->
            </template>

            <template #head(actions)="">
              <span></span>
            </template>

            <template #cell(actions)="data">
              <a
                href="#"
                class="mr-1"
                @click.prevent="toggleBlogStatus(data.index)"
                v-b-popover.hover.left
                :title="data.item.status === JOB_STATUS.ACTIVE ? 'Mark as Revoked' : 'Mark as Active'"
              >
                <md-icon :class="data.item.status === JOB_STATUS.ACTIVE ? 'text-danger' : 'text-success'">{{
                  data.item.status === JOB_STATUS.ACTIVE ? 'block' : 'check_circle_outline'
                }}</md-icon>
              </a>
              <router-link
                :to="{
                  name: 'edit-partner',
                  params: { id: data.item.id },
                }"
                class="svg-icon mr-2"
                v-b-popover.hover.top
                :title="$t('generalMsgs.edit')"
              >
                <md-icon class="text-info">edit</md-icon>
              </router-link>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalPartners"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="partners-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

import { debounce, get } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE, JOB_STATUS } from '../../../common/constants';

export default {
  components: { MdIcon, PageHeader, PageSeparator, Pagination },
  extends: Page,

  data() {
    return {
      title: 'Manage Partners',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalPartners: 0,
      partners: [],

      index: null,

      searchTerm: '',
      JOB_STATUS,
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Partners', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'title', label: 'Title' },

        { key: 'category', label: 'Category' },
        { key: 'status', label: 'Status' },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('partner', ['getAllPartners', 'updatePartner']),
    get,
    async toggleBlogStatus(index) {
      this.partners[index].status = this.partners[index].status === JOB_STATUS.ACTIVE ? 'revoke' : 'active';

      await this.updatePartner({ id: this.partners[index].id, data: { status: this.partners[index].status } });
      this.makeToast({
        variant: 'success',
        msg: `Partner marked as ${this.partners[index].status === JOB_STATUS.ACTIVE ? 'Active' : 'Revoked'}`,
      });
    },
    async fetchPartners(pageNum = 1, params = {}) {
      this.isLoading = true;

      const response = await this.getAllPartners({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });

      this.partners = response.data.results;
      this.currentPage = pageNum;
      this.totalPartners = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchPartners(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchPartners();
    },

    onSearch() {
      this.debouncedSearchPartners(this);
    },

    debouncedSearchPartners: debounce((vm) => {
      vm.fetchPartners();
    }, 500),
  },

  async mounted() {
    this.fetchPartners();
  },
};
</script>
