<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section border-bottom-2">
      <div :class="containerClass">
        <div class="row">
          <div class="col-lg-4">
            <div class="card border-1 border-left-3 border-left-accent text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading.totalSchools">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ totalSchools }}</h4>
                </b-skeleton-wrapper>
                <div>Total Schools</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading.totalStudents">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ totalStudents }}</h4>
                </b-skeleton-wrapper>
                <div>Total Students</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading.totalEmployers">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ totalEmployers }}</h4>
                </b-skeleton-wrapper>
                <div>Total Employers</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';

export default {
  components: { PageHeader },
  extends: Page,

  data() {
    return {
      title: 'Dashboard',

      totalSchools: 0,
      totalStudents: 0,
      totalEmployers: 0,

      isLoading: {
        totalSchools: false,
        totalStudents: false,
        totalEmployers: false,
      },
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Dashboard', active: true },
      ];
    },
  },

  methods: {
    ...mapActions('school', ['getAllSchools']),
    ...mapActions('student', ['getAllStudents']),
    ...mapActions('employer', ['getAllEmployers']),

    async fetchSchools() {
      this.isLoading.totalSchools = true;
      const response = await this.getAllSchools({ limit: 1, offset: 0 });
      this.totalSchools = response.data.count;
      this.isLoading.totalSchools = false;
    },

    async fetchStudents() {
      this.isLoading.totalStudents = true;
      const response = await this.getAllStudents({ limit: 1, offset: 0 });
      this.totalStudents = response.data.count;
      this.isLoading.totalStudents = false;
    },

    async fetchEmployers() {
      this.isLoading.totalEmployers = true;
      const response = await this.getAllEmployers({ limit: 1, offset: 0 });
      this.totalEmployers = response.data.count;
      this.isLoading.totalEmployers = false;
    },
  },

  async mounted() {
    this.fetchSchools();
    this.fetchStudents();
    this.fetchEmployers();
  },
};
</script>
