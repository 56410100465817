<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit Event' : 'Post Event'"
      :breadcrumb="breadcrumb"
      :container-class="null"
      class="mb-32pt"
    />

    <page-separator title="Event Details" />
    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <!-- Basic Event Info -->
      <b-form-group label="Image" label-for="image" label-class="form-label" class="row-align-items-center">
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isUploading.image">...</span>
            <b-img :src="event.image_url" class="img-fluid" width="40" alt="Logo" v-else-if="event.image_url" />
            <i class="material-icons" v-else>event</i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'image')"
            :isUploading="isUploading.image"
            :isRequired="!event.image_url"
            placeholder="Select event's image"
          />
        </b-media>
      </b-form-group>
      <b-form-group label="Title" label-for="title" label-class="form-label">
        <b-form-input id="title" placeholder="Enter event's title" v-model="event.title" required />
      </b-form-group>

      <b-form-group label="Event Summary" label-for="summary" label-class="form-label">
        <b-form-textarea
          id="summary"
          placeholder="Enter a short summary for the event"
          :rows="5"
          v-model="event.summary"
          required
        />
      </b-form-group>

      <b-form-group label="Description" label-for="description" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
          }"
          v-model="event.description"
          placeholder="Enter event's description."
        />
        <b-form-invalid-feedback :state="!$v.event.description.required && $v.event.description.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Scheduled At (Date and Time)" label-for="scheduled_at" label-class="form-label">
        <date-picker
          id="scheduled_at"
          class="form-control datepicker-custom w-100"
          placeholder="Select event's date and time"
          v-model="event.scheduled_at"
          type="datetime"
          format="MM/DD/YYYY HH:mm"
          valueType="YYYY-MM-DD HH:mm"
          :minute-step="5"
        />
        <b-form-invalid-feedback :state="!$v.event.scheduled_at.required && $v.event.scheduled_at.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Location" label-for="location" label-class="form-label">
        <b-form-input id="location" placeholder="Enter event's location" v-model="event.location" required />
      </b-form-group>

      <b-form-group label="Event Type" label-for="type" label-class="form-label">
        <div class="custom-select-icon">
          <b-form-select
            class="custom-select-icon__select"
            id="type"
            v-model="event.type"
            :options="EVENT_TYPE_OPTIONS"
            required
          >
            <template #first>
              <b-form-select-option :value="null" disabled>Select an event type</b-form-select-option>
            </template>
          </b-form-select>
          <span class="material-icons custom-select-icon__icon">sort</span>
        </div>
      </b-form-group>

      <b-form-group
        label="Event Link"
        label-for="event_link"
        label-class="form-label"
        v-if="event.type === EVENT_TYPES.VIRTUAL"
      >
        <b-form-input
          id="event_link"
          placeholder="Enter event's link"
          v-model="event.event_link"
          required
          :state="
            (!$v.event.event_link.required || !$v.event.event_link.url) && $v.event.event_link.$dirty ? false : null
          "
        />
        <b-form-invalid-feedback v-if="!$v.event.event_link.url"
          >Not a valid link. Please make sure to enter a valid link e.g.
          https://www.example.com.</b-form-invalid-feedback
        >
        <b-form-invalid-feedback v-else>This field is required.</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="Organization"
        label-for="org"
        label-class="form-label"
        v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
      >
        <v-select
          id="org"
          class="form-control v-select-custom"
          label="name"
          v-model="event.entity_id"
          :reduce="org => org.id"
          placeholder="Select Organization"
          :options="allOrgs"
          :loading="areOrgsLoading"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
      </b-form-group>
      <div>
        <page-separator title="Event Moderators" />
        <div class="d-flex justify-content-end row">
          <b-btn variant="primary" :disabled="isLoading" @click.prevent="addModerator" size="sm">
            <span>Add Moderator</span>
          </b-btn>
        </div>

        <div v-for="(mod, index) in event.moderators" :key="`mod-${index}`">
          <div class="row">
            <b-form-group
              label="Image"
              label-for="mod-image"
              label-class="form-label"
              class="row-align-items-center"
              :class="event.moderators.length > 1 ? 'col-md-11' : 'col-md-12'"
            >
              <b-media class="align-items-center" vertical-align="center">
                <user-avatar
                  slot="aside"
                  rounded="lg"
                  size="lg"
                  :isLoading="isUploading.avatar"
                  :user="mod"
                  url-field="image_url"
                  first-name-field="name"
                >
                </user-avatar>

                <image-uploader
                  @image="
                    e => setModeratorImage(e, `modImages[${index}]`, `moderators[${index}].image_url`, 'mod', index)
                  "
                  :isUploading="isModImageUploading[index]"
                  :isRequired="!mod.image_url"
                  placeholder="Select moderator image"
                />
              </b-media>
            </b-form-group>

            <div class="d-flex align-items-center col-md-1" v-if="event.moderators.length > 1">
              <b-btn
                variant="danger"
                :disabled="isLoading"
                @click.prevent="removeModerator(index)"
                size="sm"
                class="ml-auto"
              >
                <span>Remove</span>
              </b-btn>
            </div>
          </div>
          <div class="row">
            <b-form-group label="Name" :label-for="`mod-name-${index}`" label-class="form-label" class="col-md-6">
              <b-form-input
                :id="`mod-name-${index}`"
                placeholder="Enter moderator's name"
                v-model="mod.name"
                required
              />
            </b-form-group>

            <b-form-group label="Email" :label-for="`mod-email-${index}`" label-class="form-label" class="col-md-6">
              <b-form-input
                :id="`mod-email-${index}`"
                placeholder="Enter moderator's email"
                v-model="mod.email"
                type="email"
                required
              />
            </b-form-group>
          </div>

          <b-form-group label="Title" :label-for="`mod-title-${index}`" label-class="form-label">
            <b-form-input
              :id="`mod-title-${index}`"
              placeholder="Enter moderator's title"
              v-model="mod.title"
              required
            />
          </b-form-group>

          <b-form-group label="Bio" :label-for="`mod-bio-${index}`" label-class="form-label">
            <b-form-textarea
              :id="`mod-bio-${index}`"
              placeholder="Enter moderator's bio"
              :rows="5"
              v-model="mod.bio"
              required
            />
          </b-form-group>
        </div>
      </div>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? 'Update' : 'Post' }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import { get, pick, map, set, isNull } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Editor from '@tinymce/tinymce-vue';
import { required, requiredIf, url } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import { USER_ROLES } from '@/common/constants';
import 'vue2-datepicker/index.css';
import moment from 'moment';

import Page from '../../../components/Page.vue';
import { EVENT_TYPES, EVENT_TYPE_OPTIONS, USER_ROLE_TYPES } from '../../../common/constants';
import UserAvatar from '../../../components/User/UserAvatar.vue';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: { PageHeader, PageSeparator, FmvAvatar, Editor, DatePicker, UserAvatar, ImageUploader },
  extends: Page,

  data() {
    return {
      EVENT_TYPES,
      EVENT_TYPE_OPTIONS,
      USER_ROLE_TYPES,
      title: this.$route.params.id ? 'Edit Event' : 'Post Event',

      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      event: {
        title: '',
        description: '',
        summary: '',
        scheduled_at: '',
        type: null,
        event_link: null,
        location: '',
        image_url: '',
        entity_id: '',
        entity_type: '',
        moderators: [{ image_url: '', name: '', email: '', bio: '', title: '' }]
      },

      isLoading: false,
      areOrgsLoading: false,
      allOrgs: [],
      USER_ROLES,
      isUploading: { image: false },
      isModImageUploading: [false],
      uploadFile: { image: null, modImages: [null] },
      uploadPercent: { image: 0, modImages: [0] },
      uploadCancelTokenSource: { image: null, modImages: [null] }
    };
  },

  validations() {
    return {
      event: {
        title: { required },
        description: { required },
        summary: { required },
        scheduled_at: { required },
        type: { required },
        location: { required },
        event_link: { required: requiredIf(() => this.event.type === EVENT_TYPES.VIRTUAL), url },
        image_url: { required },
        moderators: {}
      }
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        {
          text: 'Events',
          to: { name: this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN ? 'sa-manage-events' : 'manage-org-events' }
        },
        {
          text: this.$route.params.id ? 'Edit Event' : 'Post Event',
          active: true
        }
      ];
    },
    isFormLoading() {
      return this.isLoading || this.isUploading.image || this.isModImageUploading.some(val => val);
    }
  },

  methods: {
    ...mapActions('event', ['getEvent', 'createEvent', 'updateEvent', 'getEventImageUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('organization', ['getAllOrganizations', 'getOrganization']),
    get,
    isNull,
    setImage(file, fileType) {
      this.uploadFile[fileType] = file;
      this.uploadImage(fileType);
    },

    setModeratorImage(file, fileType, fileField = 'image_url', entityType = 'event', modIndex = 0) {
      this.uploadFile.modImages[modIndex] = file;
      this.uploadImage(fileType, fileField, entityType, modIndex);
    },
    async fetchOrgs() {
      this.areOrgsLoading = true;
      const response = await this.getAllOrganizations({});
      this.allOrgs = response.data;

      this.areOrgsLoading = false;
    },
    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        try {
          const data = isNull(get(this.event.entity_id))
            ? {
                ...this.event,
                event_link: this.event.type === EVENT_TYPES.VIRTUAL ? this.event.event_link : null,
                scheduled_at: moment(this.event.scheduled_at, 'YYYY-MM-DD HH:mm').format()
              }
            : {
                ...this.event,
                event_link: this.event.type === EVENT_TYPES.VIRTUAL ? this.event.event_link : null,
                scheduled_at: moment(this.event.scheduled_at, 'YYYY-MM-DD HH:mm').format(),
                entity_type: USER_ROLE_TYPES.ORGANIZATION
              };
          if (this.$route.params.id) {
            await this.updateEvent({
              id: this.$route.params.id,
              data
            });
            this.makeToast({ variant: 'success', msg: 'Event Details Updated' });
          } else {
            await this.createEvent(data);
            this.makeToast({ variant: 'success', msg: 'Event Posted' });
          }
          setTimeout(
            () =>
              this.$router.push({
                name: this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN ? 'sa-manage-events' : 'manage-org-events'
              }),
            250
          );
        } catch (_err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
      }

      this.isLoading = false;
    },

    async uploadImage(fileType, fileField = 'image_url', entityType = 'event', modIndex = 0) {
      set(this.uploadCancelTokenSource, fileType, axios.CancelToken.source());
      if (entityType === 'mod') this.$set(this.isModImageUploading, modIndex, true);
      else this.isUploading.image = true;

      try {
        const urlResp = await this.getEventImageUploadPresignedUrl({
          file_name: get(this.uploadFile, fileType).name,
          content_type: get(this.uploadFile, fileType).type,
          upload_type: 'images'
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: get(this.uploadFile, fileType),
          config: {
            onUploadProgress: function(progressEvent) {
              set(this.uploadPercent, fileType, Math.round((progressEvent.loaded * 100) / progressEvent.total));
            }.bind(this),
            cancelToken: get(this.uploadCancelTokenSource, fileType).token
          }
        });
        set(this.event, fileField, urlResp.upload_url.split('?')[0]);
      } catch (error) {
        set(this.uploadFile, fileType, null);
      }

      set(this.uploadCancelTokenSource, fileType, null);
      set(this.uploadPercent, fileType, 0);

      if (entityType === 'mod') this.$set(this.isModImageUploading, modIndex, false);
      else this.isUploading.image = false;
    },

    addModerator() {
      this.event.moderators.push({ image_url: '', name: '', email: '', bio: '', title: '' });
      this.isModImageUploading.push(false);
      this.uploadFile.modImages.push(null);
      this.uploadPercent.modImages.push(0);
      this.uploadCancelTokenSource.modImages.push(null);
    },
    removeModerator(index) {
      this.event.moderators.splice(index, 1);
      this.isModImageUploading.splice(index, 1);
      this.uploadFile.modImages.splice(index, 1);
      this.uploadPercent.modImages.splice(index, 1);
      this.uploadCancelTokenSource.modImages.splice(index, 1);
    }
  },

  async mounted() {
    this.isLoading = true;
    if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
      this.fetchOrgs();
    }
    try {
      if (this.$route.params.id) {
        const resp = (await this.getEvent(this.$route.params.id)).data;
        this.event = {
          ...pick(resp, [
            'title',
            'description',
            'summary',
            'type',
            'event_link',
            'location',
            'image_url',
            'entity_id'
          ]),

          moderators: map(resp.moderators, mod => pick(mod, ['image_url', 'name', 'email', 'title', 'bio'])),
          scheduled_at: moment(resp.scheduled_at).format('YYYY-MM-DD HH:mm')
        };
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({
        name: this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN ? 'sa-manage-events' : 'manage-org-events'
      });
    }

    this.isLoading = false;
  }
};
</script>
