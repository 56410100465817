<template>
  <div class="container">
    <b-form class="col-md-12 px-0 page-section pt-0">
      <!-- Features -->
      <div class="form-label sub-title">Subsections</div>
      <page-separator>
        <b-link v-b-toggle="'features-section'" class="accordion__toggle p-0">
          <span class="flex">Features</span>
          <md-icon class="accordion__toggle-icon">
            {{ featuresVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        id="features-section"
        accordion="featuresAccordionId"
        :visible="featuresVisible"
        class="accordion__menu"
        @hide="featuresVisible = false"
        @show="featuresVisible = true"
      >
        <b-form-group label-class="form-label">
          <b-form-checkbox id="about-features" v-model="aboutSection" switch> Enable this sub-section </b-form-checkbox>
        </b-form-group>
        <div v-if="aboutSection">
          <div v-for="(feature, index) in $v.features.$each.$iter" :key="index">
            <page-separator :title="`Feature #${+index + 1}`" />
            <b-form-group label="Title" label-for="name" label-class="form-label">
              <b-form-input
                id="name"
                placeholder="Enter Feature Title"
                v-model.trim="feature.name.$model"
                :state="!feature.name.required && feature.$dirty ? false : null"
              />
              <b-form-invalid-feedback v-if="!feature.name.required && feature.$dirty">
                This field is required.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Description" label-for="desc" label-class="form-label">
              <b-form-textarea
                id="desc"
                placeholder="Enter company's short/summarized bio."
                rows="6"
                v-model.trim="feature.$model.desc"
                :state="!feature.desc.required && feature.$dirty ? false : null"
              ></b-form-textarea>
              <b-form-invalid-feedback v-if="!feature.desc.required && feature.$dirty">
                This field is required.
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
      </b-collapse>

      <!-- Team -->

      <page-separator>
        <b-link v-b-toggle="'team-section'" class="accordion__toggle p-0">
          <span class="flex">Team</span>
          <md-icon class="accordion__toggle-icon">
            {{ teamVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        id="team-section"
        accordion="teamAccordionId"
        :visible="teamVisible"
        class="accordion__menu"
        @hide="teamVisible = false"
        @show="teamVisible = true"
      >
        <b-form-group label-class="form-label">
          <b-form-checkbox id="team" v-model="teamSection" switch> Enable this sub-section </b-form-checkbox>
        </b-form-group>
        <div v-if="teamSection">
          <b-form-group label="Title" label-for="team-title" label-class="form-label">
            <b-form-input id="team-title" placeholder="Team" v-model.trim="team_title" />
          </b-form-group>

          <b-form-group label="Description" label-for="team_desc" label-class="form-label">
            <b-form-textarea id="team_desc" rows="6" v-model="team_desc" required></b-form-textarea>
          </b-form-group>

          <div v-for="(fe, index) in $v.team.$each.$iter" :key="index" class="mb-2">
            <div class="d-flex justify-content-end">
              <a class="btn btn-primary btn-md" href="#" @click.prevent="addFeat(index)"> Add Member </a>
              <a href="#" class="btn btn-danger ml-2 btn-md" @click.prevent="removeFeat(index)" v-if="team.length > 1">
                Remove Member
              </a>
            </div>

            <div>
              <page-separator :title="`Member #${+index + 1}`" />

              <b-form-group
                label="Image"
                label-for="image_url"
                label-class="form-label"
                class="row-align-items-center col-md-12"
              >
                <b-media class="align-items-center" vertical-align="center">
                  <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
                    <span v-if="featuresFile[index].isImgUploading">...</span>
                    <b-img
                      :src="fe.image_url.$model"
                      class="img-fluid"
                      width="40"
                      alt="Image"
                      v-else-if="fe.image_url.$model"
                    />
                    <i class="fas fa-university fa-lg" v-else></i>
                  </fmv-avatar>

                  <image-uploader
                    @image="(e) => setFeaturesImage(e, index)"
                    :isUploading="featuresFile[index].isImgUploading"
                    :isRequired="!fe.image_url.$model"
                    placeholder="Select Member Image"
                  />
                </b-media>
              </b-form-group>

              <b-form-group label="Name" label-for="member-title" label-class="form-label" class="col-md-12">
                <b-form-input
                  id="member-title"
                  placeholder="Member"
                  v-model.trim="fe.title.$model"
                  :state="!fe.title.required && fe.$dirty ? false : null"
                />
                <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group label="Designation" label-for="designation" label-class="form-label" class="col-md-12">
                <b-form-input
                  id="designation"
                  placeholder="Designation"
                  v-model.trim="fe.description.$model"
                  :state="!fe.description.required && fe.$dirty ? false : null"
                />

                <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>
        </div>
      </b-collapse>
    </b-form>
  </div>
</template>

<script>
import { get, isEmpty } from 'lodash';
import axios from 'axios';
import { mapActions } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import { USER_ROLES, USER_ROLE_TYPES } from '@/common/constants';
import { requiredIf } from 'vuelidate/lib/validators';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    FmvAvatar,

    PageSeparator,
    ImageUploader,
  },
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      featuresVisible: false,
      teamVisible: false,
      aboutSection: false,
      teamSection: false,
      team_title: '',
      team_desc: '',
      isLoading: false,
      isImgUploading: false,

      uploadFile: { banner_image: null, bottom_img: null },
      uploadPercent: { banner_image: 0, bottom_img: 0 },
      uploadCancelTokenSource: { banner_image: null, bottom_img: 0 },
      USER_ROLES,
      USER_ROLE_TYPES,

      team: [
        {
          title: '',
          description: '',
          image_url: '',
        },
      ],
      features: [
        {
          name: 'Mission',
          desc: '',
        },
        {
          name: 'Vision',
          desc: '',
        },
        {
          name: 'Value',
          desc: '',
        },
      ],
      featuresFile: [{ image_url: null, isImgUploading: false }],
    };
  },
  watch: {
    aboutData: {
      immediate: true,
      handler(value) {
        if (value) {
          this.aboutSection = value.show_features;
          this.teamSection = value.show_section_two;

          this.features = get(value, 'features.length') ? get(value, 'features') : this.features;

          this.team_title = value.section_two_title;
          this.team_desc = value.section_two_desc;

          this.team = get(value, 'section_two.length')
            ? get(value, 'section_two')
            : [
                {
                  title: '',
                  description: '',
                  image_url: '',
                },
              ];
          if (get(value, 'section_two')) {
            this.featuresFile = this.team.map(() => ({ image_url: null, isImgUploading: false }));
          }
        }
      },
    },
  },
  validations() {
    return {
      team: {
        $each: {
          description: {
            required: requiredIf(() => this.teamSection),
          },
          title: {
            required: requiredIf(() => this.teamSection),
          },
          image_url: {
            required: requiredIf(() => this.teamSection),
          },
        },
      },
      features: {
        required: requiredIf(() => this.aboutSection),
        $each: {
          name: {
            required: requiredIf(() => this.aboutSection),
          },
          desc: { required: requiredIf(() => this.aboutSection) },
        },
      },
    };
  },

  computed: {
    isFormLoading() {
      return this.isLoading || this.isImgUploading;
    },
  },

  methods: {
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('organization', ['getOrgImageUploadPresignedUrl']),
    get,
    isEmpty,

    validateFormData() {
      this.$v.$touch();
      return {
        isValid: !this.$v.$invalid,
        formData: {
          about_section: this.aboutSection,
          team_section: this.teamSection,

          features: this.features || [],

          team_title: this.team_title,
          team_desc: this.team_desc,
          team: this.team || [],
        },
      };
    },

    setFeaturesImage(file, index) {
      this.featuresFile[index].image_url = file;
      this.uploadImage('image_url', index);
    },

    addFeat(index) {
      this.team.splice(index + 1, 0, {
        title: '',
        description: '',
        image_url: '',
      });
      this.featuresFile.splice(index + 1, 0, {
        image_url: null,
        isImgUploading: false,
      });
    },

    removeFeat(index) {
      this.team.splice(index, 1);
      this.featuresFile.splice(index, 1);
    },

    async uploadImage(uploadType, index) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      if (uploadType === 'image_url') this.featuresFile[index].isImgUploading = true;
      try {
        const urlResp = await this.getOrgImageUploadPresignedUrl({
          file_name: this.featuresFile[index].image_url.name,
          content_type: this.featuresFile[index].image_url.type,
          upload_type: 'images',
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.featuresFile[index].image_url,
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token,
          },
        });
        if (uploadType === 'image_url') {
          this.team[index].image_url = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'image_url') this.featuresFile[index].isImgUploading = false;
    },
  },

  async mounted() {},
};
</script>
<style lang="sass" scoped>
.sub-title
  margin-left: -12px
</style>
