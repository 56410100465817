<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? this.$t('employerMsgs.editEmployer') : this.$t('employerMsgs.addEmployer')"
      :breadcrumb="breadcrumb"
      :container-class="null"
      class="mb-32pt"
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <!-- Employer Profile Info -->

      <page-separator title="Employer Profile" />

      <b-form-group class="row-align-items-center">
        <label class="form-label">
          Logo
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="`Ideal dimensions for the logo should be 1280*720.`"
            >info</i
          >
        </label>
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isUploading.logo">...</span>
            <b-img :src="employer.logo_url" class="img-fluid" width="40" alt="Logo" v-else-if="employer.logo_url" />
            <md-icon v-else>business</md-icon>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'logo')"
            :isUploading="isUploading.logo"
            :isRequired="!employer.logo_url"
            placeholder="Select logo image to upload."
          />
        </b-media>
      </b-form-group>
      <b-form-group label-for="small_logo_url" class="row-align-items-center">
        <label class="form-label">
          Small Logo
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="
              `Ideal dimensions for small logo should be 512*512. This will be shown as app's favicon.`
            "
            >info</i
          >
        </label>
        <b-media class="align-items-center" vertical-align="center">
          <!-- <user-avatar slot="aside" rounded="lg" size="lg" :isLoading="isLogoUploading" :file-url="owner.logoUrl">
          </user-avatar> -->

          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isUploading.smallLogo">...</span>
            <b-img
              :src="employer.small_logo_url"
              class="img-fluid"
              width="40"
              alt="Logo"
              v-else-if="employer.small_logo_url"
            />
            <md-icon v-else>business</md-icon>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'smallLogo', 'images', 'small_logo_url')"
            :isUploading="isUploading.smallLogo"
            :isRequired="!employer.small_logo_url"
            placeholder="Select Employer's Small Logo Picture"
          />
        </b-media>
      </b-form-group>

      <b-form-group label="Business Name" label-for="name" label-class="form-label">
        <b-form-input id="name" placeholder="Enter Business Name" v-model="employer.name" required />
      </b-form-group>

      <b-form-group
        label="Organization"
        label-for="organization"
        label-class="form-label"
        v-if="getLoggedInUser.role === USER_ROLES.SUPER_ADMIN"
      >
        <v-select
          id="organization"
          class="form-control v-select-custom"
          label="name"
          v-model="organization"
          :reduce="opt => opt.id"
          placeholder="Select Organization"
          :options="allOrganizations"
          :loading="areOrgsLoading"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
      </b-form-group>

      <b-form-group :label="$t('employerMsgs.phone')" label-for="phone" label-class="form-label">
        <b-form-input
          id="phone"
          placeholder="(888) 689 - 1235"
          v-model="employer.phone"
          v-mask="'(###) ### - ####'"
          :state="errorStates.phone"
          required
        />
        <b-form-invalid-feedback>
          Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Website" label-for="website" label-class="form-label">
        <b-form-input id="website" placeholder="Enter company's website url." v-model="employer.website_url" />
      </b-form-group>
      <b-form-group
        v-if="getLoggedInUser.role === USER_ROLES.SUPER_ADMIN"
        label="Referral School"
        label-for="referral_school"
        label-class="form-label"
      >
        <b-form-input id="referral_school" :value="referral_school.name" disabled v-if="employer.referral_school" />
        <i v-else>No referral school associated with this employer.</i>
      </b-form-group>
      <!-- Company Type & Size Fields -->

      <b-form-group label="Company Size" label-for="company_size" label-class="form-label">
        <v-select
          id="company_size"
          class="form-control v-select-custom"
          label="text"
          v-model="employer.company_size"
          :reduce="item => item.value"
          placeholder="Select Company Size"
          :options="companySizeOptions"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!employer.company_size" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
      </b-form-group>

      <b-form-group label="Company Type" label-for="company_type" label-class="form-label">
        <v-select
          id="company_type"
          class="form-control v-select-custom"
          label="text"
          v-model="employer.company_type"
          :reduce="item => item.value"
          placeholder="Select Company Type."
          :options="companyTypeOptions"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!employer.company_type" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
      </b-form-group>

      <b-form-group label="Offered Career(s)" label-for="offered_careers" label-class="form-label">
        <v-select
          id="offered_careers"
          class="form-control v-select-custom"
          label="name"
          v-model="employer.offered_careers"
          :reduce="item => item.id"
          placeholder="Select the careers you are offering"
          :options="careerPreferences"
          :loading="areCareerPrefsLoading"
          multiple
        >
          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.photo_url" class="img-fluid" width="20" alt="Logo" v-if="option.photo_url" />
                <md-icon class="text-dark" v-else>local_hospital</md-icon>
              </fmv-avatar>
              <span class="ml-1">{{ option.name }}</span>
              <!-- <span class="ml-1"> (${{ $n(option.salary_range_from) }} - ${{ $n(option.salary_range_to) }})</span> -->
              <!-- <i class="ml-1">- {{ option.description }}</i> -->
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
      </b-form-group>

      <b-form-group label="Profile Visibility" label-for="about" label-class="form-label">
        <b-form-select v-model="employer.is_profile_visible" :options="PROFILE_VISIBILITY_OPTIONS"></b-form-select>
      </b-form-group>

      <b-form-group label="Short Bio" label-for="short_bio" label-class="form-label">
        <b-form-textarea
          id="short_bio"
          placeholder="Enter company's short/summarized bio."
          :rows="5"
          v-model="employer.short_bio"
        />
      </b-form-group>
      <b-form-group label="Bio" label-for="bio" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
          }"
          v-model="employer.bio"
          placeholder="Enter company's bio"
        />
      </b-form-group>

      <!-- Address Fields -->
      <b-form-group :label="$t('addressMsgs.address')" label-for="address" label-class="form-label">
        <b-form-input id="address" :placeholder="$t('addressMsgs.address')" v-model="employer.address" required />
      </b-form-group>

      <div class="row">
        <b-form-group :label="$t('addressMsgs.zipcode')" label-for="zipcode" label-class="form-label" class="col-md-6">
          <b-form-input id="zipcode" :placeholder="$t('addressMsgs.zipcode')" v-model="employer.zipcode" required />
        </b-form-group>

        <b-form-group :label="$t('addressMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
          <b-form-input id="city" :placeholder="$t('addressMsgs.city')" v-model="employer.city" required />
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group :label="$t('addressMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
          <v-select
            id="state"
            class="form-control v-select-custom"
            label="text"
            v-model="employer.state"
            :reduce="state => state.value"
            placeholder="Select State"
            :options="stateOptions"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!employer.state" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
        </b-form-group>

        <b-form-group :label="$t('addressMsgs.country')" label-for="country" label-class="form-label" class="col-md-6">
          <v-select
            id="country"
            class="form-control v-select-custom"
            label="text"
            v-model="employer.country"
            disabled
            :reduce="country => country.value"
            :placeholder="$t('addressMsgs.countryPlaceholder')"
            :options="countryOptions"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!employer.country" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
        </b-form-group>
      </div>

      <b-form-group
        v-if="getLoggedInUser.role === USER_ROLES.SUPER_ADMIN"
        label="Available Tokens / Credits"
        label-for="available_tokens"
        label-class="form-label"
      >
        <currency-input
          v-model="employer.available_tokens"
          required
          class="form-control"
          placeholder="Enter available tokens"
          :currency="{ prefix: '', suffix: ' TT' }"
          :precision="0"
          :allow-negative="false"
        />
      </b-form-group>

      <b-form-group
        v-if="getLoggedInUser.role === USER_ROLES.SUPER_ADMIN"
        label="Available Job Credits"
        label-for="available_job_credits"
        label-class="form-label"
      >
        <currency-input
          v-model="employer.available_job_credits"
          required
          class="form-control"
          :currency="{ prefix: '', suffix: '' }"
          placeholder="Enter job credit "
          :precision="0"
        />
      </b-form-group>
      <b-form-group
        label="Profile Background Image"
        label-for="profileBgImage"
        label-class="form-label"
        class="row-align-items-center"
      >
        <b-media class="align-items-center" vertical-align="center">
          <image-uploader
            @image="e => setImage(e, 'profileBgImage', 'images', 'profile_bg_img_url')"
            :isUploading="isUploading.profileBgImage"
            :isRequired="false"
            placeholder="Select company's profile background image."
          />
        </b-media>
      </b-form-group>

      <!-- User Details -->
      <div v-if="getLoggedInUser.role === USER_ROLES.SUPER_ADMIN">
        <page-separator title="Admin's Info" />

        <b-form-group
          :label="$t('userMsgs.avatar')"
          label-for="avatar"
          label-class="form-label"
          class="row-align-items-center"
        >
          <b-media class="align-items-center" vertical-align="center">
            <user-avatar
              slot="aside"
              size="lg"
              :isLoading="isUploading.avatar"
              :user="user"
              url-field="avatarUrl"
              first-name-field="firstName"
              last-name-field="lastName"
            >
            </user-avatar>

            <image-uploader
              @image="e => setImage(e, 'avatar', 'images')"
              :isUploading="isUploading.avatar"
              :isRequired="false"
              :placeholder="$t('userMsgs.avatarDesc')"
            />
          </b-media>
        </b-form-group>

        <div class="row">
          <b-form-group
            :label="$t('userMsgs.firstName')"
            label-for="firstname"
            label-class="form-label"
            class="col-md-6"
          >
            <b-form-input id="firstname" :placeholder="$t('userMsgs.firstName')" v-model="user.firstName" required />
          </b-form-group>

          <b-form-group :label="$t('userMsgs.lastName')" label-for="lastname" label-class="form-label" class="col-md-6">
            <b-form-input id="lastname" :placeholder="$t('userMsgs.lastName')" v-model="user.lastName" required />
          </b-form-group>
        </div>

        <b-form-group :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label">
          <b-form-input
            id="email"
            :placeholder="$t('authMsgs.emailAddr')"
            v-model="user.email"
            type="email"
            autocomplete="off"
            required
            :state="errorStates.userEmail"
          />
          <b-form-invalid-feedback>{{ $t('userMsgs.userAlreadyExists') }}</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('authMsgs.password')"
          label-for="password"
          label-class="form-label"
          v-if="!$route.params.id"
        >
          <b-alert variant="soft-primary px-12pt" show>
            <div class="d-flex align-items-center justify-content-between">
              <span class="text-black-100"
                >Auto-generated password: <strong>{{ user.password }}</strong></span
              >

              <span v-if="isPassCopied" class="text-success"><md-icon>check</md-icon> Copied</span>
              <a href="#" @click.prevent="copyPass" v-else v-b-popover.hover.top="'Copy Password'">
                <md-icon>content_copy</md-icon>
              </a>
            </div>
          </b-alert>
        </b-form-group>

        <div class="row" v-else>
          <b-form-group :label="$t('authMsgs.password')" label-for="npass" label-class="form-label" class="col-md-6">
            <b-form-input
              id="npass"
              name="password"
              :placeholder="$t('authMsgs.password')"
              v-model="user.password"
              :required="!!user.repeatPassword"
              :type="fieldTypes.password"
              autocomplete="off"
              @focus="handlePassType"
              @blur="handlePassType"
              :state="errorStates.password"
            />
            <b-form-invalid-feedback>{{ $t(`passValidationMsgs.${passErrorCode}`) }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('profileMsgs.repeatPassword')"
            label-for="repeatPassword"
            label-class="form-label"
            class="col-md-6"
          >
            <b-form-input
              id="repeatPassword"
              name="repeatPassword"
              :placeholder="$t('profileMsgs.repeatPassword')"
              v-model="user.repeatPassword"
              :required="!!user.password"
              :type="fieldTypes.repeatPassword"
              autocomplete="off"
              @focus="handlePassType"
              @blur="handlePassType"
              :state="errorStates.password"
            />
          </b-form-group>
        </div>
      </div>
      <!-- <b-form-group :label="$t('employerMsgs.phone')" label-for="phone" label-class="form-label">
        <b-form-input
          id="phone"
          placeholder="(888) 689 - 1235"
          v-model="user.phone"
          v-mask="'(###) ### - ####'"
          :state="errorStates.phone"
        />
        <b-form-invalid-feedback>
          Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
        </b-form-invalid-feedback>
      </b-form-group> -->

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import { countries } from 'countries-list';
import { get, map, pick } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { FmvAvatar } from 'fmv-avatar';
import generator from 'generate-password';
import Editor from '@tinymce/tinymce-vue';
import Page from '../../../components/Page.vue';

import {
  PASSWORD_VALIDATIONS,
  PASSWORD_ALL_NUMERIC_REGEX,
  PHONE_FORMAT_REGEX,
  PROFILE_VISIBILITY_OPTIONS,
  USER_ROLES
} from '../../../common/constants';
import UserAvatar from '../../../components/User/UserAvatar.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';

var UsaStates = require('usa-states').UsaStates;
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: { PageHeader, PageSeparator, FmvAvatar, UserAvatar, MdIcon, Editor, ImageUploader },
  extends: Page,

  data() {
    return {
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      title: this.$route.params.id ? this.$t('employerMsgs.editEmployer') : this.$t('employerMsgs.addEmployer'),
      PROFILE_VISIBILITY_OPTIONS,
      USER_ROLES,

      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        repeatPassword: '',
        phone: '',
        avatarUrl: ''
      },
      employer: {
        name: '',
        logo_url: '',
        small_logo_url: null,
        profile_bg_img_url: '',
        phone: '',
        website_url: '',
        company_size: '',
        company_type: '',
        offered_careers: [],
        short_bio: '',
        bio: '',
        primary_color: '#00b3e6',
        secondary_color: '#868e96',
        address: '',
        zipcode: '',
        city: '',
        state: '',
        country: 'United States',
        available_tokens: 0,
        available_job_credits: 0,
        is_profile_visible: true
      },
      referral_school: '',
      organization: null,

      fieldTypes: { password: 'text', repeatPassword: 'text' },

      allOrganizations: [],

      countryOptions: [],
      stateOptions: [],

      companySizeOptions: [
        { value: '0-1', text: '0-1 employees' },
        { value: '2-10', text: '2-10 employees' },
        { value: '11-50', text: '11-50 employees' },
        { value: '51-200', text: '51-200 employees' },
        { value: '201-500', text: '201-500 employees' },
        { value: '501-1000', text: '501-1,000 employees' },
        { value: '1001-5000', text: '1,001-5,000 employees' },
        { value: '5001-10000', text: '5,001-10,000 employees' },
        { value: '10001+', text: '10,001+ employees' }
      ],
      companyTypeOptions: [
        { value: 'ambulatory_surgical_center', text: 'Ambulatory Surgical Center' },
        { value: 'animal_clinic', text: 'Animal Clinic' },
        { value: 'assisted_living_facility', text: 'Assisted Living Facility' },
        { value: 'biotech_company', text: 'Biotech Company' },
        { value: 'blood_bank', text: 'Blood Bank' },
        { value: 'chiropractic_clinic', text: 'Chiropractic Clinic' },
        { value: 'counseling_service', text: 'Counseling Service' },
        { value: 'crime_scene_investigation_unit', text: 'Crime Scene Investigation Unit' },
        { value: 'dental_practice', text: 'Dental Practice' },
        { value: 'diagnostic_lab', text: 'Diagnostic Lab' },
        { value: 'dietetics_practice', text: 'Dietetics Practice' },
        { value: 'dna_testing_facility', text: 'DNA Testing Facility' },
        { value: 'drug_distribution_company', text: 'Drug Distribution Company' },
        { value: 'emergency_animal_hospital', text: 'Emergency Animal Hospital' },
        { value: 'family_health_clinic', text: 'Family Health Clinic' },
        { value: 'forensic_consultancy', text: 'Forensic Consultancy' },
        { value: 'forensic_laboratory', text: 'Forensic Laboratory' },
        { value: 'general_dentistry_practice', text: 'General Dentistry Practice' },
        { value: 'general_hospital', text: 'General Hospital' },
        { value: 'gym_with_physiotherapy', text: 'Gym with Physiotherapy' },
        { value: 'health_and_wellness_clinic', text: 'Health and Wellness Clinic' },
        { value: 'healthcare_it_company', text: 'Healthcare IT Company' },
        { value: 'health_insurance_company', text: 'Health Insurance Company' },
        { value: 'holistic_wellness_center', text: 'Holistic Wellness Center' },
        { value: 'home_health_agency', text: 'Home Health Agency' },
        { value: 'hospice_care_provider', text: 'Hospice Care Provider' },
        { value: 'imaging_center', text: 'Imaging Center' },
        { value: 'long_term_acute_care_hospital', text: 'Long-Term Acute Care Hospital' },
        { value: 'meal_planning_service', text: 'Meal Planning Service' },
        { value: 'medical_billing_company', text: 'Medical Billing Company' },
        { value: 'medical_research_lab', text: 'Medical Research Lab' },
        { value: 'medical_supply_company', text: 'Medical Supply Company' },
        { value: 'medical_transportation', text: 'Medical Transportation' },
        { value: 'memory_care_facility', text: 'Memory Care Facility' },
        { value: 'mental_health_clinic', text: 'Mental Health Clinic' },
        { value: 'mobile_imaging_service', text: 'Mobile Imaging Service' },
        { value: 'multidisciplinary_clinic', text: 'Multidisciplinary Clinic including Chiropractic Care' },
        { value: 'nutrition_consulting_firm', text: 'Nutrition Consulting Firm' },
        { value: 'optometric_clinic', text: 'Optometric Clinic' },
        { value: 'oral_surgery_center', text: 'Oral Surgery Center' },
        { value: 'orthodontic_practice', text: 'Orthodontic Practice' },
        { value: 'outpatient_clinic', text: 'Outpatient Clinic' },
        { value: 'pathology_lab', text: 'Pathology Lab' },
        { value: 'pediatric_dentistry_practice', text: 'Pediatric Dentistry Practice' },
        { value: 'periodontic_practice', text: 'Periodontic Practice' },
        { value: 'pet_boarding_facility', text: 'Pet Boarding Facility' },
        { value: 'pharmacy', text: 'Pharmacy' },
        { value: 'pharmaceutical_manufacturer', text: 'Pharmaceutical Manufacturer' },
        { value: 'pharmaceutical_research_company', text: 'Pharmaceutical Research Company' },
        { value: 'physical_therapy_center', text: 'Physical Therapy Center' },
        { value: 'psychiatric_hospital', text: 'Psychiatric Hospital' },
        { value: 'radiology_department', text: 'Radiology Department in a Hospital' },
        { value: 'rehabilitation_center', text: 'Rehabilitation Center' },
        { value: 'respite_care', text: 'Respite Care' },
        { value: 'skilled_nursing_facility', text: 'Skilled Nursing Facility' },
        { value: 'specialty_hospital', text: 'Specialty Hospital' },
        { value: 'substance_abuse_treatment_center', text: 'Substance Abuse Treatment Center' },
        { value: 'teleradiology_company', text: 'Teleradiology Company' },
        { value: 'urgent_care_center', text: 'Urgent Care Center' },
        { value: 'veterinary_hospital', text: 'Veterinary Hospital' },
        { value: 'veterinary_laboratory', text: 'Veterinary Laboratory' },
        { value: 'vision_care_center', text: 'Vision Care Center' },
        { value: 'weight_loss_center', text: 'Weight Loss Center' },
        { value: 'wellness_center_with_chiropractic_services', text: 'Wellness Center with Chiropractic Services' },
        { value: 'other', text: 'Other' }
      ],
      careerPreferences: [],

      errorStates: {
        password: null,
        userEmail: null,
        phone: null
      },
      passErrorCode: null,

      isPassCopied: false,
      isLoading: false,
      areCareerPrefsLoading: false,
      areOrgsLoading: false,

      isUploading: { avatar: false, logo: false, profileBgImage: false, smallLogo: false },
      selectedUploadFile: { avatar: null, logo: null, profileBgImage: null, smallLogo: null },
      uploadPercent: { avatar: 0, logo: 0, profileBgImage: 0, smallLogo: 0 },
      uploadCancelTokenSource: { avatar: null, logo: null, profileBgImage: null, smallLogo: null },
      systemConfigs: []
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        {
          text: this.$t('employerMsgs.employers'),
          to: {
            name: this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN ? 'manage-employers' : 'manage-org-employers'
          }
        },
        {
          text: this.$route.params.id ? this.$t('employerMsgs.editEmployer') : this.$t('employerMsgs.addEmployer'),
          active: true
        }
      ];
    },
    isFormLoading() {
      return this.isLoading || Object.values(this.isUploading).some(val => val);
    }
  },

  methods: {
    ...mapActions('employer', ['getEmployer', 'createEmployer', 'updateEmployer', 'getEmployerUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('careerPreference', ['getAllCareerPreferences']),
    ...mapActions('organization', ['getAllOrganizations']),
    ...mapActions('credit', ['getAllSystemConfigs']),

    get,
    setImage(file, fileType, uploadType = 'images', profileUrlField = 'logo_url') {
      this.selectedUploadFile[fileType] = file;
      this.uploadFile(fileType, uploadType, profileUrlField);
    },

    async onSubmit() {
      this.isLoading = true;
      this.errorStates.password = this.errorStates.userEmail = this.errorStates.cnaLicenseNo = this.errorStates.phone = null;

      if (!PHONE_FORMAT_REGEX.test(this.employer.phone)) {
        this.errorStates.phone = false;
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
      } else if (this.user.password !== this.user.repeatPassword) {
        this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_NOT_MATCH;
        this.errorStates.password = false;
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
      } else if (this.user.password && this.user.password.length < 5) {
        this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_MIN_LENGTH;
        this.errorStates.password = false;
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
      } else if (this.user.password && PASSWORD_ALL_NUMERIC_REGEX.test(this.user.password)) {
        this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_ALL_NUMERIC;
        this.errorStates.password = false;
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
      } else {
        try {
          const data = {
            ...this.employer,

            ...(this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN && { organization: this.organization }),

            main_admin: {
              email: this.user.email,
              first_name: this.user.firstName,
              last_name: this.user.lastName,
              ...(this.user.password && { password: this.user.password }),
              phone: this.user.phone || this.employer.phone,
              avatar_url: this.user.avatarUrl
            }
          };
          if (this.$route.params.id) {
            await this.updateEmployer({
              id: this.$route.params.id,
              data
            });
            this.makeToast({ variant: 'success', msg: 'Employer Profile Updated!' });
          } else {
            await this.createEmployer(data);
            this.makeToast({ variant: 'success', msg: 'Employer Added!' });
          }
          setTimeout(
            () =>
              this.$router.push({
                name: this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN ? 'manage-employers' : 'manage-org-employers'
              }),
            250
          );
        } catch (err) {
          if (get(err, 'response.data.main_admin')) {
            this.errorStates.userEmail = false;
          }
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }

      this.isLoading = false;
    },

    async uploadFile(fileType, uploadType = 'images', profileUrlField = 'logo_url') {
      this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();
      this.isUploading[fileType] = true;

      try {
        const urlResp = await this.getEmployerUploadPresignedUrl({
          file_name: this.selectedUploadFile[fileType].name,
          content_type: this.selectedUploadFile[fileType].type,
          upload_type: uploadType
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile[fileType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[fileType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token
          }
        });
        if (fileType === 'avatar') {
          this.user.avatarUrl = urlResp.upload_url.split('?')[0];
        } else this.employer[profileUrlField] = urlResp.upload_url.split('?')[0];
      } catch (error) {
        this.selectedUploadFile[fileType] = null;
      }

      this.uploadCancelTokenSource[fileType] = null;
      this.uploadPercent[fileType] = 0;

      this.isUploading[fileType] = false;
    },

    handlePassType(event) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if (type === 'blur' && !value) {
        this.fieldTypes[name] = 'text';
      } else {
        this.fieldTypes[name] = 'password';
      }
    },

    async copyPass() {
      try {
        await navigator.clipboard.writeText(this.user.password);
        this.isPassCopied = true;
        setTimeout(() => (this.isPassCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },

    fillInOptions() {
      var usStates = new UsaStates();
      this.stateOptions = this.stateOptions.concat(
        map(usStates.states, state => ({
          value: state.name,
          text: state.name
        }))
      );
      this.countryOptions = this.countryOptions.concat(
        map(countries, country => ({
          value: country.name,
          text: country.name
        }))
      );
    },
    async fetchSystemConfigs() {
      const response = await this.getAllSystemConfigs({});

      this.systemConfigs = response.data;

      this.employer.available_job_credits = this.systemConfigs.filter(config => config.abbr === 'job_credits')[0].value;
      this.employer.available_tokens = this.systemConfigs.filter(config => config.abbr === 'tokens')[0].value;
    },
    async fetchCareerPreferences() {
      this.areCareerPrefsLoading = true;

      const response = await this.getAllCareerPreferences({});
      this.careerPreferences = response.data;

      this.areCareerPrefsLoading = false;
    },

    async fetchOrganizations() {
      this.areOrgsLoading = true;
      const response = await this.getAllOrganizations({});
      this.allOrganizations = response.data;

      this.areOrgsLoading = false;
    }
  },

  async mounted() {
    this.isLoading = true;
    this.fillInOptions();
    this.fetchCareerPreferences();
    this.fetchSystemConfigs();

    if (this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN) {
      this.fetchOrganizations();
    }

    // Auto-generate password.
    if (!this.$route.params.id) {
      this.user.password = this.user.repeatPassword = generator.generate({ length: 10, numbers: true, strict: true });
    }

    try {
      if (this.$route.params.id) {
        const resp = (await this.getEmployer(this.$route.params.id)).data;
        this.employer = {
          ...pick(resp, [
            'name',
            'logo_url',
            'small_logo_url',
            'profile_bg_img_url',
            'phone',
            'address',
            'zipcode',
            'city',
            'state',
            'country',
            'website_url',
            'company_size',
            'company_type',
            'short_bio',
            'bio',
            'primary_color',
            'secondary_color',
            'available_tokens',
            'available_job_credits',
            'is_profile_visible'
          ]),
          offered_careers: map(resp.offered_careers, 'id')
        };
        this.referral_school = resp.referral_school;
        this.user.firstName = resp.main_admin.first_name;
        this.user.lastName = resp.main_admin.last_name;
        this.user.email = resp.main_admin.email;
        this.user.phone = resp.main_admin.phone;
        this.user.avatarUrl = resp.main_admin.avatar_url;
        this.organization = get(resp.organization, 'id') || null;
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({
        name: this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN ? 'manage-employers' : 'manage-org-employers'
      });
    }

    this.isLoading = false;
  }
};
</script>
