<template>
  <div :class="containerClass">
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="null" class="mb-32pt" />

    <b-form-group label="Image" label-for="image" label-class="form-label" class="row-align-items-center">
      <b-media class="align-items-center" vertical-align="center">
        <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
          <span v-if="isImageLoading">...</span>
          <b-img :src="image" class="img-fluid" width="40" alt="Logo" v-else-if="image" />
          <i class="fas fa-university fa-lg" v-else></i>
        </fmv-avatar>

        <b-form-file
          id="avatar"
          placeholder="Selet GPT's Image"
          :browse-text="$t('generalMsgs.browse')"
          v-model="selectedUploadFile"
          @input="uploadFile('avatar', 'images')"
          :disabled="isImageLoading"
          accept="image/*"
        />
      </b-media>
    </b-form-group>

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="University" label-for="university" label-class="form-label">
        <v-select
          id="university"
          class="form-control v-select-custom"
          label="name"
          v-model="university"
          :reduce="item => item.id"
          placeholder="Select University"
          :options="allUniversities"
          :loading="areUnisLoading"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img
                  :src="option.campus_img_url"
                  class="img-fluid"
                  width="20"
                  alt="Logo"
                  v-if="option.campus_img_url"
                />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
      </b-form-group>
      <!-- Name -->
      <b-form-group label="Name" label-for="name" label-class="form-label">
        <b-form-input id="name" placeholder="Enter GPT's Name" v-model="name" required />
      </b-form-group>
      <b-form-group label="OpenAI GPT URL" label-for="openai_gpt_url" label-class="form-label">
        <b-form-input id="openai_gpt_url" placeholder="Enter OpenAI Url" v-model="openai_gpt_url" required />
      </b-form-group>

      <b-form-group label="Overview" label-for="overview" label-class="form-label">
        <b-form-textarea id="overview" placeholder="Enter Overview." :rows="5" v-model="overview" />
      </b-form-group>
      <b-form-group label="Description" label-for="description" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
          }"
          v-model="description"
          placeholder="Enter Description"
        />
      </b-form-group>
      <b-form-group label="Categories" label-for="category" label-class="form-label">
        <div class="custom-select-icon">
          <b-form-select
            class="custom-select-icon__select"
            style="min-width: 185px"
            v-model="sub_category"
            :options="allCategories"
          ></b-form-select>
          <span class="material-icons custom-select-icon__icon">sort</span>
        </div>
      </b-form-group>
      <b-form-group label="Majors" label-for="major" label-class="form-label">
        <v-select
          id="major"
          class="form-control v-select-custom"
          label="text"
          v-model="majors"
          multiple
          :reduce="item => item.value"
          placeholder="All Majors"
          :options="allUniMajors"
        >
        </v-select>
      </b-form-group>

      <b-form-group label="HF Stage" label-for="hf_stage" label-class="form-label">
        <v-select
          id="hf_stage"
          class="form-control v-select-custom"
          v-model="hfStage"
          :reduce="exp => exp"
          placeholder="Select HF Stage"
          :options="hfStageOptions"
        >
        </v-select>
      </b-form-group>

      <b-form-group label="HF Programs" label-for="hf_programs" label-class="form-label">
        <v-select
          id="hf_programs"
          class="form-control v-select-custom"
          v-model="hfPrograms"
          :reduce="exp => exp"
          multiple
          placeholder="Select HF Programs"
          :options="hfProgramsOptions"
        >
        </v-select>
      </b-form-group>

      <b-form-group label="HF Categories" label-for="hf_categories" label-class="form-label">
        <v-select
          id="hf_categories"
          class="form-control v-select-custom"
          v-model="hfCategories"
          :reduce="exp => exp"
          placeholder="Select HF Categories"
          :options="hfCategoriesOptions"
        >
        </v-select>
      </b-form-group>

      <b-form-group label-class="form-label">
        <b-form-checkbox v-model="is_active" name="check-button" switch> Active? </b-form-checkbox>
      </b-form-group>
      <page-separator title="Creator's Info" />
      <div class="row">
        <b-form-group :label="$t('userMsgs.firstName')" label-for="firstname" label-class="form-label" class="col-md-6">
          <b-form-input id="firstname" :placeholder="$t('userMsgs.firstName')" v-model="first_name" />
        </b-form-group>

        <b-form-group :label="$t('userMsgs.lastName')" label-for="lastname" label-class="form-label" class="col-md-6">
          <b-form-input id="lastname" :placeholder="$t('userMsgs.lastName')" v-model="last_name" required />
        </b-form-group>
      </div>

      <b-form-group :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label">
        <b-form-input
          id="email"
          :placeholder="$t('authMsgs.emailAddr')"
          v-model="email"
          type="email"
          autocomplete="off"
          required
        />
      </b-form-group>
      <b-form-group label="Submitter" label-for="submitter" label-class="form-label">
        <v-select
          id="submitter"
          class="form-control v-select-custom"
          label="text"
          v-model="submitter"
          :reduce="item => item.value"
          placeholder="Select Submitter"
          :options="statusTypes"
        >
        </v-select>
      </b-form-group>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Editor from '@tinymce/tinymce-vue';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Page from '../../../components/Page.vue';
import { FmvAvatar } from 'fmv-avatar';
import { USER_ROLES } from '../../../common/constants';

export default {
  name: 'PostOrganization',
  components: {
    PageHeader,
    Editor,
    FmvAvatar
  },
  extends: Page,

  data() {
    return {
      USER_ROLES,
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,

      title: this.$route.params.id ? 'Edit GPT' : 'Add GPT',

      name: '',
      openai_gpt_url: '',
      overview: '',
      description: '',
      first_name: '',
      last_name: '',
      email: '',
      is_active: true,
      submitter: '',
      category: '',
      majors: [],
      university: '',
      allUniversities: [],
      image: '',
      sub_category: '',
      isImageLoading: false,
      selectedUploadFile: null,
      uploadPercent: 0,
      uploadCancelTokenSource: null,
      hfStage: '',
      hfPrograms: '',
      hfCategories: '',
      hfStageOptions: ['Pre-Health', 'Healthcare', 'Both'],
      hfProgramsOptions: [
        'General',
        'Nursing',
        'Medicine',
        'Pharmacy',
        'Dentistry',
        'Optometry',
        'Veterinary Medicine',
        'Physical Therapy',
        'Occupational Therapy',
        'Chiropractic',
        'Public Health',
        'Health Sciences',
        'Nutrition and Dietetics',
        'Exercise Science / Kinesiology',
        'Psychology',
        'Social Work',
        'Health Administration',
        'Biomedical Sciences',
        'Health Informatics',
        'Radiologic Technology',
        'Medical Laboratory Science',
        'Respiratory Therapy',
        'Surgical Technology',
        'Emergency Medical Services (EMS)',
        'Nuclear Medicine Technology',
        'Speech-Language Pathology',
        'Audiology',
        'Genetic Counseling',
        'Rehabilitation Sciences',
        'Clinical Laboratory Science',
        'Healthcare Management'
      ],
      hfCategoriesOptions: [
        'General',

        'Academic Support',

        'Application Assistance',

        'Financial Planning',

        'Career Planning',

        'Professional Development'
      ],
      allUniMajors: [
        { value: 'General', text: 'General' },
        { value: 'Agriculture and Environmental Science', text: 'Agriculture and Environmental Science' },
        { value: 'Architecture and Urban Planning', text: 'Architecture and Urban Planning' },
        { value: 'Arts and Humanities', text: 'Arts and Humanities' },
        { value: 'Business and Economics', text: 'Business and Economics' },
        { value: 'Engineering and Technology', text: 'Engineering and Technology' },
        { value: 'Health Sciences', text: 'Health Sciences' },
        { value: 'Social Sciences and Education', text: 'Social Sciences and Education' },
        { value: 'Communication and Media Studies', text: 'Communication and Media Studies' },
        { value: 'Vocational and Technical Training', text: 'Vocational and Technical Training' },
        { value: 'Hospitality and Culinary Arts', text: 'Hospitality and Culinary Arts' },
        { value: 'Law and Public Policy', text: 'Law and Public Policy' },
        { value: 'Interdisciplinary Studies', text: 'Interdisciplinary Studies' }
      ],
      categoryObj: {
        'Study Aids': 'Academic Assistance',
        'Writing Tools': 'Academic Assistance',
        'Research Support': 'Academic Assistance',
        'Scheduling Tools': 'Course Management',
        'Coursework Helpers': 'Course Management',
        'Learning Resources': 'Course Management',
        'Housing Assistance': 'Campus Life',
        'Community Activities': 'Campus Life',
        'Health and Wellness Resources': 'Campus Life',
        'Internship Search': 'Career Development',
        'Professional Networking': 'Career Development',
        'Resume Building': 'Career Development',
        'Budgeting Tools': 'Financial Planning',
        'Scholarship Finders': 'Financial Planning',
        'Job Listings': 'Financial Planning',
        'Social Networks': 'Social and Recreation',
        'Sports and Fitness': 'Social and Recreation',
        Entertainment: 'Social and Recreation',
        'Skills Workshops': 'Personal Growth and Hobbies',
        'Creative Tools': 'Personal Growth and Hobbies',
        'Mindfulness Apps': 'Personal Growth and Hobbies',
        Maps: 'Campus Navigation',
        'Transportation Info': 'Campus Navigation',
        'Local Guides': 'Campus Navigation',
        'Meal Planning': 'Daily Essentials',
        'Laundry Management': 'Daily Essentials',
        'Errand Helpers': 'Daily Essentials',
        'Enrollment Support': 'Administrative Tasks',
        'Document Assistance': 'Administrative Tasks',
        'Communication Tools': 'Administrative Tasks'
      },
      allCategories: [
        { value: '', text: 'Please Select', disabled: true },
        { value: 'General', text: 'General' },
        {
          label: 'Academic Assistance',
          options: [
            { value: 'Study Aids', text: 'Study Aids' },
            { value: 'Writing Tools', text: 'Writing Tools' },
            { value: 'Research Support', text: 'Research Support' }
          ]
        },

        {
          label: 'Course Management',
          options: [
            { value: 'Scheduling Tools', text: 'Scheduling Tools' },
            { value: 'Coursework Helpers', text: 'Coursework Helpers' },
            { value: 'Learning Resources', text: 'Learning Resources' }
          ]
        },
        {
          label: 'Personal Growth and Hobbies',
          options: [
            { value: 'Skills Workshops', text: 'Skills Workshops' },
            { value: 'Creative Tools', text: 'Creative Tools' },
            { value: 'Mindfulness Apps', text: 'Mindfulness Apps' }
          ]
        },
        {
          label: 'Career Development',
          options: [
            { value: 'Internship Search', text: 'Internship Search' },
            { value: 'Professional Networking', text: 'Professional Networking' },
            { value: 'Resume Building', text: 'Resume Building' }
          ]
        },

        {
          label: 'Financial Planning',
          options: [
            { value: 'Budgeting Tools', text: 'Budgeting Tools' },
            { value: 'Scholarship Finders', text: 'Scholarship Finders' },
            { value: 'Job Listings', text: 'Job Listings' }
          ]
        },
        {
          label: 'Social and Recreation',
          options: [
            { value: 'Social Networks', text: 'Social Networks' },
            { value: 'Sports and Fitness', text: 'Sports and Fitness' },
            { value: 'Entertainment', text: 'Entertainment' }
          ]
        },
        {
          label: 'Campus Navigation',
          options: [
            { value: 'Maps', text: 'Maps' },
            { value: 'Transportation Info', text: 'Transportation Info' },
            { value: 'Local Guides', text: 'Local Guides' }
          ]
        },
        {
          label: `Administrative Tasks`,
          options: [
            { value: 'Enrollment Support', text: 'Enrollment Support' },
            { value: 'Document Assistance', text: 'Document Assistance' },
            { value: 'Communication Tools', text: 'Enrollment Support' }
          ]
        },
        {
          label: `Campus Life`,
          options: [
            { value: 'Housing Assistance', text: 'Housing Assistance' },
            { value: 'Community Activities', text: 'Community Activities' },
            { value: 'Health and Wellness Resources', text: 'Health and Wellness Resources' }
          ]
        },
        {
          label: `Daily Essentials`,
          options: [
            { value: 'Meal Planning', text: 'Meal Planning' },
            { value: 'Laundry Management', text: 'Laundry Management' },
            { value: 'Errand Helpers', text: 'Errand Helpers' }
          ]
        }
      ],
      statusTypes: [
        { value: 'student', text: 'Student' },
        { value: 'staff', text: 'Staff' },
        { value: 'business', text: 'Business' }
      ],
      areUnisLoading: false,
      isLoading: false
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'GPTs', to: { name: 'manage-gpts' } },
        {
          text: this.$route.params.id ? 'Edit GPT' : 'Add GPT',
          active: true
        }
      ];
    },
    isFormLoading() {
      return this.isLoading || this.isLogoUploading;
    }
  },

  methods: {
    ...mapActions('gpts', ['createGpt', 'getGpt', 'updateGpt', 'getGptUploadPresignedUrl']),
    ...mapActions('uni', ['getAllUnis']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),

    async uploadFile(fileType, uploadType = 'images') {
      this.uploadCancelTokenSource = axios.CancelToken.source();
      this.isImageLoading = true;

      try {
        const urlResp = await this.getGptUploadPresignedUrl({
          file_name: this.selectedUploadFile.name,
          content_type: this.selectedUploadFile.type,
          upload_type: uploadType
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile,
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource.token
          }
        });

        this.image = urlResp.upload_url.split('?')[0];
      } catch (error) {
        this.selectedUploadFile = null;
      }

      this.uploadCancelTokenSource = null;
      this.uploadPercent = 0;

      this.isImageLoading = false;
    },

    async fetchUnis() {
      this.areUnisLoading = true;

      const response = await this.getAllUnis({});
      this.allUniversities = response.data;

      this.areUnisLoading = false;
    },
    get,

    async onSubmit() {
      this.isLoading = true;

      try {
        const data = {
          name: this.name,
          openai_gpt_url: this.openai_gpt_url,
          overview: this.overview,
          description: this.description,
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          submitter: this.submitter,
          category: this.categoryObj[this.sub_category],
          sub_category: this.sub_category,
          majors: this.majors,
          university: this.university,
          is_active: this.is_active,
          image: this.image,
          hf_categories: this.hfCategories,
          hf_stage: this.hfStage,
          hf_programs: this.hfPrograms
        };

        if (this.$route.params.id) {
          await this.updateGpt({
            id: this.$route.params.id,
            data
          });
          this.makeToast({ variant: 'success', msg: 'GPT Updated Successfully!' });
        } else {
          await this.createGpt(data);
          this.makeToast({ variant: 'success', msg: 'GPT Added Successfully!' });
        }
        setTimeout(() => this.$router.push({ name: 'manage-gpts' }), 250);
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },
    async copyPass() {
      try {
        await navigator.clipboard.writeText(this.user.password);
        this.isPassCopied = true;
        setTimeout(() => (this.isPassCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    }
  },

  async mounted() {
    this.isLoading = true;
    this.fetchUnis();

    // Auto-generate password.

    try {
      if (this.$route.params.id) {
        const resp = (await this.getGpt(this.$route.params.id)).data;
        this.name = resp.name;

        this.openai_gpt_url = resp.openai_gpt_url;
        this.overview = resp.overview;
        this.description = resp.description;
        this.first_name = resp.first_name;
        this.last_name = resp.last_name;
        this.email = resp.email;
        this.submitter = resp.submitter;
        this.category = resp.category;
        this.majors = resp.majors;
        this.university = resp.university;
        this.is_active = resp.is_active;
        this.image = resp.image;
        this.sub_category = resp.sub_category;
        this.hfCategories = resp.hf_categories;
        this.hfStage = resp.hf_stage;
        this.hfPrograms = resp.hf_programs;
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({ name: 'manage-organizations' });
    }

    this.isLoading = false;
  }
};
</script>
