var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-header',{attrs:{"title":_vm.title,"container-class":_vm.containerClass,"info-text":"Manage all advisors associated with your organization."}}),_c('div',{staticClass:"page-section"},[_c('div',{class:_vm.containerClass},[_c('div',{staticClass:"card bg-primary p-2",staticStyle:{"color":"white"}},[_vm._m(0),_c('div',{staticClass:"p-2 mb-4"},[_vm._v(" "+_vm._s(_vm.inviteInfoText)+" ")]),_c('b-alert',{attrs:{"variant":"soft-light px-12pt","show":""}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('span',{staticClass:"text-black-100"},[_c('strong',[_vm._v(_vm._s(_vm.inviteURL))])]),(_vm.isUrlCopied)?_c('span',{staticClass:"text-success"},[_c('md-icon',[_vm._v("check")]),_vm._v(" Copied")],1):_c('a',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Copy Invite Link'),expression:"'Copy Invite Link'",modifiers:{"hover":true,"top":true}}],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.copyInviteUrl.apply(null, arguments)}}},[_c('md-icon',[_vm._v("content_copy")])],1)])])],1),_c('div',{staticClass:"card mb-0"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center",staticStyle:{"white-space":"nowrap"}},[_c('div',{staticClass:"col-lg-auto"},[_c('form',{staticClass:"search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-input',{staticClass:"w-lg-auto",attrs:{"placeholder":"Search advisor"},on:{"input":_vm.onSearch},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('b-btn',{attrs:{"variant":"flush","type":"submit"}},[_c('md-icon',[_vm._v("search")])],1)],1)]),_c('div',{staticClass:"col-lg d-flex flex-wrap justify-content-end"},[_c('div',{staticClass:"col-lg-6 mx-0 px-0"}),_c('b-btn',{staticClass:"ml-2",attrs:{"to":{
                  name:
                    _vm.get(_vm.getLoggedInUser, 'role') === _vm.USER_ROLES.SUPER_ADMIN ? 'sa-add-advisor' : 'add-org-advisor',
                },"exact":"","variant":"primary"}},[_vm._v("Add Advisor")])],1)])]),_c('b-table',{staticClass:"table-nowrap sticky-lcol-table",attrs:{"fields":_vm.tableColumns,"items":_vm.allAdvisors,"busy":_vm.isLoading,"head-variant":"light","hover":"","responsive":"","no-local-sorting":""},on:{"sort-changed":_vm.onSortChange,"row-clicked":_vm.editAdvisor},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v("No advisors found.")])]},proxy:true},{key:"cell(logo)",fn:function(data){return [_c('user-avatar',{attrs:{"slot":"aside","size":"md","user":data.item,"url-field":"image"},slot:"aside"})]}},{key:"cell(first_name)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.first_name)+" "+_vm._s(data.item.last_name))]}},{key:"cell(phone)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.phone))]}},{key:"cell(email)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.email))]}},{key:"cell(status)",fn:function(data){return [_c('b-badge',{class:'border border-' + (data.item.status === 'active' ? "success text-success" : "danger text-danger"),attrs:{"variant":"none"}},[_vm._v(_vm._s(data.item.status === 'active' ? 'Active' : 'Inactive'))])]}},{key:"head(actions)",fn:function(){return [_c('span')]},proxy:true},{key:"cell(actions)",fn:function(data){return [_c('router-link',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.right",modifiers:{"hover":true,"right":true}}],staticClass:"svg-icon mr-2",attrs:{"to":{
                name:
                  _vm.get(_vm.getLoggedInUser, 'role') === _vm.USER_ROLES.SUPER_ADMIN ? 'sa-edit-advisor' : 'edit-org-advisor',
                params: { id: data.item.id },
              },"title":'Edit'}},[_c('i',{staticClass:"mr-1 material-icons"},[_vm._v("edit")])]),_c('a',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.right",modifiers:{"hover":true,"right":true}}],attrs:{"href":"#","title":'Remove'},on:{"click":function($event){$event.preventDefault();return _vm.removeAdvisor(data.item.id)}}},[_c('i',{staticClass:"material-icons text-danger"},[_vm._v("delete")])])]}}])}),_c('div',{staticClass:"card-footer"},[_c('pagination',{attrs:{"total-rows":_vm.totalAdvisors,"per-page":_vm.perPage},on:{"change":_vm.onPageChange},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-center align-items-center p-2",staticStyle:{"font-size":"16px"}},[_c('strong',[_vm._v(" Invite Advisors ")])])}]

export { render, staticRenderFns }