<template>
  <div :class="containerClass">
    <page-header
      title="Configure Home Page Settings"
      :container-class="null"
      class="mb-16pt"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION
          ? `Configure homepage of your site by customizing banners and sections.`
          : ''
      "
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group
        label="Organization"
        label-for="org"
        label-class="form-label"
        v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
      >
        <v-select
          id="org"
          class="form-control v-select-custom"
          label="name"
          v-model="org"
          :reduce="org => org.id"
          placeholder="Select Organization"
          :options="allOrgs"
          :loading="areOrgsLoading"
          @input="prefillLogo(org)"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!org" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.org.required && $v.org.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <!-- Top Banner -->

      <page-separator>
        <b-link v-b-toggle="'top-banner-section'" class="accordion__toggle p-0">
          <span class="flex">Banner</span>
          <md-icon class="accordion__toggle-icon">
            {{ topBannerVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        id="top-banner-section"
        accordion="topBannerAccordionId"
        :visible="topBannerVisible"
        class="accordion__menu"
        @hide="topBannerVisible = false"
        @show="topBannerVisible = true"
      >
        <b-form-group label="Layout" label-for="prog_layout" label-class="form-label">
          <div class="row" style="margin-left: -22px">
            <div class="col-md-4" v-for="layout in homeLayoutOptions" :key="layout.image">
              <div class="layout-hover column text-center clickable-item">
                <figure>
                  <img
                    width="100%"
                    height="125px"
                    :src="getImgUrl(layout.image)"
                    @click.prevent="() => (homeLayout.hero.name = layout.value)"
                    :alt="heroLayout"
                    :class="heroLayout === layout.value ? 'border-outline-primary' : ''"
                    :style="
                      heroLayout === layout.value
                        ? 'object-fit: cover;'
                        : 'border: #f2f2f2 2px solid; border-radius: 10px; object-fit: cover;'
                    "
                  />
                </figure>
              </div>
            </div>
          </div>
        </b-form-group>

        <b-form-group
          label="Text Alignment"
          label-for="text_alignment"
          label-class="form-label"
          v-if="heroTextAlignBanners.includes(heroLayout)"
        >
          <div class="row" style="margin-left: -10px">
            <div
              @click.prevent="
                () => {
                  homeLayout.hero.textAlignment = option.value;
                }
              "
              v-for="option in textAlignmentOpts"
              :key="option.value"
              class="clickable-item py-2 mx-2"
            >
              <div
                class="card mb-0"
                :class="homeLayout.hero.textAlignment === option.value ? 'border-outline-primary-btn' : ''"
              >
                <md-icon
                  v-b-popover.hover.right
                  :title="option.text"
                  style="font-size: 30px"
                  class=""
                  :class="homeLayout.hero.textAlignment === option.value ? 'text-primary' : ''"
                  >{{ option.icon }}</md-icon
                >
              </div>
            </div>
          </div>
        </b-form-group>

        <b-form-group
          label="Text Color"
          label-for="hero_text_color"
          label-class="form-label"
          v-if="selectedOrganization"
        >
          <div class="d-flex">
            <div
              class="bg-color-circle mr-2 d-flex"
              :style="{ background: opt }"
              :class="homeLayout.hero.textColor === opt ? 'active' : ''"
              @click.prevent="homeLayout.hero.textColor = opt"
              v-for="opt in textColorOpts"
              :key="opt"
            >
              <md-icon v-if="homeLayout.hero.textColor === opt" class="text-secondary m-auto">check</md-icon>
            </div>
          </div>
        </b-form-group>

        <b-form-group
          label="Button Color"
          label-for="hero_btn_color"
          label-class="form-label"
          v-if="selectedOrganization"
        >
          <div class="d-flex align-items-center">
            <div
              class="bg-color-circle mr-2 d-flex"
              :style="{ background: opt }"
              :class="homeLayout.hero.btnColor === opt ? 'active' : ''"
              @click.prevent="homeLayout.hero.btnColor = opt"
              v-for="opt in btnColorOpts"
              :key="opt"
            >
              <md-icon v-if="homeLayout.hero.btnColor === opt" class="text-secondary m-auto">check</md-icon>
            </div>
            <div
              v-if="homeLayout.hero.btnColor"
              @click.prevent="homeLayout.hero.btnColor = ''"
              class="clickable-item"
              style="font-size: 12px;"
            >
              Reset
            </div>
          </div>
        </b-form-group>

        <b-form-group
          label="Background Color"
          label-for="hero_bg_color"
          label-class="form-label"
          v-if="selectedOrganization"
        >
          <div class="d-flex">
            <div
              class="bg-color-circle mr-2 d-flex"
              :style="{ background: backgroundColorMap[opt.value] }"
              :class="homeLayout.hero.backgroundColor === opt.value ? 'active' : ''"
              @click.prevent="homeLayout.hero.backgroundColor = opt.value"
              v-for="opt in heroBannerBgColorOpts"
              :key="opt.value"
            >
              <md-icon v-if="homeLayout.hero.backgroundColor === opt.value" class="text-secondary m-auto"
                >check</md-icon
              >
            </div>
          </div>
        </b-form-group>

        <b-form-group
          label="Banner Image"
          label-for="banner_img"
          label-class="form-label"
          class="row-align-items-center"
        >
          <b-media class="align-items-center" vertical-align="center">
            <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
              <span v-if="isImgUploading.banner_img">...</span>
              <b-img
                :src="homepageConfig.banner_img_url"
                class="img-fluid"
                width="40"
                alt="Image"
                v-else-if="homepageConfig.banner_img_url"
              />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>

            <image-uploader
              @image="e => setImage(e, 'banner_img')"
              :isUploading="isImgUploading.banner_img"
              :isRequired="false"
              placeholder="Select Banner Image"
            />
          </b-media>
        </b-form-group>

        <b-form-group label="Title" label-for="title" label-class="form-label">
          <b-form-input
            id="title"
            placeholder="Begin a Career That Cares Just As Much You"
            v-model="homepageConfig.title"
          />
        </b-form-group>

        <b-form-group label="Description" label-for="desc" label-class="form-label">
          <b-form-textarea
            id="desc"
            placeholder="A career as a Certified Nursing Assistant (CNA) can provide a life with purpose and an opportunity to build a solid career within the healthcare sector."
            rows="6"
            v-model="homepageConfig.description"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group
          label="Banner Video"
          label-for="banner_video"
          label-class="form-label"
          class="row-align-items-center"
          v-if="!homepageConfig.banner_video_url"
        >
          <b-progress :max="100" animated v-if="isImgUploading.banner_video">
            <b-progress-bar :value="uploadPercent.banner_video">
              <span>
                Uploading (<strong>{{ uploadPercent.banner_video }} %</strong>)
              </span>
            </b-progress-bar>
          </b-progress>
          <b-media v-else class="align-items-center" vertical-align="center">
            <b-form-file
              id="banner_video"
              placeholder="Upload a video to showcase on banner"
              :browse-text="$t('generalMsgs.browse')"
              v-model="uploadFile.banner_video"
              @input="uploadImage('banner_video')"
              :disabled="isImgUploading.banner_video"
              accept="video/mp4, video/webm"
            />
          </b-media>
        </b-form-group>

        <b-form-group
          v-if="homepageConfig.banner_video_url"
          label="Banner Video Preview"
          label-for="banner_video"
          label-class="form-label"
        >
          <div class="d-flex justify-content-end mb-3">
            <b-btn variant="danger" @click="() => (homepageConfig.banner_video_url = '')"> Remove </b-btn>
          </div>
          <video width="100%" height="200" controls>
            <source :src="homepageConfig.banner_video_url" type="video/mp4" />
          </video>
        </b-form-group>

        <b-form-group label="Banner Button Title" label-for="title" label-class="form-label">
          <b-form-input
            id="btn_title"
            placeholder="Enter a title banner button"
            v-model.trim="homepageConfig.btn_title"
          />
        </b-form-group>

        <b-form-group label="Banner Button Link" label-for="btn_link" label-class="form-label">
          <v-select
            id="org"
            class="form-control v-select-custom"
            label="text"
            v-model="selectedBannerLink"
            :reduce="page => page.name"
            placeholder="Select Page"
            :options="allPages"
            @input="handleBannerLinkSelection"
          >
            <!-- <template #search="{ attributes, events }">
            <input class="vs__search" :required="!org" v-bind="attributes" v-on="events" />
          </template> -->

            <!-- <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template> -->
          </v-select>

          <b-form-input
            v-if="selectedBannerLink === 'custom-link'"
            id="customLinkField"
            class="custom"
            v-model="customLinkField"
            placeholder="Add Custom Link"
            @input="updateBtnLink"
            required
          />
        </b-form-group>
      </b-collapse>

      <!-- Section Two -->

      <page-separator>
        <b-link v-b-toggle="'section-two'" class="accordion__toggle p-0">
          <span class="flex">Section Two</span>
          <md-icon class="accordion__toggle-icon">
            {{ sectionTwoVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>
      <b-form-group label-class="form-label">
        <b-form-checkbox id="sec_two" v-model="homepageConfig.visibleSections.section_two" switch>
          Enable this section
        </b-form-checkbox>
      </b-form-group>
      <b-collapse
        id="section-two"
        accordion="sectionTwoAccordionId"
        :visible="sectionTwoVisible"
        class="accordion__menu"
        @hide="sectionTwoVisible = false"
        @show="sectionTwoVisible = true"
      >
        <div>
          <b-form-group label="Section Two Title" label-for="sec_two_title" label-class="form-label">
            <b-form-input id="sec_two_title" placeholder="How It Works" v-model.trim="homepageConfig.sec_two_title" />
          </b-form-group>

          <div v-for="(sec, index) in $v.sectionTwoAttribs.$each.$iter" :key="index" class="mb-2">
            <div class="d-flex justify-content-end">
              <a class="btn btn-primary btn-md" href="#" @click.prevent="addAttrib(index)">
                <!-- <md-icon>add</md-icon> -->
                Add Attribute
              </a>
              <a
                href="#"
                class="btn btn-danger ml-2 btn-md"
                @click.prevent="removeAttrib(index)"
                v-if="sectionTwoAttribs.length > 1"
              >
                <!-- <md-icon>close</md-icon> -->
                Remove Attribute
              </a>
            </div>

            <div>
              <page-separator :title="`Attribute #${+index + 1}`" />

              <b-form-group
                label="Image"
                label-for="image_url"
                label-class="form-label"
                class="row-align-items-center col-md-12"
              >
                <b-media class="align-items-center" vertical-align="center">
                  <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
                    <span v-if="secTwoFile[index].isImgUploading">...</span>
                    <b-img :src="sec.img.$model" class="img-fluid" width="40" alt="Image" v-else-if="sec.img.$model" />
                    <i class="fas fa-university fa-lg" v-else></i>
                  </fmv-avatar>

                  <image-uploader
                    @image="e => setFeaturesImage(e, index)"
                    :isUploading="secTwoFile[index].isImgUploading"
                    :isRequired="false"
                    placeholder="Select Attribute Image"
                  />
                </b-media>
              </b-form-group>

              <b-form-group label="Title" label-for="title" label-class="form-label" class="col-md-12">
                <b-form-input
                  id="title"
                  placeholder="Attribute Title"
                  v-model.trim="sec.title.$model"
                  :state="!sec.title.required && sec.$dirty ? false : null"
                />

                <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group label="Description" label-for="description" label-class="form-label" class="col-md-12">
                <b-form-textarea
                  id="description"
                  :rows="5"
                  placeholder="Enter attribute description "
                  v-model.trim="sec.$model.desc"
                />
                <!-- <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback> -->
              </b-form-group>

              <b-form-group label="Button Text" label-for="btn_text" label-class="form-label">
                <b-form-input
                  id="btn_text"
                  placeholder="Enter a text for button"
                  v-model.trim="sec.$model.btn_text"
                  :required="get(sec.$model.btn_link, 'length') ? true : false"
                />
              </b-form-group>

              <b-form-group label="Button Link" label-for="btn_link" label-class="form-label">
                <b-form-input
                  id="btn_link"
                  placeholder="Enter link for button"
                  v-model.trim="sec.$model.btn_link"
                  :required="get(sec.$model.btn_text, 'length') ? true : false"
                />
              </b-form-group>
            </div>
          </div>
        </div>
      </b-collapse>

      <!-- Section Three -->

      <page-separator>
        <b-link v-b-toggle="'section-three'" class="accordion__toggle p-0">
          <span class="flex">Section Three</span>
          <md-icon class="accordion__toggle-icon">
            {{ secThreeVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>
      <b-form-group label-class="form-label">
        <b-form-checkbox id="section_three" v-model="homepageConfig.visibleSections.section_three" switch>
          Enable this section
        </b-form-checkbox>
      </b-form-group>

      <b-collapse
        id="section-three"
        accordion="sectionThreeAccordionId"
        :visible="secThreeVisible"
        class="accordion__menu"
        @hide="secThreeVisible = false"
        @show="secThreeVisible = true"
      >
        <b-form-group label="Section Three Title" label-for="sec_three_title" label-class="form-label">
          <b-form-input id="sec_three_title" placeholder="Our Mission" v-model="homepageConfig.sec_three_title" />
        </b-form-group>

        <b-form-group label="Section Three Description" label-for="sec_three_desc" label-class="form-label">
          <b-form-textarea
            id="sec_three_desc"
            rows="6"
            v-model="homepageConfig.sec_three_desc"
            placeholder="Our mission is to help inspiring Certified Nursing Assistants find great training programs and ensure that all students get the support they need to be successful in their career. We believe that everyone — regardless of their background — needs reliable information in order to understand their career options in healthcare."
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="Section Three Quote" label-for="sec_three_qoute" label-class="form-label">
          <b-form-input
            id="sec_three_qoute"
            placeholder="Enter a quote to show in the section"
            v-model="homepageConfig.sec_three_qoute"
          />
        </b-form-group>
        <b-form-group label="Section Three Author" label-for="sec_three_author" label-class="form-label">
          <b-form-input
            id="sec_three_author"
            placeholder="Enter author's quote"
            v-model="homepageConfig.sec_three_author"
          />
        </b-form-group>
        <label for="section-three-images" class="form-label"> Section Three Images</label>
        <b-form-group label="Image 1" label-for="image_1" label-class="form-label" class="row-align-items-center">
          <b-media class="align-items-center" vertical-align="center">
            <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
              <span v-if="isImgUploading.image_1">...</span>
              <b-img
                :src="homepageConfig.image_1"
                class="img-fluid"
                width="40"
                alt="Image"
                v-else-if="homepageConfig.image_1"
              />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>

            <image-uploader
              @image="e => setImage(e, 'image_1')"
              :isUploading="isImgUploading.image_1"
              :isRequired="false"
              placeholder="Select Image 1"
            />
          </b-media>
        </b-form-group>

        <b-form-group label="Image 2" label-for="image_2" label-class="form-label" class="row-align-items-center">
          <b-media class="align-items-center" vertical-align="center">
            <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
              <span v-if="isImgUploading.image_2">...</span>
              <b-img
                :src="homepageConfig.image_2"
                class="img-fluid"
                width="40"
                alt="Image"
                v-else-if="homepageConfig.image_2"
              />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>

            <image-uploader
              @image="e => setImage(e, 'image_2')"
              :isUploading="isImgUploading.image_2"
              :isRequired="false"
              placeholder="Select Image 2"
            />
          </b-media>
        </b-form-group>

        <b-form-group label="Image 3" label-for="image_3" label-class="form-label" class="row-align-items-center">
          <b-media class="align-items-center" vertical-align="center">
            <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
              <span v-if="isImgUploading.image_3">...</span>
              <b-img
                :src="homepageConfig.image_3"
                class="img-fluid"
                width="40"
                alt="Image"
                v-else-if="homepageConfig.image_3"
              />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>

            <image-uploader
              @image="e => setImage(e, 'image_3')"
              :isUploading="isImgUploading.image_3"
              :isRequired="false"
              placeholder="Select Image 3"
            />
          </b-media>
        </b-form-group>
      </b-collapse>

      <!-- Section Four -->

      <page-separator>
        <b-link v-b-toggle="'section-four'" class="accordion__toggle p-0">
          <span class="flex">Section Four</span>
          <md-icon class="accordion__toggle-icon">
            {{ secFourVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>
      <b-form-group label-class="form-label">
        <b-form-checkbox id="section-four" v-model="homepageConfig.visibleSections.section_four" switch>
          Enable this section
        </b-form-checkbox>
      </b-form-group>

      <b-collapse
        id="section-four"
        accordion="sectionFourAccordionId"
        :visible="secFourVisible"
        class="accordion__menu"
        @hide="secFourVisible = false"
        @show="secFourVisible = true"
      >
        <div v-if="homepageConfig.visibleSections.section_four">
          <b-form-group label="Layout" label-for="prog_layout" label-class="form-label">
            <div class="row" style="margin-left: -22px">
              <div class="col-md-4" v-for="layout in partnersLayoutOptions" :key="layout.image">
                <div class="layout-hover column text-center clickable-item">
                  <figure>
                    <img
                      width="100%"
                      height="125px"
                      :src="getImgUrl(layout.image)"
                      @click.prevent="() => (partnersLayout = layout.value)"
                      :alt="partnersLayout"
                      :class="partnersLayout === layout.value ? 'border-outline-primary' : ''"
                      :style="partnersLayout === layout.value ? '' : 'border: #f2f2f2 2px solid; border-radius: 10px;'"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </b-form-group>

          <b-form-group label="Title" label-for="bottom_title" label-class="form-label">
            <b-form-input
              id="bottom_title"
              placeholder="Enter Title"
              v-model="homepageConfig.sec_four_title"
              required
            />
          </b-form-group>

          <b-form-group label="Description" label-for="bottom_desc" label-class="form-label">
            <b-form-textarea
              id="bottom_desc"
              rows="6"
              v-model="homepageConfig.sec_four_desc"
              :required="partnersLayout === 'Layout1'"
            ></b-form-textarea>
          </b-form-group>

          <!-- <b-form-group label="CTA Title" label-for="title" label-class="form-label">
            <b-form-input id="cta_title" placeholder="Enter CTA Title" v-model="homepageConfig.ctaBtnTitle" required />
          </b-form-group> -->
          <b-form-group label-class="form-label">
            <b-form-checkbox v-model="homepageConfig.sectionFourCtaBtn" name="check-nav-button" switch
              >Show Partnership button?
            </b-form-checkbox>
          </b-form-group>
          <div v-if="homepageConfig.sectionFourCtaBtn">
            <b-form-group label="Title" label-for="nav-button-title" label-class="form-label">
              <b-form-input id="nav-button-title" placeholder="Enter a title" v-model="homepageConfig.ctaBtnTitle" />
            </b-form-group>

            <b-form-group label="Target Audience" label-for="nav-button" label-class="form-label">
              <div class="row">
                <b-form-group class="col-md-6" label-class="form-label">
                  <b-form-checkbox v-model="homepageConfig.isSchoolLink" name="check-nav-button" switch>
                    <span
                      v-b-popover.hover.right
                      :title="
                        `User will be redirected to '${transitionUrl}/school-interest?organization=${organization.slug}'`
                      "
                    >
                      Schools
                    </span>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group class="col-md-6" label-class="form-label">
                  <b-form-checkbox v-model="homepageConfig.isEmployerLink" name="check-nav-button" switch>
                    <span
                      v-b-popover.hover.right
                      :title="
                        `User will be redirected to '${transitionUrl}/employer-signup/?org_refer_id=${organization.org_refer_id}'`
                      "
                    >
                      Employers
                    </span>
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </b-form-group>
          </div>
        </div>
      </b-collapse>

      <page-separator>
        <b-link v-b-toggle="'section-five'" class="accordion__toggle p-0">
          <span class="flex">Section Five</span>
          <md-icon class="accordion__toggle-icon">
            {{ secFiveVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>
      <b-form-group label-class="form-label">
        <b-form-checkbox id="section-five" v-model="homepageConfig.visibleSections.section_five" switch>
          Enable this section
        </b-form-checkbox>
      </b-form-group>

      <b-collapse
        id="section-five"
        accordion="sectionFiveAccordionId"
        :visible="secFiveVisible"
        class="accordion__menu"
        @hide="secFiveVisible = false"
        @show="secFiveVisible = true"
      >
        <div v-if="homepageConfig.visibleSections.section_five">
          <b-form-group label="Title" label-for="bottom_title" label-class="form-label">
            <b-form-input
              id="section_five_title"
              placeholder="Enter Title"
              v-model="homepageConfig.section_five_title"
              required
            />
          </b-form-group>

          <b-form-group label="CTA Title" label-for="title" label-class="form-label">
            <b-form-input
              id="cta_section_five_title"
              placeholder="Enter CTA Title"
              v-model="homepageConfig.sectionFiveCtaBtnTitle"
              required
            />
          </b-form-group>
        </div>
      </b-collapse>

      <page-separator>
        <b-link v-b-toggle="'section-six'" class="accordion__toggle p-0">
          <span class="flex">Section Six</span>
          <md-icon class="accordion__toggle-icon">
            {{ secSixVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>
      <b-form-group label-class="form-label">
        <b-form-checkbox id="section-six" v-model="homepageConfig.visibleSections.section_six" switch>
          Enable this section
        </b-form-checkbox>
      </b-form-group>

      <b-collapse
        id="section-six"
        accordion="sectionSixAccordionId"
        :visible="secSixVisible"
        class="accordion__menu"
        @hide="secSixVisible = false"
        @show="secSixVisible = true"
      >
        <div v-if="homepageConfig.visibleSections.section_six">
          <b-form-group label="Title" label-for="bottom_title" label-class="form-label">
            <b-form-input
              id="section_six_title"
              placeholder="Enter Title"
              v-model="homepageConfig.section_six_title"
              required
            />
          </b-form-group>

          <b-form-group label="Description" label-for="sec_six_desc" label-class="form-label">
            <b-form-textarea
              id="sec_six_desc"
              rows="6"
              v-model="homepageConfig.sec_six_desc"
              required
            ></b-form-textarea>
          </b-form-group>

          <b-form-group label="CTA Title" label-for="title" label-class="form-label">
            <b-form-input
              id="cta_section_six_title"
              placeholder="Enter CTA Title"
              v-model="homepageConfig.sectionSixCtaBtnTitle"
              required
            />
          </b-form-group>
        </div>
      </b-collapse>

      <page-separator>
        <b-link v-b-toggle="'section-seven'" class="accordion__toggle p-0">
          <span class="flex">Section Seven</span>
          <md-icon class="accordion__toggle-icon">
            {{ secSevenVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>
      <b-form-group label-class="form-label">
        <b-form-checkbox id="section-seven" v-model="homepageConfig.visibleSections.section_seven" switch>
          Enable this section
        </b-form-checkbox>
      </b-form-group>

      <b-collapse
        id="section-seven"
        accordion="sectionSevenAccordionId"
        :visible="secSevenVisible"
        class="accordion__menu"
        @hide="secSevenVisible = false"
        @show="secSevenVisible = true"
      >
        <div v-if="homepageConfig.visibleSections.section_seven">
          <b-form-group label="Title" label-for="bottom_title" label-class="form-label">
            <b-form-input
              id="section_seven_title"
              placeholder="Enter Title"
              v-model="homepageConfig.section_seven_title"
              required
            />
          </b-form-group>

          <b-form-group label="Description" label-for="sec_seven_desc" label-class="form-label">
            <b-form-textarea
              id="sec_seven_desc"
              rows="6"
              v-model="homepageConfig.sec_seven_desc"
              required
            ></b-form-textarea>
          </b-form-group>

          <b-form-group label="CTA Title" label-for="title" label-class="form-label">
            <b-form-input
              id="cta_section_seven_title"
              placeholder="Enter CTA Title"
              v-model="homepageConfig.sectionSevenCtaBtnTitle"
              required
            />
          </b-form-group>
        </div>
      </b-collapse>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>Update</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import {
  get,
  isEmpty,
  // map
} from 'lodash';
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import Page from '@/components/Page.vue';
import { USER_ROLES, USER_ROLE_TYPES } from '@/common/constants';
import { requiredIf } from 'vuelidate/lib/validators';
import PageHeader from '@/components/Ui/PageHeader.vue';
import ImageUploader from '@/components/ImageUploader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import MdIcon from '@/components/Ui/MdIcon.vue';

export default {
  components: {
    PageHeader,
    FmvAvatar,
    ImageUploader,
    PageSeparator,
    MdIcon,
  },
  extends: Page,
  data() {
    return {
      topBannerVisible: true,
      statsVisible: true,
      expertiseVisible: true,
      sectionTwoVisible: true,
      secThreeVisible: true,
      secFourVisible: true,
      secFiveVisible: true,
      secSixVisible: true,
      secSevenVisible: true,
      testimonialsVisible: true,
      bottomVisible: true,
      title: 'Configure Home Page',
      isAdded: false,
      videoType: 'mp4',
      org: null,
      organization: {},
      dragging: false,
      homepageConfig: {
        title: '',
        description: '',
        banner_img_url: '',
        banner_video_url: '',
        btn_title: '',
        btn_link: '',
        sec_two_title: '',
        sec_two_attribs: [],

        sec_three_title: '',
        sec_three_desc: '',
        sec_three_qoute: '',
        sec_three_author: '',
        image_1: '',
        image_2: '',
        image_3: '',
        sec_four_title: '',
        sec_four_desc: '',
        ctaBtnTitle: '',
        sectionFourCtaBtn: true,
        isSchoolLink: true,
        isEmployerLink: true,

        section_five_title: '',
        sectionFiveCtaBtnTitle: '',

        sec_six_title: '',
        sec_six_desc: '',
        sectionSixCtaBtnTitle: '',

        visibleSections: {
          section_two: true,
          section_three: true,
          section_four: true,
          section_five: true,
          section_six: true,
          section_seven: true,
        },
      },

      linkedWebsite: null,
      areOrgsLoading: false,
      allOrgs: [],
      isLoading: false,
      isImgUploading: {
        banner_img: false,
        bottom_img: false,
        banner_video: false,
        image_1: false,
        image_2: false,
        image_3: false,
      },
      isImg1Uploading: false,
      isImg2Uploading: false,
      isImg3Uploading: false,
      isBottomImgUploading: false,
      isVideoUploading: false,
      uploadFile: {
        banner_img: null,
        bottom_img: null,
        banner_video: null,
        image_1: null,
        image_2: null,
        image_3: null,
      },
      uploadPercent: { banner_img: 0, bottom_img: 0, banner_video: 0, image_1: 0, image_2: 0, image_3: 0 },
      uploadCancelTokenSource: { banner_img: null, bottom_img: 0, banner_video: 0, image_1: 0, image_2: 0, image_3: 0 },
      USER_ROLES,
      USER_ROLE_TYPES,

      sectionTwoAttribs: [
        {
          title: '',
          desc: '',
          img: '',
        },
      ],
      secTwoFile: [{ image_url: null, isImgUploading: false }],
      selectedBannerLink: null,
      customLinkField: '',
      allPages: [
        {
          name: 'training-providers',
          text: 'Go to training providers page',
        },
        {
          name: 'programs',
          text: 'Go to programs page',
        },
        {
          name: 'careers',
          text: 'Go to career pathways page',
        },
        {
          name: 'sponsorships',
          text: 'Go to sponsorships page',
        },
        {
          name: 'employers',
          text: 'Go to employers page',
        },
        {
          name: 'jobs',
          text: 'Go to jobs page',
        },
        {
          name: 'about',
          text: 'Go to about page',
        },
        {
          name: 'simulations',
          text: 'Go to simulations page',
        },
        { name: 'custom-link', text: 'Custom Link' },
      ],
      partnersLayout: 'Layout1',
      partnersLayoutOptions: [
        { text: 'Layout 1', image: 'partner_layout1.png', value: 'Layout1' },
        { text: 'Layout 2', image: 'partner_layout2.png', value: 'Layout2' },
      ],
      homeLayout: {
        hero: {
          name: 'DefaultBanner',
          textAlignment: 'start',
          backgroundColor: 'light',
          btnColor: '',
          textColor: 'black',
        },
      },
      btnColor: '',
      homeLayoutOptions: [
        { text: 'Default', image: 'default_hero_org.png', value: 'DefaultBanner' },
        { text: 'Default 2', image: 'default_hero_org_2.png', value: 'DefaultBanner2' },
        { text: 'Default 3', image: 'default_hero_org_3.png', value: 'DefaultBanner3' },
        { text: 'Split Banner', image: 'split_hero_org.png', value: 'SplitBanner' },
        { text: 'Text Only', image: 'text_only_hero_org.png', value: 'TextOnlyBanner' },
        { text: 'Background Cover Image', image: 'bg_img_hero_org.png', value: 'BgImageBanner' },
      ],
      textAlignmentOpts: [
        { text: 'Left', icon: 'format_align_left', value: 'start' },
        { text: 'Center', icon: 'format_align_center', value: 'center' },
        { text: 'Right', icon: 'format_align_right', value: 'end' },
      ],
      heroTextAlignBanners: ['TextOnlyBanner', 'BgImageBanner'],
      heroBannerBgColorOpts: [
        { text: 'Light', value: 'light' },
        { text: 'Primary', value: 'primary' },
        { text: 'Dark', value: 'dark' },
        { text: 'Secondary', value: 'secondary' },
        { text: 'White', value: 'white' },
        { text: 'Gradient', value: 'gradient' },
      ],
      textColorOpts: ['white', 'black'],

      transitionUrl: process.env.VUE_APP_MAIN_DOMAIN,
    };
  },
  validations() {
    return {
      org: {
        required: requiredIf(() => get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN),
      },
      customLinkField: {
        required: requiredIf(() => this.selectedBannerLink === 'custom-link'),
      },

      sectionTwoAttribs: {
        required: requiredIf(() => this.homepageConfig.visibleSections.section_two),
        $each: {
          title: { required: requiredIf(() => this.homepageConfig.visibleSections.section_two) },
          img: {},
          description: {},
          // img: { required: requiredIf(() => this.homepageConfig.visibleSections.section_two) },
          // description: {
          //   required: requiredIf(() => this.homepageConfig.visibleSections.features),
          // },
        },
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isFormLoading() {
      return this.isLoading || this.isVideoUploading || this.areOrgsLoading || this.isFooterImgLoading;
    },
    heroLayout() {
      return get(this.homeLayout, 'hero.name') || 'DefaultBanner';
    },
    selectedOrganization() {
      if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
        return this.allOrgs.find(org => org.id === this.org);
      } else {
        return this.getLoggedInUser.linked_entity;
      }
    },
    backgroundColorMap() {
      if (this.selectedOrganization) {
        return {
          light: `${this.selectedOrganization.primary_color}15`,
          primary: this.selectedOrganization.primary_color,
          dark: this.selectedOrganization.dark_color,
          secondary: this.selectedOrganization.secondary_color,
          white: '#fff',
          gradient: `linear-gradient(to bottom, ${this.selectedOrganization.primary_color}15, ${this.selectedOrganization.primary_color})`,
        };
      }
      return {};
    },
    btnColorOpts() {
      if (this.selectedOrganization) {
        return [
          `${this.selectedOrganization.primary_color}15`,
          this.selectedOrganization.primary_color,
          this.selectedOrganization.dark_color,
          this.selectedOrganization.secondary_color,
          '#fff',
        ];
      }
      return [];
    },
  },

  watch: {
    'homepageConfig.sectionFourCtaBtn': {
      handler(value) {
        if (value) {
          this.homepageConfig.isEmployerLink = true;
          this.homepageConfig.isSchoolLink = true;
        }
      },
    },
    'homepageConfig.btn_link': {
      handler(value) {
        if (value) {
          this.updateBannerBtnLink();
        }
      },
      immediate: true,
    },
    'homepageConfig.isEmployerLink': 'updateSectionFourCtaBtn',
    'homepageConfig.isSchoolLink': 'updateSectionFourCtaBtn',
  },

  methods: {
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('organization', [
      'getAllOrganizations',
      'getOrganization',
      'createHomepageConfig',
      'getHomePageConfig',
      'getOrgImageUploadPresignedUrl',
    ]),
    get,
    isEmpty,
    setImage(file, image) {
      this.uploadFile[image] = file;
      this.uploadImage(image);
    },

    handleBannerLinkSelection(selected) {
      if (selected !== 'custom-link') {
        this.homepageConfig.btn_link = selected;
        this.customLinkField = '';
      }
    },
    updateBtnLink(value) {
      if (this.selectedBannerLink === 'custom-link') {
        this.homepageConfig.btn_link = value;
      }
    },

    setFeaturesImage(file, index) {
      this.secTwoFile[index].image_url = file;
      this.uploadImage('image_url', index);
    },
    getImgUrl(pic) {
      return require('@/assets/images/webConfig/' + pic);
    },
    updateSectionFourCtaBtn() {
      if (!this.homepageConfig.isEmployerLink && !this.homepageConfig.isSchoolLink) {
        this.homepageConfig.sectionFourCtaBtn = false;
      }
    },
    updateBannerBtnLink() {
      const found = this.allPages.find(page => page.name === this.homepageConfig.btn_link);
      if (found) {
        this.selectedBannerLink = found.name;
      } else {
        this.selectedBannerLink = 'custom-link';
        this.customLinkField = this.homepageConfig.btn_link;
      }
    },
    addAttrib(index) {
      this.sectionTwoAttribs.splice(index + 1, 0, {
        title: '',
        desc: '',
        img: '',
        btn_link: '',
        btn_text: '',
      });
      this.secTwoFile.splice(index + 1, 0, {
        image_url: null,
        isImgUploading: false,
      });
    },

    removeAttrib(index) {
      this.sectionTwoAttribs.splice(index, 1);
      this.secTwoFile.splice(index, 1);
    },
    resetData() {
      this.linkedWebsite = null;
      this.homepageConfig.title = '';
      this.homepageConfig.description = '';

      this.homepageConfig.banner_img_url = '';
      this.homepageConfig.banner_video_url = '';
      this.homepageConfig.btn_title = '';
      this.homepageConfig.btn_link = '';
      this.homepageConfig.sec_two_title = '';
      this.homepageConfig.sec_two_attribs = [];
      this.homepageConfig.sec_three_title = '';
      this.homepageConfig.sec_three_desc = '';
      this.homepageConfig.sec_three_qoute = '';
      this.homepageConfig.sec_three_author = '';
      this.homepageConfig.image_1 = '';
      this.homepageConfig.image_2 = '';
      this.homepageConfig.image_3 = '';

      this.homepageConfig.section_six_title = '';
      this.homepageConfig.sec_six_desc = '';
      this.homepageConfig.sectionSixCtaBtnTitle = '';

      this.homepageConfig.section_seven_title = '';
      this.homepageConfig.sec_seven_desc = '';
      this.homepageConfig.sectionSevenCtaBtnTitle = '';

      this.homepageConfig.visibleSections = {
        section_two: true,
        section_three: true,
      };
      this.secTwoFile = [{ image_url: null, isImgUploading: false }];
      this.sectionTwoAttribs = [
        {
          title: '',
          desc: '',
          img: '',
          btn_link: '',
          btn_text: '',
        },
      ];
      this.homeLayout = {
        hero: { name: 'DefaultBanner', textAlignment: 'start', btnColor: '' },
      };
    },
    prefillLogo(orgId) {
      this.resetData();
      this.fetchBasicConfig(orgId);
    },
    async fetchOrgs() {
      this.areOrgsLoading = true;
      const response = await this.getAllOrganizations({});
      this.allOrgs = response.data;

      this.areOrgsLoading = false;
    },
    async fetchBasicConfig(org_id = null) {
      let resp;
      if (org_id) {
        resp = await this.getHomePageConfig({
          org_id: org_id,
        });
      } else {
        resp = await this.getHomePageConfig({});
      }
      this.linkedWebsite = resp.linked_website;
      this.homepageConfig.title = resp.banner_title;
      this.homepageConfig.description = resp.banner_desc;
      this.homepageConfig.banner_img_url = resp.banner_img;
      this.homepageConfig.visibleSections = resp.visible_sections;
      this.sectionTwoAttribs = get(resp, 'sec_two_attribs.length')
        ? get(resp, 'sec_two_attribs')
        : [
            {
              title: '',
              desc: '',
              img: '',
              btn_link: '',
              btn_text: '',
            },
          ];
      if (get(resp, 'sec_two_attribs')) {
        this.secTwoFile = this.sectionTwoAttribs.map(() => ({ image_url: null, isImgUploading: false }));
      }
      this.homepageConfig.banner_video_url = resp.banner_video;
      this.homepageConfig.btn_title = resp.btn_title;
      this.homepageConfig.btn_link = resp.btn_link;
      this.homepageConfig.sec_two_title = resp.sec_two_title;
      this.homepageConfig.sec_two_attribs = resp.sec_two_attribs;
      this.homepageConfig.sec_three_title = resp.sec_three_title;
      this.homepageConfig.sec_three_desc = resp.sec_three_desc;
      this.homepageConfig.sec_three_qoute = resp.sec_three_qoute;
      this.homepageConfig.sec_three_author = resp.sec_three_author;

      this.homepageConfig.sec_four_title = resp.sec_four_title;
      this.homepageConfig.sec_four_desc = resp.sec_four_desc;
      this.homepageConfig.ctaBtnTitle = resp.sec_four_btn_title;
      this.partnersLayout = resp.sec_four_layout;

      this.homepageConfig.sectionFourCtaBtn = resp.sec_four_btn;

      this.homepageConfig.isSchoolLink = resp.sec_four_sch_link;
      this.homepageConfig.isEmployerLink = resp.sec_four_emp_link;

      this.organization = resp.organization;

      this.homepageConfig.section_five_title = resp.sec_five_title;
      this.homepageConfig.sectionFiveCtaBtnTitle = resp.sec_five_btn_title;

      this.homepageConfig.section_six_title = resp.sec_six_title;
      this.homepageConfig.sec_six_desc = resp.sec_six_desc;
      this.homepageConfig.sectionSixCtaBtnTitle = resp.sec_six_btn_title;

      this.homepageConfig.section_seven_title = resp.sec_seven_title;
      this.homepageConfig.sec_seven_desc = resp.sec_seven_desc;
      this.homepageConfig.sectionSevenCtaBtnTitle = resp.sec_seven_btn_title;

      this.homepageConfig.image_1 = resp.extra.image_1;
      this.homepageConfig.image_2 = resp.extra.image_2;
      this.homepageConfig.image_3 = resp.extra.image_3;
      this.homeLayout = resp.layout;

      if (!this.homeLayout.hero.btnColor) this.homeLayout.hero = Object.assign({ btnColor: '' }, this.homeLayout.hero);
      if (!this.homeLayout.hero.textColor)
        this.homeLayout.hero = Object.assign({ textColor: 'black' }, this.homeLayout.hero);
    },

    async uploadImage(uploadType, index) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      if (['banner_img', 'bottom_img', 'banner_video', 'image_1', 'image_2', 'image_3'].includes(uploadType)) {
        this.isImgUploading[uploadType] = true;
      } else this.secTwoFile[index].isImgUploading = true;
      this.isVideoUploading = uploadType === 'banner_video';
      try {
        const urlResp = await this.getOrgImageUploadPresignedUrl({
          file_name:
            uploadType === 'image_url' ? this.secTwoFile[index].image_url.name : this.uploadFile[uploadType].name,
          content_type:
            uploadType === 'image_url' ? this.secTwoFile[index].image_url.type : this.uploadFile[uploadType].type,
          upload_type: uploadType,
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: uploadType === 'image_url' ? this.secTwoFile[index].image_url : this.uploadFile[uploadType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token,
          },
        });
        if (uploadType === 'banner_img') {
          this.homepageConfig.banner_img_url = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'image_url') {
          this.sectionTwoAttribs[index].img = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'bottom_img') {
          this.homepageConfig.bottom_img_url = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'banner_video') {
          this.homepageConfig.banner_video_url = urlResp.upload_url.split('?')[0];
          const makeType = this.homepageConfig.banner_video_url.split('.');
          this.videoType = makeType[makeType.length - 1];
        } else if (['image_1', 'image_2', 'image_3'].includes(uploadType)) {
          this.homepageConfig[uploadType] = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (['banner_img', 'bottom_img', 'banner_video', 'image_1', 'image_2', 'image_3'].includes(uploadType)) {
        this.isImgUploading[uploadType] = false;
      } else this.secTwoFile[index].isImgUploading = false;
      if (uploadType === 'banner_video') this.isVideoUploading = false;
    },

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.makeToast({ variant: 'danger', msg: 'Please configure the form correctly' });
        this.isLoading = false;
        return;
      }

      try {
        const data = {
          banner_title: this.homepageConfig.title,
          banner_desc: this.homepageConfig.description,
          banner_img: this.homepageConfig.banner_img_url,

          banner_video: this.homepageConfig.banner_video_url,
          btn_title: this.homepageConfig.btn_title,
          btn_link: this.homepageConfig.btn_link,
          sec_two_title: this.homepageConfig.sec_two_title,
          sec_two_attribs: this.homepageConfig.visibleSections.section_two ? this.sectionTwoAttribs : [],
          sec_three_title: this.homepageConfig.sec_three_title,
          sec_three_desc: this.homepageConfig.sec_three_desc,
          sec_three_qoute: this.homepageConfig.sec_three_qoute,
          sec_three_author: this.homepageConfig.sec_three_author,
          visible_sections: this.homepageConfig.visibleSections,

          sec_four_title: this.homepageConfig.sec_four_title,
          sec_four_desc: this.homepageConfig.sec_four_desc,
          sec_four_btn_title: this.homepageConfig.ctaBtnTitle,
          sec_four_layout: this.partnersLayout,

          sec_four_btn: this.homepageConfig.sectionFourCtaBtn,

          sec_four_sch_link: this.homepageConfig.isSchoolLink,
          sec_four_emp_link: this.homepageConfig.isEmployerLink,

          sec_five_title: this.homepageConfig.section_five_title,
          sec_five_btn_title: this.homepageConfig.sectionFiveCtaBtnTitle,

          sec_six_title: this.homepageConfig.section_six_title,
          sec_six_desc: this.homepageConfig.sec_six_desc,
          sec_six_btn_title: this.homepageConfig.sectionSixCtaBtnTitle,

          sec_seven_title: this.homepageConfig.section_seven_title,
          sec_seven_desc: this.homepageConfig.sec_seven_desc,
          sec_seven_btn_title: this.homepageConfig.sectionSevenCtaBtnTitle,

          layout: this.homeLayout,

          extra: {
            image_1: this.homepageConfig.image_1,
            image_2: this.homepageConfig.image_2,
            image_3: this.homepageConfig.image_3,
          },
          organization:
            get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN
              ? this.org
              : this.getLoggedInUser.linked_entity.id,
        };
        await this.createHomepageConfig({
          ...data,
        });
        this.makeToast({ variant: 'success', msg: 'Settings Configured!' });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      // } else {
      //   this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      // }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.isLoading = true;

    if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
      this.fetchOrgs();
    }
    if (get(this.getLoggedInUser, 'role') === USER_ROLES.ORGANIZATION) {
      (await this.getOrganization(this.getLoggedInUser.linked_entity.id)).data;
    }
    try {
      if (get(this.getLoggedInUser, 'role') === USER_ROLES.ORGANIZATION) {
        this.fetchBasicConfig();
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
    }

    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.custom {
  margin-top: 8px;
}
.column {
  margin: 10px 10px 0;
  padding: 0;
}
.layout-hover figure img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.layout-hover figure:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  object-fit: contain !important;
}
.bg-color-circle {
  width: 42px;
  height: 42px;
  /* background-color: rgb(196, 108, 234); */
  border-radius: 50%;
  border: 1px solid #868686;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
  &.active {
    border: 2px solid var(--secondary-color) !important;
  }
}
</style>
