<template>
  <div :class="containerClass">
    <page-header title="Edit Section" :container-class="null" :breadcrumb="breadcrumb" class="mb-16pt" />

    <b-form class="col-md-12 px-0 page-section pt-2" @submit.prevent="onSubmit">
      <div class="row">
        <b-form-group
          label="Language Preference(s)"
          label-for="lang_preferences"
          label-class="form-label"
          class="col-md-6"
        >
          <div class="custom-select-icon">
            <b-form-select
              class="custom-select-icon__select"
              v-model="formData.lang_preferences"
              :options="options"
              required
            >
              ></b-form-select
            >
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </b-form-group>

        <b-form-group label="Gender" label-for="gender" label-class="form-label" class="col-md-6">
          <div class="custom-select-icon">
            <b-form-select
              class="custom-select-icon__select"
              v-model="formData.gender"
              :options="options"
              required
            ></b-form-select>
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </b-form-group>
      </div>
      <b-form-group label="Address" label-for="address" label-class="form-label">
        <div class="custom-select-icon">
          <b-form-select
            class="custom-select-icon__select"
            v-model="formData.address"
            :options="options"
            required
          ></b-form-select>
          <span class="material-icons custom-select-icon__icon">sort</span>
        </div>
      </b-form-group>
      <div class="row">
        <b-form-group label="Zipcode" label-for="zipcode" label-class="form-label" class="col-md-6">
          <div class="custom-select-icon">
            <b-form-select
              class="custom-select-icon__select"
              v-model="formData.zipcode"
              :options="options"
              required
            ></b-form-select>
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </b-form-group>
        <b-form-group label="City" label-for="city" label-class="form-label" class="col-md-6">
          <div class="custom-select-icon">
            <b-form-select
              class="custom-select-icon__select"
              v-model="formData.city"
              :options="options"
              required
            ></b-form-select>
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </b-form-group>
      </div>
      <div class="row">
        <b-form-group label="State" label-for="state" label-class="form-label" class="col-md-6">
          <div class="custom-select-icon">
            <b-form-select
              class="custom-select-icon__select"
              v-model="formData.state"
              :options="options"
              required
            ></b-form-select>
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </b-form-group>
        <b-form-group label="Country" label-for="country" label-class="form-label" class="col-md-6">
          <div class="custom-select-icon">
            <b-form-select
              class="custom-select-icon__select"
              v-model="formData.country"
              :options="options"
              required
            ></b-form-select>
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </b-form-group>
      </div>
      <div class="row">
        <b-form-group
          label="How did you hear about program?"
          label-for="reference"
          label-class="form-label"
          class="col-md-6"
        >
          <div class="custom-select-icon">
            <b-form-select
              class="custom-select-icon__select"
              v-model="formData.reference"
              :options="options"
              required
            ></b-form-select>
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </b-form-group>
        <b-form-group
          label="Registration Reason"
          label-for="registration_reason"
          label-class="form-label"
          class="col-md-6"
        >
          <div class="custom-select-icon">
            <b-form-select
              class="custom-select-icon__select"
              v-model="formData.registration_reason"
              :options="options"
              required
            ></b-form-select>
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group label="Desired Work Type" label-for="desired_work_type" label-class="form-label" class="col-md-6">
          <div class="custom-select-icon">
            <b-form-select
              class="custom-select-icon__select"
              v-model="formData.desired_work_type"
              :options="options"
              required
            ></b-form-select>
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </b-form-group>
        <b-form-group label="Desired Work Time" label-for="desired_work_time" label-class="form-label" class="col-md-6">
          <div class="custom-select-icon">
            <b-form-select
              class="custom-select-icon__select"
              v-model="formData.desired_work_time"
              :options="options"
              required
            ></b-form-select>
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group label="Desired Work Days" label-for="desired_work_day" label-class="form-label" class="col-md-6">
          <div class="custom-select-icon">
            <b-form-select
              class="custom-select-icon__select"
              v-model="formData.desired_work_day"
              :options="options"
              required
            ></b-form-select>
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </b-form-group>
        <b-form-group label="Work Travel" label-for="work_travel" label-class="form-label" class="col-md-6">
          <div class="custom-select-icon">
            <b-form-select
              class="custom-select-icon__select"
              v-model="formData.work_travel"
              :options="options"
              required
            ></b-form-select>
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group
          label="Interested Facility Types"
          label-for="interested_facility_types"
          label-class="form-label"
          class="col-md-6"
        >
          <div class="custom-select-icon">
            <b-form-select
              class="custom-select-icon__select"
              v-model="formData.interested_facility_types"
              :options="options"
              required
            ></b-form-select>
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </b-form-group>
        <b-form-group label="Ethnicity" label-for="ethnicity" label-class="form-label" class="col-md-6">
          <div class="custom-select-icon">
            <b-form-select
              class="custom-select-icon__select"
              v-model="formData.ethnicity"
              :options="options"
              required
            ></b-form-select>
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group
          label="Social Security Number(SSN)"
          label-for="student_ssn"
          label-class="form-label"
          class="col-md-6"
        >
          <div class="custom-select-icon">
            <b-form-select
              class="custom-select-icon__select"
              v-model="formData.student_ssn"
              :options="options"
              required
            ></b-form-select
            ><span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </b-form-group>
        <b-form-group label="Plan to Pay" label-for="plan_to_pay" label-class="form-label" class="col-md-6">
          <div class="custom-select-icon">
            <b-form-select
              class="custom-select-icon__select"
              v-model="formData.plan_to_pay"
              :options="options"
              required
            ></b-form-select>
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group label="Resume" label-for="resume_url" label-class="form-label" class="col-md-6">
          <div class="custom-select-icon">
            <b-form-select
              class="custom-select-icon__select"
              v-model="formData.resume_url"
              :options="options"
              required
            ></b-form-select>
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </b-form-group>

        <b-form-group label="Occupation" label-for="occupation" label-class="form-label" class="col-md-6">
          <div class="custom-select-icon">
            <b-form-select
              class="custom-select-icon__select"
              v-model="formData.occupation"
              :options="options"
              required
            ></b-form-select>
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </b-form-group>
      </div>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>Update</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import Page from '@/components/Page.vue';
import { get, pick } from 'lodash';
import { USER_ROLE_TYPES } from '@/common/constants';
import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';

export default {
  extends: Page,
  components: { PageHeader },
  data() {
    return {
      title: 'Configure Student Information',
      formData: {
        lang_preferences: null,
        gender: null,
        address: null,
        zipcode: null,
        city: null,
        country: null,
        state: null,
        reference: null,
        registration_reason: null,
        resume_url: null,
        desired_work_type: null,
        desired_work_time: null,
        desired_work_day: null,
        work_travel: null,
        interested_facility_types: null,
        ethnicity: null,
        student_ssn: null,
        plan_to_pay: null,
        occupation: null,
      },
      options: [
        { value: null, text: 'Please Select', disabled: true },
        { value: 'required', text: 'Required' },
        { value: 'optional', text: 'Optional' },
        { value: 'disabled', text: 'Disabled' },
      ],
      isLoading: false,
      isDataLoading: false,
      responseData: {},
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [{ text: 'Student Information', active: true }];
    },
    isFormLoading() {
      return this.isLoading || this.isDataLoading;
    },
  },
  methods: {
    ...mapActions('applicationForm', ['updateRegistrationForm', 'getPublicRegistrationForm']),
    async fetchRegistrationForm() {
      this.isDataLoading = true;
      try {
        const resp = (
          await this.getPublicRegistrationForm({
            params: {
              entity: this.getLoggedInUser.linked_entity.id,
              type: get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.EMPLOYER ? 'employer' : 'organization',
            },
          })
        ).data;
        resp.forEach((obj) => {
          this.responseData[`${obj.field_abbr}`] = obj.status;
        });

        this.formData = {
          ...pick(this.responseData, [
            'lang_preferences',
            'desired_work_type',
            'desired_work_time',
            'desired_work_day',
            'work_travel',
            'interested_facility_types',
            'student_ssn',
            'reference',
            'registration_reason',
            'plan_to_pay',
            'occupation',
            'gender',
            'ethnicity',
            'address',
            'zipcode',
            'city',
            'state',
            'country',
            'resume_url',
          ]),
        };
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isDataLoading = false;
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        const entries = Object.entries(this.formData);
        let static_fields = [];
        entries.forEach((obj) => {
          static_fields.push({
            field_abbr: obj[0],
            status: obj[1],
          });
        });

        await this.updateRegistrationForm({
          data: {
            static_fields,
          },
        });

        this.makeToast({ variant: 'success', msg: 'Student Information Fields Configured!' });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
  },
  async mounted() {
    await this.fetchRegistrationForm();
  },
};
</script>

<style>
</style>
