<template>
  <div>
    <page-header :title="title" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input class="w-lg-auto" placeholder="Search States" v-model="searchTerm" @input="onSearch" />

                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-6 mx-0 px-0">
                  <!-- TODO: Add filters here. (if needed) -->
                  <!-- <b-dropdown class="ml-lg-auto" :text="$t('All Topics')" variant="link text-black-70" right>
                  <b-dropdown-item active>All Topics</b-dropdown-item>
                  <b-dropdown-item>My Topics</b-dropdown-item>
                </b-dropdown>

                <b-dropdown class="mr-8pt" :text="$t('Newest')" variant="link text-black-70" right>
                  <b-dropdown-item active>Newest</b-dropdown-item>
                  <b-dropdown-item>Unanswered</b-dropdown-item>
                </b-dropdown> -->
                  <!-- <v-select
                    id="program"
                    class="form-control v-select-custom"
                    label="title"
                    :value="program"
                    :reduce="(program) => program.id"
                    placeholder="Select Program"
                    :options="allPrograms"
                    :loading="areProgramsLoading"
                    @input="onFilterApplied"
                  >
                    <template #search="{ attributes, events }">
                      <input class="vs__search" v-bind="attributes" v-on="events" />
                    </template>

                    <template slot="option" slot-scope="option">
                      <div class="d-flex align-items-center">
                        <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                          <b-img :src="option.image" class="img-fluid" width="20" alt="Logo" v-if="option.image" />
                          <md-icon v-else>local_library</md-icon>
                        </fmv-avatar>
                        <span>{{ option.title }}</span>
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.title }}
                    </template>
                  </v-select> -->
                </div>
                <b-btn class="ml-2" :to="{ name: 'sa-add-state' }" exact variant="primary" v-text="'Add Best State'" />
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="allStates"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(image_url)="data">
              <div class="d-flex align-items-center">
                <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" class="mr-2">
                  <b-img
                    :src="data.item.image_url"
                    class="img-fluid"
                    width="40"
                    alt="Logo"
                    v-if="data.item.image_url"
                  />
                  <i class="fas fa-university fa-lg" v-else></i>
                </fmv-avatar></div
            ></template>
            <template #cell(active)="data">
              <b-badge
                :class="`border border-${data.item.active ? 'success' : 'danger'} text-${
                  data.item.active ? 'success' : 'danger'
                }`"
                variant="none"
                >{{ data.item.active ? 'Active' : 'Inactive' }}</b-badge
              >
            </template>
            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <router-link
                :to="{
                  name: 'sa-edit-state',
                  params: { id: data.item.id },
                }"
                class="svg-icon mr-2"
                v-b-popover.hover.right
                :title="'Edit'"
              >
                <i class="material-icons">edit</i>

                <!-- <i class="far fa-edit text-info" /> -->
              </router-link>
              <a href="#" @click.prevent="updateStatus(data.index)" v-b-popover.hover.right :title="'Update Status'">
                <md-icon :class="data.item.active ? 'text-danger' : 'text-success'">{{
                  data.item.active ? 'block' : 'check_circle_outline'
                }}</md-icon>
              </a>
              <a href="#" @click.prevent="removeState(data.item.id)" v-b-popover.hover.right :title="'Delete State'">
                <i class="material-icons text-danger">delete</i>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalStates"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="schools-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import MdIcon from '@/components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '@/common/constants';
import { FmvAvatar } from 'fmv-avatar';

export default {
  components: { MdIcon, PageHeader, Pagination, FmvAvatar },
  extends: Page,

  data() {
    return {
      title: 'Manage Best States',
      areProgramsLoading: false,
      program: null,
      allPrograms: [],
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalStates: 0,
      searchTerm: '',
      allStates: [],
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Manage States', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'image_url', label: '' },
        { key: 'name', label: 'State' },
        // { key: 'product_description', label: 'Description' },
        { key: 'active', label: 'Status' },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('location', ['getAllStates', 'deleteState', 'updateStateStatus']),
    onFilterApplied() {
      this.fetchStates();
    },
    async fetchStates(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllStates({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.allStates = response.data.results;
      this.currentPage = pageNum;
      this.totalStates = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchStates(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchStates();
    },

    onSearch() {
      this.debouncedSearchStates(this);
    },

    debouncedSearchStates: debounce((vm) => {
      vm.fetchStates();
    }, 500),
    async updateStatus(index) {
      this.isLoading = true;
      const currStatus = this.allStates[index].active;
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to mark the status ${currStatus ? 'Inactive' : 'Active'}.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.updateStateStatus({ id: this.allStates[index].id, data: { active: !currStatus } });
          this.fetchStates();
          this.makeToast({ variant: 'success', msg: 'Status changed successfully!' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
    async removeState(id) {
      this.isLoading = true;
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to delete the selected state.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deleteState(id);
          this.fetchStates();
          this.makeToast({ variant: 'success', msg: 'State deleted successfully!' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.fetchStates();
  },
};
</script>
