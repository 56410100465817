<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator :title="title" />

        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input class="w-lg-auto" placeholder="Search GPTs" v-model="searchTerm" @input="onSearch" />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <div class="col-lg d-flex flex-wrap justify-content-end">
                <b-btn :to="{ name: 'add-gpt' }" exact variant="primary"> Add GPT </b-btn>
              </div>
            </div>
          </div>

          <!-- Organizations Table -->
          <b-table
            :fields="tableColumns"
            :items="gpts"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(image)="data">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside">
                <b-img :src="data.item.image" class="img-fluid" width="40" alt="Logo" v-if="data.item.image" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
            </template>
            <template #cell(name)="data">
              <strong> {{ data.item.first_name }} {{ data.item.last_name }}</strong>
            </template>

            <template #cell(is_active)="data">
              <b-badge
                :class="`border border-${data.item.is_active ? 'success' : 'danger'} text-${
                  data.item.is_active ? 'success' : 'danger'
                }`"
                variant="none"
                >{{ data.item.is_active ? 'Active' : 'Inactive' }}</b-badge
              >
            </template>
            <template #cell(submitter)="data"> {{ $t(`submitterTypes.${get(data.item, 'submitter')}`) }} </template>
            <!-- <template #cell(majors)="data"> {{ $t(`majorTypes.${get(data.item, 'majors')}`) }} </template> -->
            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <router-link
                :to="{
                  name: 'edit-gpt',
                  params: { id: data.item.id },
                }"
                class="svg-icon mr-2"
                v-b-popover.hover.left
                :title="$t('generalMsgs.edit')"
              >
                <md-icon class="text-info">edit</md-icon>
              </router-link>
              <a href="#" @click.prevent="updateStatus(data.index)" v-b-popover.hover.right :title="'Update Status'">
                <md-icon :class="data.item.is_active ? 'text-danger' : 'text-success'">{{
                  data.item.is_active ? 'block' : 'check_circle_outline'
                }}</md-icon>
              </a>
              <a href="#" @click.prevent="removeGpt(data.item.id)" v-b-popover.hover.right :title="'Delete GPT'">
                <i class="material-icons text-danger">delete</i>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalGpts"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="orgs-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { debounce, get } from 'lodash';
import { mapActions } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';
import PageHeader from '@/components/Ui/PageHeader.vue';
export default {
  components: { MdIcon, PageHeader, PageSeparator, Pagination, FmvAvatar },
  extends: Page,

  data() {
    return {
      title: 'Manage GPTs',
      searchTerm: '',
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalGpts: 0,
      gpts: [],
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Gpts', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'name', label: this.$t('schoolMsgs.name') },
        { key: 'image', label: 'Image' },

        { key: 'name', label: 'Name' },
        { key: 'university.name', label: 'University' },
        { key: 'category', label: 'Categories' },
        { key: 'is_active', label: this.$t('generalMsgs.status') },
        { key: 'submitter', label: 'Submitter' },
        { key: 'email', label: `Creator's email` },
        // { key: 'majors', label: 'Majors' },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('gpts', ['getAllGpts', 'updateGptStatus', 'deleteGpt']),
    get,
    async updateStatus(index) {
      this.isLoading = true;
      const currStatus = this.gpts[index].is_active;
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to mark the status ${currStatus ? 'Inactive' : 'Active'}.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.updateGptStatus({ id: this.gpts[index].id, data: { is_active: !currStatus } });
          this.fetchGpts();
          this.makeToast({ variant: 'success', msg: 'Status changed successfully!' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
    async removeGpt(id) {
      this.isLoading = true;
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to delete the selected gpt.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deleteGpt(id);
          this.fetchGpts();
          this.makeToast({ variant: 'success', msg: 'State deleted successfully!' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
    async fetchGpts(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllGpts({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.gpts = response.data.results;
      this.currentPage = pageNum;
      this.totalGpts = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchGpts(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchGpts();
    },

    onSearch() {
      this.debouncedSearchGpts(this);
    },

    debouncedSearchGpts: debounce((vm) => {
      vm.fetchGpts();
    }, 500),
  },

  async mounted() {
    await this.fetchGpts();
  },
};
</script>
