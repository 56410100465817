<template>
  <b-modal ref="systemConfigModal" hide-footer title="System Config" centered lazy @hide="hideModal" size="md">
    <b-form-group label="Title" label-for="title" label-class="form-label">
      <b-form-input disabled id="name" v-model="title" />
    </b-form-group>
    <b-form-group label="Description" label-for="description" label-class="form-label">
      <b-form-input disabled id="name" v-model="description" />
    </b-form-group>
    <b-form-group label="Value" label-for="value" label-class="form-label">
      <b-form-input type="number" min="0" id="name" v-model="value" />
    </b-form-group>

    <div class="d-flex justify-content-end mt-2">
      <b-btn
        variant="primary"
        class="ml-2"
        style="width: 70px"
        @click.prevent="submitData"
        href="#"
        data-toggle="modal"
        :disabled="isLoading"
      >
        <span>{{ 'update' }}</span></b-btn
      >
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { get } from 'lodash';

export default {
  name: 'SystemConfigModal',

  props: {
    showModal: { type: Boolean, default: false },
    systemConfig: { type: Object, default: () => {} },
  },

  data() {
    return {
      title: '',
      description: '',
      value: 0,
      updated: false,
      isLoading: false,
    };
  },
  validations() {
    return {};
  },
  methods: {
    ...mapActions('credit', ['updateSystemConfig']),
    get,

    async updateTags() {
      this.isLoading = true;

      const data = {
        value: this.value,
      };
      try {
        await this.updateSystemConfig({
          id: this.systemConfig.id,
          data,
        });
        this.makeToast({ variant: 'success', msg: 'System Config Updated!' });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
    async submitData() {
      await this.updateTags();
      this.$emit('update');

      this.$refs.systemConfigModal.hide();
    },

    async hideModal() {
      this.$emit('close');
      this.metaTags = [''];
    },

    hideModalManual() {
      this.$refs.systemConfigModal.hide();
      this.hideModal();
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        this.title = this.systemConfig.title;
        this.description = this.systemConfig.description;
        this.value = this.systemConfig.value;
        this.$refs.systemConfigModal.show();
      }
    },
  },
  mounted() {},
};
</script>

<style>
</style>