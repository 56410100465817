<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator :title="title" />

        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Programs"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon>search</md-icon>
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <!-- TODO: Add filters here. (if needed) -->
                <!-- <b-dropdown class="ml-lg-auto" :text="$t('All Topics')" variant="link text-black-70" right>
                  <b-dropdown-item active>All Topics</b-dropdown-item>
                  <b-dropdown-item>My Topics</b-dropdown-item>
                </b-dropdown>

                <b-dropdown class="mr-8pt" :text="$t('Newest')" variant="link text-black-70" right>
                  <b-dropdown-item active>Newest</b-dropdown-item>
                  <b-dropdown-item>Unanswered</b-dropdown-item>
                </b-dropdown> -->

                <b-btn :to="{ name: 'sa-add-program' }" exact variant="primary">Add Program</b-btn>
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="programs"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap sticky-lcol-table"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <router-link
                :to="{
                  name: 'sa-edit-program',
                  params: { id: data.item.id },
                }"
                class="svg-icon mr-2"
                v-b-popover.hover.left
                :title="$t('generalMsgs.edit')"
              >
                <md-icon class="text-info">edit</md-icon>
              </router-link>
              <a
                href="#"
                @click.prevent="confirmDelete(data.item.id)"
                v-b-popover.hover.left
                :title="$t('generalMsgs.remove')"
              >
                <md-icon class="text-danger">delete</md-icon>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalPrograms"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="schools-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { debounce } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';

export default {
  components: { MdIcon, PageHeader, PageSeparator, Pagination },
  extends: Page,

  data() {
    return {
      title: 'Manage Programs',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalPrograms: 0,
      programs: [],

      searchTerm: '',
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.$t('Programs'), active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'title', label: 'Title' },
        { key: 'description', label: 'Description' },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('program', ['getAllPrograms', 'deleteProgram']),
    async confirmDelete(id) {
      const h = this.$createElement;
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          h('div', [
            h('div', 'Removing a program will have following implications:'),
            h('br'),
            h('li', `All associated jobs will belong to 'others' category.`),
            h('li', `Enrolled student program will belong to 'others' category.`),
            h('li', `All associated Partnerships will have their program removed.`),
            h('li', `All associated Sponsorships will have their program removed.`),
          ]),
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deleteProgram(id);
          // const refreshPage = this.careers.length > 1 ? this.currentPage : this.currentPage - 1;
          this.fetchPrograms();
          this.makeToast({ variant: 'success', msg: 'Program Successfully Deleted' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    async fetchPrograms(params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllPrograms({
        // limit: this.perPage,
        // offset: (pageNum - 1) * this.perPage,
        // ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
        ordering: 'id',
      });
      this.programs = response.data;
      // this.currentPage = pageNum;
      this.totalPrograms = this.programs.length;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchPrograms(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchPrograms();
    },

    onSearch() {
      this.debouncedSearchPrograms(this);
    },

    debouncedSearchPrograms: debounce((vm) => {
      vm.fetchPrograms();
    }, 500),
  },

  async mounted() {
    this.fetchPrograms();
  },
};
</script>
