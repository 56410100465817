<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card bg-primary p-2" style="color: white">
          <div class="d-flex justify-content-center align-items-center p-2" style="font-size: 16px">
            <strong> Invite School </strong>
          </div>

          <div class="p-2 mb-4 text-center">Use the invite link below to invite schools on transition.</div>

          <!-- <div class="d-flex flex-wrap justify-content-end">
            <div class="col-sm-4 col-md-4 mb-2 pl-0 pr-0">
              <v-select
                id="state"
                class="form-control v-select-custom"
                label="text"
                v-model="state"
                :reduce="(state) => state.value"
                placeholder="Select State"
                :options="stateOptions"
              >
              </v-select>
            </div>
          </div> -->
          <b-row class="ml-1 mr-3">
            <div class="col-md-3 mb-2">
              <v-select
                id="state"
                class="form-control v-select-custom"
                label="text"
                v-model="state"
                :reduce="(state) => state.value"
                placeholder="Select State"
                :options="stateOptions"
              >
              </v-select>
            </div>
            <b-alert variant="soft-light px-12pt" show class="col-md-9">
              <div class="d-flex align-items-center justify-content-between">
                <span class="text-black-100"
                  ><strong>{{ inviteUrl }}</strong></span
                >

                <span v-if="isDomainCopied" class="text-success"><md-icon>check</md-icon> Copied</span>
                <a href="#" @click.prevent="copyDomain" v-else v-b-popover.hover.top="'Copy Invite Link'">
                  <md-icon>content_copy</md-icon>
                </a>
              </div>
            </b-alert>
          </b-row>
        </div>
        <page-separator :title="title" />

        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    :placeholder="$t('schoolMsgs.searchSchools')"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <!-- TODO: Add filters here. (if needed) -->
                <!-- <b-dropdown class="ml-lg-auto" :text="$t('All Topics')" variant="link text-black-70" right>
                  <b-dropdown-item active>All Topics</b-dropdown-item>
                  <b-dropdown-item>My Topics</b-dropdown-item>
                </b-dropdown>

                <b-dropdown class="mr-8pt" :text="$t('Newest')" variant="link text-black-70" right>
                  <b-dropdown-item active>Newest</b-dropdown-item>
                  <b-dropdown-item>Unanswered</b-dropdown-item>
                </b-dropdown> -->

                <b-btn :to="{ name: 'add-school' }" exact variant="primary" v-text="$t('schoolMsgs.addSchool')" />
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="schools"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(name)="data">
              <strong> {{ data.item.name }} </strong>
            </template>

            <template #cell(logo)="data">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside">
                <b-img :src="data.item.logo_url" class="img-fluid" width="40" alt="Logo" v-if="data.item.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
            </template>

            <template #cell(owner)="data"> {{ data.item.owner.first_name }} {{ data.item.owner.last_name }} </template>

            <template #cell(address)="data">
              {{ data.item.address }}, {{ data.item.zipcode }}, {{ data.item.city }}, {{ data.item.state }},
              {{ data.item.country }}
            </template>
            <template #cell(org_count)="data">
              <a href="#" @click.prevent="openOrgsModal(data.item)" class="text-primary">{{
                $n(get(data.item, 'org_count'), 0)
              }}</a>
            </template>

            <template #cell(demo_request)="data">
              <div class="text-center">
                <md-icon v-if="get(data.item, 'extra.demo_request')" class="text-success">check</md-icon>
              </div>
            </template>

            <template #cell(status)="data">
              <b-badge
                :class="
                  'border border-' +
                  (data.item.is_profile_visible ? `success text-success` : `secondary text-secondary`)
                "
                variant="none"
                >{{ data.item.is_profile_visible ? 'Active' : 'Paused' }}</b-badge
              >
            </template>
            <template #cell(sch_payment_plan)="data">
              <span class="d-flex justify-content-center">{{ get(data.item, 'sch_payment_plan.title') }}</span>
            </template>

            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <router-link
                :to="{
                  name: 'edit-school',
                  params: { id: data.item.id },
                }"
                class="svg-icon mr-2"
                v-b-popover.hover.top
                :title="$t('generalMsgs.edit')"
              >
                <md-icon class="text-info">edit</md-icon>
                <!-- <i class="far fa-edit text-info" /> -->
              </router-link>

              <a
                href="#"
                class="svg-icon mr-2"
                @click.prevent="openAddMetaTagsModal(data.item.id, data.item.meta_tags)"
                v-b-popover.hover.top
                title="Add Meta Tags"
              >
                <md-icon class="text-info">format_list_bulleted</md-icon></a
              >
              <!-- <a
                href="#"
                @click.prevent="confirmDeleteSchool(data.item.id)"
                v-b-popover.hover.top
                :title="$t('generalMsgs.remove')"
              >
                <md-icon class="text-danger">delete</md-icon>
                <i class="far fa-trash-alt text-danger" />
              </a> -->
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalSchools"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="schools-table"
            />
          </div>
        </div>
      </div>
    </div>
    <add-meta-tags-modal
      :show-modal="showAddMetaTagsModal"
      @close="hideAddMetaTagsModal"
      :index="index"
      :existingMetaTags="metaTags"
    />
    <orgs-modal :showModal="showOrgsModal" :school="school" @close="hideOrgModal" />
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { FmvAvatar } from 'fmv-avatar';
import { debounce, get, map } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';
import AddMetaTagsModal from './AddMetaTagsModal.vue';
import OrgsModal from './OrgsModal.vue';
var UsaStates = require('usa-states').UsaStates;
export default {
  components: { MdIcon, PageHeader, PageSeparator, Pagination, FmvAvatar, AddMetaTagsModal, OrgsModal },
  extends: Page,

  data() {
    return {
      title: this.$t('schoolMsgs.manageSchools'),

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalSchools: 0,
      schools: [],
      showAddMetaTagsModal: false,
      index: null,
      metaTags: [],
      searchTerm: '',
      isDomainCopied: false,
      stateOptions: [],
      state: '',
      showOrgsModal: false,
      school: null,
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Schools' },
        { text: 'School Accounts', active: true },
      ];
    },
    inviteUrl() {
      if (this.state) {
        return this.getClickableLink(
          `${process.env.VUE_APP_MAIN_DOMAIN}/school-interest?state=${encodeURIComponent(this.state)}`
        );
      } else return this.getClickableLink(`${process.env.VUE_APP_MAIN_DOMAIN}/school-interest`);
    },
    tableColumns() {
      return [
        { key: 'logo', label: '' },
        { key: 'name', label: this.$t('schoolMsgs.name') },
        { key: 'status', label: this.$t('generalMsgs.status') },
        { key: 'owner', label: this.$t('schoolMsgs.owner') },
        { key: 'owner.email', label: this.$t('schoolMsgs.ownersEmail') },
        { key: 'phone', label: this.$t('schoolMsgs.phone') },
        { key: 'address', label: this.$t('schoolMsgs.address') },
        { key: 'demo_request', label: 'Interested in Healthcare' },
        { key: 'sch_payment_plan', label: 'Payment Plan' },

        { key: 'org_count', label: 'Organizations' },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('school', ['getAllSchools']),
    get,
    openOrgsModal(data) {
      this.showOrgsModal = true;
      this.school = data;
    },
    hideOrgModal() {
      this.showOrgsModal = false;
      this.school = null;
    },
    async copyDomain() {
      try {
        let url = this.inviteUrl;
        await navigator.clipboard.writeText(url);
        this.isDomainCopied = true;

        setTimeout(() => (this.isDomainCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
    async fetchSchools(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllSchools({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.schools = response.data.results;
      this.currentPage = pageNum;
      this.totalSchools = response.data.count;
      this.isLoading = false;
    },
    openAddMetaTagsModal(index, metaTags) {
      this.metaTags = metaTags;
      this.index = index;
      this.showAddMetaTagsModal = true;
    },
    hideAddMetaTagsModal() {
      this.showAddMetaTagsModal = false;
      this.fetchSchools(this.currentPage);
    },
    onPageChange(pageNum) {
      this.fetchSchools(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchSchools();
    },

    onSearch() {
      this.debouncedSearchSchools(this);
    },

    debouncedSearchSchools: debounce((vm) => {
      vm.fetchSchools();
    }, 500),

    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://')
        ? link
        : `${process.env.VUE_APP_DOMAIN_PREFIX || 'https'}://${link}`;
    },
  },

  async mounted() {
    var usStates = new UsaStates();
    this.stateOptions = this.stateOptions.concat(
      map(usStates.states, (state) => ({
        value: state.name,
        text: state.name,
      }))
    );
    this.fetchSchools();
  },
};
</script>
