<template>
  <b-popover
    v-if="targetId"
    ref="popover"
    :target="`program-popover-${targetId}`"
    triggers="hover"
    :placement="placement"
    :html="true"
    custom-class="popover-lg"
  >
    <div v-for="(program, index) in programs" :key="program.id" :class="{ 'mb-3': index < programs.length - 1 }">
      <md-icon class="text-50 icon-16pt">check_circle</md-icon>
      <strong class="ml-2">{{ program.title }}</strong>
    </div>
  </b-popover>
</template>

<script>
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';

export default {
  name: 'ProgramsListPopover',
  components: {},

  props: {
    programs: { type: Array, default: () => [] },
    targetId: { required: true },
    placement: { type: String, default: 'left' },
  },

  data() {
    return {
      DefaultAvatar,
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Sample Page', active: true },
      ];
    },
  },

  methods: {},

  async mounted() {},
};
</script>
