<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit Program' : 'Add Program'"
      :breadcrumb="breadcrumb"
      :container-class="null"
      class="mb-32pt"
    />

    <page-separator title="Program" />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Image" label-for="image" label-class="form-label" class="row-align-items-center">
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isImgUploading">...</span>
            <b-img :src="program.image" class="img-fluid" width="40" alt="Image" v-else-if="program.image" />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'image')"
            :isUploading="isImgUploading"
            :isRequired="!program.image"
            placeholder="Select Image"
          />
        </b-media>
      </b-form-group>

      <b-form-group label="Title" label-for="title" label-class="form-label">
        <b-form-input id="name" placeholder="Enter Program's Title" v-model="program.title" required />
      </b-form-group>

      <b-form-group label="Abbreviation" label-for="abbreviation" label-class="form-label">
        <b-form-input
          id="abbreviation"
          placeholder="Enter Program's Abbreviation"
          v-model="program.abbreviation"
          required
        />
      </b-form-group>

      <b-form-group label="Description" label-for="description" label-class="form-label">
        <b-form-textarea
          id="description"
          placeholder="Enter Program's Description"
          v-model="program.description"
          required
          :rows="3"
        />
      </b-form-group>

      <b-form-group label="Employer Description" label-for="employer-description" label-class="form-label">
        <b-form-textarea
          id="employer-description"
          placeholder="Enter Program's Description"
          v-model="program.employerDescription"
          required
          :rows="3"
        />
      </b-form-group>

      <b-form-group label="Career Pathways" label-for="offered_careers" label-class="form-label">
        <v-select
          id="offered_careers"
          class="form-control v-select-custom"
          label="name"
          v-model="program.offered_careers"
          :reduce="item => item.id"
          placeholder="Select Career Pathways"
          :options="allCareers"
          :loading="areCareersLoading"
          multiple
        >
        </v-select>
      </b-form-group>

      <b-form-group label="Average Salary" label-for="avg_salary" label-class="form-label">
        <currency-input
          v-model="program.avgSalary"
          required
          class="form-control"
          placeholder="Enter Average Salary Per Year"
          :allow-negative="false"
        />
      </b-form-group>
      <b-form-group label="Duration Type" label-for="duration_type" label-class="form-label">
        <v-select
          id="duration_type"
          class="form-control v-select-custom"
          label="text"
          v-model="program.duration_type"
          :reduce="exp => exp.value"
          placeholder="Select Duration Type"
          :options="durationTypeOptions"
        >
        </v-select>
      </b-form-group>
      <div class="row">
        <b-form-group class="col-md-6" label="Duration From" label-for="duration_from" label-class="form-label">
          <b-form-input
            id="prog_weeks"
            placeholder="Enter start of duration"
            type="number"
            v-model="program.durationFrom"
          />
        </b-form-group>

        <b-form-group class="col-md-6" label="Duration To" label-for="duration_to" label-class="form-label">
          <b-form-input
            id="prog_weeks"
            placeholder="Enter end of duration"
            type="number"
            v-model="program.durationTo"
          />
        </b-form-group>
      </div>

      <b-form-group label="Stage" label-for="stage" label-class="form-label">
        <v-select
          id="stage"
          class="form-control v-select-custom"
          label="text"
          v-model="program.stage"
          :reduce="exp => exp.value"
          placeholder="Select program's stage"
          :options="stageOptions"
        >
        </v-select>
      </b-form-group>

      <!-- <b-form-group label="Resume Tips" label-for="resume_tips" label-class="form-label">
        <b-form-input id="resume_tips" placeholder="Enter Resume Tips" v-model="program.resume_tips" />
      </b-form-group> -->
      <b-form-group label="Resume Tips" label-for="resume_tips" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
          }"
          v-model="program.resume_tips"
          placeholder="Enter Resume Tips."
        />
        <!-- <b-form-invalid-feedback :state="!$v.job.description.required && $v.job.description.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        > -->
      </b-form-group>
      <!-- <b-form-group label="Interview Tips" label-for="interview_tips" label-class="form-label">
        <b-form-input id="interview_tips" v-model="program.interview_tips" placeholder="Enter Interview Tips" />
      </b-form-group> -->
      <b-form-group label="Interview Tips" label-for="interview_tips" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
          }"
          v-model="program.interview_tips"
          placeholder="Enter Interview Tips"
        />
        <!-- <b-form-invalid-feedback :state="!$v.job.description.required && $v.job.description.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        > -->
      </b-form-group>
      <b-form-group label="Resume title" label-for="resume_title" label-class="form-label">
        <b-form-input id="resume_title" placeholder="Enter Resume Title" v-model="program.resume_title" required />
      </b-form-group>
      <b-form-group label="Resume About" label-for="resume_about" label-class="form-label">
        <b-form-input id="resume_about" placeholder="Enter Resume About" v-model="program.resume_about" required />
      </b-form-group>

      <b-row class="mb-2" v-for="(skill, index) in program.skills" :key="index">
        <b-form-group label="Skills" label-for="skill" label-class="form-label" class="col-md-10">
          <b-form-input id="name" placeholder="Enter skill info" v-model="program.skills[index]" required />

          <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
        </b-form-group>
        <div class="mt-4 pl-2">
          <a class="btn btn-primary btn-md" href="#" @click.prevent="addSkill(index)">
            <md-icon>add</md-icon>
          </a>
          <a
            href="#"
            class="btn btn-danger ml-2 btn-md"
            @click.prevent="removeSkill(index)"
            v-if="program.skills.length > 1"
          >
            <md-icon>close</md-icon>
          </a>
        </div>
      </b-row>

      <b-form-group label="Education Requirement" label-for="edu_requirement" label-class="form-label">
        <v-select
          id="edu_requirement"
          class="form-control v-select-custom"
          label="text"
          v-model="program.edu_requirement"
          :reduce="item => item.value"
          placeholder="Select Education Requirement"
          :options="eduRequirementOptions"
          multiple
        >
        </v-select>
      </b-form-group>

      <b-form-group label="Program Type" label-for="duration_type" label-class="form-label">
        <v-select
          id="program_type"
          class="form-control v-select-custom"
          label="text"
          v-model="program.prg_type"
          :reduce="exp => exp.value"
          placeholder="Select Program Type"
          :options="programTypeOptions"
        >
        </v-select>
      </b-form-group>

      <!-- Owner Details -->

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
//import { get, map } from 'lodash';
import axios from 'axios';
import { mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Editor from '@tinymce/tinymce-vue';
import Page from '../../../components/Page.vue';
// import UserAvatar from '../../../components/User/UserAvatar.vue';
import 'vue-swatches/dist/vue-swatches.css';
import ImageUploader from '@/components/ImageUploader.vue';
import { FmvAvatar } from 'fmv-avatar';

export default {
  components: {
    PageHeader,
    PageSeparator,
    Editor,
    ImageUploader,
    FmvAvatar
  },
  extends: Page,

  data() {
    return {
      title: this.$route.params.id ? 'Edit Program' : 'Add Program',
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      program: {
        title: '',
        abbreviation: '',
        description: '',
        employerDescription: '',
        resume_tips: '',
        interview_tips: '',
        resume_title: '',
        resume_about: '',
        skills: [''],
        image: '',
        durationFrom: 0,
        durationTo: 0,
        avgSalary: 0,
        stage: null,
        edu_requirement: ['certification'],
        duration_type: '',
        prg_type: '',
        offered_careers: []
      },
      stageOptions: [
        { value: 'beginner', text: 'Beginner' },
        { value: 'intermediate', text: 'Intermediate' },
        { value: 'advanced', text: 'Advanced' }
      ],
      eduRequirementOptions: [
        { value: 'high_school_diploma', text: 'High-school diploma' },
        { value: 'apprenticeship', text: 'Apprenticeship' },
        { value: 'certification', text: 'Certification' },
        { value: 'some_college', text: 'Some College' },
        { value: 'associate', text: 'Associate' },
        { value: 'bachelors', text: 'Bachelors' },
        { value: 'masters_or_prof_degree', text: `Master's or Professional Degree` },
        { value: 'doctrate_or_more', text: 'Doctorate or More' }
      ],
      durationTypeOptions: [
        { value: 'days', text: 'Days' },
        { value: 'weeks', text: 'Weeks' },
        { value: 'months', text: 'Months' },
        { value: 'years', text: 'Years' }
      ],
      programTypeOptions: [
        { value: 'certificate', text: 'Certificate' },
        { value: 'degree', text: 'Degree' },
        { value: 'continuing_education', text: 'Continuing Education' },
        { value: 'extracurricular', text: 'Extracurricular' }
      ],
      uploadFile: { image: null, video: null },
      uploadPercent: { image: 0, video: 0 },
      uploadCancelTokenSource: { image: null, video: null },
      isLoading: false,
      isImgUploading: false,
      areCareersLoading: false,
      allPrograms: [],
      allCareers: []
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Programs', to: { name: 'sa-manage-programs' } },
        {
          text: this.$route.params.id ? 'Edit Program' : 'Add Program',
          active: true
        }
      ];
    },
    isFormLoading() {
      return this.isLoading || this.isLogoUploading || this.isProfileBgImgUploading;
    }
  },

  methods: {
    ...mapActions('program', ['getProgram', 'updateProgram', 'createProgram', 'getProgramImageUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('careerPreference', ['getAllCareerPreferences']),
    setImage(file, fileType) {
      this.uploadFile[fileType] = file;
      this.uploadImage(fileType);
    },
    addSkill(index) {
      this.program.skills.splice(index + 1, 0, '');
    },
    async fetchCareers() {
      this.areCareersLoading = true;

      const response = await this.getAllCareerPreferences({});
      this.allCareers = response.data;

      this.areCareersLoading = false;
    },
    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isImgUploading = uploadType === 'image';

      try {
        const urlResp = await this.getProgramImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type,
          upload_type: 'images'
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token
          }
        });
        if (uploadType === 'image') {
          this.program.image = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'image') this.isImgUploading = false;
    },
    removeSkill(index) {
      this.program.skills.splice(index, 1);
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        const data = {
          title: this.program.title,
          abbr: this.program.abbreviation,
          description: this.program.description,
          emp_description: this.program.employerDescription,
          resume_tips: this.program.resume_tips,
          interview_tips: this.program.interview_tips,
          resume_title: this.program.resume_title,
          resume_about: this.program.resume_about,
          skills: this.program.skills,

          image_url: this.program.image,
          week_length_from: this.program.durationFrom,
          week_length_to: this.program.durationTo,
          avg_salary: this.program.avgSalary,
          stage: this.program.stage,
          edu_requirement: this.program.edu_requirement,
          duration_type: this.program.duration_type,
          offered_careers: this.program.offered_careers,
          prg_type: this.program.prg_type
        };
        if (this.$route.params.id) {
          await this.updateProgram({
            id: this.$route.params.id,
            data
          });
          this.makeToast({ variant: 'success', msg: this.$t('Program Updated!') });
        } else {
          await this.createProgram({
            ...data
          });
          this.makeToast({ variant: 'success', msg: 'Program Added!' });
        }
        setTimeout(() => this.$router.push({ name: 'sa-manage-programs' }), 250);
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    }
  },

  async mounted() {
    this.isLoading = true;
    this.fetchCareers();
    try {
      if (this.$route.params.id) {
        const resp = (await this.getProgram(this.$route.params.id)).data;
        this.program.title = resp.title;
        this.program.abbreviation = resp.abbr;
        this.program.description = resp.description;
        this.program.employerDescription = resp.emp_description;

        this.program.resume_tips = resp.resume_tips;
        this.program.interview_tips = resp.interview_tips;
        this.program.resume_title = resp.resume_title;
        this.program.resume_about = resp.resume_about;
        this.program.skills = resp.skills;

        this.program.image = resp.image_url;
        this.program.durationFrom = resp.week_length_from;
        this.program.durationTo = resp.week_length_to;
        this.program.avgSalary = resp.avg_salary;
        this.program.stage = resp.stage;
        this.program.edu_requirement = resp.edu_requirement;
        this.program.duration_type = resp.duration_type;
        this.program.offered_careers = resp.offered_careers;
        this.program.prg_type = resp.prg_type;
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({ name: 'sa-manage-programs' });
    }

    this.isLoading = false;
  }
};
</script>
