<template>
  <div :class="containerClass">
    <page-header
      :title="
        this.$route.params.id
          ? this.$route.params.backTo
            ? 'Edit User'
            : this.$t('studentMsgs.editStudent')
          : this.$route.params.backTo
          ? 'Add User'
          : this.$t('studentMsgs.addStudent')
      "
      :breadcrumb="breadcrumb"
      :container-class="null"
      class="mb-32pt"
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <!-- User Details -->
      <page-separator :title="$t('studentMsgs.userInfo')" />

      <b-form-group :label="$t('schoolMsgs.school')" label-for="school" label-class="form-label">
        <v-select
          id="school"
          class="form-control v-select-custom"
          label="name"
          v-model="student.school"
          :reduce="school => school.id"
          :placeholder="$t('studentMsgs.schoolPlaceholder')"
          :options="allSchools"
          :loading="areSchoolsLoading"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
      </b-form-group>

      <b-form-group label="Organization" label-for="org" label-class="form-label">
        <v-select
          id="org"
          class="form-control v-select-custom"
          label="name"
          v-model="org"
          :reduce="org => org.id"
          placeholder="Select Organizations"
          :options="allOrgs"
          :loading="areOrgsLoading"
          multiple
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
      </b-form-group>

      <b-form-group
        :label="$t('userMsgs.avatar')"
        label-for="avatar"
        label-class="form-label"
        class="row-align-items-center"
      >
        <b-media class="align-items-center" vertical-align="center">
          <user-avatar
            slot="aside"
            rounded="lg"
            size="lg"
            :isLoading="isUploading.avatar"
            :user="user"
            url-field="avatarUrl"
            first-name-field="firstName"
            last-name-field="lastName"
          >
          </user-avatar>

          <image-uploader
            @image="e => setImage(e, 'avatar', 'images')"
            :isUploading="isUploading.avatar"
            :isRequired="false"
            :placeholder="$t('userMsgs.avatarDesc')"
          />
        </b-media>
      </b-form-group>

      <div class="row">
        <b-form-group :label="$t('userMsgs.firstName')" label-for="firstname" label-class="form-label" class="col-md-6">
          <b-form-input id="firstname" :placeholder="$t('userMsgs.firstName')" v-model="user.firstName" required />
        </b-form-group>

        <b-form-group :label="$t('userMsgs.lastName')" label-for="lastname" label-class="form-label" class="col-md-6">
          <b-form-input id="lastname" :placeholder="$t('userMsgs.lastName')" v-model="user.lastName" required />
        </b-form-group>
      </div>

      <b-form-group :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label">
        <b-form-input
          id="email"
          :placeholder="$t('authMsgs.emailAddr')"
          v-model="user.email"
          type="email"
          autocomplete="off"
          required
          :state="errorStates.userEmail"
        />
        <b-form-invalid-feedback>{{ $t('userMsgs.userAlreadyExists') }}</b-form-invalid-feedback>
        <b-form-checkbox id="sendEmail" v-model="sendEmail" class="mt-2" v-if="!$route.params.id">
          Send student an email about new account creation.
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        :label="$t('authMsgs.password')"
        label-for="password"
        label-class="form-label"
        v-if="!$route.params.id"
      >
        <b-alert variant="soft-primary px-12pt" show>
          <div class="d-flex align-items-center justify-content-between">
            <span class="text-black-100"
              >Auto-generated password: <strong>{{ user.password }}</strong></span
            >

            <span v-if="isPassCopied" class="text-success"><md-icon>check</md-icon> Copied</span>
            <a href="#" @click.prevent="copyPass" v-else v-b-popover.hover.top="'Copy Password'">
              <md-icon>content_copy</md-icon>
            </a>
          </div>
        </b-alert>
      </b-form-group>

      <div class="row" v-else>
        <b-form-group :label="$t('authMsgs.password')" label-for="npass" label-class="form-label" class="col-md-6">
          <b-form-input
            id="npass"
            name="password"
            :placeholder="$t('authMsgs.password')"
            v-model="user.password"
            :required="!!user.repeatPassword"
            :type="fieldTypes.password"
            autocomplete="off"
            @focus="handlePassType"
            @blur="handlePassType"
            :state="errorStates.password"
          />
          <b-form-invalid-feedback>{{ $t(`passValidationMsgs.${passErrorCode}`) }}</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('profileMsgs.repeatPassword')"
          label-for="repeatPassword"
          label-class="form-label"
          class="col-md-6"
        >
          <b-form-input
            id="repeatPassword"
            name="repeatPassword"
            :placeholder="$t('profileMsgs.repeatPassword')"
            v-model="user.repeatPassword"
            :required="!!user.password"
            :type="fieldTypes.repeatPassword"
            autocomplete="off"
            @focus="handlePassType"
            @blur="handlePassType"
            :state="errorStates.password"
          />
        </b-form-group>
      </div>

      <b-form-group :label="$t('studentMsgs.phone')" label-for="phone" label-class="form-label">
        <b-form-input
          id="phone"
          placeholder="(888) 689 - 1235"
          v-model="user.phone"
          v-mask="'(###) ### - ####'"
          :state="errorStates.phone"
        />
        <b-form-invalid-feedback>
          Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Gender" label-for="gender" label-class="form-label">
        <v-select
          id="gender"
          class="form-control v-select-custom"
          label="text"
          v-model="user.gender"
          :reduce="item => item.value"
          placeholder="Select Student's Gender"
          :options="genderOptions"
        >
        </v-select>
      </b-form-group>

      <b-form-group :label="$t('userMsgs.bio')" label-for="about" label-class="form-label">
        <b-form-textarea id="about" :placeholder="`${$t('studentMsgs.bioPlaceholder')}`" :rows="5" v-model="user.bio" />
      </b-form-group>

      <!-- Student Profile Info -->

      <page-separator :title="$t('studentMsgs.studentProfile')" />

      <b-form-group label="Profile Visibility" label-for="profile-vis" label-class="form-label">
        <b-form-select
          v-model="student.is_profile_visible"
          :options="profileVisOptions"
          id="profile-vis"
        ></b-form-select>
      </b-form-group>

      <b-form-group label="Enrolled Program" label-for="enrolled_program" label-class="form-label">
        <v-select
          id="enrolled_program"
          class="form-control v-select-custom"
          label="title"
          v-model="student.enrolled_program"
          :reduce="enrolled_program => enrolled_program.id"
          placeholder="Select Enrolled Program"
          :options="allPrograms"
          :loading="areProgramsLoading"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>
          <template slot="option" slot-scope="option">
            <span>{{ option.title }}</span>
          </template>
        </v-select>
      </b-form-group>
      <b-form-group label="Program Type" label-for="program_type" label-class="form-label">
        <v-select
          id="program_type"
          class="form-control v-select-custom"
          label="text"
          v-model="student.program_type"
          :reduce="p => p.value"
          placeholder="Select Program type"
          :options="programTypeOptions"
        >
        </v-select>
      </b-form-group>
      <b-form-group label="Approx Program Weeks" label-for="prog_weeks" label-class="form-label">
        <b-form-input
          id="prog_weeks"
          placeholder="Enter Approx Program Weeks"
          type="number"
          v-model="student.prog_weeks"
        />
      </b-form-group>

      <div class="row">
        <b-form-group label="Start Date" label-for="start_date" label-class="form-label" class="col-md-6">
          <date-picker
            v-model="student.start_date"
            type="date"
            format="MM/DD/YYYY"
            value-type="YYYY-MM-DD"
            style="width: 100%"
            lang="en"
            placeholder="MM/DD/YYYY"
            id="start_date"
          ></date-picker>
        </b-form-group>
        <b-form-group
          :label="$t('studentMsgs.gradDate')"
          label-for="gradDate"
          label-class="form-label"
          class="col-md-6"
        >
          <date-picker
            v-model="student.grad_date"
            type="month"
            format="MMMM, YYYY"
            style="width: 100%"
            value-type="date"
            id="grad_date"
            lang="en"
            placeholder="No Date Selected"
          ></date-picker>
        </b-form-group>
      </div>

      <b-form-group
        label="Do you have a High School Diploma/ GED?*"
        label-for="ged_or_diploma"
        label-class="form-label"
      >
        <b-form-radio-group
          id="ged_or_diploma"
          v-model="student.ged_or_diploma"
          :options="YES_NO_OPTIONS"
          required
        ></b-form-radio-group>
        <!-- :state="!$v.student.ged_or_diploma.required && $v.student.ged_or_diploma.$dirty ? false : null"
        <b-form-invalid-feedback
          :state="!$v.student.ged_or_diploma.required && $v.student.ged_or_diploma.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        > -->
      </b-form-group>

      <b-form-group :label="`Graduation Year`" label-for="gradDate" label-class="form-label">
        <date-picker
          v-model="student.diploma_date"
          type="year"
          format="YYYY"
          style="width: 100%"
          value-type="date"
          id="diploma_date"
          lang="en"
          placeholder="No Date Selected"
        ></date-picker>
      </b-form-group>

      <b-form-group label="Highest Educational Level" label-for="highest_edu_level" label-class="form-label">
        <v-select
          id="highest_edu_level"
          class="form-control v-select-custom"
          v-model="student.highest_edu_level"
          placeholder="Select Your Highest Educational Level"
          :options="highestEducationalLevelOptions"
        >
        </v-select>
      </b-form-group>

      <b-form-group
        v-if="student.highest_edu_level === 'Other (Please Specify)'"
        label="Other"
        label-for="highest_edu_level_other"
        label-class="form-label"
      >
        <b-form-input
          id="highest_edu_level_other"
          placeholder="Enter other"
          v-model="student.highest_edu_level_other"
          :state="
            !$v.student.highest_edu_level_other.required && $v.student.highest_edu_level_other.$dirty ? false : null
          "
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Current Educational Status" label-for="current_edu_level" label-class="form-label">
        <v-select
          id="current_edu_level"
          class="form-control v-select-custom"
          v-model="student.current_edu_level"
          placeholder="Select Your Current Educational Status"
          :options="currentEducationalStatusOptions"
        >
        </v-select>
      </b-form-group>

      <b-form-group
        v-if="student.current_edu_level === 'Other (Please Specify)'"
        label="Other"
        label-for="current_edu_level_other"
        label-class="form-label"
      >
        <b-form-input
          id="current_edu_level_other"
          placeholder="Enter other"
          v-model="student.current_edu_level_other"
          :state="
            !$v.student.current_edu_level_other.required && $v.student.current_edu_level_other.$dirty ? false : null
          "
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="CNA License Number" label-for="cnaLicenseNo" label-class="form-label">
        <b-form-input id="cnaLicenseNo" placeholder="Enter CMA License Number" v-model="student.cna_license_no" />
      </b-form-group>
      <b-form-group label="CMA License Number" label-for="cmaLicenseNo" label-class="form-label">
        <b-form-input id="cmaLicenseNo" placeholder="Enter CMA License Number" v-model="student.cma_license_no" />
      </b-form-group>

      <b-form-group :label="$t('studentMsgs.quote')" label-for="quote" label-class="form-label">
        <b-form-input
          id="quote"
          :placeholder="$t('studentMsgs.quotePlaceholder')"
          v-model="student.quote"
          maxlength="70"
        />
      </b-form-group>

      <!-- Work Schedule Fields -->
      <b-form-group label="Desired Work Type" label-for="desired_work_type" label-class="form-label">
        <v-select
          id="desired_work_type"
          class="form-control v-select-custom"
          label="text"
          v-model="student.desired_work_type"
          :reduce="item => item.value"
          placeholder="Select Desired Work Type"
          :options="workTypeOptions"
          multiple
        >
        </v-select>
      </b-form-group>

      <b-form-group label="Desired Work Time" label-for="desired_work_time" label-class="form-label">
        <v-select
          id="desired_work_time"
          class="form-control v-select-custom"
          label="text"
          v-model="student.desired_work_time"
          :reduce="item => item.value"
          placeholder="Select Desired Work Time"
          :options="workTimeOptions"
          multiple
        >
        </v-select>
      </b-form-group>

      <b-form-group label="Desired Work Days" label-for="desired_work_day" label-class="form-label">
        <v-select
          id="desired_work_day"
          class="form-control v-select-custom"
          label="text"
          v-model="student.desired_work_day"
          :reduce="item => item.value"
          placeholder="Select Desired Work Days"
          :options="workDayOptions"
          multiple
        >
        </v-select>
      </b-form-group>

      <b-form-group label="Work Travel" label-for="work_travel" label-class="form-label">
        <v-select
          id="work_travel"
          class="form-control v-select-custom"
          label="text"
          v-model="student.work_travel"
          :reduce="item => item.value"
          placeholder="Select preferable miles to travel."
          :options="workTravelOptions"
        >
        </v-select>
      </b-form-group>

      <b-form-group label="Interested Facility Type(s)" label-for="interested_facility_types" label-class="form-label">
        <v-select
          id="interested_facility_types"
          class="form-control v-select-custom"
          label="text"
          v-model="student.interested_facility_types"
          :reduce="item => item.value"
          placeholder="Select the facility types the student is interested in working at"
          :options="facilityTypeOptions"
          multiple
        >
        </v-select>
      </b-form-group>

      <b-form-group label="Career Preference(s)" label-for="career_preferences" label-class="form-label">
        <v-select
          id="career_preferences"
          class="form-control v-select-custom"
          label="name"
          v-model="student.career_preferences"
          :reduce="item => item.id"
          placeholder="Select the careers you prefer"
          :options="careerPreferences"
          :loading="areCareerPrefsLoading"
          multiple
        >
          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.photo_url" class="img-fluid" width="20" alt="Logo" v-if="option.photo_url" />
                <md-icon class="text-dark" v-else>local_hospital</md-icon>
              </fmv-avatar>
              <i>{{ option.name }}</i
              ><span class="ml-1">- (${{ $n(option.salary_range_from) }} - ${{ $n(option.salary_range_to) }})</span
              ><span class="ml-1">- {{ option.description }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
      </b-form-group>

      <b-form-group label="Language Preference(s)" label-for="lang_preferences" label-class="form-label">
        <v-select
          id="lang_preferences"
          class="form-control v-select-custom"
          label="name"
          v-model="student.lang_preferences"
          :reduce="item => item.value"
          placeholder="Select Preferred Language"
          :options="langOptions"
          multiple
        >
        </v-select>
      </b-form-group>

      <!-- Address Fields -->
      <b-form-group :label="$t('addressMsgs.address')" label-for="address" label-class="form-label">
        <b-form-input id="address" :placeholder="$t('addressMsgs.address')" v-model="student.address" />
      </b-form-group>

      <div class="row">
        <b-form-group :label="$t('addressMsgs.zipcode')" label-for="zipcode" label-class="form-label" class="col-md-6">
          <b-form-input id="zipcode" :placeholder="$t('addressMsgs.zipcode')" v-model="student.zipcode" />
        </b-form-group>

        <b-form-group :label="$t('addressMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
          <b-form-input id="city" :placeholder="$t('addressMsgs.city')" v-model="student.city" />
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group :label="$t('addressMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
          <v-select
            id="state"
            class="form-control v-select-custom"
            label="text"
            v-model="student.state"
            :reduce="state => state.value"
            placeholder="Select State"
            :options="stateOptions"
          >
          </v-select>
        </b-form-group>

        <b-form-group :label="$t('addressMsgs.country')" label-for="country" label-class="form-label" class="col-md-6">
          <v-select
            id="country"
            disabled
            class="form-control v-select-custom"
            label="text"
            v-model="student.country"
            :reduce="country => country.value"
            :placeholder="$t('addressMsgs.countryPlaceholder')"
            :options="countryOptions"
          >
          </v-select>
        </b-form-group>
      </div>

      <!-- Personal Identification Identifiers -->
      <page-separator title="Records" />

      <b-form-group label="State Id" label-for="state_id" label-class="form-label" class="row-align-items-center">
        <span v-if="!piiTypes.state_id">
          <a href="#" class="text-primary" @click.prevent="openAddModal('state_id', 'State Id')"
            ><i class="fas fa-file mr-2"></i>Add Document
          </a>
        </span>
        <span v-else-if="piiTypes.state_id">
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadFile({
                    fileUrl: piiTypes.state_id,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(piiTypes.state_id, true) }}
              </a>
              <span v-if="expiresAt.state_id"> (Expires on: {{ formatDateSimple(expiresAt.state_id) }})</span>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removePiiFile('state_id', 'state_id')"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
      </b-form-group>

      <b-form-group
        label="Social Security Card"
        label-for="social_sec_card"
        label-class="form-label"
        class="row-align-items-center"
      >
        <span v-if="!piiTypes.social_sec_card">
          <a href="#" class="text-primary" @click.prevent="openAddModal('social_sec_card', 'Social Security Card')"
            ><i class="fas fa-file mr-2"></i>Add Document
          </a>
        </span>
        <span v-else-if="piiTypes.social_sec_card">
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadFile({
                    fileUrl: piiTypes.social_sec_card,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(piiTypes.social_sec_card, true) }}
              </a>
              <span v-if="expiresAt.social_sec_card">
                (Expires on: {{ formatDateSimple(expiresAt.social_sec_card) }})</span
              >
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removePiiFile('social_sec_card', 'social_sec_card')"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
      </b-form-group>
      <b-form-group label="Green Card" label-for="green_card" label-class="form-label" class="row-align-items-center">
        <span v-if="!piiTypes.green_card">
          <a href="#" class="text-primary" @click.prevent="openAddModal('green_card', 'Green Card')"
            ><i class="fas fa-file mr-2"></i>Add Document
          </a>
        </span>
        <span v-else-if="piiTypes.green_card">
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadFile({
                    fileUrl: piiTypes.green_card,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(piiTypes.green_card, true) }}
              </a>
              <span v-if="expiresAt.green_card"> (Expires on: {{ formatDateSimple(expiresAt.green_card) }})</span>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removePiiFile('green_card', 'green_card')"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
      </b-form-group>
      <b-form-group label="Passport" label-for="passport" label-class="form-label" class="row-align-items-center">
        <span v-if="!piiTypes.passport">
          <a href="#" class="text-primary" @click.prevent="openAddModal('passport', 'Passport')"
            ><i class="fas fa-file mr-2"></i>Add Document
          </a>
        </span>
        <span v-else-if="piiTypes.passport">
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadFile({
                    fileUrl: piiTypes.passport,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(piiTypes.passport, true) }}
              </a>
              <span v-if="expiresAt.passport"> (Expires on: {{ formatDateSimple(expiresAt.passport) }})</span>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removePiiFile('passport', 'passport')"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
      </b-form-group>

      <b-form-group
        label="Birth Certificate"
        label-for="birth_cert"
        label-class="form-label"
        class="row-align-items-center"
      >
        <span v-if="!piiTypes.birth_cert">
          <a href="#" class="text-primary" @click.prevent="openAddModal('birth_cert', 'Birth Certificate')"
            ><i class="fas fa-file mr-2"></i>Add Document
          </a>
        </span>
        <span v-else-if="piiTypes.birth_cert">
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadFile({
                    fileUrl: piiTypes.birth_cert,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(piiTypes.birth_cert, true) }}
              </a>
              <span v-if="expiresAt.birth_cert"> (Expires on: {{ formatDateSimple(expiresAt.birth_cert) }})</span>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removePiiFile('birth_cert', 'birth_cert')"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
      </b-form-group>

      <b-form-group
        label="Immunization"
        label-for="immunization"
        label-class="form-label"
        class="row-align-items-center"
      >
        <span v-if="!piiTypes.immunization">
          <a href="#" class="text-primary" @click.prevent="openAddModal('immunization', 'Immunization')"
            ><i class="fas fa-file mr-2"></i>Add Document
          </a>
        </span>
        <span v-else-if="piiTypes.immunization">
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadFile({
                    fileUrl: piiTypes.immunization,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(piiTypes.immunization, true) }}
              </a>
              <span v-if="expiresAt.immunization"> (Expires on: {{ formatDateSimple(expiresAt.immunization) }})</span>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removePiiFile('immunization', 'immunization')"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
      </b-form-group>
      <b-form-group label="TB Test" label-for="tb_test" label-class="form-label" class="row-align-items-center">
        <span v-if="!piiTypes.tb_test">
          <a href="#" class="text-primary" @click.prevent="openAddModal('tb_test', 'TB Test')"
            ><i class="fas fa-file mr-2"></i>Add Document
          </a>
        </span>
        <span v-else-if="piiTypes.tb_test">
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadFile({
                    fileUrl: piiTypes.tb_test,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(piiTypes.tb_test, true) }}
              </a>
              <span v-if="expiresAt.tb_test"> (Expires on: {{ formatDateSimple(expiresAt.tb_test) }})</span>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removePiiFile('tb_test', 'tb_test')"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
      </b-form-group>

      <b-form-group
        label="COVID-19 Vaccination"
        label-for="covid_vacc"
        label-class="form-label"
        class="row-align-items-center"
      >
        <span v-if="!piiTypes.covid_vacc">
          <a href="#" class="text-primary" @click.prevent="openAddModal('covid_vacc', 'COVID-19 Vaccination')"
            ><i class="fas fa-file mr-2"></i>Add Document
          </a>
        </span>
        <span v-else-if="piiTypes.covid_vacc">
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadFile({
                    fileUrl: piiTypes.covid_vacc,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(piiTypes.covid_vacc, true) }}
              </a>
              <span v-if="expiresAt.covid_vacc"> (Expires on: {{ formatDateSimple(expiresAt.covid_vacc) }})</span>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removePiiFile('covid_vacc', 'covid_vacc')"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
      </b-form-group>

      <b-form-group
        label="Vehicle Registration"
        label-for="vehicle_reg"
        label-class="form-label"
        class="row-align-items-center"
      >
        <span v-if="!piiTypes.vehicle_reg">
          <a href="#" class="text-primary" @click.prevent="openAddModal('vehicle_reg', 'Vehicle Registration')"
            ><i class="fas fa-file mr-2"></i>Add Document
          </a>
        </span>
        <span v-else-if="piiTypes.vehicle_reg">
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadFile({
                    fileUrl: piiTypes.vehicle_reg,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(piiTypes.vehicle_reg, true) }}
              </a>
              <span v-if="expiresAt.vehicle_reg"> (Expires on: {{ formatDateSimple(expiresAt.vehicle_reg) }})</span>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removePiiFile('vehicle_reg', 'vehicle_reg')"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
      </b-form-group>

      <page-separator title="Credentials" />

      <b-form-group label="CPR Licence" label-for="cpr" label-class="form-label" class="row-align-items-center">
        <span v-if="!piiTypes.cpr">
          <a href="#" class="text-primary" @click.prevent="openAddModal('cpr', 'CPR Licence')"
            ><i class="fas fa-file mr-2"></i>Add Document
          </a>
        </span>
        <span v-else-if="piiTypes.cpr">
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadFile({
                    fileUrl: piiTypes.cpr,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(piiTypes.cpr, true) }}
              </a>
              <span v-if="expiresAt.cpr"> (Expires on: {{ formatDateSimple(expiresAt.cpr) }})</span>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removePiiFile('cpr', 'cpr')"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
      </b-form-group>
      <b-form-group
        label="School Waiver"
        label-for="school_waiver"
        label-class="form-label"
        class="row-align-items-center"
      >
        <span v-if="!piiTypes.school_waiver">
          <a href="#" class="text-primary" @click.prevent="openAddModal('school_waiver', 'School Waiver')"
            ><i class="fas fa-file mr-2"></i>Add Document
          </a>
        </span>
        <span v-else-if="piiTypes.school_waiver">
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadFile({
                    fileUrl: piiTypes.school_waiver,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(piiTypes.school_waiver, true) }}
              </a>
              <span v-if="expiresAt.school_waiver"> (Expires on: {{ formatDateSimple(expiresAt.school_waiver) }})</span>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removePiiFile('school_waiver', 'school_waiver')"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
      </b-form-group>
      <b-form-group
        label="Basic Life Support (BLS) Certification"
        label-for="bls"
        label-class="form-label"
        class="row-align-items-center"
      >
        <span v-if="!piiTypes.bls">
          <a
            href="#"
            class="text-primary"
            @click.prevent="openAddModal('bls', 'Basic Life Support (BLS) Certification')"
            ><i class="fas fa-file mr-2"></i>Add Document
          </a>
        </span>
        <span v-else-if="piiTypes.bls">
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadFile({
                    fileUrl: piiTypes.bls,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(piiTypes.bls, true) }}
              </a>
              <span v-if="expiresAt.bls"> (Expires on: {{ formatDateSimple(expiresAt.bls) }})</span>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removePiiFile('bls', 'bls')"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
      </b-form-group>
      <b-form-group
        label="Certification of Completion"
        label-for="completion_cert"
        label-class="form-label"
        class="row-align-items-center"
      >
        <span v-if="!piiTypes.completion_cert">
          <a
            href="#"
            class="text-primary"
            @click.prevent="openAddModal('completion_cert', 'Certification of Completion')"
            ><i class="fas fa-file mr-2"></i>Add Document
          </a>
        </span>
        <span v-else-if="piiTypes.completion_cert">
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadFile({
                    fileUrl: piiTypes.completion_cert,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(piiTypes.completion_cert, true) }}
              </a>
              <span v-if="expiresAt.completion_cert">
                (Expires on: {{ formatDateSimple(expiresAt.completion_cert) }})</span
              >
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removePiiFile('completion_cert', 'completion_cert')"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
      </b-form-group>
      <b-form-group
        label="High School Diploma / GED"
        label-for="ged"
        label-class="form-label"
        class="row-align-items-center"
      >
        <span v-if="!piiTypes.ged">
          <a href="#" class="text-primary" @click.prevent="openAddModal('ged', 'High School Diploma / GED')"
            ><i class="fas fa-file mr-2"></i>Add Document
          </a>
        </span>
        <span v-else-if="piiTypes.ged">
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadFile({
                    fileUrl: piiTypes.ged,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(piiTypes.ged, true) }}
              </a>
              <span v-if="expiresAt.ged"> (Expires on: {{ formatDateSimple(expiresAt.ged) }})</span>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removePiiFile('ged', 'ged')"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
      </b-form-group>

      <b-form-group
        label="First Aid Certification"
        label-for="first_aid"
        label-class="form-label"
        class="row-align-items-center"
      >
        <span v-if="!piiTypes.first_aid">
          <a href="#" class="text-primary" @click.prevent="openAddModal('first_aid', 'First Aid Certification')"
            ><i class="fas fa-file mr-2"></i>Add Document
          </a>
        </span>
        <span v-else-if="piiTypes.first_aid">
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadFile({
                    fileUrl: piiTypes.first_aid,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(piiTypes.first_aid, true) }}
              </a>
              <span v-if="expiresAt.first_aid"> (Expires on: {{ formatDateSimple(expiresAt.first_aid) }})</span>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removePiiFile('first_aid', 'first_aid')"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
      </b-form-group>

      <page-separator title="Experience" />

      <b-form-group label="Resume" label-for="resume" label-class="form-label" class="row-align-items-center">
        <b-media class="align-items-center" vertical-align="center">
          <b-form-file
            id="resume"
            placeholder="Select Resume File"
            :browse-text="$t('generalMsgs.browse')"
            v-model="selectedUploadFile.resume"
            @input="uploadFile('resume', 'documents', 'resume_url')"
            :disabled="isUploading.resume"
            accept=".pdf, .docx, .doc"
          />
        </b-media>
      </b-form-group>

      <b-form-group label="Work Experience" label-for="workExp" label-class="form-label">
        <v-select
          id="workExp"
          class="form-control v-select-custom"
          label="text"
          v-model="student.work_experience"
          :reduce="exp => exp.value"
          placeholder="Select Work Experience"
          :options="workExpOptions"
        >
        </v-select>
      </b-form-group>

      <b-form-group label="Current Employer" label-for="curr_employer" label-class="form-label">
        <b-form-input
          id="curr_employer"
          placeholder="Enter current employer's name (if any)."
          v-model="student.curr_employer"
        />
      </b-form-group>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>

    <add-pii-modal
      :show-modal="showDocModal"
      :title="addDocTitle"
      :type="addDocType"
      @add="piiDocAdded"
      @close="hideModal"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { countries } from 'countries-list';
import { requiredIf } from 'vuelidate/lib/validators';
import { get, map, pick } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { FmvAvatar } from 'fmv-avatar';
import moment from 'moment';
import generator from 'generate-password';
import Page from '../../../components/Page.vue';
import {
  PASSWORD_VALIDATIONS,
  PASSWORD_ALL_NUMERIC_REGEX,
  PHONE_FORMAT_REGEX,
  PII_TYPES,
  PROGRAM_TYPE_OPTIONS,
} from '../../../common/constants';
import { getLangOptions, formatToAPIDate, parseFileNameFromUrl, formatDateSimple } from '../../../common/utils';
import DatePicker from 'vue2-datepicker';
import AddPiiModal from './AddPiiModal.vue';
import UserAvatar from '../../../components/User/UserAvatar.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
var UsaStates = require('usa-states').UsaStates;
import 'vue2-datepicker/locale/en';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: { PageHeader, PageSeparator, FmvAvatar, UserAvatar, MdIcon, DatePicker, AddPiiModal, ImageUploader },
  extends: Page,

  data() {
    return {
      title: this.$route.params.id
        ? this.$route.params.backTo
          ? 'Edit User'
          : this.$t('studentMsgs.editStudent')
        : this.$route.params.backTo
        ? 'Add User'
        : this.$t('studentMsgs.addStudent'),
      PII_TYPES,
      showDocModal: false,
      addDocType: '',
      addDocTitle: '',
      documentAdded: false,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        repeatPassword: '',
        phone: '',
        bio: '',
        avatarUrl: '',
        gender: '',
      },
      student: {
        school: null,
        grad_date: null,
        is_profile_visible: true,
        prog_weeks: 0,
        cna_license_no: '',
        work_experience: '',
        curr_employer: '',
        quote: '',
        start_date: null,
        cma_license_no: '',
        desired_work_type: [],
        desired_work_time: [],
        desired_work_day: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
        work_travel: '',
        interested_facility_types: [],
        career_preferences: [],
        lang_preferences: [],
        diploma_date: '',
        ged_or_diploma: null,
        highest_edu_level: null,
        highest_edu_level_other: null,
        current_edu_level: null,
        current_edu_level_other: null,

        resume_url: '',
        cpr_url: '',
        bls_url: '',
        vehicle_url: '',

        first_aid_url: '',

        address: '',
        zipcode: '',
        city: '',
        state: '',
        country: 'United States',
        enrolled_program: '',
        program_type: '',
      },
      sendEmail: true,

      fieldTypes: { password: 'text', repeatPassword: 'text' },
      student_piis: [],
      allSchools: [],
      areOrgsLoading: false,
      allOrgs: [],
      org: [],
      allPrograms: [],
      countryOptions: [],
      stateOptions: [],
      workExpOptions: [
        { value: '0-1', text: '0-1 year' },
        { value: '1-2', text: '1-2 years' },
        { value: '3-4', text: '3-4 years' },
        { value: '5-6', text: '5-6 years' },
        { value: '7+', text: '7+ years' },
      ],
      workDayOptions: [],
      programTypeOptions: PROGRAM_TYPE_OPTIONS,
      workTypeOptions: [
        { value: 'part_time', text: 'Part-time' },
        { value: 'full_time', text: 'Full-time' },
        { value: 'not_sure', text: 'Not sure' },
      ],
      workTimeOptions: [
        { value: 'day', text: 'Day' },
        { value: 'evening', text: 'Evening' },
        { value: 'overnight', text: 'Overnight' },
      ],
      workTravelOptions: [],
      facilityTypeOptions: [
        { value: 'adult_day_care', text: 'Adult day care' },
        { value: 'assisted_living', text: 'Assisted living' },
        { value: 'hospital', text: 'Hospital' },
        { value: 'hospice_care', text: 'Hospice care' },
        { value: 'home_care', text: 'Home care' },
        { value: 'longterm_care', text: 'Long-term care' },
        { value: 'other', text: 'Other' },
      ],
      careerPreferences: [],
      profileVisOptions: [
        { value: true, text: 'Active' },
        { value: false, text: 'Paused' },
      ],
      langOptions: [],
      genderOptions: [
        { value: 'male', text: 'Male' },
        { value: 'female', text: 'Female' },
        { value: 'other', text: 'Other' },
      ],

      errorStates: {
        password: null,
        userEmail: null,
        // cnaLicenseNo: null,
        // cmaLicenseNo: null,
        phone: null,
      },
      passErrorCode: null,

      isPassCopied: false,
      isLoading: false,
      areSchoolsLoading: false,
      isLogoUploading: false,
      areCareerPrefsLoading: false,
      piiTypes: {
        tb_test: null,
        covid_vacc: null,
        social_sec_card: null,
        state_id: null,
        cpr: null,
        bls: null,
        first_aid: null,
        vehicle_reg: null,
        green_card: null,
        passport: null,
        completion_cert: null,
        birth_cert: null,
        school_waiver: null,
        ged: null,
        immunization: null,
      },
      expiresAt: {
        tb_test: null,
        covid_vacc: null,
        social_sec_card: null,
        state_id: null,
        cpr: null,
        bls: null,
        first_aid: null,
        vehicle_reg: null,
        green_card: null,
        passport: null,
        completion_cert: null,
        birth_cert: null,
        school_waiver: null,
        ged: null,
        immunization: null,
      },
      isUploading: {
        avatar: false,
        resume: false,
        cpr: false,
        bls: false,
        vehicle: false,
        tb_test: false,
        firstAid: false,
        covid_vacc: false,
        state_id: false,
        social_sec_card: false,
        green_card: null,
        passport: null,
        completion_cert: null,
        birth_cert: null,
        school_waiver: null,
        ged: null,
        immunization: null,
      },
      selectedUploadFile: {
        state_id: null,
        avatar: null,
        resume: null,
        cpr: null,
        bls: null,
        vehicle: null,
        tb_test: null,
        firstAid: null,
        covid_vacc: null,
        social_sec_card: null,
        green_card: null,
        passport: null,
        completion_cert: null,
        birth_cert: null,
        school_waiver: null,
        ged: null,
        immunization: null,
      },
      uploadPercent: {
        avatar: 0,
        resume: 0,
        cpr: 0,
        bls: 0,
        vehicle: 0,
        tb_test: 0,
        firstAid: 0,
        covid_vacc: 0,
        state_id: 0,
        social_sec_card: 0,
        green_card: null,
        passport: null,
        completion_cert: null,
        birth_cert: null,
        school_waiver: null,
        ged: null,
        immunization: null,
      },
      uploadCancelTokenSource: {
        avatar: null,
        resume: null,
        cpr: null,
        bls: null,
        vehicle: null,
        tb_test: null,
        firstAid: null,
        covid_vacc: null,
        state_id: null,
        social_sec_card: null,
        green_card: null,
        passport: null,
        completion_cert: null,
        birth_cert: null,
        school_waiver: null,
        ged: null,
        immunization: null,
      },
      areProgramsLoading: false,
      YES_NO_OPTIONS: [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ],
      highestEducationalLevelOptions: [
        'High School Diploma or GED',
        'Trade/Vocational Certificate',
        'Associate Degree',
        'Bachelor’s Degree',
        'Master’s Degree',
        'Doctoral Degree',
        'Professional Degree (e.g., MD, JD, PharmD)',
        'Completed Certification Program',
        'Other (Please Specify)',
      ],

      currentEducationalStatusOptions: [
        'High School Student',
        'Trade/Vocational School Student',
        'Community College Student',
        'Undergraduate Student',
        'Graduate Student',
        'Professional School Student',
        'Certification Program Student',
        'Postdoctoral Researcher',
        'Continuing Education Student',
        'Not Currently Enrolled in Any Educational Program',
        'Other (Please Specify)',
      ],
    };
  },
  validations() {
    return {
      piiTypes: {
        state_id: {},
        social_sec_card: {},
        tb_test: {},
        covid_vacc: {},
        cpr: {},
        bls: {},
        first_aid: {},
        vehicle_reg: {},
      },
      student: {
        highest_edu_level_other: {
          required: requiredIf(() => this.student.highest_edu_level === 'Other (Please Specify)'),
        },
        current_edu_level_other: {
          required: requiredIf(() => this.student.current_edu_level === 'Other (Please Specify)'),
        },
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        {
          text: this.$route.params.backTo ? 'Users' : 'Students',
          to: { name: this.$route.params.backTo ? 'manage-users' : 'manage-students' },
        },
        {
          text: this.$route.params.id
            ? this.$route.params.backTo
              ? 'Edit User'
              : this.$t('studentMsgs.editStudent')
            : this.$route.params.backTo
            ? 'Add User'
            : this.$t('studentMsgs.addStudent'),
          active: true,
        },
      ];
    },
    isFormLoading() {
      return (
        this.isLoading ||
        this.isLogoUploading ||
        this.areSchoolsLoading ||
        Object.values(this.isUploading).some(val => val)
      );
    },
  },
  watch: {
    'student.school': {
      handler() {
        this.fetchPrograms();
      },
      deep: true,
      immediate: false,
    },
  },

  methods: {
    ...mapActions('student', ['getStudent', 'createStudent', 'updateStudent', 'getStudentUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('school', ['getAllSchools']),
    ...mapActions('careerPreference', ['getAllCareerPreferences']),
    ...mapActions('program', ['getAllPrograms']),
    ...mapActions('fileDownload', ['downloadFile']),
    ...mapActions('organization', ['getAllOrganizations']),
    parseFileNameFromUrl,
    formatDateSimple,

    setImage(file, fileType, uploadType = 'documents', profileUrlField = 'resume_url') {
      this.selectedUploadFile[fileType] = file;
      this.uploadFile(fileType, uploadType, profileUrlField);
    },

    openAddModal(docType, title) {
      this.showDocModal = true;
      this.addDocType = docType;
      this.addDocTitle = title;
    },
    piiDocAdded(file, url, type, date) {
      this.showDocModal = false;
      this.documentAdded = true;
      this.selectedUploadFile[type] = file;
      this.piiTypes[type] = url;
      this.expiresAt[type] = date;
      this.addDocType = '';
      this.addDocTitle = '';
    },
    hideModal() {
      this.showDocModal = false;
      this.addDocType = '';
      this.addDocTitle = '';
    },
    removePiiFile(fileType) {
      this.selectedUploadFile[fileType] = null;
      this.piiTypes[fileType] = null;
      this.expiresAt[fileType] = null;
    },
    removeFile(fileType, profileUrlField = 'resume_url') {
      this.selectedUploadFile[fileType] = null;
      if (PII_TYPES.includes(profileUrlField)) {
        this.piiTypes[fileType] = null;
      } else this.student[profileUrlField] = null;
    },
    async fetchPrograms() {
      this.areProgramsLoading = true;
      const response = await this.getAllPrograms({ school: this.student.school, ordering: 'id' });
      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },

    async onSubmit() {
      this.isLoading = true;

      let dashboards = [];
      if (this.student.school) {
        dashboards.push({
          linked_entity_id: this.student.school,
          type: 'school',
        });
      }

      this.org.map(org =>
        dashboards.push({
          linked_entity_id: org,
          type: 'organization',
        }),
      );

      // this.$v.$touch();
      // if (!this.$v.$invalid) {
      this.errorStates.password = this.errorStates.userEmail = this.errorStates.phone = null;
      if (this.user.phone && !PHONE_FORMAT_REGEX.test(this.user.phone)) {
        this.errorStates.phone = false;
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
      } else if (this.user.password !== this.user.repeatPassword) {
        this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_NOT_MATCH;
        this.errorStates.password = false;
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
      } else if (this.user.password && this.user.password.length < 5) {
        this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_MIN_LENGTH;
        this.errorStates.password = false;
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
      } else if (this.user.password && PASSWORD_ALL_NUMERIC_REGEX.test(this.user.password)) {
        this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_ALL_NUMERIC;
        this.errorStates.password = false;
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
      } else {
        try {
          let piiArr = [];

          for (const item in this.piiTypes) {
            if (this.piiTypes[item]) {
              piiArr.push({
                type: item,
                document_url: this.piiTypes[item],
                expire_at: this.expiresAt[item] ? formatToAPIDate(this.expiresAt[item]) : null,
              });
            }
          }
          this.student.diploma_date = this.student.diploma_date
            ? formatToAPIDate(moment(this.student.diploma_date))
            : null;
          this.student.grad_date = this.student.grad_date ? formatToAPIDate(moment(this.student.grad_date)) : null;
          this.student.start_date = this.student.start_date ? formatToAPIDate(moment(this.student.start_date)) : null;
          const data = {
            ...this.student,
            user_dashboards: dashboards,
            student_piis: piiArr,
            ...(!this.$route.params.id && { send_email: this.sendEmail }),
            user: {
              email: this.user.email,
              first_name: this.user.firstName,
              last_name: this.user.lastName,
              ...(this.user.password && { password: this.user.password }),
              phone: this.user.phone,
              bio: this.user.bio,
              avatar_url: this.user.avatarUrl,
              gender: this.user.gender,
            },
          };
          if (this.$route.params.id) {
            await this.updateStudent({
              id: this.$route.params.id,
              data,
            });
            this.makeToast({ variant: 'success', msg: this.$t('studentMsgs.studentProfileUpdated'), data });
          } else {
            await this.createStudent(data);
            this.makeToast({ variant: 'success', msg: this.$t('studentMsgs.studentAdded') });
          }
          setTimeout(() => this.$router.push({ name: 'manage-students' }), 250);
        } catch (err) {
          if (get(err, 'response.data.user')) {
            this.errorStates.userEmail = false;
          }
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }
      // } else {
      //   this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      // }

      this.isLoading = false;
    },

    async uploadFile(fileType, uploadType = 'documents', profileUrlField = 'resume_url') {
      this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();
      this.isUploading[fileType] = true;

      try {
        const urlResp = await this.getStudentUploadPresignedUrl({
          file_name: this.selectedUploadFile[fileType].name,
          content_type: this.selectedUploadFile[fileType].type,
          upload_type: uploadType,
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile[fileType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[fileType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token,
          },
        });
        if (fileType === 'avatar') this.user.avatarUrl = urlResp.upload_url.split('?')[0];
        else if (PII_TYPES.includes(fileType)) {
          this.piiTypes[fileType] = urlResp.upload_url.split('?')[0];
        } else this.student[profileUrlField] = urlResp.upload_url.split('?')[0];
      } catch (error) {
        this.selectedUploadFile[fileType] = null;
      }

      this.uploadCancelTokenSource[fileType] = null;
      this.uploadPercent[fileType] = 0;

      this.isUploading[fileType] = false;
    },

    async fetchSchools() {
      this.areSchoolsLoading = true;

      const response = await this.getAllSchools({});
      this.allSchools = response.data;

      this.areSchoolsLoading = false;
    },

    async fetchOrgs() {
      this.areOrgsLoading = true;
      const response = await this.getAllOrganizations({});
      this.allOrgs = response.data;

      this.areOrgsLoading = false;
    },

    handlePassType(event) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if (type === 'blur' && !value) {
        this.fieldTypes[name] = 'text';
      } else {
        this.fieldTypes[name] = 'password';
      }
    },

    async copyPass() {
      try {
        await navigator.clipboard.writeText(this.user.password);
        this.isPassCopied = true;
        setTimeout(() => (this.isPassCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },

    fillInOptions() {
      var usStates = new UsaStates();
      this.stateOptions = this.stateOptions.concat(
        map(usStates.states, state => ({
          value: state.name,
          text: state.name,
        })),
      );
      this.countryOptions = this.countryOptions.concat(
        map(countries, country => ({
          value: country.name,
          text: country.name,
        })),
      );

      this.workDayOptions = map(moment.weekdays(), day => ({ value: day.toLowerCase(), text: day }));

      this.workTravelOptions = map([...Array(9).keys()], num => ({
        value: `${(num + 1) * 10}`,
        text: `${(num + 1) * 10} miles`,
      }));
      this.workTravelOptions.push({ value: '100+', text: '100+ miles' });

      this.langOptions = getLangOptions();
    },

    async fetchCareerPreferences() {
      this.areCareerPrefsLoading = true;

      const response = await this.getAllCareerPreferences({});
      this.careerPreferences = response.data;

      this.areCareerPrefsLoading = false;
    },
  },

  async mounted() {
    this.isLoading = true;

    this.fetchSchools();
    this.fetchOrgs();
    this.fillInOptions();
    this.fetchCareerPreferences();

    // Auto-generate password.
    if (!this.$route.params.id) {
      this.user.password = this.user.repeatPassword = generator.generate({ length: 10, numbers: true, strict: true });
    }

    try {
      if (this.$route.params.id) {
        const resp = (await this.getStudent(this.$route.params.id)).data;

        this.student = {
          ...pick(resp, [
            'cna_license_no',
            'work_experience',
            'curr_employer',
            'quote',
            'desired_work_type',
            'desired_work_time',
            'desired_work_day',
            'work_travel',
            'interested_facility_types',
            'career_preferences',
            'lang_preferences',
            'resume_url',
            'cpr_url',
            'bls_url',
            'vehicle_url',
            'cma_license_no',
            'address',
            'zipcode',
            'city',
            'state',
            'country',
            'is_profile_visible',
            'start_date',
            'prog_weeks',
            'program_type',
            'highest_edu_level',
            'highest_edu_level_other',
            'current_edu_level',
            'current_edu_level_other',
          ]),
          //start_date: resp.start_date ? new Date(resp.start_date) : null,
          enrolled_program: get(resp, 'enrolled_program.id', null),
          grad_date: resp.grad_date ? new Date(resp.grad_date) : null,
          diploma_date: resp.diploma_date ? new Date(resp.diploma_date) : null,
          ged_or_diploma: resp.ged_or_diploma,
        };
        this.student_piis = resp.student_piis;
        this.student_piis.forEach(pii => {
          this.piiTypes[pii.type] = pii.document_url;
        });
        this.student_piis.forEach(pii => {
          this.piiTypes[pii.type] = pii.document_url;
          this.expiresAt[pii.type] = pii.expire_at;
        });

        this.user.firstName = resp.user.first_name;
        this.user.lastName = resp.user.last_name;
        this.user.email = resp.user.email;
        this.user.phone = resp.user.phone;
        this.user.bio = resp.user.bio;
        this.user.avatarUrl = resp.user.avatar_url;
        this.user.gender = resp.user.gender;

        this.student.school = (resp.user_dashboards || []).find(
          dashboard => dashboard.type === 'school',
        )?.linked_entity_id;
        const orgDashboards = (resp.user_dashboards || []).filter(dashboard => dashboard.type === 'organization');
        this.org = (orgDashboards || []).map(dashboard => dashboard.linked_entity_id);
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({ name: 'manage-students' });
    }

    this.isLoading = false;
  },
};
</script>
