<template>
  <div :class="containerClass">
    <page-header
      title="Configure Pages Settings"
      :container-class="null"
      class="mb-16pt"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION
          ? `Configure different pages of your site by customizing information displayed on banner.`
          : ''
      "
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group
        label="Organization"
        label-for="org"
        label-class="form-label"
        v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
      >
        <v-select
          id="org"
          class="form-control v-select-custom"
          label="name"
          v-model="org"
          :reduce="(org) => org.id"
          placeholder="Select Organization"
          :options="allOrgs"
          :loading="areOrgsLoading"
          @input="prefillLogo(org)"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!org" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.org.required && $v.org.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <!-- Top Banner -->

      <!-- Section Two -->

      <div v-for="(pg, index) in $v.pages_config.$each.$iter" :key="index" class="mb-2">
        <page-separator>
          <b-link v-b-toggle="pg.$model.abbr" class="accordion__toggle p-0">
            <span class="flex">{{ headings[pg.$model.abbr] }}</span>
            <md-icon class="accordion__toggle-icon">
              {{ pg.isVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
            </md-icon>
          </b-link>
        </page-separator>

        <b-collapse
          :id="pg.$model.abbr"
          :accordion="`${pg.abbr}-Id`"
          :visible="pg.isVisible"
          class="accordion__menu"
          @hide="pg.isVisible = false"
          @show="pg.isVisible = true"
        >
          <b-form-group
            label="Image"
            label-for="image_url"
            label-class="form-label"
            class="row-align-items-center col-md-12"
          >
            <b-media class="align-items-center" vertical-align="center">
              <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
                <span v-if="isImgUploading[pg.$model.abbr]">...</span>
                <b-img :src="pg.img.$model" class="img-fluid" width="40" alt="Image" v-else-if="pg.img.$model" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>

              <image-uploader
                @image="(e) => setFeaturesImage(e, pg.$model.abbr, index)"
                :isUploading="isImgUploading[pg.$model.abbr]"
                :isRequired="false"
                placeholder="Select Image"
              />
            </b-media>
          </b-form-group>

          <b-form-group label="Title" label-for="title" label-class="form-label" class="col-md-12">
            <b-form-input id="title" placeholder="Title" v-model.trim="pg.title.$model" />

            <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Description" label-for="description" label-class="form-label" class="col-md-12">
            <b-form-textarea
              id="description"
              :rows="5"
              placeholder="Enter description "
              v-model.trim="pg.$model.desc"
            />
          </b-form-group>

          <div v-if="pg.$model.abbr === 'about'">
            <AboutPage ref="aboutForm" :aboutData="pg.$model" />
          </div>
        </b-collapse>
      </div>
      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>Update</span>
      </b-btn>
    </b-form>
  </div>
</template>

<style lang="css" scoped></style>

<script>
import { get, isEmpty } from 'lodash';
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import Page from '@/components/Page.vue';
import { USER_ROLES, USER_ROLE_TYPES } from '@/common/constants';
import { required, requiredIf } from 'vuelidate/lib/validators';
import PageHeader from '@/components/Ui/PageHeader.vue';
import ImageUploader from '@/components/ImageUploader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import AboutPage from './AboutPage.vue';

export default {
  components: {
    FmvAvatar,
    PageHeader,
    ImageUploader,
    PageSeparator,
    AboutPage,
  },
  extends: Page,
  data() {
    return {
      org: null,
      pages_config: [
        {
          abbr: 'all_jobs',
          title: '',
          desc: '',
          img: '',
          isVisible: true,
        },
        {
          abbr: 'all_employers',
          title: '',
          desc: '',
          img: '',
          isVisible: true,
        },
        {
          abbr: 'all_sponsorships',
          title: '',
          desc: '',
          img: '',
          isVisible: true,
        },
        {
          abbr: 'all_programs',
          title: '',
          desc: '',
          img: '',
          isVisible: true,
        },
        {
          abbr: 'training_providers',
          title: '',
          desc: '',
          img: '',
          isVisible: true,
        },
        {
          abbr: 'upcoming_classes',
          title: '',
          desc: '',
          img: '',
          isVisible: true,
        },
        {
          abbr: 'simulations',
          title: '',
          desc: '',
          img: '',
          isVisible: true,
        },
        {
          abbr: 'about',
          title: '',
          desc: '',
          img: '',
          isVisible: true,
        },
        {
          abbr: 'events',
          title: '',
          desc: '',
          img: '',
          isVisible: true,
        },
        {
          abbr: 'advisors',
          title: '',
          desc: '',
          img: '',
          isVisible: true,
        },
      ],
      headings: {
        all_jobs: 'Jobs',
        all_employers: 'Employers',
        all_sponsorships: 'Sponsorships',
        all_programs: 'Programs',
        training_providers: 'Training Providers',
        upcoming_classes: 'Upcoming Classes',
        simulations: 'Simulations',
        about: 'About',
        events: 'Events',
        advisors: 'Advisors',
      },
      title: 'Configure Pages',

      areOrgsLoading: false,
      allOrgs: [],
      isLoading: false,
      isImgUploading: {
        training_providers: false,
        all_jobs: false,
        all_employers: false,
        all_sponsorships: false,
        all_programs: false,
      },
      uploadFile: {
        training_providers: null,
        all_jobs: null,
        all_employers: null,
        all_sponsorships: null,
        all_programs: null,
      },
      uploadPercent: { training_providers: 0, all_jobs: 0, all_employers: 0, all_sponsorships: 0, all_programs: 0 },
      uploadCancelTokenSource: {
        training_providers: null,
        all_jobs: null,
        all_employers: null,
        all_sponsorships: null,
        all_programs: null,
      },
      USER_ROLES,
      USER_ROLE_TYPES,
    };
  },
  validations() {
    return {
      org: {
        required: requiredIf(() => get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN),
      },

      pages_config: {
        required,
        $each: {
          title: { required },

          desc: { required },
          img: { required },
        },
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isFormLoading() {
      return this.isLoading || this.areOrgsLoading;
    },
  },

  methods: {
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('organization', [
      'getAllOrganizations',
      'getOrganization',
      'createPagesConfig',
      'getPagesConfig',
      'getOrgImageUploadPresignedUrl',
    ]),
    get,
    isEmpty,
    setFeaturesImage(file, image, index) {
      this.uploadFile[image] = file;
      this.uploadImage(image, index);
    },

    resetData() {
      this.pages_config = [
        {
          abbr: 'all_jobs',
          title: '',
          desc: '',
          img: '',
          isVisible: true,
        },
        {
          abbr: 'all_employers',
          title: '',
          desc: '',
          img: '',
          isVisible: true,
        },
        {
          abbr: 'all_sponsorships',
          title: '',
          desc: '',
          img: '',
          isVisible: true,
        },
        {
          abbr: 'all_programs',
          title: '',
          desc: '',
          img: '',
          isVisible: true,
        },
        {
          abbr: 'training_providers',
          title: '',
          desc: '',
          img: '',
          isVisible: true,
        },
        {
          abbr: 'upcoming_classes',
          title: '',
          desc: '',
          img: '',
          isVisible: true,
        },
        {
          abbr: 'simulations',
          title: '',
          desc: '',
          img: '',
          isVisible: true,
        },
      ];
    },
    prefillLogo(orgId) {
      this.resetData();
      this.fetchBasicConfig(orgId);
    },
    async fetchOrgs() {
      this.areOrgsLoading = true;
      const response = await this.getAllOrganizations({});
      this.allOrgs = response.data;

      this.areOrgsLoading = false;
    },
    async fetchBasicConfig(org_id = null) {
      let resp;
      if (org_id) {
        resp = await this.getPagesConfig({
          org_id: org_id,
        });
      } else {
        resp = await this.getPagesConfig({});
      }
      this.pages_config = resp.pages_config.map((page) => ({
        ...page,
        isVisible: true,
      }));
    },
    async uploadImage(uploadType, index) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isImgUploading[uploadType] = true;
      try {
        const urlResp = await this.getOrgImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type,
          upload_type: uploadType,
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token,
          },
        });

        this.pages_config[index].img = urlResp.upload_url.split('?')[0];
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;
      this.isImgUploading[uploadType] = false;
    },

    async onSubmit() {
      let dataValidation = { isValid: false, formData: {} };
      if (this.$refs.aboutForm) {
        dataValidation = this.$refs.aboutForm[0].validateFormData();
      }
      if (!dataValidation.isValid) {
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
        return;
      }

      const aboutData = {
        show_features: dataValidation.formData.about_section,
        features: dataValidation.formData.features,
        show_section_two: dataValidation.formData.team_section,
        section_two_title: dataValidation.formData.team_title,
        section_two_desc: dataValidation.formData.team_desc,
        section_two: dataValidation.formData.team,
      };

      this.isLoading = true;

      let configArr = this.pages_config.map((pg) => ({
        abbr: pg.abbr,
        title: pg.title,
        desc: pg.desc,
        img: pg.img,
        ...(pg.abbr === 'about' && aboutData),
      }));
      try {
        const data = {
          pages_config: configArr,
          organization:
            get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN
              ? this.org
              : this.getLoggedInUser.linked_entity.id,
        };
        await this.createPagesConfig({
          ...data,
        });
        this.makeToast({ variant: 'success', msg: 'Settings Configured!' });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },
  },

  async mounted() {
    this.isLoading = true;
    if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
      this.fetchOrgs();
    }
    if (get(this.getLoggedInUser, 'role') === USER_ROLES.ORGANIZATION) {
      (await this.getOrganization(this.getLoggedInUser.linked_entity.id)).data;
    }
    try {
      if (get(this.getLoggedInUser, 'role') === USER_ROLES.ORGANIZATION) {
        this.fetchBasicConfig();
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
    }
    this.isLoading = false;
  },
};
</script>
