<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator :title="title" />

        <div class="card mb-0">
          <!-- System Config Table -->
          <b-table
            :fields="tableColumns"
            :items="systemConfigs"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <a
                @click.prevent="openSystemConfigModal(data.item)"
                class="svg-icon mr-2 clickable-item"
                v-b-popover.hover.left
                :title="$t('generalMsgs.edit')"
              >
                <md-icon class="text-info">edit</md-icon>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalSystemConfigs"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="system-config-table"
            />
          </div>
        </div>
      </div>
    </div>
    <system-config-modal
      :show-modal="showSystemConfigModal"
      @close="hideSystemConfigModal"
      :systemConfig="systemConfig"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { debounce } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';
import SystemConfigModal from './SystemConfigModal.vue';

export default {
  components: { MdIcon, PageHeader, PageSeparator, Pagination, SystemConfigModal },
  extends: Page,

  data() {
    return {
      title: 'Manage System Configuration',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalSystemConfigs: 0,
      systemConfigs: [],
      showSystemConfigModal: false,
      searchTerm: '',
      systemConfig: {},
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.$t('System Config'), active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'title', label: 'Title' },
        { key: 'description', label: 'Description' },
        { key: 'value', label: 'Value' },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('credit', ['getAllSystemConfigs']),
    openSystemConfigModal(data) {
      this.systemConfig = data;
      this.showSystemConfigModal = true;
    },
    hideSystemConfigModal() {
      this.showSystemConfigModal = false;
      this.fetchSystemConfigs();
    },

    async fetchSystemConfigs(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllSystemConfigs({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,

        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });

      this.systemConfigs = response.data.results;
      this.currentPage = pageNum;
      this.totalSystemConfigs = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchSystemConfigs(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchSystemConfigs();
    },

    onSearch() {
      this.debouncedSearchPrograms(this);
    },

    debouncedSearchPrograms: debounce((vm) => {
      vm.fetchSystemConfigs();
    }, 500),
  },

  async mounted() {
    this.fetchSystemConfigs();
  },
};
</script>
