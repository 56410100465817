var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.containerClass},[_c('page-header',{staticClass:"mb-32pt",attrs:{"title":this.$route.params.id ? 'Edit Feature Update' : 'Post Feature Update',"breadcrumb":_vm.breadcrumb,"container-class":null}}),_c('page-separator',{attrs:{"title":"Feature Update Details"}}),_c('b-form',{staticClass:"col-md-12 px-0 page-section pt-0",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Target Portal","label-for":"target-portal","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",attrs:{"id":"target-portal","label":"text","reduce":function (feature) { return feature.value; },"placeholder":"Select portal","options":_vm.updateTargetOptions,"state":!_vm.$v.updateTarget.required && _vm.$v.updateTarget.$dirty ? false : null},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search"},'input',attributes,false),events))]}}]),model:{value:(_vm.updateTarget),callback:function ($$v) {_vm.updateTarget=$$v},expression:"updateTarget"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.updateTarget.required && _vm.$v.updateTarget.$dirty ? false : null}},[_vm._v("This field is required.")])],1),_c('b-form-group',{attrs:{"label":"Title","label-for":"title","label-class":"form-label"}},[_c('b-form-input',{attrs:{"value":"dd","id":"title","placeholder":"Enter title","required":""},model:{value:(_vm.updateTitle),callback:function ($$v) {_vm.updateTitle=$$v},expression:"updateTitle"}})],1),(!_vm.$route.params.id)?_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"same-title","name":"same-title","value":"yes","unchecked-value":"no"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Keep notification title same as feature title ")])],1):_vm._e(),(_vm.status === 'no')?_c('b-form-group',{attrs:{"label-for":"notification-title","label-class":"form-label"}},[_c('label',{staticClass:"form-label",attrs:{"for":"notification-title"}},[_vm._v(" Notification Title ")]),_c('b-form-input',{attrs:{"id":"notification-title","placeholder":"Enter notification's title","required":_vm.status === 'no' && !_vm.$route.params.id,"disabled":_vm.$route.params.id},model:{value:(_vm.notificationTitle),callback:function ($$v) {_vm.notificationTitle=$$v},expression:"notificationTitle"}})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Summary","label-for":"text","label-class":"form-label"}},[_c('editor',{attrs:{"init":{
          height: 400,
          menubar: 'edit view insert format tools table help',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | removeformat | help'
        },"placeholder":"Enter summary"},model:{value:(_vm.summary),callback:function ($$v) {_vm.summary=$$v},expression:"summary"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.summary.required && _vm.$v.summary.$dirty ? false : null}},[_vm._v("This field is required.")])],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"details-req","name":"details-req","value":"yes","unchecked-value":"no"},model:{value:(_vm.detailsReq),callback:function ($$v) {_vm.detailsReq=$$v},expression:"detailsReq"}},[_vm._v(" Details required? ")])],1),(_vm.detailsReq === 'yes')?_c('b-form-group',{attrs:{"label":"Description","label-for":"description","label-class":"form-label"}},[_c('editor',{attrs:{"init":{
          height: 400,
          menubar: 'edit view insert format tools table help',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | removeformat | help'
        },"placeholder":"Enter details"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.description.required && _vm.$v.description.$dirty ? false : null}},[_vm._v("This field is required.")])],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Entities","label-for":"entity_type","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",attrs:{"id":"states","label":"text","reduce":function (entity) { return entity.value; },"placeholder":"Select site","options":_vm.entityTypeOptions,"multiple":"","state":!_vm.$v.entityType.required && _vm.$v.entityType.$dirty ? false : null},scopedSlots:_vm._u([{key:"search",fn:function(ref){
        var attributes = ref.attributes;
        var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search"},'input',attributes,false),events))]}}]),model:{value:(_vm.entityType),callback:function ($$v) {_vm.entityType=$$v},expression:"entityType"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.entityType.required && _vm.$v.entityType.$dirty ? false : null}},[_vm._v("This field is required.")])],1),_c('b-btn',{staticClass:"btn-normal",staticStyle:{"width":"150px"},attrs:{"variant":"primary","disabled":_vm.isFormLoading,"type":"submit"}},[(_vm.isLoading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('span',[_vm._v(_vm._s(_vm.$route.params.id ? 'Update' : 'Post'))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }